import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Console } from 'console';
import { ToastService } from 'src/app/core/services/toast.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SchedulerFormComponent } from 'src/app/schedulerComp/components/scheduler-form/scheduler-form.component'
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from 'src/app/core/services/title.service';

import { Subscription } from 'rxjs';
import { WorkflowService } from '../../services/workflow.service';
import { WorkflowModel, WorkflowJobDetails, IngestJob, CustomJobs } from '../../models/workflow.model';

import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from 'src/app/user/services/user.service';
import { CookieService } from "ngx-cookie-service";
import { SchedulerWorkFlow } from './scheduler.model';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {
  //@ViewChild(SchedulerFormComponent, { read: false, static: false })
  workflowDropDown: any[] = [];
  workflowData: any[] = [];
  workflowId: number;
  workflowName: string;
  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  //editSchedulerValue: any = null;
  schedulerWorkFlow: SchedulerWorkFlow;
  viewMode: boolean = false;
  editMode: boolean = false;
  rolePermission:any;
  clientData:any=null;

  constructor(private workflowService: WorkflowService, private toast: ToastService,
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private loader: LoaderService,
    private router: Router,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef, private fileService: FileService,private clientService:ClientService) {

    this.headerForm = new FormGroup({
      client: new FormControl(null),
      workflow: new FormControl(null),

    });

  }
  ngOnInit() {
   this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllWorkflow() {
    // console.log("getting called");
    this.workflowDropDown = [];

    // this.setupJobForm.controls.workflow.reset();
    const requestModel = {
      "clientId": this.headerForm.controls.client.value.key
    }

    this.workflowService.getAllWorkflow(requestModel)
      .subscribe(
        (data) => {
          this.workflowData = data;

          this.workflowData.forEach(item => {
            this.workflowDropDown.push({ key: item.workflowId, value: item.workflowName })
          })

        },
        (err) => {

          this.toast.showError('error', err.error.message);
        }
      )

  }

  getWorkflow() {
    this.workflowId = this.headerForm.controls.workflow.value.key
    console.log("id  ", this.workflowId)
    this.workflowName = this.headerForm.controls.workflow.value.value
    console.log("name  ", this.workflowName)
  }



  onClientChange(event) {
    this.headerForm.controls.workflow.reset();
    this.getAllWorkflow();
  }


  addScheduler(data: any) {

    console.log("this.workflowId ",this.headerForm.controls.workflow.value)
    if(this.workflowId==null || this.workflowId==undefined || this.workflowId==0){
      this.toast.showWarn('Select Workflow is  mandatory ','error');
      return;
    }


    if (data.scheduleType === 'Not Recurring') {
      data.startDate = data.oneTimeDate;
    }
    const schedulerModel = {
      schedulerId: this.schedulerWorkFlow !== null ? this.schedulerWorkFlow?.schedulerId : 0,
      workflowId: this.workflowId,
      schedulerName: data.schedulerName,
      scheduleType: data.scheduleType,
      freequency: data.occurs,
      dailyFreequencyOccurOnlyOnce: data.dailyFrequencyOccurOnlyOnce,

      dailyFreequencyCount: data.dailyFrequencyCount,
      dailyFreequency: data.dailyFrequency,
      dailyFreequencyStartTime: data.dailyFrequencyStartTime,
      dailyFreequencyEndTime: data.dailyFrequencyEndTime,
      weekDaysList: data.onWeeks,
      weekDays: null,

      weekCount: data.recursEveryWeeks,

      monthlyDayCount: data.monthlyDayCount,
      monthlyMonthNumber: data.monthlyMonthNumber,
      monthlyDayPosition: data.monthlyDayPosition,
      monthlyWeekDay: data.monthlyWeekDay,

      startDate: data.startDate,
      endDate: data.endDate,
      isActive: true,
      dailyDayCount: data.recursEveryDays,
      clientId:this.headerForm.controls.client.value.key,
      endDateEnable:data.endDateEnable,

    }

    console.log("extract inputs ", schedulerModel)
    this.workflowService
      .saveScheduler(schedulerModel)
      .subscribe((data) => {
        // this.userSelectorComponent.userForm.reset();
        this.schedulerWorkFlow = null;
        
        this.toast.showSuccess('saved successfully', 'success');
        this.router.navigate(['schedulerlist']);
      },
        (err) => {
          this.toast.showError(err.error.error, "error");
        }
      );

  }



  navigateToSchedulerList() {
    let page = 'schedulerlist';
    this.router.navigate([page], {
      queryParams: {},
    });
    // this.cookieService.set('schedulerId','0');
  }


  getCookies() {
    const sheduleId = this.cookieService.get('schedulerId')
    const viewModeString = this.cookieService.get('viewMode')
    const editModeString = this.cookieService.get('editMode')
    if (viewModeString === 'true') {
      this.viewMode = true;
    }
    if (editModeString === 'true') {
      this.editMode = true;
    }
    console.log("sheduleIdcookie ", sheduleId);
    if (sheduleId) {
      this.workflowService.getSchedulerById(sheduleId).subscribe(
        (data: SchedulerWorkFlow) => {
          //this.editSchedulerValue = data;
          this.schedulerWorkFlow = data;
          // this.getClient(data.clientId);
          // console.log(" client data",  this.headerForm.controls.client.value);
          this.workflowId=data.workflowId;
          this.cdr.detectChanges();
          console.log(" this.SchedulerWorkFlow ", this.schedulerWorkFlow);
        },
        (err) => {
          this.toast.showError(err.error.message, "error");
        }
      );
    }
  }



  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.setTitle();
      this.getCookies();
      this.getPreviousState();
      this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
        par => {
          if (par.has("clientId") && par.has("clientName")) {
            this.headerForm.get("client").setValue({
              key: par.get("clientId"),
              value: par.get("clientName")
            });
            this.getAllWorkflow();
          }
          if (par.has("workflowId") && par.has("workflowName")) {
            this.headerForm.get("workflow").setValue({
              key: par.get("workflowId"),
              value: par.get("workflowName")
            });
            //this.getAllWorkflow();
          }
        }
      );
}

getPreviousState() {
  const clientIdC=this.cookieService.get('clientId');
  const clientNameC=this.cookieService.get('clientName');
  //console.log("cookies check ",this.cookieService.get('toDaaaate')===null)
  //console.log("cookies check ",this.cookieService.get('toDaaaate'))
  // console.log(clientIdC);
  // console.log(clientNameC);
  // console.log("from date ",fromDatec);
  // console.log("todate ",toDatec);
  if(clientIdC!==undefined && clientIdC!==null && clientIdC!==''){
    this.headerForm.get("client").patchValue({
             key: clientIdC,
             value: clientNameC,
             });
             this.getAllWorkflow();
  }
}

}
