import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { UserService } from '../user/services/user.service';

import {UserSelectorComponent } from './components/user-selector/user-selector.component';
import { UserTableComponent } from './components/user-table/user-table.component';
import { ChangelogTableComponent } from './components/changelog-table/changelog-table.component';
import { MultiSelectModule } from "primeng/multiselect";

@NgModule({
  declarations: [
    
    UserSelectorComponent,
    UserTableComponent,
    ChangelogTableComponent
  
  ],
  imports: [SharedModule,MultiSelectModule],
  exports: [
    UserTableComponent,
    UserSelectorComponent,
    ChangelogTableComponent
   
  ],
  providers: [UserService]
})
export class UserCompModule {}
