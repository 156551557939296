import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { LookupColumnListModel } from 'src/app/dashboard/models/lookupColumn.model';


@Component({
  selector: 'app-lookup-column-list',
  templateUrl: './lookup-column-list.component.html',
  styleUrls: ['./lookup-column-list.component.scss']
})
export class LookupColumnListComponent implements OnInit {

  @Input() data:LookupColumnListModel[] = [];
  @Input() hidebutton:boolean;
  @Output() editLookupCol=new EventEmitter();
  @Output() deleteLookupCol=new EventEmitter();
  @Input() permission:any;
  
  visible:boolean=false;
  dataValue:any;

  cols: { field: string; header: string }[];
  constructor() { 
    this.cols = [
      // { field: "primaryId", header: "Id" },
      { field: "field1", header: "Field-1 Values" },
      { field: "field2", header: "Field-2 Values" },
      { field: "field3", header: "Field-3 Values" },
      { field: "createdAt", header: "Created At" },
      { field: "modifiedAt", header: "Modified At" }
    ];
  }

  ngOnInit(): void {
  }

  editLookupColumn(data){
    console.log("hello listing",data);
    this.editLookupCol.emit(data);

  }

  deleteLookupColumn(data){
   // console.log('table data ',data)
    this.deleteLookupCol.emit(data);
  }

  
  hidePopup(e){
    this.visible=false;
    this.dataValue=null;

  }

  showPopup(data){
    this.dataValue=data;
    this.visible=true;
  }
 
  actionDo(str){
    //console.log("yes delete do "+this.dataValue);
    this.deleteLookupColumn(this.dataValue);
    this.dataValue=null;
    this.visible=false;
  }

}
