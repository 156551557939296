import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges , OnChanges  } from '@angular/core';
import { FormGroup,FormBuilder, FormControl,Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';


@Component({
  selector: 'app-notification-selector',
  templateUrl: './notification-selector.component.html',
  styleUrls: ['./notification-selector.component.scss']
})
export class NotificationSelectorComponent implements OnInit,OnChanges{
  
  notificationForm: FormGroup=null;
  @Output() saveNotification = new EventEmitter();
  @Output() resetNotification = new EventEmitter();
  @Input()  editNotificationList;

  @Input() rolePermission;
  

  constructor(private toast: ToastService) {

    this.notificationForm= new FormGroup({

      notificationName: new FormControl(null,Validators.required),
      statusMap: new FormControl(null,Validators.required),
    })
   }

  ngOnInit(): void {

    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.notificationForm.get('statusMap').setValue(defaultMap);
  }




  ngOnChanges(changes: SimpleChanges) {

    if (changes && changes.editNotificationList && changes.editNotificationList.currentValue) {
      let currentChangeValue = changes.editNotificationList.currentValue;




      const statusActive = {
        key: "Active",
        value:"Active",
      };
      const statusInactive = {
        key: "Inactive",
        value:"Inactive",
      };
   
        //console.log("edit user data"+this.editUser)
       if(currentChangeValue.status==0){
        this.notificationForm.patchValue({
          
          notificationName: currentChangeValue.notificationName,
         
          statusMap:statusInactive,
        
        });
       }else{
        this.notificationForm.patchValue({
        
          notificationName: currentChangeValue.notificationName,
        
          statusMap:statusActive,
         
        });
      }




  }


}


  get status() {
    const status = [
      "Active",
      "Inactive",
    ];
   return status.map((t) => ({ key: t, value: t }));
  }


  saveNotificationValues(){

    if(this.notificationForm.controls.notificationName.value==null || this.notificationForm.controls.notificationName.value==undefined || this.notificationForm.controls.notificationName.value==""){
      this.toast.showWarn('Enter Notification Name ','error');
      return;
    }

    if(this.notificationForm.controls.statusMap.value==null || this.notificationForm.controls.notificationName.value==undefined || this.notificationForm.controls.notificationName.value==""){
      this.toast.showWarn('Select Status ','error');
      return;
    }

    console.log("saving values ",this.notificationForm.value);
    this.saveNotification.emit(this.notificationForm.value);

  }

  resetForm(){

    this.notificationForm.reset();
  }

  resetFormData(){
    this.notificationForm.reset();
    // const statusReset = {
    //   key: "null",
    //   value:"null",
    // };
    // this.notificationForm.patchValue({
    //   statusMap:statusReset

    // })

    this.resetNotification.emit("reset");
  }

}
