import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { JobListSelectorComponent } from 'src/app/workflows/components/job-list-selector/job-list-selector.component';
import { WorkflowIngestCustomListModel } from '../../models/workflow.model';
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { WorkflowService } from '../../services/workflow.service';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';

@Component({
  selector: 'app-workflow-job-list',
  templateUrl: './workflow-job-list.component.html',
  styleUrls: ['./workflow-job-list.component.scss']
})
export class WorkflowJobListComponent implements OnInit {

  @ViewChild(JobListSelectorComponent, { read: false, static: false })
  jobListSelectorComponent:JobListSelectorComponent;


  activeRoute$: Subscription;
  jobListForm: FormGroup = null;
  jobTypeValue:string=null;
  clientId:any=null;
  workflowIngestCustomListModel:WorkflowIngestCustomListModel[]=[];
  eidtWorkflowIngestCustomListModel:WorkflowIngestCustomListModel=null;
  clientMasterList:any;
  rolePermission:any;

  constructor(private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private workflowService: WorkflowService,
    private clientFileMasterService:ClientFileMasterService,
    private toast:ToastService,
    private datePipe:DatePipe,
    private router: Router,
    private storePermission: Store<{ role: any }>, private fileService: FileService
    ) { 
    this.jobListForm=new FormGroup({    
      client:new FormControl(null),
      jobTypeMap:new FormControl(null)
    });
  }

  jobTypeList=[
    {
      "key":"Ingest",
      "value":"Ingest"
    },
    {
      "key":"Custom",
      "value":"Custom"
    },
  ]
  ngOnInit(): void {
    this.buttonShowHidePermission();
   
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllJobs(e){
    this.getAllClientMasterData(e.key);
    this.getAllJobsList("data");
    this.resetData();
   
  }

  getSelected(e){
      console.log(e);
      this.jobTypeValue=e.key;
      this.getAllJobsList("data");
      this.resetData();
  }

  getAllJobsList(data){
    this.clientId=this.jobListForm.controls.client.value.key;
    
    const requestModel={
      "mappingType":this.jobListForm.controls.jobTypeMap.value.key,
      "clientId":this.clientId
    }
    console.log(data, requestModel);
    this.workflowService.getAllJobList(requestModel)
    .subscribe(
      res=>{
       
        this.workflowIngestCustomListModel=res;
        this.workflowIngestCustomListModel.forEach(item=>{
          
          item.createdAt=this.datePipe.transform(item.createdAt,"MM-dd-yyyy")
        }
        )
        //console.log("data",res);
      },
      error=>{
        this.toast.showError(error.error.message, "error"); 
      }
    )
  }

  getAllClientMasterData(clientId){
    console.log("clientFile master");
    this.clientMasterList=[];
    this.clientFileMasterService.getClientFileMasterListByClient(clientId).subscribe(
      (data) => {
        const clientMasterData=data;
        for(var a=0;a<clientMasterData.length;a++){
         // console.log("type "+clientApplicationFileType[a].type);
          this.clientMasterList.push({ key: clientMasterData[a].clientfilemasterId, value: clientMasterData[a].name });
        }
        console.log(this.clientMasterList);
      },
      (err) => {
      //  this.toast.showSuccess("Status Not Changed", "error");
      this.toast.showError(err.error.message, "error");
      }
    
    );
  
  }

  saveJob(data){

    const requestData={
      "clientId":this.jobListForm.controls.client.value.key,
      "jobType":this.jobListForm.controls.jobTypeMap.value.key,
      "jobName":data.jobName,
      "description":data.description,
      "masterFileId":data.masterFileId,
      "primaryId":this.eidtWorkflowIngestCustomListModel!==null?this.eidtWorkflowIngestCustomListModel.primaryId:0,
      "apiMethod":data.apiMethod
    }
  
    this.workflowService.saveIngestCustomJob(requestData)
    .subscribe(
      res=>{
      this.toast.showSuccess('success','Job saved')
      this.getAllJobsList("data");
      this.resetData();
      },
      err=>{
        this.toast.showError('error',err.error.message)
        console.log(err);
      }
    )

  }

  editJob(data){
    console.log("editjob ",data);
    this.getIngestCustomJobById(data);
  }

  getIngestCustomJobById(data){

    console.log("data ",data);
    const requestData={
      "primaryId":data.primaryId,
      "jobType":this.jobListForm.controls.jobTypeMap.value.key,
       "clientId":this.jobListForm.controls.client.value.key
    }

    this.workflowService.getIngestCustomJobById(requestData)
    .subscribe(
      res=>{
        this.eidtWorkflowIngestCustomListModel=res;
        console.log(this.eidtWorkflowIngestCustomListModel);
      },
      err=>{

      }
    )

  }

  resetData(){
    this.eidtWorkflowIngestCustomListModel=null;
    this.jobListSelectorComponent.jobSelectorForm.reset();

  }

  deleteJob(data){

    const requestData={
      "primaryId":data.primaryId,
      "jobType":this.jobListForm.controls.jobTypeMap.value.key
    }


    this.workflowService.deleteIngestCustomJobById(requestData)
    .subscribe(
      res=>{
        this.toast.showSuccess('success','Job deleted');
        this.getAllJobsList("data");
        this.resetData();
      },
      err=>{
        this.toast.showError('error',err.error.message);
      }
    )

  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.setTitle();  
    }

}
