import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDate } from 'src/app/utils/dates';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidationRuleService {

  constructor(private http: HttpClient) { }

  getAllFields(data) {
    return this.http.post<any[]>(environment.baseUrl + "/apis/validateRule/getAllFields",data);
    }
  
  saveValidationRuleData(data){
      return this.http.post<any[]>(environment.baseUrl + "/apis/validateRule/saveValidationRule",data);
    }
  
  getAllValidationRules(data) {
      return this.http.post<any[]>(environment.baseUrl + "/apis/validateRule/getAllValidationRulesByClient",data);
      }

  applyValidationRule(data){
    const formData = new FormData();
    formData.append('client_id',data.client_id);
    formData.append('asofdate',data.asofdate);
    formData.append('datatype_id',data.datatype_id);
    formData.append('id',data.id);;
    formData.append("status",data.status);
    return this.http.post<any[]>(environment.baseUrl +  "/api/etl/validation/rule/", formData);
      }

      getValidationRuleById(ruleId){
        return this.http.get<[]>(environment.baseUrl +  "/apis/validateRule/getValidationRulesById/"+ruleId);   
      }

      updateValidationRuleStatus(ruleId){
        return this.http.post<[]>(environment.baseUrl +  "/apis/validateRule/updateValidationRuleStatus",ruleId);   
      }

      getLogDataByRuleId(ruleId){
        return this.http.get<[]>(environment.baseUrl +  "/apis/validateRule/getValidationRuleLogs/" +ruleId);   
      }
      // applyAllValidationRule(data){
      //   const formData = new FormData();
      //   formData.append('client_id',data.client_id);
      //   formData.append('asofdate',data.asofdate);
      //   formData.append('datatype_id',data.datatype_id);
      //   formData.append('ruleids',data.ruleId);
      //   return this.http.post<any[]>(environment.baseUrl +  "/api/etl/validation/rule/", formData);
      //     }
  
    
}
