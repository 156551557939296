import { formatDate, getCurrentDate } from 'src/app/utils/dates';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ToastService } from 'src/app/core/services/toast.service';
import { currentMonthRange } from 'src/app/utils/dates';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ClientPortalService } from '../../services/client-portal.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { ClientService } from '../../services/client.service';
import {isEmpty,trim} from 'lodash';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-client-portal-data',
  templateUrl: './client-portal-data.component.html',
  styleUrls: ['./client-portal-data.component.scss']
})
export class ClientPortalDataComponent implements OnInit {
clientPortalList:any;
user:any;
showClient:boolean;
headerForm:FormGroup;
fromDt:any;
toDt:any;
dataTransferModel:DataTransferModel;
activeRoute$: Subscription;
clientData:any;
clientId:number;
clientIdVal:any;
cols: { field: string; header: string }[];

addHeader:string="Add";
deleteHeader:string="Remove Layout";
visible:boolean=false;

popUpForm:FormGroup;
dropDownList:any[]=[];
action:string=null;
clientFileId:any=null;
clientFileUpdate:any=null;
deleteVisible:boolean=false;
rolePermission:any;

constructor(private clientPortalService: ClientPortalService,
            private toast:ToastService,
            private store: Store<{ user: any }>,
            private activeRoute: ActivatedRoute, 
            private titleService:TitleService,
            private loader:LoaderService,
            private clientService:ClientService,
            private fileService:FileService,
            public observalbleDataService: ObservalbleDataService,
            private cookieService: CookieService,
            private storePermission: Store<{ role: any }>
           
            ) {
         
      this.headerForm = new FormGroup({
        client: new FormControl(null),
        toDate: new FormControl(null),
        fromDate: new FormControl(null)
      });

      this.popUpForm = new FormGroup({
        fileData: new FormControl(null),
        deleteMaster:new FormControl(false),
       
      });
     }



  ngOnInit(): void {
    
     this.buttonShowHidePermission();

     //this.storePermission.select(x => this.rolePermission = x).subscribe();
    }

    clientSelected(event){
      console.log("event :",event);
      this.clientId=event.key;
      this.cookieService.set('clientId',event.key);
      this.cookieService.set('clientName',event.value);
      this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
     // this.maintainClientState(event,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }

  getAllclientPortalData(clientId,fromDate,toDate){
    const ClientPortal={
      "clientId":clientId,
      "fromDate":formatDate(fromDate),
      "toDate":formatDate(toDate)
    }
    this.loader.showLoader();
   // console.log("Called");
this.clientPortalService.getAllclientPortalData(ClientPortal) 
.subscribe((data) => {
  //console.log("client-portal data "+data)
  this.clientPortalList=data;
  this.loader.hideLoader();
  },
(err) =>{
  this.toast.showError(err.error.message, "error");
  this.loader.hideLoader();
} 
); 
}

setTitle() {
  this.activeRoute$ = this.activeRoute.data.subscribe(
    (data) => this.titleService.setTitle(data["title"]),
    (err) => console.log("error", err)
  );
}

changeToDate(e){
  this.headerForm.controls.toDate.setValue(e);
  let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  if(validate===true){
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
    this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  }
  if(validate===false){
    this.toast.showWarn('error','date range incorrect')
    
  }
  //console.log(e,'  todate');
  //this.maintainStateOnDateChange(e,'toDateChange');
}

changeFromDate(e){
  console.log("Hello Shail" , e);
  this.headerForm.controls.fromDate.setValue(e);
  let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  if(validate===true){
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
    this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  }
  if(validate===false){
    this.toast.showWarn('error','date range incorrect')
    
  }
  //this.maintainStateOnDateChange(e,'fromDateChange');
}

validationOnDate(fromdate,todate){
  if(fromdate && todate){
  if(new Date(fromdate)>new Date(todate)){
  
      return false;
    }
    return true;
    
  }else{
    return false;
  }
}

loadDate(){
  let currentDateRange=currentMonthRange();
  //console.log(currentDateRange,'  current date range');
  this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
  this.headerForm.controls.toDate.setValue(currentDateRange[1]);
   //console.log(this.headerForm.controls.fromDate.value,'  from Date range');
   //console.log(this.headerForm.controls.toDate.value,'  To Date date range');
   this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
 
}

getClient(clientId){
  this.clientService.getClientsById(clientId).subscribe((data) => {
    this.clientData=data;
    },
  (err) =>{
   // this.toast.showError(err.error.message, "error");
   } 
  ); 
  }

  addLayout(data){
     console.log("main layout",data);
     this.clientFileId=null;
     this.action=null;
     this.addHeader="Add Layout : "+data.filename;
     this.action='Layout';
     this.clientFileId=data.clientFileId;
     this.clientFileUpdate=data;
     this.getAllClientLayoutFiles(data);
     
  }

  addMaster(data){
    console.log(data)
    console.log("main layout",data);
     this.clientFileId=null;
     this.action=null;
     this.addHeader="Add Master : "+data.filename;
     this.action='Master';
     this.clientFileId=data.clientFileId;
     this.clientFileUpdate=data;
    this.getAllClientMasterFile(data);
  }

  updateCall(data){

  }

  doAction(){
     this.visible=false;
     this.clientFileUpdate=null;
     this.action=null;
     this.deleteVisible=false;
     this.popUpForm.reset();
  }

  getAllClientLayoutFiles(data){
     this.dropDownList=[];
    this.fileService.getFilesByClientIdModel(this.headerForm.controls.client.value.key)
    .subscribe(
      data=>{
        console.log(data);
        data.forEach(item=>
          this.dropDownList.push({'key':item.clientApplicationFileId,'value':item.fileName})
         
        )
        this.visible=true;
        console.log("dropdownlost",this.dropDownList);
      },
      error => {
        this.toast.showError(error.error.message, "error");
      }
    )

  }

  getAllClientMasterFile(data){
    this.dropDownList=[];
    this.fileService.getClientFileMasterListByClient(this.headerForm.controls.client.value.key)
    .subscribe(
      data=>{
        console.log(data);
        data.forEach(item=>
          this.dropDownList.push({'key':item.clientfilemasterId,'value':item.name})
         
        )
        this.visible=true;

      },
      error=>{
        this.toast.showError(error.error.message, "error"); 
      }
    )

  }



  updateAddClientFile(){
    //console.log("error check ",this.popUpForm.controls.filedata);
   // console.log("error check ",this.clientFileUpdate)
  
    try{
   
     const fileId=this.popUpForm.controls.fileData.value.key;
   }catch{     
       this.toast.showInfo('error','select file');
       return;
   }

   
    if(this.action==='Layout'){
      const requestModel={
        "clientApplicationFileId":this.popUpForm.controls.fileData.value.key,
        "clientFileId":this.clientFileUpdate.clientFileId,
        "fileName":this.clientFileUpdate.filename,
        "clientId":this.headerForm.controls.client.value.key,
        "fromDate":this.clientFileUpdate.asOfDate,
        "clientPortalId":this.clientFileUpdate.clientPortalId
      }

      this.clientPortalService.updateClientFile(requestModel)
      .subscribe(
        data=>{
          this.toast.showSuccess('success','client file updated');
          this.visible=false;
          this.clientFileUpdate=null;
          this.action=null;
          this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
      )
    }
    if(this.action==='Master'){
      const requestModel={
        "clientFileMasterId":this.popUpForm.controls.fileData.value.key,
        "clientFileId":this.clientFileUpdate.clientFileId,
        "fileName":this.clientFileUpdate.filename,
        "clientId":this.headerForm.controls.client.value.key,
        "fromDate":this.clientFileUpdate.asOfDate,
        "clientPortalId":this.clientFileUpdate.clientPortalId
      }

      this.clientPortalService.updateClientFilMaster(requestModel)
      .subscribe(
        data=>{
          this.toast.showSuccess('success','client file updated');
          this.visible=false;
          this.clientFileUpdate=null;
          this.action=null;
          this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
      )
    }
       
  }


  getColsData(isClient){
    if(isClient){
      this.cols = [
      { field: "filename", header: "File Name" },
      { field: "asOfDate", header: "AsOfDate" },
      { field: "uploadMessage", header: "Upload Status" },
      { field: "userName", header: "Created By" },
      { field: "createdAt", header: "Created At" }
    ];
    }else{
      this.cols = [
        { field: "filename", header: "File Name" },
        { field: "clientApplicationFile", header: "Layout File" },
        { field: "clientFileMaster", header: "Master File" },
        { field: "asOfDate", header: "AsOfDate" },
        { field: "uploadMessage", header: "Upload Status" },
        { field: "userName", header: "Created By" },
        { field: "createdAt", header: "Created At" }
      ];
    }
    
  }

  deleteFiles(data){
    //console.log("delete data :",data);
    if(data.clientFileId===0){
      this.toast.showInfo('info','no layout linked');
      return;
    }
    this.deleteHeader="Remove Layout : "+data.filename;
    this.clientFileId=data.clientFileId;
    this.clientFileUpdate=data;
    this.deleteVisible=true;
  }

  deleteFileLayout(){
    //console.log(this.clientFileId);
    //console.log(this.popUpForm.controls.deleteMaster.value);

    const requestModel={
      "clientPortalId":this.clientFileUpdate.clientPortalId,
      "clientFileId":this.clientFileId,
      "deleteLayout":this.popUpForm.controls.deleteMaster.value===true?false:true,
      "deleteMaster":this.popUpForm.controls.deleteMaster.value
    }

    this.clientPortalService.deleteFileLayout(requestModel)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','removed');
        this.doAction();
        this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);

      },
      err=>{
        this.doAction();
        this.toast.showError('error',err.error.message);
      }
    )

   

  }

  maintainClientState(client,fromDate,todate){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: todate,
      fromDate: fromDate,
      clientName: client.value,
      page: '',
      fileName:'',
      workflowName:'',
      workflowId:0,
      dataType:'',
      dataTypeId:0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  /*maintainStateOnDateChange(newDate,typeOfRequest){
    if(typeOfRequest==='fromDateChange'){
      this.dataTransferModel.fromDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
    if(typeOfRequest==='toDateChange'){
      this.dataTransferModel.toDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
  }*/


  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    console.log(clientIdC);
    console.log(clientNameC);
    console.log(fromDatec);
    console.log(toDatec);
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
      this.clientId=this.headerForm.controls.client.value.key;
    }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
       this.headerForm.controls.fromDate.setValue(frm)
       this.headerForm.controls.toDate.setValue(toDt)
    }else{
      this.loadDate();
    }
    if(!isEmpty(this.headerForm.get("client").value.key)){
    this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }

    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     this.fromDt=new Date(this.dataTransferModel.fromDate);
    //     this.toDt=new Date(this.dataTransferModel.toDate);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && isNaN(this.fromDt)===false && isNaN(this.toDt)===false) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.headerForm.patchValue({
    //         fromDate: this.fromDt,
    //         toDate: this.toDt,
    //       });
    //       this.getAllclientPortalData(this.dataTransferModel.clientId,this.fromDt,this.toDt);
    //     }

    //     else if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && (isNaN(this.fromDt)===true || isNaN(this.toDt)===true)) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.loadDate();
    //       this.getAllclientPortalData(this.dataTransferModel.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    //     }

    //     else{
    //       this.loadDate();
    //     }
        
    //   });
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};


    callNgOnInitMethods(){
  

      this.getPreviousState();
      this.setTitle();
      //this.loadDate();
     
      this.store.select('user').pipe(take(1))
        .subscribe(u => this.user = u)
       // this.getAllclientPortalData(this.user.clientId);
       if(this.user.clientId!==0 && !this.user.clientId!==null){
        this.showClient=true;
        this.getColsData(this.showClient);
        this.clientId=this.user.clientId;
        this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value); 
        this.getClient(this.clientId);
      }else{
        this.showClient=false;
        this.getColsData(this.showClient);
        this.clientIdVal=this.headerForm.controls.client.value.key;
        this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value); 
       if(this.clientIdVal!==null && this.clientIdVal!==""){
        this.getClient(this.clientIdVal);
       }
       }



    }


}
