import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ActionModel } from '../models/action.model';
import { RoleModel } from '../models/role.model';
import { RoleMenuType } from '../pages/role-menu-permission/role-menu';
import { ActionDataModel } from '../models/action.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }


  saveAllActions(ActionData) {
  return this.http.post<ActionModel[]>(environment.baseUrl + "/users/saveAllAssignedActions",ActionData);
  }

  saveRole(roleData){
    return this.http.post<RoleModel[]>(environment.baseUrl + "/users/saveRole",roleData);
  }


  saveMappingForRole(mappedData){
    return this.http.post<any>(environment.baseUrl + "/apis/saveRoleMapping",mappedData); 
  }


  getMappedRolesById(id){

    return this.http.get<any>(
      `${environment.baseUrl}/apis/getRoleMapping/${id}`
    );
  }

  setRoleStatus(data){
    return this.http.post<any>(environment.baseUrl + "/apis/setRoleStatus",data);

  }

  getUserData(){

    return this.http.get<any>(
      `${environment.baseUrl}/api/userData`
    );
  }

  //Start - Pradeep Yadav 
  getRolePageMenuData(clientId: number) {
    const request = {
      "clientId": clientId
    }
    return this.http.post<RoleMenuType[]>(environment.baseUrl + "/apis/menu/getRolePageMenuData", request);
  }
  //End - Pradeep Yadav 


  getAllRoles(){

    return this.http.get<any>(
      `${environment.baseUrl}/users/getAllRoles`
    );

  }


  getAllActions(){
    return this.http.get<any>(
      `${environment.baseUrl}/users/getAllActions`
    );

  }


  getAllActionList(){
   
    return this.http.get<ActionDataModel>(
      `${environment.baseUrl}/users/getAllActionList`
    );


  }

}
