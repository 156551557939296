import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsyModule } from '../formsy/formsy.module';

import { AuditHomeService } from '../dashboard/services/audit-home.service';
import { ClientFileTabComponent } from './components/client-file-tab/client-file-tab.component';
import { AuditTabsComponent } from './components/audit-tabs/audit-tabs.component';
import {AccordionModule} from 'primeng/accordion';

@NgModule({
  declarations: [
  ClientFileTabComponent,
  AuditTabsComponent
],
  imports: [SharedModule,AccordionModule],
  exports: [
    ClientFileTabComponent,
    AuditTabsComponent
   
   
  ],
  providers: [AuditHomeService]
})
export class AuditTabModule { }
