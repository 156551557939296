
import { Component, Input, OnInit } from "@angular/core";
import { RoleMenuType } from "./role-menu";


@Component({
    selector: 'app-role-menu-permission',
    templateUrl: './role-menu-permission.component.html',
    styleUrls: ['./role-menu-permission.component.scss']
})
export class RoleMenuComponent implements OnInit {
    @Input() roleMenuType: RoleMenuType[];

    ngOnInit() {
    }
}