import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { currentMonthRange } from 'src/app/utils/dates';
import { environment } from 'src/environments/environment';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { CashflowService } from '../../services/cashflow.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';

@Component({
  selector: 'app-cashflow-list',
  templateUrl: './cashflow-list.component.html',
  styleUrls: ['./cashflow-list.component.scss']
})
export class CashflowListComponent implements OnInit {
  cashFlowList:any;
  activeRoute$:Subscription;
  tableList:any;
  visible:boolean=false;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  currentFileUpload:File;
  headerForm:FormGroup;
  obj:any;
  tableData:any;
  showList:boolean=false;
  dataTransferModel: DataTransferModel;
  clientList:any=[];
  bankNumber:any="";
  rolePermission:any;


  constructor(private titleService:TitleService,private toast:ToastService,
    public observalbleDataService: ObservalbleDataService,
    private activeRoute: ActivatedRoute,
    private cashflowService:CashflowService,
    private cookieService: CookieService,
    private fileService:FileService,private storePermission: Store<{ role: any }>) { 

      this.headerForm = new FormGroup({
        tableName:new FormControl(null),
        client:new FormControl(null,[Validators.required]),
        bankNumber:new FormControl(null)
      });
    }

  ngOnInit(): void {
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllCashflowTables(){
    this.cashflowService.getAllTables() .subscribe(
      data=>{
        this.cashFlowList=data;
        if(this.tableList.length===0){
          this.toast.showInfo("info","No data present");
        }
      },
      error=>{
        this.toast.showError(error.error.message,'error');
      }
    )
  }

  doActionFile(type){
    if (type === "yes") {
      //this.modalForm.get("client_id").reset();
      this.visible=false;
      this.currentFileUpload=null;
      this.myInputVariable.nativeElement.value = "";
    }
  }
    showFileModalPopUp(rowData){
    this.visible=true;
    this.tableData=rowData;
  }

  selectFile(event){
    this.currentFileUpload = event.target.files;
  }

  saveCashflowFile(rowData){
   if(this.headerForm.valid){
    if(this.headerForm.controls.client.value.key===""){
      this.headerForm.controls.client.setValue(null);
    }
  if(this.headerForm.controls.client.value!==null){
    if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      this.toast.showWarn('no file selected','error');
      return;
    }
    
    this.cashflowService.importCashflow(this.tableData.tableName,this.currentFileUpload,this.headerForm.controls.client.value.key).subscribe(
      data=>{
         this.obj=data;
        if(this.obj.status==200){
        this.toast.showSuccess("File imported successfully", "success");
        //this.lookupForm.reset();
        this.visible=false;
        this.currentFileUpload=null;
        this.myInputVariable.nativeElement.value = "";
        this.headerForm.controls.tableName.reset;
       }else{
        this.toast.showError(this.obj.msg, "error");
       }
      },
        error=>{
          this.toast.showError(error.error.message, "error");
        }
    )
    }else{
      this.toast.showError("Please Select Client", "error");
    }
  }else{
    this.toast.showError("Please Select Client", "error");
  }
  }

    getFilesData(event){
     this.showList=true;
     this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
     this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
     this.fileService.getAllClientList().subscribe((data)=>{
      this.clientList=data;
      for(let i=0;i<this.clientList.length;i++){
        if(this.clientList[i].clientId===Number(this.headerForm.controls.client.value.key)){
         this.bankNumber=this.clientList[i].cert;
         console.log("bank number "+this.bankNumber);
          break;
        }
      }
    });
   
  }


  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
   
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               this.showList=true;     
                     
   this.getFilesData("data");
  }
   
  }

  maintainClientState(client){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  downloadFile(rowData){
   if(this.headerForm.valid){
    if(this.headerForm.controls.client.value.key===""){
      this.headerForm.controls.client.setValue(null);
    }
  if(this.headerForm.controls.client.value!==null){
        let downloadSuccess:boolean=false;
      var link=document.createElement('a');
      //link.href="https://janus-dev.vivit.co/api/etl/lookup/download/?table="+rowData+"&connection_id=2&client_id="+this.lookupForm.controls.client.value.key;
      link.href=environment.baseUrl+"/api/etl/cashflow/download/file/?tablename="+rowData.tableName+"&client_id="+this.headerForm.controls.client.value.key+"&template=0";  
      link.click();
      this.toast.showSuccess('success','Your download will begin shortly');
    }else{
      this.toast.showError("Please Select Client", "error");
    }
  }else{
    this.toast.showError("Please Select Client", "error");
  }
  }

    downloadTempFile(rowData){
      if(this.headerForm.valid){
        if(this.headerForm.controls.client.value.key===""){
          this.headerForm.controls.client.setValue(null);
        }
      if(this.headerForm.controls.client.value!==null){
      let downloadSuccess:boolean=false;
      var link=document.createElement('a');
      //link.href="https://janus-dev.vivit.co/api/etl/lookup/download/?table="+rowData+"&connection_id=2&client_id="+this.lookupForm.controls.client.value.key;
      link.href=environment.baseUrl+"/api/etl/cashflow/download/file/?tablename="+rowData.tableName+"&client_id="+this.headerForm.controls.client.value.key+"&template=1";  
      link.click();
      this.toast.showSuccess('success','Your download will begin shortly');
    }else{
      this.toast.showError("Please Select Client", "error");
    }
  }else{
    this.toast.showError("Please Select Client", "error");
  }
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getPreviousState();
      this.setTitle();
      this.getAllCashflowTables();
    }
}
