import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { WorkflowIngestCustomListModel } from 'src/app/dashboard/models/workflow.model';

@Component({
  selector: 'app-job-list-table',
  templateUrl: './job-list-table.component.html',
  styleUrls: ['./job-list-table.component.scss']
})
export class JobListTableComponent implements OnInit {

  @Input() jobTypeValue;
  @Input() jobList:WorkflowIngestCustomListModel[];
  @Output() editJob=new EventEmitter();
  @Output() deleteJob=new EventEmitter();
  @Input()  rolePermission;
  jobDataValue:WorkflowIngestCustomListModel=null;
  visible:boolean=false;

  cols: { field: string; header: string }[];

  constructor() { 
    this.cols = [
      { field: "jobName", header: "Job" },  
      { field: "description", header: "Desc" },
      { field: "userName", header: "Created By" },
      { field: "createdAt", header: "Created At" }
    ];
    
  }

  ngOnInit(): void {
    
  }

  

  ngOnChanges(changes: SimpleChanges) {
   
    if (changes && changes.jobTypeValue && changes.jobTypeValue.currentValue) {
      console.log("gfgfgf ",this.jobTypeValue)
      if(changes.jobTypeValue.currentValue==='Ingest'){
        this.cols = [
          { field: "jobName", header: "Job" },  
          { field: "description", header: "Desc" },
          { field: "masterFile", header: "Master File" },
          { field: "userName", header: "Created By" },
          { field: "createdAt", header: "Created At" }
        ];
      }
  
      if(changes.jobTypeValue.currentValue==='Custom'){
        this.cols = [
          { field: "jobName", header: "Job" },  
          { field: "description", header: "Desc" },
          { field: "userName", header: "Created By" },
          { field: "createdAt", header: "Created At" }
        ];
      }
    }
  }

  editJobData(data){
    console.log("edit job data ",data);
    this.editJob.emit(data);
  }

  deleteJobData(data){
    this.deleteJob.emit(data);
  }

  showPopup(data){
    this.jobDataValue=data;
    this.visible=true;

  }

  confirmDelete(data){
    this.deleteJobData(this.jobDataValue);
    this.hidePopup();
  }

  hidePopup(){
    this.visible=false;
    this.jobDataValue=null;
  }

}
