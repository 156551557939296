import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";

import { FormGroup, FormControl } from "@angular/forms";
import { SubSink } from "subsink";
import { orderBy } from "lodash";
import { LogsModel } from "../../models/logs.model";
import { AlfredPermission } from "../../models/alfredPermission.model";
import { ToastService } from "src/app/core/services/toast.service";
@Component({
  selector: "app-alfred-logs",
  templateUrl: "./alfred-logs.component.html",
  styleUrls: ["./alfred-logs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlfredLogsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() logs: LogsModel[];
  @Input() filterDates: { startDate: any; endDate: any };
  @Output() load = new EventEmitter();
  @Output() datesChanged = new EventEmitter();
  @Output("filter") filterData = new EventEmitter();
  @Output() deleteBatchEmit = new EventEmitter();
  @Input() alfredPermissionData:AlfredPermission;
  @Input() permission:any;

  visible:boolean=false;
  deleteBatchHeader="Delete Batch";
  deleteBatchData:any=null;
  private sink = new SubSink();
  filter = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
  });
  cols = [
    "Series",
    "Start Date",
    "End Date",
    "Vintage Date",
    "Rows",
    "units",
    "freq",
    "agg",
    "status",
    "detail",
    "Seasonal Adjustment",
    "created",
  ];
  rowGroupMetadata: any;
  sortedLogs: any;
  constructor(private toast:ToastService) {}
  getDateFromTimeStamp(ts) {
    return new Date(ts * 1000);
  }
  getName(name) {
    if (!name || name === "" || name === "null") {
      return "Batch Date";
    }
    return name;
  }
  ngOnInit(): void {
    this.sink.add(
      this.filter.valueChanges.subscribe(
        (d) => this.datesChanged.emit(d),
        (e) => {}
      )
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.filterDates && changes.filterDates.currentValue) {
      const dt = changes.filterDates.currentValue;
      this.filter.setValue({
        startDate: new Date(dt.startDate),
        endDate: new Date(dt.endDate),
      });
    }
    if (changes && changes.logs && changes.logs.currentValue) {
      this.updateRowGroupMetaData();
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  selectData(data) {
   const d = this.logs.filter((d) => d.batch_id === data.batch_id);
   if(this.alfredPermissionData.alfredDetailLogs){
      this.load.emit(d);
   }else{
    this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
   }
  }
  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.logs) {
      //console.log('Logs : ',this.logs)
      // this.sortedLogs = orderBy(
      //   this.logs,
      //   (d) => d["created_at"].toLowerCase(),
      //   ["asc"]
      // );
      this.sortedLogs=this.logs;
     // console.log('sorted logs :',this.sortedLogs);
      for (let i = 0; i < this.sortedLogs.length; i++) {
        let rowData = this.sortedLogs[i];
        let brand = rowData.batch_id;
        if (i == 0) {
          this.rowGroupMetadata[brand] = { index: 0, size: 1 };
        } else {
          let previousRowData = this.sortedLogs[i - 1];
          let previousRowGroup = previousRowData.batch_id;
          if (brand === previousRowGroup) this.rowGroupMetadata[brand].size++;
          else this.rowGroupMetadata[brand] = { index: i, size: 1 };
        }
      }
    }
  }
  notifyClick() {
    this.filterData.emit();
  }

  deleteBatch(data){
    console.log("here delete batch ",data)
    this.deleteBatchEmit.emit(this.deleteBatchData);
    this.deleteBatchHeader="Delete Batch";
    this.deleteBatchData=null;
    this.visible=false;
  }
  doAction(data,val){
    if(val===true){
      this.deleteBatchData=data;
      this.deleteBatchHeader="Delete Batch :"+data.batch_name;
    this.visible=true;
  }else{
    this.deleteBatchData=null;
    this.deleteBatchHeader="Delete Batch";
    this.visible=false;
  }
  }
}
