import { Component, OnInit, ViewChild } from '@angular/core';
import { LookupColumnSelectorComponent } from 'src/app/lookupColumn/components/lookup-column-selector/lookup-column-selector.component';
import { FormGroup, FormControl } from '@angular/forms';
import { LookupColumnListModel } from '../../models/lookupColumn.model';
import { Subscription } from 'rxjs';
import { LookupColumnService } from '../../services/lookup-column.service';
import { DatePipe } from '@angular/common';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import {isEmpty,trim} from 'lodash';
import { ErrorModel } from '../../models/errorModel.model';
import { _isNumberValue } from '@angular/cdk/coercion';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';

@Component({
  selector: 'app-lookup-column',
  templateUrl: './lookup-column.component.html',
  styleUrls: ['./lookup-column.component.scss']
})
export class LookupColumnComponent implements OnInit {
  @ViewChild(LookupColumnSelectorComponent, { read: false, static: false })  


  lookupColumnSelectorComponent: LookupColumnSelectorComponent;
  lookupColumnForm: FormGroup = null;
  lookupColumnListModel:LookupColumnListModel[]=[];
  lookupColumnListEdit:LookupColumnListModel;
  activeRoute$: Subscription;
  errorModel:ErrorModel;
  lookUpColumnTable:any;
  rolePermission:any;

  constructor(
    private lookupColumnService:LookupColumnService,
    private datePipe:DatePipe,
    private toast:ToastService,
    private loader:LoaderService,private storePermission: Store<{ role: any }>,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,private fileService: FileService
    ) {
      this.lookupColumnForm=new FormGroup({
        lookupColumnMap:new FormControl(null) 
       
      });
     }

  ngOnInit(): void {
    this.buttonShowHidePermission();
 }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getLookupColumnTable(){
    const lookupColumnMap=[
      "accrualbasis_accrualbasiscode_list",
      "call_report_code_callreportcode_list",
      "product_type_callreportcode_list",
      "reprice_index_code_repriceindex_list",
      "rate_change_frequency_ratechangefrequencycode_list",
      "state_statecode_list",
      
    ];
  
    return lookupColumnMap.map((t) => ({ key: t, value: t }));
   
  }

  getSelected(data){
    this.lookUpColumnTable=data.key;
    console.log("data.key",data.key);
    this.getLookupColumnDataByTable();
    console.log("inside get selected");
    this.lookupColumnSelectorComponent.resetData();
    this.lookupColumnListEdit=null;      

    }
    
  getLookupColumnDataByTable(){
   
    console.log("inside get table data");
      this.loader.showLoader();
      this.lookupColumnListModel=[];

      const lookupColumnModel={
        "tableName":this.lookupColumnForm.controls.lookupColumnMap.value.key,
        
      }
      // console.log("this.lookupColumnForm.controls.lookupColumnMap.value.key",this.lookupColumnForm.controls.lookupColumnMap.value.key );
      this.lookupColumnService.getLookupColumnDataByTable(lookupColumnModel)
      .subscribe(
        data=>{
          this.loader.hideLoader();
            this.lookupColumnListModel=data;
          console.log("gettabledata",this.lookupColumnListModel),
            this.lookupColumnListModel.forEach(item=>{
              item.field1=(item.field1===null)?"NA":item.field1,
              item.field2=(item.field2===null)?"NA":item.field2,
              item.field3=(item.field3===null)?"NA":item.field3,
              // (age < 18) ? "Too young" : "Old enough";
              item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
              if(item.modifiedAt===null){
                item.modifiedAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
              }else{
                item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
              }
    
             })
             
        },
        err=>{
          this.toast.showError(err.error.message, "error");
          this.loader.hideLoader();
        }
      )
  
    }
  
    ///////
    getLookupColumnOnEdit(data){
      console.log('LookupColumn data value ',data)
      this.getLookupColumnById(data);
    }

    
    getLookupColumnById(data){
      this.loader.showLoader();
      const lookupColumnModel={
        "tableName":this.lookupColumnForm.controls.lookupColumnMap.value.key,
        "primaryId":data.primaryId
      }
  
      this.lookupColumnService.getLookupColumnById(lookupColumnModel)
      .subscribe(
        data=>{
          this.loader.hideLoader();
           this.lookupColumnListEdit=data;
        },
        err=>{
         // this.lookupColumnListEdit=data;
         this.toast.showError(err.error.message, "error");
        }
      )
  
    }

    deleteLookupColumnById(data){
      // console.log("inside main deleteLookupColumnById")
      this.loader.showLoader();
      // console.log("delete id :",data);
      const lookupColumnModel={
        "tableName":this.lookupColumnForm.controls.lookupColumnMap.value.key,
        "primaryId":data.primaryId,
        "code":data.code,
        "description":data.description,
      }
  
      this.lookupColumnService.deleteLookupColumn(lookupColumnModel)
      .subscribe(
        data=>{
  
          this.loader.hideLoader();
          this.toast.showSuccess('success','Data deleted successfully');
          this.resetForm();
          this.getLookupColumnDataByTable();
          
        },
        err=>{
          this.toast.showError(err.error.message, "error");
          this.loader.hideLoader();
        }
      )
    }
  
resetForm(){
  this.lookupColumnSelectorComponent.resetData();
  this.lookupColumnListEdit=null;
}

  saveData(data){
    // this.loader.showLoader();
    console.log("error model",this.lookupColumnForm.controls.lookupColumnMap.value.key)
    this.errorModel=this.validateLookUp(data,this.lookupColumnForm.controls.lookupColumnMap.value.key);
    // this.errorModel=this.validateLookUp(data,this.lookupColumnSelectorComponent.selectorForm.controls.lookupMap.value.key);
  
    console.log("main comp data ",data.field1);
    console.log("main comp data2 ",data.field2);
      //
      // if(data.field1===null ||data.field1===undefined ||trim(data.field1).length<1){
      //   this.toast.showError('error','Field Value mandatory ')
      //   this.loader.hideLoader();
      //   return;
      // }

      if(!this.errorModel.isValid){
        this.toast.showWarn(this.errorModel.message,'error');
        return;
      }
     
    console.log("checkingSave ############"+this.lookupColumnListEdit);
      const lookupColumnModel={
        "tableName":this.lookupColumnForm.controls.lookupColumnMap.value.key,
        "primaryId":this.lookupColumnListEdit!==null&&this.lookupColumnListEdit!==undefined?this.lookupColumnListEdit.primaryId:0,
        "field1":data.field1,
        "field2":data.field2,
        "field3":data.field3,
        
  
      }
                               
      this.lookupColumnService.saveLookupColumnData(lookupColumnModel)
      .subscribe(
        data=>{
          this.loader.hideLoader();
          this.toast.showSuccess('success','data saved successfully');
          this.resetForm();
          this.getLookupColumnDataByTable();
        },
        err=>{
          this.toast.showError(err.error.message, "error");
          this.loader.hideLoader();
        }
      )
    }


    validateLookUp(data,tableName){
      // console.log("validate called");
      // console.log("in validate: field1",data.field1 );
      // console.log("in validate: field2",data.field2 );
      // console.log("in validate: field3",data.field3 );

    switch(tableName){
      case 'accrualbasis_accrualbasiscode_list':
        // if(data.code===null || data.code===undefined || _isNumberValue(data.code)===false || data.code.length>4||trim(data.code).length<1){
         //  if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
             if(data.field1!==null && data.field1!==undefined  && data.field1.length>100||trim(data.field1).length<1){
               this.errorModel={
                 isValid:false,
                 message:"Accrual Basis mandatory, not more than 255 characters"
               }
               return  this.errorModel;
      
             }
            
         break;
         case 'call_report_code_callreportcode_list':
           if(data.field1!==null && data.field1!==undefined  && data.field1.length>255||trim(data.field1).length<1){
                 this.errorModel={
                   isValid:false,
                   message:"Call Report Code mandatory, not more than 255 characters"
                 }
                 return  this.errorModel;
        
               }

               if(data.field2!==null && data.field2!==undefined  && data.field2.length>255||trim(data.field2).length<1){
                this.errorModel={
                  isValid:false,
                  message:"Call Report Name Code mandatory, not more than 255 characters"
                }
                return  this.errorModel;
       
              }
              
           break;
        case 'product_type_callreportcode_list':
        if(data.field1!==null && data.field1!==undefined  && data.field1.length>255||trim(data.field1).length<1){
              this.errorModel={
                isValid:false,
                message:"Product Type mandatory, not more than 255 characters"
              }
              return  this.errorModel;
      
            }

            if(_isNumberValue(data.field2)===false ||data.field2!==null && data.field2!==undefined  && data.field2.length>4||trim(data.field2).length<1){
              this.errorModel={
                isValid:false,
                message:"Risk Weight mandatory, must be numeric, upto 4 digits"
              }
              return  this.errorModel;
    
            }

            if(_isNumberValue(data.field3)===false ||data.field3!==null && data.field3!==undefined  && data.field3.length>4||trim(data.field3).length<1){
            this.errorModel={
              isValid:false,
              message:"Max-am mandatory, must be numeric, upto 4 digits"
            }
            return  this.errorModel;
    
          }
            
        break;
        
        case 'reprice_index_code_repriceindex_list':
        if(data.field1!==null && data.field1!==undefined  && data.field1.length>255||trim(data.field1).length<1){
              this.errorModel={
                isValid:false,
                message:"Reprice Index mandatory, not more than 255 characters"
              }
              return  this.errorModel;
      
            }

        break;
        
        case 'rate_change_frequency_ratechangefrequencycode_list':
        if(_isNumberValue(data.field1)===false ||data.field1!==null && data.field1!==undefined  && data.field1.length>4||trim(data.field1).length<1){
              this.errorModel={
                isValid:false,
                message:"Rate Change Frequency mandatory, must be numeric, upto 4 digits"
              }
              return  this.errorModel;
      
            }

        break;


        case 'state_statecode_list':
          if(data.field1!==null && data.field1!==undefined  && data.field1.length>255||trim(data.field1).length<1){
                this.errorModel={
                  isValid:false,
                  message:"State mandatory, not more than 255 characters"
                }
                return  this.errorModel;
        
              }
  
          break;
          
// -------------------
      }
         //DONOTTOUCH#####
    // console.log("ENTERED Out of switch## "+  data);
    this.errorModel={
      isValid:true,
      message:" success"
    }
    return this.errorModel;
    }

    buttonShowHidePermission(){
      this.fileService.buttonShowHidePermission().subscribe(
        (res) =>{ 
             this.rolePermission = res  
             this.callNgOnInitMethods();
     }
      )};
    
      callNgOnInitMethods(){
        this.setTitle();
      }




}
