import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { take, map } from "rxjs/operators";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<{ user: any }>, private router: Router,private authService:AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      return this.store.select("user").pipe(
        take(1),
        map((u) => {
          if (!u) {
           // console.log("here route guard false ==================",!u);
            this.router.navigate(["/login"], {
              queryParams: { returnUrl: state.url },
            });
          }
         // console.log("here route guard ==================",!!u);
          if(!!u){
          //  console.log("state url ===",state.url)
            const reuquestModel={
              "requestUrl":state.url
            }
            this.authService.getUrlInfo(reuquestModel)
            .subscribe(
              data=>{

            //      console.log("return api url data ",data.responseCode);
                  if(data.responseCode==="200"){
                    return !!u;
                  }
                  if(data.responseCode==="500"){
             //       console.log("routing to login ");
                    this.router.navigate(["/**"]);
                  }
              },
              error=>{
                console.log("return api url data error ",error);
              }
            )
          }
          return !!u;
        })
      );
    }
}
