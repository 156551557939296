import { Component, OnInit, Input, Output,EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ListingListModel } from 'src/app/dashboard/models/listing.model';

@Component({
  selector: 'app-listing-selector',
  templateUrl: './listing-selector.component.html',
  styleUrls: ['./listing-selector.component.scss']
})
export class ListingSelectorComponent implements OnInit {

  listingListEdit:ListingListModel;
  selectorForm: FormGroup = null;
  @Input() editListing;
  @Output() saveListing = new EventEmitter();
  @Input() permission:any;
  enableEdit=false;

  constructor() { 

    this.selectorForm = new FormGroup({
      code: new FormControl(null),
      description: new FormControl(null)
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
       
    if (changes && changes.editListing && changes.editListing.currentValue) {
     console.log(changes.editListing.currentValue,' inside changes') 
       const lookUp=changes.editListing.currentValue;
       this.enableEdit = true;
       this.checkForEdit() 
    }
 
    }
    checkForEdit() {

      if (this.editListing && this.enableEdit) { 

        this.selectorForm.patchValue({
          code: this.editListing.code,
          description:this.editListing.description ,
        });

      

        this.enableEdit = false;
       
      }
    }

  saveData(){

    
    const data={
      "code":this.selectorForm.controls.code.value,
      "description":this.selectorForm.controls.description.value,
    }
    this.saveListing.emit(data);

  }

  resetForm(){
    this.selectorForm.reset();
    this.listingListEdit=null;
  }

  // resetData(){
  //   this.selectorForm.reset();
  //   this.enableEdit = false;
  //   this.editListing=null;
  //       // if(this.selectorForm!==null){
  //       // this.selectorForm.reset();
  //       // }
  // }

}
