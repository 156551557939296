import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Observable, Subscription } from 'rxjs';
import { ClientFileFormComponent } from 'src/app/client-master/components/client-file-form/client-file-form.component';
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup, FormControl } from '@angular/forms';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ClientFileMasterModel } from '../../models/clientFileMaster.model';
import { FileService } from 'src/app/files/services/file.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-client-file-master',
  templateUrl: './client-file-master.component.html',
  styleUrls: ['./client-file-master.component.scss']
})
export class ClientFileMasterComponent implements OnInit {
  @ViewChild(ClientFileFormComponent, { read: false, static: false })
  clientFileFormComponent: ClientFileFormComponent;
  headerForm:FormGroup;
  activeRoute$: Subscription;
  clientMasterList:any;
  editClientMasterValue=null;
  fileDataTypeList: any = [];
  fileTypeList: any = [];
  dataTransferModel: DataTransferModel;
  fromDt: any;
  toDt: any;
  showBackButton:boolean=false;
  pageName:string
  state$: Observable<object>;
  rolePermission:any;
  
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,private clientFileMasterService:ClientFileMasterService,
    private toast:ToastService,
    public observalbleDataService: ObservalbleDataService,
    private loader:LoaderService,
    private fileService:FileService,
    private router: Router,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>) { 
      this.headerForm = new FormGroup({
        client: new FormControl(),
      });
    }

  ngOnInit(): void {
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  addMaster(data){
    if(this.headerForm.value.client==null){
  this.toast.showError("Please Select The Client First","error")
    }else{
    const clientMasterData = {
      clientfilemasterId:this.editClientMasterValue!==null?this.editClientMasterValue.clientfilemasterId:null,
      clientId:this.headerForm.value.client.key,
          name:data.name,
          fileNamePrefix:data.fileNamePrefix,
          desc:data.desc,
          fileTypeId:data.fileTypeId!==null?data.fileTypeId.key:null,
          fileDataTypeId:data.fileDataTypeId!==null?data.fileDataTypeId.key:null,
          frequency:data.frequency!==null?data.frequency.key:null,
          
    };
    this.clientFileMasterService
      .saveOrUpdateData(clientMasterData)
      .subscribe((data) => {
        this.clientSelected(this.headerForm.value.client.key, 'onAddMaster');
        this.clientFileFormComponent.clientMasterForm.reset();
        this.toast.showSuccess('saved successfully','success');
        this.editClientMasterValue=null;
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
      ); 
    }   
  }

  clientSelected(data, loadType) {
    if (loadType !== 'onLoad') {
     // this.maintainClientState();
    }
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    this.loader.showLoader();
    this.getClientFileMasterList(data);
  }

  getClientFileMasterList(data) {
    this.clientFileMasterService.getClientFileMasterListByClient(data)
      .subscribe(
        data => {
          this.loader.hideLoader();
          this.clientMasterList = data;
          this.clientFileFormComponent.clientMasterForm.reset();
          // for (var j = 0; j <= this.clientMasterList.length - 1; j++) {
          //   for (var i = 0; i <= this.fileTypeList.length - 1; i++) {
          //     if (this.fileTypeList[i].key === this.clientMasterList[j].fileTypeId)
          //       this.clientMasterList[j].fileTypeId = this.fileTypeList[i].value;
          //   }
          // }
          // for (var j = 0; j <= this.clientMasterList.length - 1; j++) {
          //   for (var i = 0; i <= this.fileDataTypeList.length - 1; i++) {
          //     if (this.fileDataTypeList[i].key === this.clientMasterList[j].fileDataTypeId)
          //       this.clientMasterList[j].fileDataTypeId = this.fileDataTypeList[i].value;
          //   }
          // }
        },
        err => {
          //this.listingListEdit=data;
          this.toast.showError(err.error.message, "error");
        }
      );
  }
    editClientFileMaster(data){
    this.getClientFileMaster(data.clientfilemasterId);
    }

    getClientFileMaster(id){
      this.clientFileMasterService.getClientFileMasterById(id)
      .subscribe(
        data=>{
          this.editClientMasterValue=data;  
        })
      }


      deleteById(data){
    
        this.loader.showLoader();
        console.log("delete id :",data);
        var clientfilemasterId=data.clientfilemasterId;
        this.clientFileMasterService.deleteClientFileMasterById(clientfilemasterId)
        .subscribe(
          data=>{
            this.clientSelected(this.headerForm.value.client.key, 'onDelete'); 
            this.clientFileFormComponent.clientMasterForm.reset();
            this.loader.hideLoader();
            this.toast.showSuccess('success','data deleted successfully');
          },
          err=>{
            this.loader.hideLoader();
            this.toast.showError('error','error deleting data');
          }
        )
      }

      getApplicationFileType(){
        this.fileService.getApplicationDataTypeByUser().subscribe(
       (data) => {
         const fileDataType=data;
         for(var a=0;a<fileDataType.length;a++){
         // console.log("type "+fileDataType[a].type);
           this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
         }
       },
       (err) => {
       //  this.toast.showSuccess("Status Not Changed", "error");
       this.toast.showError(err.error.message, "error");
       }
     
     );
        
     }
     
     getClientApplicationFileTypeData(){
       this.fileService.getClientApplicationFileType().subscribe(
         (data) => {
           const clientApplicationFileType=data;
           for(var a=0;a<clientApplicationFileType.length;a++){
           //  console.log("type "+clientApplicationFileType[a].type);
             this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
           }
         },
         (err) => {
         //  this.toast.showSuccess("Status Not Changed", "error");
         this.toast.showError(err.error.message, "error");
         }
       
       );
     
  }

  maintainClientState(){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: this.headerForm.value.client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: this.headerForm.value.client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    } 
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               this.clientSelected(this.headerForm.controls.client.value.key, 'onLoad');
               
                     
    }
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     console.log("Sahil Ahuja:", this.dataTransferModel);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.clientSelected(this.dataTransferModel.clientId, 'onLoad');
    //     }
    //     if(this.dataTransferModel.page !=='' && this.dataTransferModel.page !== null && this.dataTransferModel.page !== undefined){
    //         this.showBackButton=true;
    //         this.pageName=this.dataTransferModel.page
    //     }else{
    //       this.showBackButton=false;
    //     }
    //   });
    }


    goToPage(){
      if(this.showBackButton){
      this.dataTransferModel={
             clientFileId:this.dataTransferModel.clientFileId,
             clientId: this.headerForm.controls.client.value.key,
             isRedirected:1,
             applicationId:this.dataTransferModel.applicationId,
             toDate:this.dataTransferModel.toDate,
             fromDate:this.dataTransferModel.fromDate,
             clientName:this.headerForm.controls.client.value.value,
             page:this.dataTransferModel.page,
             fileName:this.dataTransferModel.fileName,
             workflowName:this.dataTransferModel.workflowName,
            workflowId:this.dataTransferModel.workflowId,
            dataType:this.dataTransferModel.dataType,
            dataTypeId:this.dataTransferModel.dataTypeId
      }
     // console.log(this.dataTransferModel,'helooo ',this.pageName);
      this.observalbleDataService.addToInventory(this.dataTransferModel);
      this.router.navigate(['/layout-files']);
    
    }
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getApplicationFileType();
      this.getClientApplicationFileTypeData();
      this.setTitle();  
      this.getPreviousState();
    }
  }
  

