import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PickListModule } from "primeng/picklist";
import { ButtonModule } from "primeng/button";
import { DualListComponent } from './component/dual-list/dual-list.component';
import { MultiSelectModule } from "primeng/multiselect";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DualListComponent],
  imports: [CommonModule, PickListModule, ButtonModule, MultiSelectModule,FormsModule],
  exports: [DualListComponent],
})
export class DualListModule {}