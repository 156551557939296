import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListingListModel } from '../models/listing.model';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ListingService {

  constructor(private http: HttpClient) { }

  getAllListingByTable(data) {
    return this.http.post<ListingListModel[]>(environment.baseUrl + "/listing/getListingDataList",data);
  }

  getListingById(data){
    return this.http.post<ListingListModel>(environment.baseUrl + "/listing/getListingById",data);
  }

  saveListing(data){
    return this.http.post<ListingListModel>(environment.baseUrl + "/listing/saveListing",data);
  }

  deleteListing(data){
    return this.http.post<any>(environment.baseUrl + "/listing/deleteById",data);
  }
}
