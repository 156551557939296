import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ClientPortalService } from '../../services/client-portal.service';
import { ClientService } from '../../services/client.service';
import { ClientComponent } from '../client/client.component';
import {isEmpty,trim} from 'lodash';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { ButtonPermissionModel } from '../../models/button-permission.model';


@Component({
  selector: 'app-client-portal-form-data',
  templateUrl: './client-portal-form-data.component.html',
  styleUrls: ['./client-portal-form-data.component.scss']
})
export class ClientPortalFormDataComponent implements OnInit {
  activeRoute$:Subscription;
  visible:boolean=false;
  headerForm:FormGroup;
  currentFileUpload:FileList;
  currentFileUploadList:FileList;
  clientFileList:any[];
  user:any;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  @ViewChild('myInput2')
  myInputVariable2: ElementRef;
  fileList:any=[];
  popUpHeader:string="Upload File";
  showClientFilePopUp:boolean=false;
  showClientAppFilePopUp:boolean=false;
  popUpName:string;
  visibleLayoutPopUp:boolean=false;
  clientApplicationFileForm:FormGroup;
  fieldSeparatorList:{ key: string ; value: string }[];
  clientData:any=null;
  showButton:boolean=false;
  fileDataTypeList: any = [];
  clientMasterList: any=[];
  layoutFieldList: any = [];
  dependentUponList: any = [];
  fileTypeList: any = [];
  showClient:boolean=false;
  clientId:number=0;
  imgSrc:string='/assets/images/uploading.gif';
  showLoading:boolean=false;
  fileDat:File;
  isZipUpload:boolean=false;
  dataTransferModel: DataTransferModel;
  rolePermission:any;
  dataModel:ButtonPermissionModel[];

  constructor(private activeRoute:ActivatedRoute,private titleService:TitleService,
    private toast:ToastService,private store: Store<{ user: any }>,private loader:LoaderService,
    private clientPortalService:ClientPortalService,private clientService:ClientService,
    private fileService:FileService,
    public observalbleDataService: ObservalbleDataService,
    private datePipe:DatePipe,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>) {
     
    this.headerForm = new FormGroup({
      client: new FormControl(null),
      toDate: new FormControl(null),
      fromDate: new FormControl(null),
      file: new FormControl(null),
      asOfDate: new FormControl(null),
      zipUpload:new FormControl(null)
    });
   
    this.clientApplicationFileForm = new FormGroup({
      fileType: new FormControl(null),
      fileDataType: new FormControl(null),
      layoutFieldId: new FormControl(null),
      dependentUpon: new FormControl(null),
      fieldSeparator: new FormControl(null),
      schedule: new FormControl(null)
    })
   }

  ngOnInit(): void {
//     this.getPreviousState();
    
//  this.setTitle();
//  this.store.select('user').pipe(take(1))
//       .subscribe(u => this.user = u)
//       if(this.user.clientId===0){
//         this.showClient=false;
//         this.showButton=true;
//        }else{
//       this.getClient(this.user.clientId); 
//       this.showButton=true;
//       this.showClient=true;
//       this.clientId=this.user.clientId;
//       }
//       this.getApplicationFileType();
//       this.getClientApplicationFileTypeData();
//       this.getAllSeparators();  
this.buttonShowHidePermission();
    
     // this.storePermission.select(x => this.rolePermission = x).subscribe();
  }


  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  showPopup(){
  // this.popUpHeader="Add Client File";
   this.showClientFilePopUp=true; 
    this.showClientAppFilePopUp=false;
    this.visible=true;
    this.isZipUpload=false;
    // if(this.headerForm.controls.zipUpload && this.headerForm.controls.zipUpload.value===true){
    //   this.isZipUpload=true;
    // }else{
    //   this.isZipUpload=false;
    // }
  
  }

  showZipPopup(){
    // this.popUpHeader="Add Client File";
     this.showClientFilePopUp=true; 
      this.showClientAppFilePopUp=false;
      this.visible=true;
      this.isZipUpload=true;
      // if(this.headerForm.controls.zipUpload && this.headerForm.controls.zipUpload.value===true){
      //   this.isZipUpload=true;
      // }else{
      //   this.isZipUpload=false;
      // }
    
    }

  doAction(type){
    if (type === "yes") {
    //  console.log("helloo");
      this.visible = false;
      this.currentFileUpload=null;
      console.log("myinput var ",this.myInputVariable)
      if(this.myInputVariable!==undefined){
      this.myInputVariable.nativeElement.value = "";
      }
      if(this.myInputVariable2!==undefined){
        this.myInputVariable2.nativeElement.value = "";
        }
      
      this.visible=false;
   // this.headerForm.reset();
      this.fileList=[];
      this.currentFileUpload=null;
      this.currentFileUploadList=null;
    }else{
      this.visible = true;
    }
  }

  selectFile(event) {
    //this.currentFileUploadList=[];
   // this.fileList=[];
  //  console.log("event target ",event.target.files[0])
  //  const file=event.target.files[0];
  //  console.log("zipupload ",this.headerForm.controls.zipUpload.value);
  //  //if(this.headerForm.controls.zipUpload.value)
  this.currentFileUpload=null;
  //  console.log(file.type)
    this.currentFileUploadList = event.target.files;
   // this.currentFileUpload.push(this.currentFileUploadList);
    for(let i=0;i<this.currentFileUploadList.length;i++){
      this.fileList.push(this.currentFileUploadList[i]);
      }
    this.currentFileUpload=this.fileList;
  // this.currentFileUpload=event.target.files;
  console.log(" file 1 :",this.currentFileUpload);
    // for(let i=0;i<this.currentFileUploadList.length;i++){
    //   this.currentFileUpload=this.currentFileUploadList[i];
    //   }

  }
  selectFile2(event) {
    //this.currentFileUploadList=[];
   // this.fileList=[];
  //  console.log("event target ",event.target.files[0])
    const file=event.target.files[0];
    console.log("zipupload ",file.type);
    this.currentFileUpload=null;
    if(file.type!=='application/x-zip-compressed'){
      this.toast.showError('error','select zip file');
      this.myInputVariable2.nativeElement.value="";
      event.target=null;
      return;
    }
  //  //if(this.headerForm.controls.zipUpload.value)
  
   
    this.currentFileUploadList = event.target.files;
   // this.currentFileUpload.push(this.currentFileUploadList);
    for(let i=0;i<this.currentFileUploadList.length;i++){
      this.fileList.push(this.currentFileUploadList[i]);
      }
   // this.currentFileUpload=this.fileList;
   this.currentFileUpload=event.target.files;
    console.log(" file 2 :",this.currentFileUpload);
    // for(let i=0;i<this.currentFileUploadList.length;i++){
    //   this.currentFileUpload=this.currentFileUploadList[i];
    //   }

  }

  removeFile(index){
    console.log(index);
    this.fileList=[];
    if(index===0 && this.currentFileUpload.length===1){
      event=null;
      return this.currentFileUpload=null;
    }
    for(let i=0;i<this.currentFileUpload.length;i++){
      this.fileList.push(this.currentFileUpload[i]);
      }
    console.log("before list "+this.fileList)
   // this.fileList = this.fileList.filter(obj => obj !== this.fileList[index]);
   this.fileList.splice(index,1);
   console.log("after list "+this.fileList)
   this.currentFileUpload=this.fileList;
  
  }


  saveClientFile(){
    if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      this.toast.showWarn('no file selected','error');
      return;
    }
    //if(this.modalForm.controls.asOfDate.value===null || this.modalForm.controls.file.value===null){
    if(this.headerForm.controls.asOfDate.value===null){
     this.toast.showWarn('ApplicationFile and AsOfDate are mandatory','error');
     return;
    }
    if(this.clientId!=0){
      this.headerForm.controls.client.setValue(this.clientId);
    }
     this.loader.showLoader();
     this.showLoading=true;
     //this.visible=false;
  if(this.isZipUpload===false){
     this.clientPortalService.saveClientFile(this.currentFileUpload,this.headerForm)
     .subscribe(
       data=>{
         this.toast.showSuccess('Success','File Uploaded Successfully');
         //this.clientId=null;
         this.currentFileUpload=null;
         this.myInputVariable.nativeElement.value = "";
         this.visible=false;
         //this.headerForm.reset();
         //this.headerForm.controls.asofDate.setValue(null);
         this.loader.hideLoader();
         this.showLoading=false;
        
       },
       error=>{
         this.toast.showError(error.error.message, "error");
         this.loader.hideLoader();
         this.visible=false;
         this.showLoading=false;
       },
      
     );
      }
      if(this.isZipUpload===true){
        this.clientPortalService.saveZipClientFile(this.currentFileUpload,this.headerForm)
     .subscribe(
       data=>{
         this.toast.showSuccess('Success','File Uploaded Successfully');
         //this.clientId=null;
         this.currentFileUpload=null;
         this.myInputVariable2.nativeElement.value = "";
         this.visible=false;
         //this.headerForm.reset();
         //this.headerForm.controls.asofDate.setValue(null);
         this.loader.hideLoader();
         this.showLoading=false;
         this.isZipUpload=false;
        
       },
       error=>{
         this.toast.showError(error.error.message, "error");
         this.loader.hideLoader();
         this.visible=false;
         this.showLoading=false;
       },
      
     );
      }
     
   }

   showPopupAddFile(data) {
    this.showClientAppFilePopUp=true;
    this.showClientFilePopUp=false; 
    this.popUpName="Upload Layout File";
    this.visibleLayoutPopUp = true;
   
    if(this.headerForm.controls.client.value>0){
      this.clientId=this.headerForm.controls.client.value;
       }else{
        this.clientId=this.headerForm.controls.client.value.key;
       }
    this.getLayoutField(this.clientId);
    this.getDependentUponData(this.clientId);   
  }

  doAddFileAction(type){
    if (type === "yes") {
      console.log("helloo");
      this.visibleLayoutPopUp = false;
    //  this.modalForm.reset();
    this.clientApplicationFileForm.reset();
    this.currentFileUpload=null;
    this.myInputVariable.nativeElement.value = "";
    }else{
      this.clientApplicationFileForm.reset();
      this.visibleLayoutPopUp = false;
    }
  }
  
  onDateChange(e){
    // console.log(e);
    // const asOfDate1=this.datePipe.transform(e,'yyyy-MM-dd');
    // console.log(asOfDate1);
    this.headerForm.controls.asOfDate.setValue(e);
  }

  getAllSeparators(){
    this.fieldSeparatorList= [
     { key: ',', value: "Comma" },
     { key: 'space', value: "Space" },
     { key: '|', value: "Pipe(|)" },
     { key: '"', value: "Quotes" },
     { key: ';', value: "Semicolon" },
     { key: ':', value: "Colon"},
     { key: '\t', value: "Tab" }
   ];
 // this.clientApplicationFileForm.get('fieldSeparator').setValue(fieldSeparatorList);
  }

  selectClientApplicationFile(event){
    this.currentFileUpload = event.target.files;
  } 

  getClient(clientId){
    this.clientService.getClientsById(clientId).subscribe((data) => {
      this.clientData=data;
      const mapVal={
        "clientId":clientId,
        "clientName":this.clientData.clientName
      }
       this.headerForm.controls.client.setValue(mapVal);
     
      },
    (err) =>{
      this.toast.showError(err.error.message, "error");
     } 
    ); 
    }

    getLayoutField(clientId){
      this.fileService.getAppFilesByClientId(clientId).subscribe(
        (data) => {
          const layoutFieldFile=data;
          for(var a=0;a<layoutFieldFile.length;a++){
          // console.log("type "+layoutFieldFile[a].fileName);
            this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
          }
        },
        (err) => {
        //  this.toast.showSuccess("Status Not Changed", "error");
        }
      
      );
    
    }
    
    getDependentUponData(clientId){
      this.fileService.getDependentUpon(clientId).subscribe(
        (data) => {
          const dependentUpon=data;
          for(var a=0;a<dependentUpon.length;a++){
            console.log("type "+dependentUpon[a].fileName);
            this.dependentUponList.push({ key: dependentUpon[a].clientApplicationFileId, value: dependentUpon[a].fileName });
          }
        },
        (err) => {
        //  this.toast.showSuccess("Status Not Changed", "error");
        }
      
      );
    
    }
    
    getClientApplicationFileTypeData(){
      this.fileService.getClientApplicationFileType().subscribe(
        (data) => {
          const clientApplicationFileType=data;
          for(var a=0;a<clientApplicationFileType.length;a++){
           // console.log("type "+clientApplicationFileType[a].type);
            this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
          }
        },
        (err) => {
        //  this.toast.showSuccess("Status Not Changed", "error");
        this.toast.showError(err.error.message, "error");
        }
      
      );
    
    }

    getApplicationFileType(){
      this.fileService.getApplicationDataTypeByUser().subscribe(
     (data) => {
       const fileDataType=data;
       for(var a=0;a<fileDataType.length;a++){
       // console.log("type "+fileDataType[a].type);
         this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
         }
     },
     (err) => {
     //  this.toast.showSuccess("Status Not Changed", "error");
     this.toast.showError(err.error.message, "error");
     }
   
   );
      
   }

   saveClientApplicationFile(){
    if(this.clientApplicationFileForm.valid){
    if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      this.toast.showWarn('no file selected','error');
      return;
    }
    if(this.clientId===0){
      this.clientId=this.headerForm.controls.client.value.key;
       }

      const ClientAppliactionFileConst = {
       "clientId": this.clientId,
       "clientApplicationFileTypeId": this.clientApplicationFileForm.controls.fileType.value.key,
       "dependentUpon":this.clientApplicationFileForm.controls.dependentUpon.value,
       "schedule":this.clientApplicationFileForm.controls.schedule.value,
       "fieldSeparator":this.clientApplicationFileForm.controls.fieldSeparator.value,
       "clientApplicationFileDataTypeId":this.clientApplicationFileForm.controls.fileDataType.value.key,
       "layoutFieldId":this.clientApplicationFileForm.controls.layoutFieldId.value
     };
     this.fileService.saveClientApplicationFile(this.currentFileUpload,ClientAppliactionFileConst) .subscribe(
      data=>{
        this.toast.showSuccess('Success','File Uploaded Successfully');
        this.currentFileUpload=null;
        this.myInputVariable.nativeElement.value = "";
        this.visibleLayoutPopUp=false;
        this.clientApplicationFileForm.reset();
        this.loader.hideLoader();

       
      },
      error=>{
        this.toast.showError(error.error.message, "error")
        this.loader.hideLoader();
      },
     
    );
   }else{
    this.toast.showError('Please Fill the Mandatory Fields','Error')
   }
  }

  maintainClientState(client){
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    //   this.dataTransferModel = {
    //   clientFileId: 0,
    //   clientId: client.key,
    //   isRedirected: 1,
    //   applicationId: 0,
    //   toDate: '',
    //   fromDate: '',
    //   clientName: client.value,
    //   page: '',
    //   fileName:'',
    //   workflowName:'',
    //   workflowId:0,
    //   dataType:'',
    //   dataTypeId:0
    // }
    // this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               
                     
    }
  //   this.observalbleDataService.inventoryChanged$.subscribe(
  //     data => {
  //       this.dataTransferModel = data;
  //       if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
  //         this.headerForm.get("client").patchValue({
  //           key: this.dataTransferModel.clientId,
  //           value: this.dataTransferModel.clientName,
  //         });
  //       }
  //     });
   }
   


   buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};

    callNgOnInitMethods(){

      this.getPreviousState();
    
 this.setTitle();
 this.store.select('user').pipe(take(1))
      .subscribe(u => this.user = u)
      if(this.user.clientId===0){
        this.showClient=false;
        this.showButton=true;
       }else{
      this.getClient(this.user.clientId); 
      this.showButton=true;
      this.showClient=true;
      this.clientId=this.user.clientId;
      }
      this.getApplicationFileType();
      this.getClientApplicationFileTypeData();
      this.getAllSeparators();


    }

}
