import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerFormComponent } from './components/scheduler-form/scheduler-form.component';
import { SharedModule } from '../shared/shared.module';
import { SchedulerListTableComponent } from './components/scheduler-list-table/scheduler-list-table.component';
import { SchedulerLogTableComponent } from './components/scheduler-log-table/scheduler-log-table.component';





@NgModule({
  declarations: [
    SchedulerFormComponent,
    SchedulerListTableComponent,
    SchedulerLogTableComponent, 
    
  
  ],
  imports: [SharedModule],
  exports: [
    SchedulerFormComponent, 
    SchedulerListTableComponent,
    SchedulerLogTableComponent,
    
  ],
  providers: []
  })
export class SchedulerCompModule { }
