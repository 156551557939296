import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';

@Component({
  selector: 'app-scheduler-list-table',
  templateUrl: './scheduler-list-table.component.html',
  styleUrls: ['./scheduler-list-table.component.scss']
})
export class SchedulerListTableComponent implements OnInit {

  @Input() schedulerList;
  @Output() edit = new EventEmitter();
  @Output() view = new EventEmitter();
  @Output() setData = new EventEmitter();
  @Output() deleteData = new EventEmitter();
  @Input() permission:any;
  @Output() logData = new EventEmitter();
  visible=false;
  dataValue:any;

  // schedulerList= [{'schedulerName': 'daily Schedule', 'schedulerType': 'Recurring','schedularTime': '2.30PM','createdAt':'10/8/2021','modifiedAt':''}];
  constructor(private titleService: TitleService) { }

  ngOnInit(): void {

    console.log("schedulerList  ", this.schedulerList);

  }


  editSchedular(data: any) {
    this.edit.emit(data);
  }

  viewScheduler(data: any) {
    this.view.emit(data);
  }


  SchedulerStatus(data: any) {
    console.log("dataforset  ", data)

    this.setData.emit(data);


  }

  DeleteScheduler() {

    this.deleteData.emit(this.dataValue);
    this.visible=false;
  }

  getLog(data){
    this.logData.emit(data);
  }

  showPopup(data){
   this.dataValue=data;
    this.visible=true;
  }

  hidePopup(){
    this.visible=false;
  }


}
