import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LoaderService } from "src/app/loader/services/loader.service";
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { currentMonthRange, dateBetween } from "src/app/utils/dates";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastService } from "src/app/core/services/toast.service";
import { FileLogModel } from "../../models/filelog.model";
import { FileService } from "src/app/files/services/file.service";
import { ObservalbleDataService} from 'src/app/dashboard/services/observalble-data.service';
import {DataTransferModel} from 'src/app/dashboard/models/dataTransfer.model';
import { exportCSVFile } from "src/app/utils/excel";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import {isEmpty,trim} from 'lodash';
import { DatePipe } from "@angular/common";
import { Store } from '@ngrx/store';


@Component({
  selector: "app-client-file",
  templateUrl: "./client-file.component.html",
  styleUrls: ["./client-file.component.scss"],
})
export class ClientFileComponent implements OnInit, OnDestroy {
  headerForm: FormGroup = null;
  clientsList: { key: number | string; value: string }[] = [];
  activeRoute$: Subscription;
  clientFiles: any[];
  clientFilesCount: any[];
  columns: string[] = [
    "serial.",
    "file name",
    "layout file",
  ];
  filesLog: FileLogModel[];
  dateRange$: Subscription;
  dataTransferModel:DataTransferModel;
  fromDt:any;
  toDt:any;
  currentPage:string='/client-file'
  dataValue:any;
  visibleDelete=false;
  visibleCleanData=false;
  modalForm: FormGroup = null;
  visible:boolean=false;
  fileName:string=null;
  layoutFieldList: any = [];
  clientId:number=null;
  clientFileId:number=null;
  clientFileMasterListVal:any=[];
  clientFileMasterList:any=[];
  cols: { field: string; header: string }[];
  status; type;
  ingestedAt:any;
  comment:any;
  rolePermission:any;

  constructor(
    private fileService: FileService,
    private loader: LoaderService,
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private datePipe: DatePipe,
    private storePermission: Store<{ role: any }>
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      //dateRange: new FormControl(currentMonthRange(), [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
    });

    this.modalForm = new FormGroup({
      clientFieMaster:new FormControl(null),
      layoutFile:new FormControl(null),
      isActive:new FormControl(null),
      comment:new FormControl(null)
     });

     this.cols = [
      { field: "fileName", header: "File Name" },
      { field: "applicationFileName", header: "Layout File" },
    ];
    this.status=["Ingested","Not Ingested"].map((d)=> ({key:d,value:d}));
    this.type=["Core","Addon"].map((d)=> ({key:d,value:d}));
     
  }
  ngOnInit(): void {
    this.buttonShowHidePermission();
    //console.log(this.dataTransferModel,' datat transfer model')
    
    // this.dateRange$ = this.headerForm
    //   .get("dateRange")
    //   .valueChanges.subscribe((data) => {
    //     if (data[0] !== null && data[1] !== null) {
    //      // this.getFilesData(this.headerForm.get("client").value, data);
    //     }
    //   });
   // this.storePermission.select(x => this.rolePermission = x).subscribe();
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getFilesData(client,fromDate,toDate) {
    this.clientFiles = this.clientFilesCount = []; // reset data
   // this.loader.showLoader();
    this.filesLog=[]; 
    //const { dateRange } = this.headerForm.value;
    //console.log(fromDate," client file ",toDate);
    const files = this.fileService
      .getFilesByClient(client.key, fromDate,toDate)
      .pipe(catchError((err) => of(err.status)));
    const filesCount = this.fileService
      .getFilesCountByClient(client.key, fromDate,toDate)
      .pipe(catchError((err) => of(err.status)));
    forkJoin([files, filesCount]).subscribe(
      (res) => {
       if(res[0] ===500 && res[1]===500){
        this.toast.showError("User is not Authorised to perform this action. Please contact to Admin", "error")
       }else{
        this.clientFiles = res[0];
        this.clientFilesCount = res[1];
        this.clientFiles.forEach(item=>{
          item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
          item.asofDate=this.datePipe.transform(item.asofDate,"MM/dd/yyyy")
          item.ingestedAt=this.datePipe.transform(item.ingestedAt,"MM/dd/yyyy")
        
         })
      }
    },
      () => this.toast.showError("Server Error", "Unable to fetch data"),
      //() => this.loader.hideLoader()
    );
    this.layoutFieldList=[];
    this.clientFileMasterList=[];
    this.clientFileMasterListVal=[];
    this.getClientFileMaster(this.headerForm.controls.client.value.key);
    this.getClientAppFile(this.headerForm.controls.client.value.key);
   // this.dataTransferModel.isRedirected=0;
  }
  navigateToPage(page: string, data: any) {
   
  this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
  this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
  this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    const fileData = {
      clientId: data.clientId,
      clientFile: data.clientApplicationFileId,
      fileName: data.fileName,
      clientName: this.headerForm.value.client.value,
      fromDate:this.headerForm.controls.fromDate.value,
      toDate:this.headerForm.controls.toDate.value,
      pageName:this.currentPage
    };
    this.router.navigate([page], {
      queryParams: { ...fileData },
    });
  }
  getLog(id) {
    this.loader.showLoader();
  
    this.fileService.getLogByClientFieldId(id).subscribe(
      (data) => {
        this.filesLog = data;
        
        this.loader.hideLoader();
      },
      () => this.loader.showLoader()
    );
  }
  ngOnDestroy() {
    try{
      this.activeRoute$.unsubscribe();
    }catch(ex){
      
    }
    
   // this.dateRange$.unsubscribe();
  }

  ingestRuleBySequence(data: any) {
    const clientFileModel = {
      clientId: data.clientId,
      clientApplicationFileId: data.clientApplicationFileId,
      clientFileId: data.clientfileid,
      logflag: true,
    };

    this.loader.showLoader();
    this.fileService.ingestRuleBySequence(clientFileModel).subscribe(
      (data) => {
        this.toast.showSuccess('success','Ingested success');
        this.loader.hideLoader();
        this.clientFiles=[];
        this.getFilesData(this.headerForm.get("client").value,this.headerForm.value.fromDate,this.headerForm.value.toDate);
      },
      (err) =>{
       // console.log(err.error.message,' errorororoor');
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader()
      } 
    );
  }

  testAllRules(data: any) {
    console.log("insie testAllRules data", data);
    console.log("filename: ",data.fileName);
    let fileName=data.fileName;
    const clientFileModel = {
      clientId: data.clientId,
      clientApplicationFileId: data.clientApplicationFileId,
      clientFileId: data.clientfileid,
      logflag: false,
      isTestAll:true
    };

    this.loader.showLoader();
    this.fileService.testAllRulesBySequence(clientFileModel).subscribe(
      (data) => {
        this.toast.showSuccess('success','Test Success');
        this.loader.hideLoader();
        this.downloadExcel(fileName);
       
        
      },
      (err) =>{
       // console.log(err.error.message,' errorororoor');
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader()
      } 
    );
  }

  downloadExcel(fileName){
    let downloadSuccess:boolean=false;
    var link=document.createElement('a');
    link.href=environment.baseUrl+"/ingestRule/download/"+fileName;
   // document.body.appendChild(link);
    link.download="TestResult.xlsx";
    link.click();
  }

  changeToDate(e){
    this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
      this.getFilesData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
    //this.maintainStateOnDateChange(e,'toDateChange');
  }

  changeFromDate(e){

    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
      this.getFilesData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //this.maintainStateOnDateChange(e,'fromDateChange');
  }

  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
    this.headerForm.controls.toDate.setValue(currentDateRange[1]);
   //console.log(this.headerForm.controls.fromDate.value,'  current date range ',this.headerForm.controls.toDate.value);
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
  }


  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }

  downloadAnyFile(clientFileId){
  this.loader.showLoader();
  let downloadSuccess:boolean=false;
  var link=document.createElement('a');
  link.href=environment.baseUrl+"/files/downloadClientFile/"+clientFileId;      
  link.click();
  this.toast.showSuccess('success','Download Begin Shortly'); 
  this.loader.hideLoader();
}

showDeletePopup(data){
  this.dataValue=data;
  this.visibleDelete=true;
}

hidePopup(){
  this.dataValue=null;
  this.visibleDelete=false;
}

showCleanDataPopup(data){
  this.dataValue=data;
  this.visibleCleanData=true;
}

hideCleanDataPopup(){
  this.dataValue=null;
  this.visibleCleanData=false;
}

onClientChange(event){
  this.getFilesData(event, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
  this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  //this.maintainClientState(event,this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value);
}

deleteClientFile(clientFileId){
  const requestModel={
    "clientFileId":this.dataValue
  }
  this.loader.showLoader();
  this.fileService.deleteClientFile(requestModel)
  .subscribe(
    data=>{
      this.loader.hideLoader();
      this.toast.showSuccess('success','Client File deleted');
      this.visibleDelete=false;
      this.getFilesData(this.headerForm.get("client").value,this.headerForm.value.fromDate,this.headerForm.value.toDate);
    },
    err=>{
      this.loader.hideLoader();
      this.visibleDelete=false;
      this.toast.showError('error',err.error.message);
    }
    
  )

}

getLayoutField(clientId){
  console.log(this.clientId);
  this.fileService.getAppFilesByClientId(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}

doAction(type) {
  if (type === "yes") {
    console.log("helloo");
    this.visible = false;
   this.modalForm.reset();
       }else{
    this.modalForm.reset();
  }
}


showEditFile(clientFile){
  this.visible = true;
 // this.layoutFieldList=[];
  this.fileName=clientFile.fileName;
 // this.getClientAppFile(clientFile.clientId);
  //this.getClientFileMaster(clientFile.clientId);
  this.clientId=clientFile.clientId;
 // this.clientName=this.modalForm.controls.client.va
  this.clientFileId=clientFile.clientfileid;
  const defaultMap = {
    key: clientFile.clientApplicationFileId,
    value: clientFile.applicationFileName,
  };
  this.defaultMapFormasterFile(clientFile);

  // this.modalForm.get("layoutFile").patchValue({
  //   key: clientFile.clientApplicationFileId,
  //   value: clientFile.fileName,
  // });
 this.modalForm.controls.layoutFile.setValue(defaultMap);  
 this.modalForm.controls.comment.setValue(clientFile.comment);
 this.modalForm.controls.isActive.setValue(clientFile.active);  
 //console.log(this.modalForm.controls);
}

// editClientFile(){
// console.log(this.modalForm.controls)
// }

editClientFile(){
   const clientFile={
     "clientFileId":this.clientFileId,
     "client_id":this.clientId,
     "fileName":null,
     "clientApplicationFileId":this.modalForm.controls.layoutFile.value.key,
     "createdBy":1, 
     "clientFileMasterId":this.modalForm.controls.clientFieMaster.value!==null ? this.modalForm.controls.clientFieMaster.value.key:0,
    "isActive":this.modalForm.controls.isActive.value,
    "comment":this.modalForm.controls.comment.value
  }
   this.loader.showLoader();

   this.fileService.editClientFile(clientFile)
   .subscribe(
     data=>{
       this.toast.showSuccess('Updated Successfully','Success');
       this.clientId=null;
       this.visible=false;
       this.modalForm.reset();
       this.loader.hideLoader();
       this.getFilesData(this.headerForm.get("client").value,this.headerForm.value.fromDate,this.headerForm.value.toDate);
       //  this.fromCalVariable.nativeElement.value = "";
      
     },
     error=>{
       this.toast.showError(error.error.message, "error");
       this.loader.hideLoader();
     },
    
   );
   
 }


 getClientAppFile(clientId){
  console.log(this.clientId);
  this.fileService.getclientAppFile(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
    }
  
  );

}


getClientFileMaster(clientId){
  console.log(this.clientId);
  this.fileService.getclientFileMaster(clientId).subscribe(
    (data) => {
      const clientFileMasterLitData=data;
      for(var a=0;a<clientFileMasterLitData.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
      this.clientFileMasterListVal.push({ key: clientFileMasterLitData[a].clientfilemasterId, value: clientFileMasterLitData[a].name ,fileDataTypeId:clientFileMasterLitData[a].fileDataTypeId,fileTypeId:clientFileMasterLitData[a].fileTypeId});
      }
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
    }
  
  );

}
  defaultMapFormasterFile(clientFile){
 //   const values=this.clientFileMasterList;
 this.clientFileMasterList=[];
for(var a=0;a<this.clientFileMasterListVal.length;a++){
console.log(" id "+this.clientFileMasterList)

if(this.clientFileMasterListVal[a].fileDataTypeId=== clientFile.fileDataTypeId && this.clientFileMasterListVal[a].fileTypeId===clientFile.fileTypeId){
  this.clientFileMasterList.push({ key: this.clientFileMasterListVal[a].key, value: this.clientFileMasterListVal[a].value ,fileDataTypeId:this.clientFileMasterListVal[a].fileDataTypeId});
}
}
for(var a=0;a<this.clientFileMasterList.length;a++){
if(this.clientFileMasterList[a].key===clientFile.clientFileMasterId){
  const defaultMasterMap = {
    key: this.clientFileMasterList[a].key,
    value: this.clientFileMasterList[a].value,
  };
  this.modalForm.get("clientFieMaster").setValue(defaultMasterMap);
}
}
  }

  onLayoutChange(event){
    this.modalForm.controls.clientFieMaster.reset();
  }

  maintainClientState(client, fromDate, toDate){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: toDate,
      fromDate: fromDate,
      clientName: client.value,
      page: '',
      fileName:'',
      workflowName:'',
      workflowId:0,
      dataType:'',
      dataTypeId:0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  /*maintainStateOnDateChange(newDate,typeOfRequest){
    if(typeOfRequest==='fromDateChange'){
      this.dataTransferModel.fromDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
    if(typeOfRequest==='toDateChange'){
      this.dataTransferModel.toDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
  }*/

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    //console.log("cookies check ",this.cookieService.get('toDaaaate')===null)
    //console.log("cookies check ",this.cookieService.get('toDaaaate'))
    // console.log(clientIdC);
    // console.log(clientNameC);
    // console.log("from date ",fromDatec);
    // console.log("todate ",toDatec);
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
    }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
       // console.log("hello")
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
       this.headerForm.controls.fromDate.setValue(frm)
       this.headerForm.controls.toDate.setValue(toDt)
    }else{
      this.loadDate();
    }

   // this.loadDate();

  //console.log("client Value ",this.headerForm.get("client").value);
  if(!isEmpty(this.headerForm.get("client").value.key)){
    this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
  }
   
    // if(fromDatec!==null && toDatec!==null){
    //   this.fromDt = new Date(fromDatec);
    //   this.toDt = new Date(toDatec);
    //   this.headerForm.patchValue({
    //             fromDate: this.fromDt,
    //             toDate: this.toDt,
    //           });
    // this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
    // }else{
    //   this.loadDate();
    // }
    
    
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     this.fromDt = new Date(this.dataTransferModel.fromDate);
    //     this.toDt = new Date(this.dataTransferModel.toDate);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && isNaN(this.fromDt)===false && isNaN(this.toDt)===false) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.headerForm.patchValue({
    //         fromDate: this.fromDt,
    //         toDate: this.toDt,
    //       });
    //       this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
    //     }

    //     else if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && (isNaN(this.fromDt)===true || isNaN(this.toDt)===true)) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.loadDate();
    //       this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
    //     }

    //     else {
    //       this.loadDate();
    //     }
    //   });
    }

    cleanData(dataValue){
      console.log("clean data "+this.dataValue);
      const requestModel={
        "clientFileId":this.dataValue.clientfileid,
        "clientId":this.dataValue.clientId,
        "clientFileDataTypeId":this.dataValue.fileDataTypeId,
      }
      this.fileService.cleanData(requestModel) .subscribe(
        data=>{
          this.loader.hideLoader();
          this.toast.showSuccess('success','Client File Data Deleted');
          this.visibleCleanData=false;
          this.getFilesData(this.headerForm.get("client").value,this.headerForm.value.fromDate,this.headerForm.value.toDate);
        },
        err=>{
          this.loader.hideLoader();
          this.visibleCleanData=false;
          this.toast.showError('error',err.error.message);
        }
        
      )
    
    }
 
    downloadFileUnmatchedAcct(clientFileId){
      this.loader.showLoader();
      let downloadSuccess:boolean=false;
      var link=document.createElement('a');
      link.href=environment.baseUrl+"/ingestRule/downloadUnmachedAcctAddonFile/"+clientFileId;      
      link.click();
      this.toast.showSuccess('success','Download Begin Shortly'); 
      this.loader.hideLoader();
    }



    buttonShowHidePermission(){
      this.fileService.buttonShowHidePermission().subscribe(
        (res) =>{ 
             this.rolePermission = res  
             this.callNgOnInitMethods();
     }
      )};



      callNgOnInitMethods(){

    this.getPreviousState();
    this.setTitle();

      }

}