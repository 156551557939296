import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup-custom-table',
  templateUrl: './setup-custom-table.component.html',
  styleUrls: ['./setup-custom-table.component.scss']
})
export class SetupCustomTableComponent implements OnInit {

  cols: { field: string; header: string }[];

  data = [
    {
      changeTypeName: "MV WF1 Job1",
      changeName: "Mission Valley job1",
      target: "/api/transform4",
      changeList: "Auto",
      modifiedUsername: "Custom",
      createdTime: "Yes",
      
    },
    {
      changeTypeName: "MV WF1 Job2",
      changeName: "Mission Valley job2",
      target: "/api/transform3",
      changeList: "Auto",
      modifiedUsername: "Output",
      createdTime: "No",
      
    },
    {
      changeTypeName: "MV Wf1 Job4",
      changeName: "Mission Valley job4",
      target: "/api/transform2",
      changeList: "Manual",
      modifiedUsername: "Transform",
      createdTime: "No",
      
    },
    {
      changeTypeName: "MV Wf1 Job5",
      changeName: "Mission Valley job5",
      target: "/api/transform1",
      changeList: "Manual",
      modifiedUsername: "Ingest",
      createdTime: "Yes",
      
    },
    
    
  ];
  constructor() { 
    this.cols = [
      { field: "changeTypeName", header: "Job Name" },
      { field: "changeName", header: "Desc" },
      { field: "target", header: "Target" },
      { field: "changeList", header: "Trigger" },
      { field: "modifiedUsername", header: "Type" },
      { field: "createdTime", header: "Enabled" }
    ];
  }

  ngOnInit(): void {
  }

}
