import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-auto-complete2',
  templateUrl: './auto-complete2.component.html',
  styleUrls: ['./auto-complete2.component.scss']
})
export class AutoComplete2Component implements OnInit {

  @Input() ctrl: FormControl;
  @Input() data: any[];
  @Input() label: string;
  @Input() blockLabel: string = "inline-block";
  @Input() value: any;
  @Input() inline: boolean;
  @Output() onSelect = new EventEmitter();
  @Input() groupInline: boolean = false;
  @Input() disabled: boolean = false;

  filteredData: any[];
  className = "form-group";
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.inline && changes.inline.currentValue) {
      this.className = "form-inline";
    }
    if (changes && changes.groupInline) {
      this.className = "form-group form-inline";
    }
    // if (changes && changes.data && changes.data.currentValue) {
    //   this.ctrl.setValue({ key: "", value: "" });
    // }
  }
  filterData(event) {
    let query = event.query;
    this.filteredData = this.data.filter(
      (d) => String(d.value).toLowerCase().indexOf(query.toLowerCase()) === 0
    );
  }
  triggerSelectEvent(val) {
    this.onSelect.emit(val);
  }
  clearField() {
    this.ctrl.setValue(null);
  }

}
