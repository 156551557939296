import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-auto-complete",
  templateUrl: "./auto-complete.component.html",
  styleUrls: ["./auto-complete.component.scss"],
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input() ctrl: FormControl;
  @Input() data: any[];
  @Input() label: string;
  @Input() value: any;
  @Input() inline: boolean;
  @Input() size:any;
  @Output() onSelect = new EventEmitter();
  @Input() groupInline: boolean = false;

  filteredData: any[];
  className = "form-group";
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.inline && changes.inline.currentValue) {
      this.className = "form-inline";
    }
    if (changes && changes.groupInline) {
      this.className = "form-group form-inline";
    }
    // if (changes && changes.data && changes.data.currentValue) {
    //   this.ctrl.setValue({ key: "", value: "" });
    // }


  }
  filterData(event) {
    let query = event.query;
    this.filteredData = this.data.filter(
     

      (d) => String(d.value).toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  triggerSelectEvent(val) {
    this.onSelect.emit(val);
  }
}
