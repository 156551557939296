import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import {ListingTableComponent} from "../listingComp/components/listing-table/listing-table.component";
import { ListingSelectorComponent } from './components/listing-selector/listing-selector.component'


@NgModule({
  declarations: [
    ListingTableComponent,
    ListingSelectorComponent
  
  ],
  imports: [SharedModule],
  exports: [
    ListingTableComponent,
    ListingSelectorComponent
   
  ],
  providers: []
})
export class ListingModule {}
