import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { DatepickerComponent } from 'src/app/formsy/components/datepicker/datepicker.component';
import { RulesService } from 'src/app/ingestion-rules/services/rules.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { currentMonthRange, formatDate, getCurrentDate } from 'src/app/utils/dates';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ValidationLogComponent } from 'src/app/validation-rules/validation-log/validation-log.component';
import { ValidationRulesFormComponent } from 'src/app/validation-rules/validation-rules-form/validation-rules-form.component';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { ValidationRuleService } from '../../services/validation-rule.service';

@Component({
  selector: 'app-validation-rule',
  templateUrl: './validation-rule-page.component.html',
  styleUrls: ['./validation-rule-page.component.scss']
})
export class ValidationRulePageComponent implements OnInit {
  @ViewChild(ValidationRulesFormComponent, { read: false, static: false })
  validationRulesFormComponent: ValidationRulesFormComponent;

  @ViewChild(ValidationLogComponent, { read: false, static: false })
  validationLogComponent: ValidationLogComponent;

  activeRoute$:Subscription
  fieldMappings: any[];
  headerForm:FormGroup;
  clientId:number;
  fileLayout:any;
  fields: { key: string | number; value: string }[];
  fileDataTypeList: any = [];
  showDataType:boolean=false;
  fieldsData:any;
  dataList:any;
  editDataList:any;
  ruleIdList:any[];
  obj:any;
  dataTransferModel: DataTransferModel;
  validationRuleLog:any;
  rolePermission:any;
@Input() apply;
@Input() edit;
@Input() deactivate;
@Input() logData;


  constructor(private titleService:TitleService,private activeRoute:ActivatedRoute,
    public observalbleDataService: ObservalbleDataService,
    private fileService:FileService,private loader:LoaderService,private validationService:ValidationRuleService
    ,private ruleService:RulesService,
    private toast:ToastService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>) {

      this.headerForm = new FormGroup({
        client: new FormControl(),
        fileType: new FormControl(),
        asOfDate:new FormControl()
      });

      
    }


  ngOnInit(): void {
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      data => this.titleService.setTitle(data["title"]),
      err => console.log("error", err)
    );
  }

  getFiles() {
    this.clientId = this.headerForm.value.client.key;
    this.headerForm.get("file").reset();
  }

  getRules(id) {
    var clientId=id.key;
    this.loader.showLoader();
    // this.ingestRuleId=null;
    // this.ingestionRules=[]
    // this.showTemplateDropdown=false
    // this.createRule();
    this.ruleService.getIngestionRulesById(clientId).subscribe(
      data => {
        if(data!==null && data.length>0){
       // this.showTemplateDropdown=false
      // this.ingestionRules = data;
       // this.ruleTemplateVersion=[];
       
        }
        const { client, file } = this.headerForm.value;      
        this.getFileLayout(client.key, file.key);
        //this.getRawExcelData(client.key, file.key);
        this.loader.hideLoader();
      },
      err => {
        console.log(err);
        this.loader.showLoader();
      }
    );
  }

  getFileLayout(client, file) {
    this.loader.showLoader();
    this.fileService.getFileLayoutByClientFile(client, file).subscribe(
      data => {
        if (data === null) {
       //   this.showTemplateDropdown=false;
          //this.toast.showInfo("No Layout defined", "Select another file");
        } else {
          this.fileLayout = data;
          this.getFieldMapping(this.fileLayout.clientApplicationFileId);
        }
        this.loader.hideLoader();
      },
      err => {
        this.loader.hideLoader();
      }
    );
  }

  getFieldMapping(id) {
    this.loader.showLoader();
    this.fileService.getFieldMappingsById(id,'Validate Rule').subscribe(
      data => {
        this.fieldMappings = data;
        this.fieldsList(this.fieldMappings);
        this.loader.hideLoader();
      },
      err => {
        this.loader.hideLoader();
      }
    );
  }
  fieldsList(fieldValue){
    this.fields = this.formatFields(fieldValue);
    console.log("Parent data "+this.fields);    
  }

  formatFields(fields) {
    //changes for multiple mappings    
     //   return reMapForAutoComplete(fields, "clientFileLayoutFieldId", "fieldName");
     return reMapForAutoComplete(fields, "clientFileMappingId", "fieldName");
    }
     
    getApplicationFileType(){
      this.fileService.getApplicationDataTypeByUser().subscribe(
     (data) => {
       const fileDataType=data;
       for(var a=0;a<fileDataType.length;a++){
       // console.log("type "+fileDataType[a].type);
         this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
       
       }
     },
     (err) => {
     //  this.toast.showSuccess("Status Not Changed", "error");
     this.toast.showError(err.error.message, "error");
     }
   
   );
      
   }
   getDataType(event){
    // this.maintainClientState(event);
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    this.showDataType=true;
    this.dataList=[];
    this.headerForm.controls.fileType.reset();
    this.validationRulesFormComponent.validationRulesForm.reset();
    this.validationLogComponent.data=[];
   }

   getFields(event){
    this.fieldsData=[];
     console.log(event.key)
     const value={
       "dataTypeId":event.key,
       "connectionId":2,
     }
     this.validationService.getAllFields(value).subscribe(
       (data) => {
        // this.fieldsData=data;
         this.fieldsData = data.map(person => ({ key: person, value: person }));
         this.validationRulesFormComponent.validationRulesForm.reset();
         this.validationLogComponent.data=[];
         this.onDateChange(event);
         if(this.headerForm.controls.asOfDate.value==null){
          this.toast.showWarn("Please Select date too","error");
        }
         // console.log("applies to "+this.fieldsData)
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
     );
   }

   addRule(data){
     console.log("finally here "+data);
if(this.headerForm.controls.client.value==null && this.headerForm.controls.fileType.value==null && this.headerForm.controls.asOfDate.value==null){
  this.toast.showWarn('no file selected','error');
  return;
}
  
const requestModel={
  "ruleId":data.ruleId.value!==0?data.ruleId.value:null,
  "dataTypeId":this.headerForm.controls.fileType.value.key,
  "ruleName":data.ruleName.value,
  "validationruletypeId":data.ruleType.value.key,
  "clientId":this.headerForm.controls.client.value.key,
  "asOfDate":formatDate(this.headerForm.controls.asOfDate.value),
  "appliesTo":data.appliesTo.value.key,
  "upperConditionOperator":data.upperConditionOperator.value!==null?data.upperConditionOperator.value.key:null,
  "upperConditionValue":data.upperConditionValue.value,
  "lowerConditionOperator":data.lowerConditionOperator.value!=null?data.lowerConditionOperator.value.key:null,
  "lowerConditionValue":data.lowerConditionValue.value,
  "overrideValue":data.overrideValue.value,
  "lowerConditionAppliesTo":data.lowerConditionAppliesTo.value!==null?data.lowerConditionAppliesTo.value.key:null,
  "upperConditionAppliesTo":data.upperConditionAppliesTo.value!==null?data.upperConditionAppliesTo.value.key:null,
  "overrideType":data.overrideType.value.key,
  "overrideAppliesTo":data.overrideAppliesTo.value!==null?data.overrideAppliesTo.value.key:null
}

this.validationService.saveValidationRuleData(requestModel).subscribe(
  (data) => {
   this.getRule();
   this.validationRulesFormComponent.validationRulesForm.reset();
   this.validationRulesFormComponent.showOverrideApplies=false;
   this.validationRulesFormComponent.showOverrideValue=false;
   this.toast.showSuccess('Saved successfully','success');
   this.validationLogComponent.data=[];
    //this.validationRulesFormComponent.getAllRuleType();
},
(err) => {
 this.toast.showError(err.error.message, 'error');
}
);
}

onDateChange(event){
  this.loader.showLoader();
  this.dataList=[];
  if(this.headerForm.controls.client.value==null && this.headerForm.controls.fileType.value==null){
    this.toast.showWarn('please select client and data type','error');
    return;
  }
  const requestData={
  "clientId":this.headerForm.controls.client.value.key,
  "asOfDate":formatDate(this.headerForm.controls.asOfDate.value),
  "dataTypeId":this.headerForm.controls.fileType.value.key,
  }
  this.validationService.getAllValidationRules(requestData).subscribe(
    (data) => {
      this.dataList = data;
      this.loader.hideLoader();
      this.validationLogComponent.data=[];
    },
   (err) => {
    this.toast.showError('Error Occured', 'error');
    this.loader.hideLoader(); 
  }
   );
   }

   loadDate(){
    let currentDateRange=new Date;
    console.log(currentDateRange,'  current date range');
    this.headerForm.controls.asOfDate.setValue(currentDateRange);
  }

  applyRule(data){
    const dataVal={
    "client_id":this.headerForm.controls.client.value.key,
    "asofdate":formatDate(this.headerForm.controls.asOfDate.value),
    "datatype_id":data.dataTypeId,
    "id":data.ruleId,
    "status":data.status

    }
    this.validationService.applyValidationRule(dataVal).subscribe(
      (data)=> {
        this.obj=data;
       this.validationRulesFormComponent.validationRulesForm.reset();
       this.validationLogComponent.data=[];
    if(this.obj.status==200){
       this.toast.showSuccess(this.obj.msg,'success');
    }else{
      this.toast.showError(this.obj.msg, 'error');
    }
     },
     (err) => {
      this.toast.showError('Error Occured', 'error');
     }
     );
     }

     editRule(data){
      this.validationService.getValidationRuleById(data.ruleId).subscribe(
        (data) => {
          this.editDataList = data;
          if(this.editDataList.validationruletypeId===2){
            this.validationRulesFormComponent.showConditionAppliesTo=true;
          }else{
            this.validationRulesFormComponent.showConditionAppliesTo=false; 
          }
       },
       (err) => {
        this.toast.showError('Error Occured', 'error');
       }
       );
       }

       getPreviousState() {
         this.loadDate();
         const clientIdC=this.cookieService.get('clientId');
        const clientNameC=this.cookieService.get('clientName');
        console.log(clientIdC);
        console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });   
         this.getDataType(null);            
    }
        // this.observalbleDataService.inventoryChanged$.subscribe(
        //   data => {
        //     this.dataTransferModel = data;
        //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
        //       this.headerForm.get("client").patchValue({
        //         key: this.dataTransferModel.clientId,
        //         value: this.dataTransferModel.clientName,
        //       });
        //       this.loadDate();
        //       this.showDataType=true;
        //       this.dataList=[];
        //       this.headerForm.controls.fileType.reset();
        //       this.validationRulesFormComponent.validationRulesForm.reset();
        //     }
        //     else{
        //       this.loadDate();
        //     }
        //   });
      }

      maintainClientState(client){    
        this.dataTransferModel = {
          clientFileId: 0,
          clientId: client.key,
          isRedirected: 1,
          applicationId: 0,
          toDate: '',
          fromDate: '',
          clientName: client.value,
          page: '',
          fileName: '',
          workflowName: '',
          workflowId: 0,
          dataType: '',
          dataTypeId: 0
        }
        this.observalbleDataService.addToInventory(this.dataTransferModel);
      }

       getRule(){
        this.dataList=[]; 
        if(this.headerForm.controls.client.value==null && this.headerForm.controls.fileType.value==null){
            this.toast.showWarn('please select client and data type','error');
            return;
          }
          const requestData={
          "clientId":this.headerForm.controls.client.value.key,
          "asOfDate":formatDate(this.headerForm.controls.asOfDate.value),
          "dataTypeId":this.headerForm.controls.fileType.value.key,
          }
        this.loader.showLoader();
          this.validationService.getAllValidationRules(requestData).subscribe(
            (data) => {
              this.dataList = data;
              console.log("dataList "+this.dataList);
              this.loader.hideLoader();
           },
           (err) => {
            this.toast.showError('Error Occured', 'error');
            this.loader.hideLoader();
           }
           );
           }

           showPopup(){
             this.validationRulesFormComponent.validationRulesForm.reset();
           }


           statusChange(data){
            const dataVal={
               "status":data.status,
               "ruleId":data.ruleId,
               "clientId":data.clientId
              }
            
             this.validationService.updateValidationRuleStatus(dataVal).subscribe(
              (data) => {
               this.getRule();
              //  this.validationRulesFormComponent.validationRulesForm.reset();
              //  this.validationRulesFormComponent.showOverrideApplies=false;
              //  this.validationRulesFormComponent.showOverrideValue=false;
               this.toast.showSuccess('Updated successfully','success');
                //this.validationRulesFormComponent.getAllRuleType();
            },
            (err) => {
             this.toast.showError(err.error.message, 'error');
            }
            );
            
           }


           getLogData(data){
            this.validationService.getLogDataByRuleId(data).subscribe(
              (data) => {
              this.validationRuleLog=data;
              if(this.validationRuleLog.length==0){
                this.toast.showSuccess('No Log Present','success');
              }else{
                this.toast.showSuccess('Log Fetched successfully','success');
              }        
            },
            (err) => {
             this.toast.showError(err.error.message, 'error');
            }
            );
            
           }

          //  testAll(){
          //    console.log("here" +this.dataList.ruleId);
          //    for(let data=0;data<this.dataList.length;data++){
          //      this.ruleIdList.push(this.dataList[data].ruleId);
          //    }
          //    const dataVal={
          //     "client_id":this.headerForm.controls.client.value.key,
          //     "asofdate":formatDate(this.headerForm.controls.asOfDate.value),
          //     "ruleIds":this.ruleIdList
          //     }

          //    this.validationService.applyAllValidationRule(dataVal).subscribe(
          //     (data) => {
          //       this.ruleIdList = data;
          //       console.log("dataList "+this.ruleIdList)
          //    },
          //    (err) => {
          //     this.toast.showError("Error Occured", "error");
          //    }
          //    );
          //    }

          buttonShowHidePermission(){
            this.fileService.buttonShowHidePermission().subscribe(
              (res) =>{ 
                   this.rolePermission = res  
                   this.callNgOnInitMethods();
           }
            )};
          
            callNgOnInitMethods(){
              this.setTitle();
              this.getApplicationFileType();
              this.getPreviousState();
              this.loadDate();
            }
        }