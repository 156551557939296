import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CleanDataConfigModel } from '../models/cleanDataConfig.model';

@Injectable({
  providedIn: 'root'
})
export class CleanDataConfigService {

  constructor(private http: HttpClient) { }

  getAll(data) {
    return this.http.post<CleanDataConfigModel[]>(environment.baseUrl + "/apis/cleanData/getAllCleanData",data);
  }

  getById(data){
    return this.http.post<CleanDataConfigModel>(environment.baseUrl + "/apis/cleanData/getById",data);
  }

  getByClientId(data){
    return this.http.post<CleanDataConfigModel[]>(environment.baseUrl + "/apis/cleanData/getByClientId",data);
  }

  saveData(data){
    return this.http.post<CleanDataConfigModel>(environment.baseUrl + "/apis/cleanData/save",data);
  }

  deleteById(data){
    return this.http.post<any>(environment.baseUrl + "/apis/cleanData/deleteByIds",data);
  }

}
