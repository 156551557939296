export const environment = {
  production: false,
  logo: "/assets/images/logo.jpg",
  baseUrl: "https://process1.pcbb.com",
  firebase: {
    apiKey: "AIzaSyBVDVvHifrD241puPr9nXfJPnADqeKEmT8",
    authDomain: "pcbb-janus-test-1.firebaseapp.com",
    databaseURL: "https://pcbb-janus-test-1.firebaseio.com",
    projectId: "pcbb-janus-test-1",
    storageBucket: "pcbb-janus-test-1.appspot.com",
    messagingSenderId: "810550008433",
    appId: "1:810550008433:web:c4c045781277fdb13859d0",
    measurementId: "G-T9LS0G5SJR"
  }
};
