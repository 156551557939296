import { Component, Input, OnInit ,Output,EventEmitter} from '@angular/core';
import { TabModel } from 'src/app/dashboard/models/audit.model';
import { WorkflowQueueDataModel } from 'src/app/dashboard/models/workflow.model';

@Component({
  selector: 'app-queue-table',
  templateUrl: './queue-table.component.html',
  styleUrls: ['./queue-table.component.scss']
})
export class QueueTableComponent implements OnInit {

  @Input() data:WorkflowQueueDataModel[];
  @Output() removeWorkflow=new EventEmitter();
  @Output() disableJob=new EventEmitter();
  @Output() stopWorkflow=new EventEmitter();
  
  rowData:any=null;
  jobData:any=null;
  headerData2:string="Disable Job";
  headerData:string="Remove Workflow From Queue"



  visible:boolean=false;
  visible2:boolean=false;

 
  constructor() { }

  cols: { field: string; header: string }[];
  columns = ["Workflow","Client/Job Name","Type","Status/Msg","Enabled","Continue On Error","Created At","Created By","Action"];
  ngOnInit(): void {
  }

  showPopUp(data,val){
    this.rowData=data;
    
    this.visible=true;
    
    
  }

  showPopUpforDisable(data,jobData){
        this.rowData=data;
        this.jobData=jobData;
        this.visible2=true;
  }

  hidePopup(data){
    this.rowData=null;
     this.jobData=null;
     this.visible=false;
     this.visible2=false;
  }

  actionDo2(data){

    const requestData={
      "queueId":this.rowData.workflowQueueId,
      "workflowId":this.rowData.workflowId,  
      "workflowDetailId":this.jobData.workflowDetailId    
   }
   this.disableJob.emit(requestData);
   this.hidePopup("data");
  }

  actionDo(data){
    const requestData={
      "queueId":this.rowData.workflowQueueId,
      "workflowId":this.rowData.workflowId,      
   }
   this.removeWorkflow.emit(requestData);
   this.hidePopup("data");
  }
  

}
