import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { WorkflowJobListModel, WorkflowModel, WorkflowJobDetails, IngestJob, WorkflowResponseModel, WorkflowTableField, WorkflowLogsTableField, WorkflowDetailParam, CustomJobs, WorkflowQueueDataModel, WorkflowIngestCustomListModel } from '../models/workflow.model';
import { FileLogModel } from '../models/filelog.model';
import { SchedulerWorkFlow } from '../pages/scheduler/scheduler.model';
import { formatDate } from 'src/app/utils/dates';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  constructor(private http: HttpClient) { }
  getAllWorkflow(requestModel) {
    return this.http.post<WorkflowModel[]>(environment.baseUrl + "/apis/workflow/getAllWorkflow", requestModel)
  }


  getAllWorkflowJob(requestModel) {
    return this.http.post<WorkflowJobDetails[]>(environment.baseUrl + "/apis/workflow/getAllWorkflowJob", requestModel)
  }


  getAllIngestJobByClientId(requestModel) {
    return this.http.post<IngestJob[]>(environment.baseUrl + "/apis/workflow/getAllIngestJobByClientId", requestModel)
  }
  ///wf-job-page//////////////////////////////////////////////////////////////////////////////////////////

  getWorkflowByClientNameDataType(workflowResponseModel) {
    console.log("Data Passed Is:", workflowResponseModel);
    //console.log(workflowResponseModel,' service getLookupColumnDataByTable')
    return this.http.post<WorkflowResponseModel[]>(
      `${environment.baseUrl}/apis/workflowJob/getWorkflowByClientNameDataType`, workflowResponseModel
    );

  }

  getWorkflowById(workflowData) {
    console.log(workflowData, ' service getWorkflowJobById')
    return this.http.post<WorkflowTableField>(
      `${environment.baseUrl}/apis/workflowJob/getWorkflowById`, workflowData
    );

  }

  saveWorkflowById(workflowData) {
    console.log(workflowData, ' service saveLookupColumnData')
    return this.http.post<WorkflowTableField>(
      `${environment.baseUrl}/apis/workflowJob/saveWorkflowById`, workflowData
    );
  }



  // getWorkflowLogsByWorkflowId(workflowLogsData){
  //   console.log(workflowLogsData,' service getWorkflowJobById')
  //   return this.http.post<any[]>(
  //     `${environment.baseUrl}/apis/workflowJob/getWorkflowLogsByWorkflowId`,workflowLogsData
  //   );  

  // }


  // return this.http.post<any[]>(  //changed
  getWorkflowLogsByWorkflowId(workflowLogsData) {
    console.log(workflowLogsData, ' service getWorkflowJobById')
    return this.http.post<WorkflowLogsTableField[]>(
      `${environment.baseUrl}/apis/workflowJob/getWorkflowLogsByWorkflowId`, workflowLogsData
    );

  }

  saveWorkflowDetails(workflowData) {

    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/saveWorkflowDeatils`, workflowData
    );
  }

  getWorkflowDetailsById(data) {
    return this.http.post<WorkflowJobDetails>(
      `${environment.baseUrl}/apis/workflow/getWorkflowDeatilsById`, data
    );
  }


  saveIngestJob(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/saveIngestJob`, data
    );
  }

  //////////////////////////////////////////////////
  getAllWorkflowList() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/apis/workflow/getAllWorkflowList`
    );
  }

  // getApplicationFileDataType(){
  //   return this.http.get<any[]>(
  //     `${environment.baseUrl}/client-application-file/getApplicationDataType`
  //   );
  // }

  getWorkflowJobLogsByJobId(workflowLogsData) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/apis/workflow/getWorkflowJobLogsByJobIdJobType`, workflowLogsData
    );
  }


  getWorkflowJobStatusData(workflowLogsData) {
    return this.http.post<WorkflowJobDetails[]>(
      `${environment.baseUrl}/apis/workflow/getAllJobListByWfIdJobDataType`, workflowLogsData

    );
  }
  ///////////////////////////////////////////
  executeWorkflow(data) {
    return this.http.post<any>(
      //   `${environment.baseUrl}/apis/workflow/exceuteWorkflow`,data
      // );
      `${environment.baseUrl}/apis/workflowqueue/addtoqueue`, data
    );
  }

  deleteJob(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/deleteWorkflowDeatilsById`, data
    );
  }

  saveWorkflowParam(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/saveWorkflowDetailParam`, data
    );
  }

  getWorkflowDetailParamByDetailId(data) {
    return this.http.post<WorkflowDetailParam>(
      `${environment.baseUrl}/apis/workflow/getWorkflowDetailParamByDetailId`, data
    );
  }

  saveCustomJob(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/saveCustomJobs`, data
    );
  }


  getCustomJobsByClient(data) {
    return this.http.post<CustomJobs[]>(
      `${environment.baseUrl}/apis/workflow/getAllCustomJobByClientId`, data
    );
  }

  //NOT NEEDED (old)
  getLogByClientFieldId(id: number) { //not this
    return this.http.get<FileLogModel[]>(
      `${environment.baseUrl}/files/findbyclientid/${id}`
    );
  }


  //workflow-job page:  View detail Log
  getIngestRuleLogsByWorkflowJobLogId(id: number) {//use this
    return this.http.get<FileLogModel[]>(
      `${environment.baseUrl}/files/findbyWorkflowJobLogId/${id}`
    );
  }

  //workflow-job page:  View detail Log 2
  getIngestRuleLogsByWorkflowJobLogId2(requestModel) {//use this
    return this.http.post<FileLogModel[]>(
      `${environment.baseUrl}/files/findbyWorkflowJobLogId`, requestModel
    );
  }

  executeSingleJob(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/runSingleJob`, data
    );
  }

  updateWorkflowJobSequence(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/updateWorkflowJobSequence`, data
    );
  }

  resumeWorkflow(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/resumeWorkflowExecution`, data
    );
  }

  getAllWorkflowQueue(data) {
    return this.http.post<WorkflowQueueDataModel[]>(
      `${environment.baseUrl}/apis/workflowqueue/getAll`, data
    );
  }

  removeWorkflowFromQueue(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflowqueue/removeWorkflow`, data
    );
  }

  disableWorkflowJobFromQueue(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflowqueue/disableJob`, data
    );
  }

  getAllJobList(data) {
    return this.http.post<WorkflowIngestCustomListModel[]>(
      `${environment.baseUrl}/apis/workflow/getAllIngestCustomJobs`, data
    );
  }

  saveIngestCustomJob(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/saveIngestCustomJob`, data
    );
  }

  getIngestCustomJobById(data) {
    return this.http.post<WorkflowIngestCustomListModel>(
      `${environment.baseUrl}/apis/workflow/getIngestCustomJobById`, data
    );
  }

  deleteIngestCustomJobById(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/workflow/deleteIngestCustomJobById`, data
    );
  }

  stopWorkflowFromQueue() {

    return this.http.get<any>(
      `${environment.baseUrl}/apis/workflowqueue/stopWorkflow`
    );
  }



  saveScheduler(schedulerModel) {

    return this.http.post<any>(
      `${environment.baseUrl}/apis/saveWorkflowScheduler`, schedulerModel
    );

  }


  getScheduler() {
    return this.http.get<any>(
      `${environment.baseUrl}/apis/getAllWorkflowScheduler`
    );
  }

  getSchedulerById(id) {
    return this.http.get<SchedulerWorkFlow>(
      `${environment.baseUrl}/apis/getWorkflowSchedulerById/${id}`
    );

  }

  setSchedulerStatus(data) {

    return this.http.post<SchedulerWorkFlow[]>(
      `${environment.baseUrl}/apis/setSchedulerStatus`, data
    );

  }

  deleteScheduler(id) {
    return this.http.delete<any>(
      `${environment.baseUrl}/apis/deleteScheduler/${id}`
    );

  }

  getSchedulersByClientId(clientId: number | string, fromDate: Date,toDate:Date){
    
    const data = {
      clientId: clientId,
      startDate: formatDate(fromDate),
      endDate: formatDate(toDate),
    };
    return this.http.post<SchedulerWorkFlow[]>(
      environment.baseUrl + "/apis/schedulersByClient",
      data
    );


  }
  getSchedulerLogData(id) {
    return this.http.get<any>(
      `${environment.baseUrl}/apis/getSchedulerLogData/${id}`
    );

  }


}
