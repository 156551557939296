import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification-list-table',
  templateUrl: './notification-list-table.component.html',
  styleUrls: ['./notification-list-table.component.scss']
})
export class NotificationListTableComponent implements OnInit {

  @Input() notificationList;
  @Output() editNotification = new EventEmitter();

  @Input() rolePermission;
  

  constructor() { }

  ngOnInit(): void {
  }


  editRowNotification(data){

  this.editNotification.emit(data);
    
  }

}
