import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WorkflowService } from '../../services/workflow.service';
import { WorkflowModel, WorkflowJobDetails, IngestJob, CustomJobs } from '../../models/workflow.model';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { TransformModel } from '../../models/tranform.model';
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { SetupSelectorComponent } from 'src/app/workflows/components/setup-selector/setup-selector.component';


@Component({
  selector: 'app-workflow-setup',
  templateUrl: './workflow-setup.component.html',
  styleUrls: ['./workflow-setup.component.scss']
})
export class WorkflowSetupComponent implements OnInit {

  @ViewChild(SetupSelectorComponent, { read: false, static: false })
  setupSelectorComponent: SetupSelectorComponent;
 
  activeRoute$: Subscription;
  headerForm: FormGroup = null;
  //data:any=[];
  workflowData:WorkflowModel[]=[];
  workflowDropDown:any[]=[];
  workflowJobDetails:WorkflowJobDetails[]=[];
  ingestJobData:IngestJob[]=[];
  ingestJobDropDown:any[]=[];
  transformList: TransformModel[] = [];
  ingestJobDetails:WorkflowJobDetails[]=[];
  transformJobDetails:WorkflowJobDetails[]=[];
  customJobDetails:WorkflowJobDetails[]=[];
  outputJobDetails:WorkflowJobDetails[]=[];
  workflowJobDetailsEdit:WorkflowJobDetails=null;
  transformMapData = new Map();
  visible:boolean=false;
  addJobHeader="Add Job";
  modalForm: FormGroup = null;
  clientMasterList:any[]=[];
  isCustomJob:boolean=false;
  customJobsList:CustomJobs[]=[];
  //customJobDropDown:any[]=[];
  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private toast:ToastService,
    private loader:LoaderService,
    private fileService: FileService,
    private clientFileMasterService:ClientFileMasterService) {
    this.headerForm = new FormGroup({
      client: new FormControl(null),
      workflow: new FormControl(null),
      
    });

    this.modalForm = new FormGroup({
      jobType:new FormControl(null,Validators.required),
      jobName: new FormControl(null,Validators.required),
      jobDesc: new FormControl(null),
      clientFile: new FormControl(null),
      targetApi: new FormControl(null),
      apiMethod: new FormControl(null)
      
    });
   }

   customData=[
    { key: '1', value: "Yes" },
    { key: '0', value: "No" }
 
  ]

  apiMethodList=[
    { key: '1', value: "POST" },
    { key: '2', value: "GET" }
 
  ]

   jobTypeList=[
    { key: 'Ingest', value: "Ingest" },
    { key: 'Transform', value: "Transform" },
    { key: 'Output', value: "Output" },
    { key: 'Custom', value: "Custom" }
  ]
  
  ngOnInit(): void {
   
    this.setTitle();
    this.getAllWorkflow();
   // this.onClientChange();
    
   
    

  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllWorkflow(){
    this.workflowDropDown=[];
    this.workflowData=[];
   // this.setTitle();
    const requestModel={
      "clientId":1
    }
    
    this.workflowService.getAllWorkflow(requestModel)
    .subscribe(
      (data)=>{
        this.workflowData=data;
        
        this.workflowData.forEach(item=>{
          this.workflowDropDown.push({key:item.workflowId,value:item.workflowName})
        })
      },
      (err)=>{
       
      //  this.toast.showError('error',err.error.message);
      this.toast.showError(err.error.message, "error");
      }
    )

  }

   
  getAllWorkflowJob(){
    this.ingestJobDetails=[];
    this.transformJobDetails=[];
    this.workflowJobDetails=[];
    this.customJobDetails=[];
    this.resetForm();
    const requestModel={
      "workflowId":this.headerForm.controls.workflow.value.key
    }
    this.workflowService.getAllWorkflowJob(requestModel)
    .subscribe(
      (data)=>{
        this.workflowJobDetails=data;
        if(this.workflowJobDetails.length>0){
          if(this.headerForm.controls.client.value.key!==undefined){
            const onchange=false;
            this.getAllTransformJob(this.headerForm.controls.client.value.key,onchange);
            console.log("transform map data :"+this.transformMapData)
          }
        }
        this.workflowJobDetails.forEach(item=>{
          if(item.jobType==='Ingest'){
            this.ingestJobDetails.push(item)
          }
          if(item.jobType==='Custom'){
            this.customJobDetails.push(item)
          }
          if(item.jobType==='Transform'){
            console.log(this.transformMapData.get(item.jobId))
            const mainJobName=this.transformMapData.get(item.jobId)!==null && this.transformMapData.get(item.jobId)!==undefined?this.transformMapData.get(item.jobId):"";
            const nextJobName=this.transformMapData.get(item.nextJobId)!==null && this.transformMapData.get(item.nextJobId)!==undefined?this.transformMapData.get(item.nextJobId):"";
            const dependentJobJobName=this.transformMapData.get(item.dependentUponId)!==null && this.transformMapData.get(item.dependentUponId)!==undefined?this.transformMapData.get(item.dependentUponId):"";
            item.jobName=mainJobName;
            item.nextJob=nextJobName;
            item.dependentUpon=dependentJobJobName;
            // item.jobName=this.transformMapData.get(item.jobId);
            // item.nextJob=this.transformMapData.get(item.jobId);
            // item.dependentUpon=this.transformMapData.get(item.jobId);
            this.transformJobDetails.push(item);
            
          }
          if(item.jobType==='Output'){
            this.outputJobDetails.push(item)
          }
        })
       
      },
      (err)=>{
      //  this.toast.showError('error','server error');
      this.toast.showError(err.error.message, "error");
      }
    )    
  }

  getAllIngestJobByClientId(){
    this.ingestJobDropDown=[];
    this.ingestJobData=[];
    const requestModel={
      "clientId":this.headerForm.controls.client.value.key
    }

    this.workflowService.getAllIngestJobByClientId(requestModel)
    .subscribe(
      (data)=>{
          this.ingestJobData=data;
          this.ingestJobData.forEach(item=>{
            this.ingestJobDropDown.push({key:item.ingestjobId,value:item.jobName});
          })

      },
      (err)=>{
      //  this.toast.showError('error','server error');
      this.toast.showError(err.error.message, "error");
      }
    )
  }

  getAllTransformJob(id,onchange){
    this.transformList =[];
    this.ingestJobDropDown=[];
    this.fileService.getTransformListByClient(id)
    .subscribe(
      data=>{
        if (data && data["status"] === 200) {
          this.transformList = data["data"];
          if(onchange===true){
          this.transformList.forEach(item=>{
            this.ingestJobDropDown.push({key:item.job_id,value:item.job_name});
          })
        }else{
          this.transformList.forEach(item=>{
           this.transformMapData.set(item.job_id,item.job_name);
            })
            console.log("elese portion");
        }
        }
        this.loader.hideLoader();

      }
    )
  }

  onJobTypeChange(data){
    console.log('onJobTypeChange ',data)
    if(data.key==='Ingest'){
      this.getAllIngestJobByClientId();
    }
    if(data.key==='Transform'){
      const onchange=true;
      this.getAllTransformJob(this.headerForm.controls.client.value.key,onchange);
    }
    if(data.key==='Custom'){
      const onchange=true;
      this.getAllCustomJobByClientId();
    }
  
  }

  saveJobToWorkflow(data){

    const workflowDetail={
      "workflowdetailId":this.workflowJobDetailsEdit!==null?this.workflowJobDetailsEdit.workflowDetailId:0,
      "workflowId":this.headerForm.controls.workflow.value.key,
      "jobType":data.jobType,
      "jobId":data.jobId,
      "jobDesc":data.description,
      "trigger1":data.trigger1,
      "dependentUpon":data.dependentUpon,
      "nextjobId":data.jobId,
      "enabled":data.enabled===true?1:0,
      "target":data.target,
      "custom":data.custom,
      "frequency":data.frequency
    }
    this.saveWorkflowDetails(workflowDetail);
    
  }


  saveWorkflowDetails(data){

    this.workflowService.saveWorkflowDetails(data)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','data saved');
        this.resetForm();
        this.getAllWorkflowJob();
      },
      error=>{
      //  this.toast.showError('error','error');
      this.toast.showError(error.error.message, "error");
      }
    )

  }

  onClientChange(){
   
    this.getAllClientMasterData(this.headerForm.controls.client.value.key);
    this.getAllTransformJob(this.headerForm.controls.client.value.key,false);

   // this.getAllWorkflowJob();
    
   
  }


  getWorkflowDetailsById(data){
    const reuestModel={
      "workflowDetailId":data.workflowDetailId
    }

    this.workflowService.getWorkflowDetailsById(reuestModel)
    .subscribe(
      data=>{
          this.workflowJobDetailsEdit=data;
          const jobTypeData={
            "key":this.workflowJobDetailsEdit.jobType
          }
          this.onJobTypeChange(jobTypeData);
      },
      error=>{
       // this.toast.showError('error',error.error.message);
       this.toast.showError(error.error.message, "error");
      }
    )
  }

  editWorkflowDetails(data){

    this.getWorkflowDetailsById(data);
  }

  doAction(data){
    if(data===false){
      this.visible=false;
    }
    if(data===true){
      this.visible=true;
    }
  }

  saveJob(){
   
    // if(this.modalForm.invalid){
    //   this.toast.showError('error','jobName and file are mandatory')
    //   return;
    // }
    if(this.modalForm.controls.jobType.value!==null && this.modalForm.controls.jobType.value.key==='0')
    {
    const ingestJob={
       "ingestjobId":0,
       "jobName":this.modalForm.controls.jobName.value,
       "jobDesc":this.modalForm.controls.jobDesc.value,
       "clientId":this.headerForm.controls.client.value.key,
       "clientfileId":this.modalForm.controls.clientFile.value.key,
    }

    this.workflowService.saveIngestJob(ingestJob)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','Job Saved');
        this.getAllIngestJobByClientId();
        this.visible=false;
        this.modalForm.reset();
      },
      error=>{
       // this.toast.showError('error','error saving job');
       this.toast.showError(error.error.message, "error");
      }
    )
    }
    if(this.modalForm.controls.jobType.value!==null && this.modalForm.controls.jobType.value.key==='1'){
    
      const customJobs={
        "customJobId":0,
        "jobName":this.modalForm.controls.jobName.value,
        "description":this.modalForm.controls.jobDesc.value,
        "clientId":this.headerForm.controls.client.value.key,
        "targetApi":"",
        "apiMethod":"GET",
     }

     this.workflowService.saveCustomJob(customJobs)
     .subscribe(
       data=>{
        this.toast.showSuccess('success','Job Saved');
        this.getAllIngestJobByClientId();
        this.visible=false;
        this.modalForm.reset();
       },
       error=>{
       // this.toast.showError('error','error saving job');
       this.toast.showError(error.error.message, "error");
      }
     )
    }

  }

  getAllClientMasterData(clientId){
    console.log("clientFile master");
    this.clientMasterList=[];
    this.clientFileMasterService.getClientFileMasterListByClient(clientId).subscribe(
      (data) => {
        const clientMasterData=data;
        for(var a=0;a<clientMasterData.length;a++){
         // console.log("type "+clientApplicationFileType[a].type);
          this.clientMasterList.push({ key: clientMasterData[a].clientfilemasterId, value: clientMasterData[a].name });
        }
      },
      (err) => {
      //  this.toast.showSuccess("Status Not Changed", "error");
      this.toast.showError(err.error.message, "error");
      }
    
    );
  
  }

  deleteJobFromWorkflow(data){

    const requestModel={
      "workflowDetailId":data.workflowDetailId
    }

    this.workflowService.deleteJob(requestModel)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','Job Deleted SuccessFully');
        this.getAllIngestJobByClientId();
        this.getAllWorkflowJob();
      },
      error=>{
      //  this.toast.showError('error','error');
      this.toast.showError(error.error.message, "error");
      }
      
    )
  }

  deleteWorkflowJob(data){
     this.deleteJobFromWorkflow(data);
  }

  saveIngestParam(data){
    
    const workflowDetailParam={
      "workflowDetailParamId":data.workflowDetailParamId!==null?data.workflowDetailParamId:0,
      "workflowDetailId":data.workflowDetailId,
      "asofdate":data.asOdDate
    }

    this.workflowService.saveWorkflowParam(workflowDetailParam)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','job param saved');
        this.getAllIngestJobByClientId();
        this.getAllWorkflowJob();

      },
      error=>{
      //  this.toast.showError('error','error');
      this.toast.showError(error.error.message, "error");
      }
    )
  }

  getWorkflowParam(data){
    console.log("getworkflowparam ",data);
    const requestModel={
      "workflowDetailId":data.workflowDetailId
    }

    this.workflowService.getWorkflowDetailParamByDetailId(requestModel)
    .subscribe(
      data=>{
        console.log(data);
      }
    )
  }

  onCustomChange(e){
   
  if(this.modalForm.controls.jobType.value.key==='1'){
    console.log("true value :"+e.target.value)
    this.isCustomJob=true;
  }
  if(this.modalForm.controls.jobType.value.key==='0'){
    console.log("false value :"+e.target.value)
    this.isCustomJob=false;
  }
  }

  getAllCustomJobByClientId(){
    this.ingestJobDropDown=[];
    this.customJobsList=[];
    const requestModel={
      "clientId":this.headerForm.controls.client.value.key
    }

    this.workflowService.getCustomJobsByClient(requestModel)
    .subscribe(
      (data)=>{
          this.customJobsList=data;
          this.customJobsList.forEach(item=>{
            this.ingestJobDropDown.push({key:item.customJobId,value:item.jobName});
          })

      },
      (err)=>{
      //  this.toast.showError('error','server error');
      this.toast.showError(err.error.message, "error");
      }
    )
  }


  resetForm(){
    this.setupSelectorComponent.setupForm.reset();
    this.workflowJobDetailsEdit=null;
  }

 

}
