import { NgModule, ModuleWithProviders } from "@angular/core";
import { SidebarService } from "./services/sidebar.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MessageService } from "primeng/api";

import { TitleService } from "./services/title.service";
import { ToastService } from "./services/toast.service";
import { LoaderInterceptor } from "./services/loader-interceptor.service";
import { StoreModule as NgrxStoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { metaReducers } from "./meta-reducer";
import { TokenInterceptorService } from "./services/token-interceptor.service";

@NgModule({
  imports: [
    HttpClientModule,
    NgrxStoreModule.forRoot({}, { metaReducers }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
        })
      : [],
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        SidebarService,
        TitleService,
        ToastService,
        MessageService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptorService,
          multi: true,
        },
      ],
    };
  }
}
