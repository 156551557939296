import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setup-custom',
  templateUrl: './setup-custom.component.html',
  styleUrls: ['./setup-custom.component.scss']
})
export class SetupCustomComponent implements OnInit {
  activeRoute$: Subscription;
  headerForm: FormGroup = null;
  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
  ) { 
    this.headerForm = new FormGroup({
      client: new FormControl(null),
      
    });
  }

  
  ngOnInit(): void {
    this.setTitle();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getJobs(){

  }

  getLookupTable(){
    const lookupMap=[
      "Mission Valley Wf1",
      "Oxford Wf1",
      "CornerStone Wf1",
      "Bank1 Wf1"
    

   ];
  
    return lookupMap.map((t) => ({ key: t, value: t }));
   
  }

}
