import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { TabModel } from 'src/app/dashboard/models/audit.model';
import {AccordionModule} from 'primeng/accordion';

@Component({
  selector: 'app-audit-tabs',
  templateUrl: './audit-tabs.component.html',
  styleUrls: ['./audit-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AuditTabsComponent implements OnInit {

  @Input() tabsModel: TabModel[];
  @Input() multiple: boolean = false;
  @Input() ingestJob;
  @Input() transformJob;
  @Output() viewLogData=new EventEmitter();
  @Input() permission:any;

  cols: { field: string; header: string }[];
  cols2: { field: string; header: string }[];

// sampleData:TabModel[]=[
//   {heading:"Workflow1",status:"SUCCESS",logs:"Hello1"}
// ]
columns = ["Workflow","Job","Type","Trigger","Status","Last Job Status","Action"];
transformColumns = ["Workflow","Job","Type","Trigger","Status","Last Job Status","Action"];
  constructor() { 

   
  }

  ngOnInit(): void {
  }

  viewLogs(jobLogId,jobName,type){
      const data={
        'workflowJobLogId':jobLogId,
        'jobName':jobName,
        'type':type
      }

      console.log(" audit tab ",data);
      this.viewLogData.emit(data);
  }

}
