import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Routes } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { currentMonthRange, formatDate } from 'src/app/utils/dates';
import { environment } from 'src/environments/environment';
import { AddonUnmatchedAcctFilesService } from '../../addon-unmatched-acct-files.service';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';

@Component({
  selector: 'app-addon-unmatched-acct-files',
  templateUrl: './addon-unmatched-acct-files.component.html',
  styleUrls: ['./addon-unmatched-acct-files.component.scss']
})
export class AddonUnmatchedAcctFilesComponent implements OnInit {
  clientId:any;
  headerForm:FormGroup;
  activeRoute$:Subscription;
  addonFilesList:any;
  cols:{ field: string; header: string }[];
  rolePermission:any;
  constructor(private cookieService: CookieService,private toast:ToastService,private activeRoute:ActivatedRoute,
    private titleService:TitleService,private addonService:AddonUnmatchedAcctFilesService,private loader:LoaderService,
    private storePermission: Store<{ role: any }>,private fileService: FileService) {
    this.headerForm = new FormGroup({
      client: new FormControl(null,[Validators.required]),
      toDate: new FormControl(null,[Validators.required]),
      fromDate: new FormControl(null,[Validators.required])
    }); 

    this.cols = [
      { field: "clientName", header: "Client Name" }, 
      { field: "asOfDate", header: "Date" },
      { field: "path", header: "File Path" },
      { field: "fileName", header: "File Name" },
           ];
   }
  

   
  ngOnInit(): void {
    this.buttonShowHidePermission();
   }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  

  clientSelected(event){
    console.log("event :",event);
    this.clientId=event.key;
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    this.getAllAddonFiles(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value); 
  }

  changeFromDate(e){

    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
      this.getAllAddonFiles(this.headerForm.controls.client.value.key,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //this.maintainStateOnDateChange(e,'fromDateChange');
  }
  
  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }
  
  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
    this.headerForm.controls.toDate.setValue(currentDateRange[1]);
     //console.log(this.headerForm.controls.fromDate.value,'  from Date range');
     //console.log(this.headerForm.controls.toDate.value,'  To Date date range');
   
  }

  changeToDate(e){
    this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
      this.getAllAddonFiles(this.headerForm.controls.client.value.key,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
       }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
    //this.maintainStateOnDateChange(e,'toDateChange');
  }

  downloadFileUnmatchedAcct(rowData){
    console.log("data "+rowData);
    let downloadSuccess:boolean=false;
    var link=document.createElement('a');
    link.href=environment.baseUrl+"/ingestRule/downloadUnmachedAcctAddonFile/"+rowData.clientAddonExtractId;      
    link.click();
    this.toast.showSuccess('success','Download Begin Shortly'); 
   }

  getAllAddonFiles(clientId,fromDate,toDate){
   if(this.headerForm.valid){
    this.loader.showLoader();
    const model={
      "client_id":clientId,
      "fromDate":formatDate(fromDate),
      "toDate":formatDate(toDate),
    }

   this.addonService.getAllDataByClientId(model).subscribe(
    data=>{
      this.addonFilesList=data;
      this.loader.hideLoader();
      if(this.addonFilesList.length===0){
        this.toast.showSuccess("NO Data Found", "success");
      }else{
      this.toast.showSuccess("Data Fetched Successfully", "success");
      }
    },
      error=>{
        this.toast.showError(error.error.message, "error");
      }
  )
}else{
  this.toast.showError("client and Date Range are mandatory","error");
}
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
   
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
    }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
       // console.log("hello")
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
       this.headerForm.controls.fromDate.setValue(frm)
       this.headerForm.controls.toDate.setValue(toDt)
    }else{
      this.loadDate();
    }
  if(!isEmpty(this.headerForm.get("client").value.key)){
    this.getAllAddonFiles(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
  }
  
    }

    buttonShowHidePermission(){
      this.fileService.buttonShowHidePermission().subscribe(
        (res) =>{ 
             this.rolePermission = res  
             this.callNgOnInitMethods();
     }
      )};
    
      callNgOnInitMethods(){
        this.setTitle();
        this.loadDate();
        this.getPreviousState();
      }

}