import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';
import { CleanDataConfigModel} from 'src/app/dashboard/models/cleanDataConfig.model';


@Component({
  selector: 'app-clean-data-config-list',
  templateUrl: './clean-data-config-list.component.html',
  styleUrls: ['./clean-data-config-list.component.scss']
})
export class CleanDataConfigListComponent implements OnInit {

  // constructor() { }

  // ngOnInit(): void {
  // }

  @Input() data:CleanDataConfigModel[] = [];
  @Input() hidebutton:boolean;
  @Output() edit=new EventEmitter();
  @Output() delete=new EventEmitter();
  @Input() permission:any;

  visible:boolean=false;
  dataValue:any;

  cols: { field: string; header: string }[];
  constructor() { 
    this.cols = [
      { field: "fileName", header: "File Name" },
      { field: "startIndex", header: "Start Index" },
      { field: "length", header: "Length" },
      { field: "createdAt", header: "Created At" },
      { field: "modifiedAt", header: "Modified At" }
    ];
  }

  ngOnInit(): void {
  }

  editCleanDataConfig(data){
    console.log("hello listing",data);
    this.edit.emit(data);

  }

  deleteCleanDataConfig(data){
   // console.log('table data ',data)
    this.delete.emit(data);
  }

  
  hidePopup(e){
    this.visible=false;
    this.dataValue=null;

  }

  showPopup(data){
    this.dataValue=data;
    this.visible=true;
  }
 
  actionDo(str){
    //console.log("yes delete do "+this.dataValue);
    this.deleteCleanDataConfig(this.dataValue);
    this.dataValue=null;
    this.visible=false;
  } 

}
