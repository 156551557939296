import { Component, Input, OnInit, Output, SimpleChanges,EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ClientFileMasterService } from 'src/app/dashboard/services/clientFileMaster.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-client-file-master-list',
  templateUrl: './client-file-master-list.component.html',
  styleUrls: ['./client-file-master-list.component.scss']
})
export class ClientFileMasterListComponent implements OnInit {
  clientFileMasterList: any[] = [];
  @Input() ctrl: FormControl;
  @Output() onSelect = new EventEmitter();
  @Input() id: number;

  constructor(
    private clientfilemasterService: ClientFileMasterService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue !== null) {
      this.clientFileMasterList = [];
      this.loader.showLoader();
      this.getAllClientMasterList(changes.id.currentValue);
    }
  }
  getAllClientMasterList(id) {
    this.clientfilemasterService.getClientFileMasterListByClient(id).subscribe(
      (data) => {
        this.clientFileMasterList = reMapForAutoComplete(
          data,
          "clientfilemasterId",
          "name"
        );
        this.loader.hideLoader();
      },
      (err) => this.loader.hideLoader()
    );
  }
  getSelected(val) {
    this.onSelect.emit(val);
  }
}

