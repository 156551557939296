import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LookupModel, PopUpModel, LookUpListModel, LookUpField } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';
import { exportCSVFile } from "src/app/utils/excel";
import { DatePipe } from '@angular/common';
import { LookupSelectorComponent } from 'src/app/lookup-tables/components/lookup-selector/lookup-selector.component';
import { _isNumberValue } from '@angular/cdk/coercion';
import { ErrorModel } from '../../models/errorModel.model';
import { isNullOrUndefined } from 'util';
import {isEmpty,trim} from 'lodash';
import { LookupColumnService } from '../../services/lookup-column.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit {
  @ViewChild(LookupSelectorComponent, { read: false, static: false })

  
  lookUpSelectorComponent: LookupSelectorComponent;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  activeRoute$: Subscription;
  lookupForm: FormGroup = null;
  lookupModel:LookupModel[];
  popUpModel:PopUpModel[];
  modalForm: FormGroup=null;
  lookUpData:any;
  visible:any;
  popUp:any;
  lookUpModelList: LookUpListModel[] = [];
  selectedLookup: any;
  lookUpField:LookUpField=null;
  lookUpTable:any;
  validateForm:boolean=false;
  errorModel:ErrorModel;
  currentFileUpload: File;
  
  columnDropDownData:{ key: string | number; value: string }[];
  columnDropDownData2:{ key: string | number; value: string }[];
  dataTransferModel: DataTransferModel;
  rolePermission:any;
  // cols: { field: string; header: string }[];

  constructor(private activeRoute: ActivatedRoute,
    public observalbleDataService: ObservalbleDataService,
    private titleService: TitleService,
    private storePermission: Store<{ role: any }>,
    private loader: LoaderService,
    private fileService: FileService,
    private router: Router,
    private toast: ToastService,
    private lookupService: LookupService,
    private datePipe:DatePipe,
    private lookupColumnService:LookupColumnService,
    private cookieService: CookieService) {
    
      this.lookupForm=new FormGroup({
        lookupMap:new FormControl(null,[Validators.required]) ,
        client:new FormControl(null,[Validators.required])  
      });
     
      this.modalForm = new FormGroup({
        client_id:new FormControl(null,[Validators.required]),
        asofdate:new FormControl(null,[Validators.required]),
      });
    }



  ngOnInit(): void {
  this.buttonShowHidePermission();
  }
  
 

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  goToLookupAdmin(){
    this.router.navigate(['lookup-admin']);
  }
  
  getLookupTable(){
    const lookupMap=[
      "accrualbasis_code",
      "branch_code",
      "call_report_code",
      "collateral_code",
      "line_of_credit_code",
      "loan_type_code",
      "non_accrual_code",
      "officer_code",
      "owner_occupied_code",
      "payment_frequency_code",
      "payment_type_code",
      "purpose_code",
      "rate_change_frequency_code",
      "reprice_index_code",
      "risk_rating_code",
      // "system_fields_coll_code"
      "interest_payment_frequency_code",
      //newBorrowTables
      "rate_flag_code",
      //newBorrowTables
      "accrualbasis_borrow_code",
      "call_put_frequency_borrow_code",
      "call_put_index_borrow_code",
      "call_put_method_borrow_code",
      "call_report_borrow_code",
      "compound_frequency_borrow_code",
      "credit_rating_borrow_code",
      "interest_payment_frequency_borrow_code",
      "payment_frequency_borrow_code",
      "payment_type_borrow_code",
      "rate_change_frequency_borrow_code",
      "reprice_index_borrow_code",
      "deposit_ownership_code",
      "deposit_product_code",
      "state_code",
      "general_ledger_code",
      "loan_class_code",
      "credit_rating_code",

   ];
  
    return lookupMap.map((t) => ({ key: t, value: t }));
   
  }

 dropdownTableList=[
  
    { key: "reprice_index_code", value: "reprice_index_code_repriceindex_list" },
    { key: "call_report_code", value: "call_report_code_callreportcode_list" },

    { key: "accrualbasis_code", value: "accrualbasis_accrualbasiscode_list" },
    { key: "rate_change_frequency_code", value: "rate_change_frequency_ratechangefrequencycode_list" },
    { key: "product_type", value: "product_type_callreportcode_list" },
    { key: "state_code", value: "state_statecode_list" },
   
 ];

  getLookupExcel() {
     if(this.modalForm.valid){
     var newDate=this.datePipe.transform(this.modalForm.controls.asofdate.value, 'yyyy/MM/dd');
     const modelVal={
      client_id:this.modalForm.controls.client_id.value.key,
      asofdate:newDate
    }
    // console.log(modelVal);
      this.lookupService.getLookupExcel(modelVal).subscribe(
        (data)=>{
          // console.log(data);
          this.lookUpData=data;
         if(data && data.length > 0){
          exportCSVFile(null, data, "lookupfile");
          this.toast.showSuccess('success','Excel Returned');
          this.modalForm.reset();
        }else{
          this.toast.showSuccess('error','No Data Found');
        }

        },
        (err)=>{
          this.toast.showError(err.error.message, "error");
        }

      )
      this.popUp = false;  
     }else{
      this.toast.showError('error',"All Fields Are Mandatory");
     }
    }

    // this.modalForm.get("file").reset();
    // this.modalForm.get("client").reset();
   
  

  doAction(type) {
    if (type === "yes") {
      this.modalForm.get("client_id").reset();
       this.modalForm.get("asofdate").reset();
      this.popUp=false;
    }
  }

  showPopup(data) {
    if(data.hasRules===0){
      this.toast.showInfo('info','file has no rules');
      return;
    }
   // this.cloneApplicationFileId=data.clientApplicationFileId;
    this.visible = true;
  }

  showModalPopUp(){
    this.popUp=true;     
   }

   getSelected(e){
     console.log(e);
     this.lookUpModelList=[];
     this.lookUpTable=e.key;
    //  this.getColsData(this.lookUpTable);
    //  console.log("Outside loop", this.lookUpTable);
    this.dropdownTableList.forEach(item=>{
      this.columnDropDownData=[];
      this.columnDropDownData2=[];
      // console.log("out of conditn item.key",item.key);
      if(item.key===this.lookUpTable){
        // console.log("inside item.key===this.lookUpTable",item.key);
        this.getLookupColumnDataByTable(item.value); 
        if(this.lookUpTable==="call_report_code"){
          this.getLookupColumnDataByTable2("product_type_callreportcode_list"); 
        }
      }
    });
     this.getLookUpListByClientIdAndTable();
     
   }

   getLookUpListByClientIdAndTable(){
    this.cookieService.set('clientId',this.lookupForm.controls.client.value.key);
    this.cookieService.set('clientName',this.lookupForm.controls.client.value.value);
    //this.maintainClientState();
    this.lookUpField=null;
    this.lookUpSelectorComponent.lookUpForm.reset();   
    if(this.lookUpSelectorComponent.extraRulesForm!==null){
    this.lookUpSelectorComponent.extraRulesForm.reset();
    }
     if(this.lookupForm.controls.client.value==null || this.lookupForm.controls.client.value==undefined){
      //  this.toast.showInfo('select client','to display data');
     }
     if(this.lookupForm.controls.lookupMap.value==null || this.lookupForm.controls.lookupMap.value==undefined){
      this.toast.showInfo('select lookup','info');
    }
     const lookupModel={
       "tableName":this.lookupForm.controls.lookupMap.value.key,
       "clientId":this.lookupForm.controls.client.value.key
     }

     this.lookupService.getLookUpDataByTableAndClient(lookupModel)
     .subscribe(
       data=>{
         //console.log("data :",data);
         this.lookUpModelList=data;
        //  console.log('this modellist', this.lookUpModelList)
         this.lookUpModelList.forEach(item=>{
          // var result = (first > second) ? "That is true : 5>3" : "That is false : 5<3";
           item.code=(item.code.length<1)?"Na":item.code; 
           item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
         
          if(item.modifiedAt===null){
            item.modifiedAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
          }else{
            item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
          }

         })
        // console.log(this.lookUpModelList);
       },
       err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
     )
   }

   editLookUp(lookUpData){
   //  console.log('lookupcomponene:',lookUpData)
     //this.lookUpField=null;
    const lookupModel={
      "tableName":this.lookupForm.controls.lookupMap.value.key,
      "clientId":this.lookupForm.controls.client.value.key,
      "primaryId":lookUpData.primaryId
    }
    this.dropdownTableList.forEach(item=>{
      this.columnDropDownData=[];
      // console.log(item.key)
      if(item.key===this.lookupForm.controls.lookupMap.value.key){
        console.log("edit called main comp");
        this.getLookupColumnDataByTable(item.value);

        if(this.lookupForm.controls.lookupMap.value.key==="call_report_code"){
          this.getLookupColumnDataByTable2("product_type_callreportcode_list"); 
        }
      }
    })

    this.lookupService.getLookUpById(lookupModel)
    .subscribe(
      data=>{
        // console.log("data :",data);
        this.lookUpField=data;

      },
        error=>{
          this.toast.showError(error.error.message, "error");
        }
    )
  }

  saveLookUp(data){

       this.errorModel=this.validateLookUp(data,this.lookupForm.controls.lookupMap.value.key);

        if(!this.errorModel.isValid){
          this.toast.showWarn(this.errorModel.message,'error');
          return;
        }

    const lookUpData={
      "primaryId":this.lookUpField!==null?this.lookUpField.primaryId:0,
      "tableName":this.lookupForm.controls.lookupMap.value.key,
      "clientId":this.lookupForm.controls.client.value.key,
      "code":data.code,
      "description":data.description,
      "field1":data.field1!==undefined?data.field1:null,
      "field2":data.field2!==undefined?data.field2:null,
      "field3":data.field3!==undefined?data.field3:null,
      "field4":data.field4!==undefined?data.field4:null,
      "field5":data.field5!==undefined?data.field5:null,
      "field6":data.field6!==undefined?data.field6:null,
      "field7":data.field7!==undefined?data.field7:null,
      "field8":data.field8!==undefined?data.field8:null,
      "field9":data.field9!==undefined?data.field9:null,
      "field10":data.field10!==undefined?data.field10:null,
      "field11":data.field11!==undefined?data.field11:null,
      "field12":data.field12!==undefined?data.field12:null,
      "field13":data.field13!==undefined?data.field13:null,
      "createdBy":this.lookUpField!==null?this.lookUpField.createdBy:0,
      "createdAt":this.lookUpField!==null?this.lookUpField.createdAt:null,
      "modifiedBy":0,

    }

    this.lookupService.saveLookUpData(lookUpData)
    .subscribe(
      data=>{
        this.toast.showSuccess('save successfully','success');
        this.lookUpField=null;
        this.lookUpSelectorComponent.lookUpForm.reset();
        if(this.lookUpSelectorComponent.extraRulesForm!==null){
        this.lookUpSelectorComponent.extraRulesForm.reset();
        }
        this.getLookUpListByClientIdAndTable();
      },
        err=>{
          this.toast.showError(err.error.message, "error");
      }
    )
    

  }

  validatedecimalplaces(val,beforedecimallen,afterdecimallen){
    var numarray= (val.toString()).split('.');
    if(numarray[0].length > beforedecimallen){
      return true
    }
    
    if (numarray[1] && (numarray[1].length > afterdecimallen)){
      return true
    }
    return false

  }


  validateLookUp(data,tableName){
  
    switch(tableName){
      
      case 'accrualbasis_code':
  // smallint
  // vc255
  // vc5
  // if(data.code===null || data.code===undefined || _isNumberValue(data.code)===false || data.code.length>4||trim(data.code).length<1){
   //  if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
       if(data.code!==null && data.code!==undefined  && data.code.length>100){
         this.errorModel={
           isValid:false,
           message:"Code Size not more than 100 characters"
         }
         return  this.errorModel;

       }
      //  if(data.description===null || data.description==null||data.description.length>255){
      //   this.errorModel={
      //     isValid:false,
      //     message:"Description is mandatory, Size not more than 255 characters"
      //   }
      //   return  this.errorModel;
      // }
       if(data.field1!==null && data.field1!==undefined && data.field1.length>5||trim(data.field1).length<1){
        this.errorModel={
          isValid:false,
          message:"Accural Basis mandatory, Size not more than 5 characters"
        }
        return  this.errorModel;
       }
        break;
    
    

    case 'branch_code'://1
  // smallint
  // vc255

    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code Size not more than 100 characters"
      }
      return  this.errorModel;
    }    
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }

     break;

 

    case  'call_report_code'://5
    // small int
    // vc255
    // vc255
    // small int
    // vc255
    // small int
    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    if( data.field1!==null && data.field1.length>255 && data.field3!==undefined||trim(data.field1).length<1){
     this.errorModel={
       isValid:false,
       message:"Call Report Code is mandatory, Size not more than 255 characters"
     }
     return  this.errorModel;
    }
    // if( (_isNumberValue(data.field2)===false ||data.field2.length>4) && data.field2!==null &&  data.field2!==undefined && trim(data.field2).length>0){
    //  // console.log(data.field2.lenght,'risk weight')
    //   this.errorModel={
    //     isValid:false,
    //     message:"Risk Weight should be Numeric, Size not more than 4 characters"
    //   }
    //   return  this.errorModel;
    //  }
     if(data.field3 !== null && data.field3.length>255 &&  data.field3!==undefined||trim(data.field3).length<1){
      this.errorModel={
        isValid:false,
        message:"Product Type mandatory, Size not more than 255 characters"
      }
      return  this.errorModel;
     }
     if( (_isNumberValue(data.field4)===false || data.field4.length>4) && data.field4!==null &&  data.field4!==undefined && trim(data.field4).length>0){
      this.errorModel={
        isValid:false,
        message:"BidCd should be Numeric, Size not more than 4 characters"
      }
      return  this.errorModel;
     }
     break;
 
 

    case  'collateral_code'://5
    // vc25
    // vc255
    // vc255
    // vc255
    // vc255
    // int unsigned
    
    

    // if(data.code===null || data.code===undefined || data.code.length>100 ||trim(data.code).length<1 ){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code length should be less than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if(data.field1!==null && data.field1!==undefined && data.field1.length>255){
     // console.log(data.field1,' grouping');
     this.errorModel={
       isValid:false,
       message:"Grouping Size not more than 255 characters"
     }
     return  this.errorModel;
    }
    if( data.field2!==null && data.field2!==undefined && data.field2.length>255){
      this.errorModel={
        isValid:false,
        message:"Category1 Size not more than 255 characters"
      }
      return  this.errorModel;
     }
     if(data.field3!==null && data.field3!==undefined && data.field3.length>255){
      this.errorModel={
        isValid:false,
        message:"Category2 Size not more than 255 characters"
      }
      return  this.errorModel;
     }
    // console.log(data.field4.length,' length');
     if((_isNumberValue(data.field4)===false ||data.field4.length>9) && data.field4!==null && data.field4!==undefined  && trim(data.field4).length>0){ 
      // console.log(data.field4);
      this.errorModel={
        isValid:false,
        message:"Profit should be Numeric , Size not more than 9 digits"
      }
      return  this.errorModel;
     }
     break;
 
 

    case  'line_of_credit_code'://5
    //     vc5
    // vc255
    // vc255
    // vc255
    // vc255
    // vc255

    // console.log("codelenghth#####"+data.code.length);
    if(data.code!==null  && data.code!==undefined && data.code.length>100){
      // if(data.code===null  || data.code===undefined || data.code.length>100||trim(data.code).length<1){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if( data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
     this.errorModel={
       isValid:false,
       message:"Loc Flag Size not more than 255 characters"
     }
     return  this.errorModel;
    }
    if( data.field2!==null && data.field2!==undefined && data.field2.length>255){
      this.errorModel={
        isValid:false,
        message:"Loc Flag Profit Size not more than 255 characters"
      }
      return  this.errorModel;
     }
     if( data.field3!==null && data.field3!==undefined && data.field3.length>255 ){
      this.errorModel={
        isValid:false,
        message:"Loc Type Profit Size not more than 255 characters"
      }
      return  this.errorModel;
     }
     if(data.field4!==null && data.field4!==undefined && data.field4.length>255 ){
      this.errorModel={ 
        isValid:false,
        message:"Loc Type Name Size not more than 255 characters"
      }
      return  this.errorModel;
     }
     break;
 
    case  'loan_type_code'://2
    // smallint
    // vc255
    // vc255

    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if(data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
     this.errorModel={
       isValid:false,
       message:"Loan Type Name Size not more than 255 characters"
     }
     return  this.errorModel;
    }
     break;
 
 

    case  'non_accrual_code'://3
    //     vc15
    // vc255
    // vc1
    // tiny int

    // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if( data.field1!==null && data.field1!==undefined && data.field1.length>1){
     this.errorModel={
       isValid:false,
       message:" Non Accural Flag  Only 1 character allowed"
     }
     return  this.errorModel;
    }
    if(( _isNumberValue(data.field2)===false || data.field2.length>2) && data.field2!==null && data.field2!==undefined && trim(data.field2).length>0){
      this.errorModel={
        isValid:false,
        message:"Non Accural Flag1 Should be Numeric, Size not more than 2 characters"
      }
      return  this.errorModel;
     }
    
     break;
 
 

    case  'officer_code'://3
    // medInt
    // vc255
    // vc255
    // vc255

    // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if(data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
     this.errorModel={
       isValid:false,
       message:"Division Size not more than 255 characters"
     }
     return  this.errorModel;
    }
    if(data.field2!==null && data.field2!==undefined  && data.field2.length>255){
      this.errorModel={
        isValid:false,
        message:"Department Size not more than 255 characters"
      }
      return  this.errorModel;
     }
    
     break;
 
 

    case  'owner_occupied_code'://1
    // vc5
    // vc5

    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    if(data.description!==null && data.description!==undefined && data.description.length>5){
      this.errorModel={
        isValid:false,
        message:" Description Size not more than 5 characters"
      }
      return  this.errorModel;

    }
    
     break;
 
 

    case  'payment_frequency_code'://3
    // vc255
    // vc255
    // smallint
    // vc255
    // console.log("ENTERED switch case Pfc## "+data);

    // if(data.code===null || data.code===undefined ||data.code.length>255 ||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>255){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 255 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if( (_isNumberValue(data.field1)===false ||  data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
     this.errorModel={
       isValid:false,
       message:"Payment Frequency Should be numeric , Size not more than 4 characters"
     }
     return  this.errorModel;
    }
    if(data.field2!==null && data.field2!==undefined && data.field2.length>255){
      // console.log("ENTERED if case field2## "+  data.field2);
      this.errorModel={
        isValid:false,
        message:"Payment Frequency Bank Description Size not more than 255 characters"
      }
      return   this.errorModel;
     }
    
     break;
 
 

    case  'payment_type_code'://2
    // smallInt
    // vc255
    // smallInt

    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if( (_isNumberValue(data.field1)===false|| data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
     this.errorModel={
       isValid:false,
       message: "Payment Type should be Numeric, Size not more than 4 characters"
     }
     return  this.errorModel;
    }
     break;
 
 

    case  'purpose_code'://2
    // smallInt
    // vc255
    // vc255

    // if(data.code===null || data.code===undefined   || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null ||data.description.length>255||trim(data.field2).length<1){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if( data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
     this.errorModel={
       isValid:false,
       message:"Product Type Size not more than 255 characters"
     }
     return  this.errorModel;
    }
     break;
 
 

    case  'rate_change_frequency_code'://3
    // vc255
    // vc255
    // smallInt
    // vc255

    // if(data.code===null || data.code===undefined|| data.code.length>255 || trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>255){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 255 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null ||data.description.length>255 || trim(data.field2).length<1){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if( (_isNumberValue(data.field1)===false || data.field1.length>4) && data.field1!==null && data.field1!==undefined ||trim(data.field1).length<1){
     this.errorModel={
       isValid:false,
       message:"Rate Change Frequency mandatory"
      //  message:"Rate Change Frequency mandatory, should be numeric,size not more than 4 characters"
     }
     return  this.errorModel;
    //  console.log("ENTERED out of if ## "+  data);
    }
    if( data.field2!==null && data.field2!==undefined && data.field2.length>255 ){
      this.errorModel={
        isValid:false,
        message:"Rate Change Frequency Pcbb Decs Size not more than 255 characters"
      }
      return  this.errorModel;
     }
    
     break;
    //  console.log("ENTERED break## "+  data);

    case  'reprice_index_code'://4
    // smallInt
    // vcc255
    // vcc255
    // vcc255
    // vcc255

    // if(data.code===null || data.code===undefined || data.code.length>250||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>250){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 250 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null ||data.description.length>255||trim(data.field2).length<1){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if(  data.field1!==null && data.field1!==undefined && data.field1.length>255  ){
     this.errorModel={
       isValid:false,
       message:"Rate Flag Size not more than 255 characters"
     }
     return  this.errorModel;
    }
    if( data.field2!==null && data.field2!==undefined && data.field2.length>255||trim(data.field2).length<1 ){
      this.errorModel={
        isValid:false,
        message:"Reprice Index  is mandatory, Size not more than 255 characters"
      }
      return  this.errorModel;
     }
     if( data.field3!==null && data.field3!==undefined && data.field3.length>255 ){
      this.errorModel={
        isValid:false,
        message:"Rate Flag Desc Size not more than 255 characters"
      }
      return  this.errorModel;
     }

     break;
 
 

    case  'risk_rating_code'://2
    // smallInt
    // vc255
    // vc255

    // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
     // console.log(data.code);
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null || data.description==null||data.description.length>255){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }
    if( data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
     this.errorModel={
       isValid:false,
       message:"Risk Rating Substandard Size not more than 255 characters"
     }
     return  this.errorModel;
    }
     break;



     case  'interest_payment_frequency_code'://3
    //  vc25
    //  vc255
    //  smallint
    //  vc255
     

    // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;

    }
    // if(data.description===null ||data.description.length>255||trim(data.field2).length<1){
    //   this.errorModel={
    //     isValid:false,
    //     message:"Description is mandatory, Size not more than 255 characters"
    //   }
    //   return  this.errorModel;
    // }

    // console.log("#### data checking1"+data.field1.length);
    if( (_isNumberValue(data.field1)===false|| data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
     this.errorModel={
       isValid:false,
       message:"Interest Payment Frequency Should be Numeric, Size not more than 4 digits"
     }
     return  this.errorModel;
    }
    // console.log("check task##"+trim(data.field2))
    // if(data.field2==!null){
    // console.log("#### data checking2"+data.field2.length);}
  //  let str=trim(data.field2);
    if(data.field2!==null && data.field2!==undefined && data.field2.length>255 ){
      this.errorModel={
        isValid:false,
        message:"Interest Payment Frequency Bank Description Size not more than 255 characters"
      }
      return  this.errorModel;
     }
    
     break;

//NEWLookupTables

case 'accrualbasis_borrow_code':
  //  if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
         this.errorModel={
           isValid:false,
           message:"Code size not more than 100 characters"
         }
         return  this.errorModel;

       }
      //  if(data.description===null || data.description==null||data.description.length>255){
      //   this.errorModel={
      //     isValid:false,
      //     message:"Description is mandatory, Size not more than 255 characters"
      //   }
      //   return  this.errorModel;
      // }
       if(data.field1!==null && data.field1!==undefined && data.field1.length>5){
        this.errorModel={
          isValid:false,
          message:"Accural Basis Size not more than 5 characters"
        }
        return  this.errorModel;
       }
        break;

case 'interest_payment_frequency_borrow_code':
  // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null ||data.description.length>255||trim(data.field2).length<1){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }

  // console.log("#### data checking1"+data.field1.length);
  if( (_isNumberValue(data.field1)===false|| data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
   this.errorModel={
     isValid:false,
     message:"Interest Payment Frequency Should be Numeric, Size not more than 4 digits"
   }
   return  this.errorModel;
  }
  // console.log("check task##"+trim(data.field2))
  // if(data.field2==!null){
  // console.log("#### data checking2"+data.field2.length);}
//  let str=trim(data.field2);
  if(data.field2!==null && data.field2!==undefined && data.field2.length>255 ){
    this.errorModel={
      isValid:false,
      message:"Interest Payment Frequency Bank Description Size not more than 255 characters"
    }
    return  this.errorModel;
   }
  
   break;

case 'payment_frequency_borrow_code':
  // if(data.code===null || data.code===undefined ||data.code.length>255 ||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>255){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 255 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null || data.description==null||data.description.length>255){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if( (_isNumberValue(data.field1)===false ||  data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
   this.errorModel={
     isValid:false,
     message:"Payment Frequency Should be numeric , Size not more than 4 characters"
   }
   return  this.errorModel;
  }
  if(data.field2!==null && data.field2!==undefined && data.field2.length>255){
    // console.log("ENTERED if case field2## "+  data.field2);
    this.errorModel={
      isValid:false,
      message:"Payment Frequency Bank Description Size not more than 255 characters"
    }
    return   this.errorModel;
   }
  
   break;

case 'payment_type_borrow_code':
  // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null || data.description==null||data.description.length>255){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if( (_isNumberValue(data.field1)===false|| data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
   this.errorModel={
     isValid:false,
     message: "Payment Type should be Numeric, Size not more than 4 characters"
   }
   return  this.errorModel;
  }
   break;



case 'rate_change_frequency_borrow_code':
  // if(data.code===null || data.code===undefined|| data.code.length>255 || trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>255){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 255 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null ||data.description.length>255 || trim(data.field2).length<1){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if( (_isNumberValue(data.field1)===false || data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>1){
   this.errorModel={
     isValid:false,
     message:"Rate Change Frequency should be numeric,size not more than 4 characters"
   }
   return  this.errorModel;
  //  console.log("ENTERED out of if ## "+  data);
  }
  if( data.field2!==null && data.field2!==undefined && data.field2.length>255 ){
    this.errorModel={
      isValid:false,
      message:"Rate Change Frequency Pcbb Decs Size not more than 255 characters"
    }
    return  this.errorModel;
   }
  
   break;

case 'reprice_index_borrow_code':
  // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null ||data.description.length>255||trim(data.field2).length<1){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if(  data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
   this.errorModel={
     isValid:false,
     message:"Rate Flag Size not more than 255 characters"
   }
   return  this.errorModel;
  }
  if( data.field2!==null && data.field2!==undefined && data.field2.length>255 ){
    this.errorModel={
      isValid:false,
      message:"Reprice Index Size not more than 255 characters"
    }
    return  this.errorModel;
   }
   if( data.field3!==null && data.field3!==undefined && data.field3.length>255 ){
    this.errorModel={
      isValid:false,
      message:"Rate Flag Desc Size not more than 255 characters"
    }
    return  this.errorModel;
   }

   break;


case 'call_put_frequency_borrow_code':
  // if(data.code===null || data.code===undefined ||data.code.length>255 ||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>255){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 255 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null || data.description==null||data.description.length>255){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if( (_isNumberValue(data.field1)===false ||  data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
   this.errorModel={
     isValid:false,
     message:"Call Put Frequency Should be numeric , Size not more than 4 characters"
   }
   return  this.errorModel;
  }
  if(data.field2!==null && data.field2!==undefined && data.field2.length>255){
    // console.log("ENTERED if case field2## "+  data.field2);
    this.errorModel={
      isValid:false,
      message:"Call Put Frequency Bank Description Size not more than 255 characters"
    }
    return   this.errorModel;
   }
  
   break;


case 'compound_frequency_borrow_code':
  // if(data.code===null || data.code===undefined ||data.code.length>255 ||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>255){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 255 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null || data.description==null||data.description.length>255){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if( (_isNumberValue(data.field1)===false ||  data.field1.length>4) && data.field1!==null && data.field1!==undefined && trim(data.field1).length>0){
   this.errorModel={
     isValid:false,
     message:"Compound Frequency Should be numeric , Size not more than 4 characters"
   }
   return  this.errorModel;
  }
  if(data.field2!==null && data.field2!==undefined && data.field2.length>255){
    // console.log("ENTERED if case field2## "+  data.field2);
    this.errorModel={
      isValid:false,
      message:"Compound Frequency Bank Description Size not more than 255 characters"
    }
    return   this.errorModel;
   }
  
   break;


case 'call_put_index_borrow_code':
  // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null ||data.description.length>255||trim(data.field2).length<1){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if(  data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
   this.errorModel={
     isValid:false,
     message:"Rate Flag Size not more than 255 characters"
   }
   return  this.errorModel;
  }
  if( data.field2!==null && data.field2!==undefined && data.field2.length>255 ){
    this.errorModel={
      isValid:false,
      message:"Call Put Index Size not more than 255 characters"
    }
    return  this.errorModel;
   }
   if( data.field3!==null && data.field3!==undefined && data.field3.length>255 ){
    this.errorModel={
      isValid:false,
      message:"Rate Flag Desc Size not more than 255 characters"
    }
    return  this.errorModel;
   }

   break;

case 'call_put_method_borrow_code':
  // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null || data.description==null||data.description.length>255){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if(data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
   this.errorModel={
     isValid:false,
     message:"Call Put Method Name Size not more than 255 characters"
   }
   return  this.errorModel;
  }
   break;


case 'call_report_borrow_code':
  // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  if( data.field1!==null && data.field1.length>255 && data.field1!==undefined){
   this.errorModel={
     isValid:false,
     message:"Call Report Code Size not more than 255 characters"
   }
   return  this.errorModel;
  }
  // if( (_isNumberValue(data.field2)===false ||data.field2.length>4) && data.field2!==null &&  data.field2!==undefined && trim(data.field2).length>0){
  //  // console.log(data.field2.lenght,'risk weight')
  //   this.errorModel={
  //     isValid:false,
  //     message:"Risk Weight should be Numeric, Size not more than 4 characters"
  //   }
  //   return  this.errorModel;
  //  }
   if(data.field3 !== null && data.field3.length>255 &&  data.field3!==undefined||trim(data.field3).length<1){
    this.errorModel={
      isValid:false,
      message:"Product Type Size not more than 255 characters"
    }
    return  this.errorModel;
   }
   if( (_isNumberValue(data.field4)===false || data.field4.length>4) && data.field4!==null &&  data.field4!==undefined && trim(data.field4).length>0){
    this.errorModel={
      isValid:false,
      message:"BidCd should be Numeric, Size not more than 4 characters"
    }
    return  this.errorModel;
   }
   break;
  
case 'credit_rating_borrow_code':
  // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    // console.log(data.code);
     this.errorModel={
       isValid:false,
       message:"Code size not more than 100 characters"
     }
     return  this.errorModel;

   }
   // if(data.description===null || data.description==null||data.description.length>255){
   //   this.errorModel={
   //     isValid:false,
   //     message:"Description is mandatory, Size not more than 255 characters"
   //   }
   //   return  this.errorModel;
   // }
   if( data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
    this.errorModel={
      isValid:false,
      message:"Credit Rating Substandard Size not more than 255 characters"
    }
    return  this.errorModel;
   }
    break;


case 'rate_flag_code':

  // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  // if(data.description===null ||data.description.length>255||trim(data.field2).length<1){
  //   this.errorModel={
  //     isValid:false,
  //     message:"Description is mandatory, Size not more than 255 characters"
  //   }
  //   return  this.errorModel;
  // }
  if(  data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
   this.errorModel={
     isValid:false,
     message:"Rate Flag Size not more than 255 characters"
   }
   return  this.errorModel;
  }
 
   break;

case 'call_report_borrow_code':
  // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
    if(data.code!==null && data.code!==undefined  && data.code.length>100){
    this.errorModel={
      isValid:false,
      message:"Code size not more than 100 characters"
    }
    return  this.errorModel;

  }
  if( data.field1!==null && data.field1.length>255 && data.field3!==undefined){
   this.errorModel={
     isValid:false,
     message:"Call Report Code Size not more than 255 characters"
   }
   return  this.errorModel;
  }
  if( (_isNumberValue(data.field2)===false ||data.field2.length>4) && data.field2!==null &&  data.field2!==undefined && trim(data.field2).length>0){
   // console.log(data.field2.lenght,'risk weight')
    this.errorModel={
      isValid:false,
      message:"Risk Weight should be Numeric, Size not more than 4 characters"
    }
    return  this.errorModel;
   }
   if(data.field3 !== null && data.field3.length>255 &&  data.field3!==undefined){
    this.errorModel={
      isValid:false,
      message:"Product Type Size not more than 255 characters"
    }
    return  this.errorModel;
   }
   if( (_isNumberValue(data.field4)===false || data.field4.length>4) && data.field4!==null &&  data.field4!==undefined && trim(data.field4).length>0){
    this.errorModel={
      isValid:false,
      message:"BidCd should be Numeric, Size not more than 4 characters"
    }
    return  this.errorModel;
   }
   break;

   //NEW DEPOSIT Table1
   case 'deposit_ownership_code':
    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;
  
    }
    if( data.field1!==null && data.field1.length>255 && data.field2!==undefined){
      this.errorModel={
        isValid:false,
        message:"Ownership Code Size not more than 255 characters"
      }
      return  this.errorModel;
     }
    if( data.field2!==null && data.field2.length>255 && data.field2!==undefined){
      // console.log(data.field2.lenght,'risk weight')
       this.errorModel={
         isValid:false,
         message:"Alm Ownership Category Size not more than 255 characters"
       }
       return  this.errorModel;
      }
    
     break;


   //NEW DEPOSIT TABLE2
   case 'deposit_product_code':
    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;
  
    }
    if( data.field1!==null && data.field1.length>255 && data.field2!==undefined){
     this.errorModel={
       isValid:false,
       message:"Product Code Size not more than 255 characters"
     }
     return  this.errorModel;
    }
    if( data.field2!==null && data.field2.length>255 && data.field2!==undefined){
     // console.log(data.field2.lenght,'risk weight')
      this.errorModel={
        isValid:false,
        message:"Alm Product Category Size not more than 255 characters"
      }
      return  this.errorModel;
     }
         break;


   case 'state_code':
    // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;
  
    }

    // if( data.field1!==null && data.field1.length>255 && data.field2!==undefined||trim(data.code).length<1){
    if( data.field1!==null && data.field1.length>255 && data.field1!==undefined||trim(data.field1).length<1){
    this.errorModel={
      isValid:false,
      message:"State is mandatory"
    }
    return  this.errorModel;
    }
    if( data.field2!==null && data.field2.length>255 && data.field2!==undefined){
    // console.log(data.field2.lenght,'risk weight')
      this.errorModel={
        isValid:false,
        message:"Region1 Size not more than 255 characters"
      }
      return  this.errorModel;
    }

    if( data.field3!==null && data.field3.length>255 && data.field3!==undefined){
      // console.log(data.field2.lenght,'risk weight')
        this.errorModel={
          isValid:false,
          message:"Region2 Size not more than 255 characters"
        }
        return  this.errorModel;
      }
        break;


    case 'general_ledger_code':
      // if(data.code===null || data.code===undefined  || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
      this.errorModel={
        isValid:false,
        message:"Code size not more than 100 characters"
      }
      return  this.errorModel;
    
      }

      // // if(data.code!==null && data.code!==undefined  && data.code.length>100){
      //    if( (_isNumberValue(data.field1)===false || data.field1.length>10) && data.field1!==null &&  data.field1!==undefined && trim(data.field1).length>0){
      // // if( (_isNumberValue(data.field1)===false || data.field1.length>10) && data.field1!==null &&  data.field1!==undefined && trim(data.field1).length>0){   
      // this.errorModel={
      //   isValid:false,
      //   message:"Sub Gl must be numeric"
      // }
      // return  this.errorModel;
      // }
    //   if( trim(data.field1).length>0){
    //   if(this.validatedecimalplaces(data.field1,7,2)){
    //     this.errorModel={
    //       isValid:false,
    //       message:"Sub Gl: Upto 7 digit before decimal and 2 digits after decimal allowed"
    //     }
    //     return  this.errorModel;
    //   }
    // }
    
      // if( (_isNumberValue(data.field2)===false || data.field2.length>10) && data.field2!==null &&  data.field2!==undefined && trim(data.field2).length>0){
      // // if(  _isNumberValue(data.field2)===false || data.field2!==null && data.field2.length>10 && data.field2!==undefined && trim(data.field2).length>0){
      //   // console.log(data.field2.lenght,'risk weight')
      //     this.errorModel={
      //       isValid:false,
      //       message:"Branch must be numeric"
      //     }
      //     return  this.errorModel;
      //   }
      //   if( trim(data.field2).length>0){
      //   if(this.validatedecimalplaces(data.field2,7,2)){
      //     this.errorModel={
      //       isValid:false,
      //       message:"Branch: Upto 7 digit before decimal and 2 digits after decimal allowed"
      //     }
      //     return  this.errorModel;
      //   }
      // }

    //   if( (_isNumberValue(data.field3)===false || data.field3.length>10) && data.field3!==null &&  data.field3!==undefined && trim(data.field3).length>0){
    //   // if( _isNumberValue(data.field3)===false || data.field3!==null && data.field3.length>10 && data.field3!==undefined && trim(data.field3).length>0){
    //   // console.log(data.field2.lenght,'risk weight')
    //     this.errorModel={
    //       isValid:false,
    //       message:"Month End Balance must be numeric"
    //     }
    //     return  this.errorModel;
    //   } 
    //   if( trim(data.field3).length>0){
    //   if(this.validatedecimalplaces(data.field3,7,2)){
    //     this.errorModel={
    //       isValid:false,
    //       message:"Month End Balance: Upto 7 digit before decimal and 2 digits after decimal allowed"
    //     }
    //     return  this.errorModel;
    //   }
    // }

      // if( (_isNumberValue(data.field4)===false || data.field4.length>10) && data.field4!==null &&  data.field4!==undefined && trim(data.field4).length>0){
      // // if(  _isNumberValue(data.field4)===false || data.field4!==null && data.field4.length>10 && data.field4!==undefined && trim(data.field4).length>0){
      //   // console.log(data.field2.lenght,'risk weight')
      //     this.errorModel={
      //       isValid:false,
      //       message:"Month Average Balance must be numeric"
      //     }
      //     return  this.errorModel;
      //   } 
      //   if( trim(data.field4).length>0){
      //   if(this.validatedecimalplaces(data.field4,7,2)){
      //     this.errorModel={
      //       isValid:false,
      //       message:"Month Average Balance: Upto 7 digit before decimal and 2 digits after decimal allowed"
      //     }
      //     return  this.errorModel;
      //   }
      // }
      //   if( (_isNumberValue(data.field5)===false || data.field5.length>10) && data.field5!==null &&  data.field5!==undefined && trim(data.field5).length>0){
      // // if(  _isNumberValue(data.field5)===false || data.field5!==null && data.field5.length>10 && data.field5!==undefined && trim(data.field5).length>0){
      //   // console.log(data.field2.lenght,'risk weight')
      //     this.errorModel={
      //       isValid:false,
      //       message:"Quarter End Balance must be numeric"
      //     }
      //     return  this.errorModel;
      //   }
      //   if( trim(data.field5).length>0){
      //   if(this.validatedecimalplaces(data.field5,7,2)){
      //     this.errorModel={
      //       isValid:false,
      //       message:"Quarter End Balance: Upto 7 digit before decimal and 2 digits after decimal allowed"
      //     }
      //     return  this.errorModel;
      //   }
      // }

      //   if( (_isNumberValue(data.field6)===false || data.field6.length>10) && data.field6!==null &&  data.field6!==undefined && trim(data.field6).length>0){
      // // if(  _isNumberValue(data.field6)===false || data.field6!==null && data.field6.length>10 && data.field6!==undefined && trim(data.field6).length>0){
      //   // console.log(data.field2.lenght,'risk weight')
      //     this.errorModel={
      //       isValid:false,
      //       message:"Quarter Average Balance must be numeric"
      //     }
      //     return  this.errorModel;
      //   }
      //   if( trim(data.field6).length>0){
      //   if(this.validatedecimalplaces(data.field6,7,2)){
      //     this.errorModel={
      //       isValid:false,
      //       message:"Quarter Average Balance: Upto 7 digit before decimal and 2 digits after decimal allowed"
      //     }
      //     return  this.errorModel;
      //   }
      // }

      //   if( (_isNumberValue(data.field7)===false || data.field7.length>10) && data.field7!==null &&  data.field7!==undefined && trim(data.field7).length>0){
      // // if(  _isNumberValue(data.field7)===false || data.field7!==null && data.field7.length>10 && data.field7!==undefined && trim(data.field7).length>0){
      //   // console.log(data.field2.lenght,'risk weight')
      //     this.errorModel={
      //       isValid:false,
      //       message:"Year To Date End Balance must be numeric"
      //     }
      //     return  this.errorModel;
      //   }
      //   if( trim(data.field7).length>0){
      //   if(this.validatedecimalplaces(data.field7,7,2)){
      //     this.errorModel={
      //       isValid:false,
      //       message:"Year To Date End Balance: Upto 7 digit before decimal and 2 digits after decimal allowed"
      //     }
      //     return  this.errorModel;
      //   }
      // }

      //   if( (_isNumberValue(data.field8)===false || data.field8.length>10) && data.field8!==null &&  data.field8!==undefined && trim(data.field8).length>0){
      // // if(  _isNumberValue(data.field8)===false && data.field8!==null && data.field8.length>10 && data.field8!==undefined && trim(data.field8).length>0){
      //   // console.log(data.field2.lenght,'risk weight')
      //     this.errorModel={
      //       isValid:false,
      //       message:"Year To Date Average Balance must be numeric"
      //     }
      //     return  this.errorModel;
      //   }
      //   if( trim(data.field8).length>0){
      //   if(this.validatedecimalplaces(data.field8,7,2)){
      //     this.errorModel={
      //       isValid:false,
      //       message:"Year To Date Average Balance: Upto 7 digit before decimal and 2 digits after decimal allowed"
      //     }
      //     return  this.errorModel;
      //   }
      // }

      if(data.field9!==null && data.field9.length>255 && data.field9!==undefined){
        // console.log(data.field2.lenght,'risk weight')
          this.errorModel={
            isValid:false,
            message:"BS/IS must be numeric"
          }
          return  this.errorModel;
        }

      break;

      case  'loan_class_code'://2
      // smallint
      // vc255
      // vc255
  
      
        if(data.code!==null && data.code!==undefined  && data.code.length>100){
        this.errorModel={
          isValid:false,
          message:"Code size not more than 100 characters"
        }
        return  this.errorModel;
  
      }
     
      if(data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
       this.errorModel={
         isValid:false,
         message:"Loan Class Name Size not more than 255 characters"
       }
       return  this.errorModel;
      }
       break;

//new Table Credit rating code
   case  'credit_rating_code'://2
    // smallInt
    // vc255
    // vc255

    // if(data.code===null || data.code===undefined || data.code.length>100||trim(data.code).length<1){
      if(data.code!==null && data.code!==undefined  && data.code.length>100){
        // console.log(data.code);
         this.errorModel={
           isValid:false,
           message:"Code size not more than 100 characters"
         }
         return  this.errorModel;
   
       }
       // if(data.description===null || data.description==null||data.description.length>255){
       //   this.errorModel={
       //     isValid:false,
       //     message:"Description is mandatory, Size not more than 255 characters"
       //   }
       //   return  this.errorModel;
       // }
       if( data.field1!==null && data.field1!==undefined && data.field1.length>255 ){
        this.errorModel={
          isValid:false,
          message:"Credit Rating Substandard Size not more than 255 characters"
        }
        return  this.errorModel;
       }
        break;

        }

    this.errorModel={
      isValid:true,
      message:" success"
    }
    return this.errorModel;
    
    
 
}

// doActionFile(type){
//     if (type === "yes") {
//       this.modalForm.get("client_id").reset();
//       this.visible=false;
//       this.currentFileUpload=null;
//       this.myInputVariable.nativeElement.value = "";
//     }
//     this.modalForm.reset();
// }

// showFileModalPopUp(){
// if(this.lookupForm.valid){
//   const mapValue={
//   "key":this.lookupForm.controls.client.value.key,
//   "value":this.lookupForm.controls.client.value.value
// }
// const lookupMap={
//   "key":this.lookupForm.controls.lookupMap.value.key,
//   "value":this.lookupForm.controls.lookupMap.value.value
// }
// this.lookupForm.controls.client.setValue(mapValue);
// this.lookupForm.controls.lookupMap.setValue(lookupMap);
// this.visible=true;
// }else{
//   this.toast.showError("First Select Lookup Table And Client Name","error");
// }
// }

doActionFile(data){

}

selectFile(event){
  this.currentFileUpload = event.target.files;
}

saveLookupExcel(){
  if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
    this.toast.showWarn('no file selected','error');
    return;
  }
  if(this.lookupForm.valid){
  const lookupData={
    "client_id":this.lookupForm.controls.client.value.key,
    "table":this.lookupForm.controls.lookupMap.value.key,
  }
  this.lookupService.saveLookupExcelData(lookupData,this.currentFileUpload).subscribe(
  data=>{
    this.toast.showSuccess("Successfully Updated", "success");
    this.lookupForm.reset();
    this.visible=false;
    this.currentFileUpload=null;
    this.myInputVariable.nativeElement.value = "";
  },
    error=>{
      this.toast.showError(error.error.message, "error");
    }
)
}else{
  this.toast.showError("All Fields Are Mandatory", "error");
}
}


deleteById(data){
  this.loader.showLoader();
  console.log("delete id :",data);
  const lookupModel={
    "tableName":this.lookupForm.controls.lookupMap.value.key,
    "primaryId":data.primaryId,
    "code":data.code,
    "description":data.description,
  }

  this.lookupService.deleteLookup(lookupModel)
  .subscribe(
    data=>{

      this.loader.hideLoader();
      this.toast.showSuccess('success','data deleted successfully');
      // this.resetForm();
      this.getLookUpListByClientIdAndTable();
    },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
  )
}

getLookupColumnDataByTable(data){
  this.columnDropDownData=[];
  const lookupColumnModel={
    "tableName":data,
    
  }

  this.lookupColumnService.getLookupColumnDataByTable(lookupColumnModel)
  .subscribe(
    data=>{
     // console.log("in getLookupColumnDataByTable",data);
      data.forEach(item=>{
       // console.log("dropdown: item.field1", item.field1);
        let value1= "";
        let value2= "";

        if(item.field1!==null){value1= item.field1;}
        if(item.field2!==null){value2= item.field2;}
 
        if(lookupColumnModel.tableName==="call_report_code_callreportcode_list"){
          let fieldValue= item.field2;
          //let fieldValue=value1+" "+value2;
          // // this.columnDropDownData.push({key:item.primaryId,value:item.field1})
          this.columnDropDownData.push({key:item.primaryId,value:fieldValue})
        }else{
        // let fieldValue= item.field1;
        // let fieldValue= item.field2;
         let fieldValue=value1+" "+value2;
        // // this.columnDropDownData.push({key:item.primaryId,value:item.field1})
        this.columnDropDownData.push({key:item.primaryId,value:fieldValue})
        console.log("this.columnDropDownData  :", this.columnDropDownData);
        }
      }
      )

    },
    err=>{
      this.toast.showError(err.error.message, "error");
      this.loader.hideLoader();
    }
  )
}
getLookupColumnDataByTable2(data){
  this.columnDropDownData2=[];
  const lookupColumnModel={
    "tableName":data,
    
  }

  this.lookupColumnService.getLookupColumnDataByTable(lookupColumnModel)
  .subscribe(
    data=>{

      data.forEach(item=>{
        //  console.log("dropdown: item.field1", item.field1);
        let value1= "";
        let value2= "";
        let value3= "";

        if(item.field1!==null){value1= item.field1;}
        if(item.field2!==null){value2= item.field2;}
        if(item.field3!==null){value3= item.field3;}

        if(lookupColumnModel.tableName==="product_type_callreportcode_list"){
          let fieldValue2=value1;
          this.columnDropDownData2.push({key:item.primaryId,value:fieldValue2})
        }else{
        //   console.log("dropdown: item.field1", item.field1);
        let fieldValue2=value1+" "+value2+" "+value3;
       // let fieldValue2=value1;
        this.columnDropDownData2.push({key:item.primaryId,value:fieldValue2})
        console.log("this.columnDropDownData2   :", this.columnDropDownData2);
        }
      })

    },
     err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
  )
}

getPreviousState() {
  const clientIdC=this.cookieService.get('clientId');
  const clientNameC=this.cookieService.get('clientName');
  console.log(clientIdC);
  console.log(clientNameC);
  
  if(clientIdC!==undefined && clientIdC!==null){
    this.lookupForm.get("client").patchValue({
             key: clientIdC,
             value: clientNameC,
             });
             
                   
  }
  // this.observalbleDataService.inventoryChanged$.subscribe(
  //   data => {
  //     this.dataTransferModel = data;
  //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
  //       this.lookupForm.get("client").patchValue({
  //         key: this.dataTransferModel.clientId,
  //         value: this.dataTransferModel.clientName,
  //       });
  //     }
  //   });
   // this.getLookUpListByClientIdAndTable();
}

maintainClientState(){
  this.dataTransferModel = {
    clientFileId: 0,
    clientId: this.lookupForm.controls.client.value.key,
    isRedirected: 1,
    applicationId: 0,
    toDate: '',
    fromDate: '',
    clientName: this.lookupForm.controls.client.value.value,
    page: '',
    fileName: '',
    workflowName: '',
    workflowId: 0,
    dataType: '',
    dataTypeId: 0
  }
  this.observalbleDataService.addToInventory(this.dataTransferModel); 
}


// getColsData(tableName){
//   console.log("getColsData called")

// console.log("tableName  ",tableName);


//   if(tableName==="state_code"){
//     this.cols = [
//       { field: "code", header: "Code" },
//       { field: "description", header: "Description" },
//       { field: "field1", header: "Payment Type" },
//       { field: "createdAt", header: "Created At" },
//       { field: "modifiedAt", header: "Modified At" }
//   ];


//   }else{
//     this.cols = [
//       { field: "code", header: "Code" },
//       { field: "description", header: "Description" },
//       { field: "createdAt", header: "Created At" },
//       { field: "modifiedAt", header: "Modified At" }
//     ];
//   }

//   return this.cols;
//   console.log("cols data",this.cols);
// }
  
buttonShowHidePermission(){
  this.fileService.buttonShowHidePermission().subscribe(
    (res) =>{ 
         this.rolePermission = res  
         this.callNgOnInitMethods();
 }
  )};

  callNgOnInitMethods(){
    this.setTitle();
    this.getLookupTable();
    this.getPreviousState();
  }


}


