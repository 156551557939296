import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolelistTableComponent }  from './components/rolelist-table/rolelist-table.component';
import { SharedModule } from '../shared/shared.module';





@NgModule({
  declarations: [
    RolelistTableComponent, 
    
  
  ],
  imports: [SharedModule],
  exports: [
    RolelistTableComponent, 
    
  ],
  providers: []
  })
export class RolelistCompModule { }
