import { Component, Input, OnChanges, OnInit, Output, SimpleChanges,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/core/services/toast.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-validation-rules-form',
  templateUrl: './validation-rules-form.component.html',
  styleUrls: ['./validation-rules-form.component.scss']
})
export class ValidationRulesFormComponent implements OnInit,OnChanges {
  validationRulesForm:FormGroup;
  fields:any;
  //ruleType: { key: string | number; value: string }[];
  data:{ key: any; value: any }[];
  listOfData:string[]=[];
  @Input() field: { key: string | number; value: string }[];
  @Output() saveFormData = new EventEmitter();
  @Input() editForm;
  @Input() permission:any;
  fieldOpearatorList:any;
  showForm:boolean=false;
  showConditionAppliesTo:boolean=false;
  showOverrideApplies:boolean=false;
  showOverrideValue:boolean=false;
  validationRuleType: { key: string | number; value: string };
  ruleCreated:boolean=true;
  ruleType= [
     { key: 1, value: "Static value validation" },
     { key: 2, value: "Field based validation" },
     
   ];

   overrideType= [
    { key: "overrideByValue", value: "Override By Value" },
    { key: "overrideByField", value: "Override By Field" },
    { key: "logError",  value:  "Error Log"}
  ];

  constructor(private toast:ToastService) { 
    this.validationRulesForm = new FormGroup({
      ruleId:new FormControl(null),
      ruleName: new FormControl(null, [Validators.required]),
      appliesTo: new FormControl(null, [Validators.required]),
      lowerConditionOperator: new FormControl(null),
      upperConditionOperator: new FormControl(null),
      overrideValue: new FormControl(null),
      lowerConditionValue:new FormControl(null),
      upperConditionValue:new FormControl(null),
      ruleType:new FormControl(null,[Validators.required]),
      lowerConditionAppliesTo:new FormControl(null),
      upperConditionAppliesTo:new FormControl(null),
      overrideType:new FormControl(null,[Validators.required]),
      overrideAppliesTo:new FormControl(null)

    });
  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.editForm && changes.editForm.currentValue) {
      const d = changes.editForm.currentValue;
     // console.log("RULE" + d.status);
     
     if(d.validationruletypeId===1){
      this.validationRuleType=
         {key: 1, value: "Static value validation"}
        
  

    }else{
      this.validationRuleType=
        {key: 2, value: "Field based validation"}
       
    }
    const overrideTypeData={
      key:d.overrideType,
      value:d.overrideType,
    };

    const overrideApplies={
key:d.overrideAppliesTo,
value:d.overrideAppliesTo,
    };

    if(d.overrideType==="overrideByValue"){
      this.showOverrideValue=true;
      this.showOverrideApplies=false;
    }else if(d.overrideType==="overrideByField"){
      this.showOverrideValue=false;
      this.showOverrideApplies=true;
    }else{
      this.showOverrideValue=false;
      this.showOverrideApplies=false;
    }

    const lowerCondOperator={
      key: d.lowerConditionOperator,
      value:d.lowerConditionOperator,
    };

    const upperCondOperator={
      key: d.upperConditionOperator,
      value:d.upperConditionOperator,
    };

    const applies={
      key:d.appliesTo,
      value:d.appliesTp
    }

    const lowerConditionApplies={
      key:d.lowerConditionAppliesTo,
      value:d.lowerConditionAppliesTo
    }

    
    const upperConditionApplies={
      key:d.upperConditionAppliesTo,
      value:d.upperConditionAppliesTo
    }

     this.validationRulesForm.patchValue({
      ruleId:d.ruleId,
      ruleName: d.ruleName,
      appliesTo: applies,
      lowerConditionOperator: lowerCondOperator,
      upperConditionOperator: upperCondOperator,
      overrideValue: d.overrideValue,
      lowerConditionValue:d.lowerConditionValue,
      upperConditionValue: d.upperConditionValue,
      ruleType:this.validationRuleType,
      lowerConditionAppliesTo:lowerConditionApplies,
      upperConditionAppliesTo:upperConditionApplies,
      overrideType:overrideTypeData,
      overrideAppliesTo:overrideApplies,
      
      });
     
    }

  }

  ngOnInit(): void {
  this.getAllOperators();
  this.getAllRuleType();
  console.log('This if the value for user-id: ' + this.field);

}
  

saveRule(){
  this.ruleCreated=true;
  if(this.validationRulesForm.valid){
  if(this.validationRulesForm.controls.ruleType.value.key==1){
    if(this.validationRulesForm.valid){
      if(this.validationRulesForm.controls.overrideType.value.key==="overrideByValue"){
        if(this.validationRulesForm.controls.overrideValue.value!==null){
       // this.saveFormData.emit(this.validationRulesForm.controls);
      }else{
        this.ruleCreated=false;
        this.toast.showError("Please Fill Required Fields","error");
      }
    } else if(this.validationRulesForm.controls.overrideType.value.key==="overrideByField"){
      if(this.validationRulesForm.controls.overrideAppliesTo.value.key!==null){
    //  this.saveFormData.emit(this.validationRulesForm.controls);
    }else{
      this.ruleCreated=false;
      this.toast.showError("Please Fill Required Fields","error");
    }
   }else{
   // this.saveFormData.emit(this.validationRulesForm.controls);
   }
  }

  
  if(this.validationRulesForm.controls.upperConditionValue.value==""){
    this.validationRulesForm.controls.upperConditionValue.setValue(null);
  }
  if(this.validationRulesForm.controls.lowerConditionValue.value==""){
    this.validationRulesForm.controls.lowerConditionValue.setValue(null);
  }

  if(this.validationRulesForm.controls.upperConditionOperator.value!=null){
    if(this.validationRulesForm.controls.upperConditionOperator.value.key==null){
      this.validationRulesForm.controls.upperConditionOperator.setValue(null);

    }
  }

  if(this.validationRulesForm.controls.lowerConditionOperator.value!=null){
    if(this.validationRulesForm.controls.lowerConditionOperator.value.key==null){
      this.validationRulesForm.controls.lowerConditionOperator.setValue(null);

    }
  }

  if(this.validationRulesForm.controls.upperConditionValue.value==null){
    if(this.validationRulesForm.controls.lowerConditionValue.value==null){
if(this.validationRulesForm.controls.upperConditionOperator.value==null ){
  if(this.validationRulesForm.controls.lowerConditionOperator.value==null){ 
  this.ruleCreated=false;
      this.toast.showError("Atleast Add One Condition","error");
  }else{
    if(this.validationRulesForm.controls.lowerConditionOperator.value.key==null){
      this.ruleCreated=false;
      this.toast.showError("Atleast Add One Condition","error");
    }else{
      if(this.validationRulesForm.controls.lowerConditionValue.value==null){
        this.ruleCreated=false;
        this.toast.showError("Please Add Lower Condition Value","error");
      }
    }
}
}else{
  if(this.validationRulesForm.controls.upperConditionOperator.value.key==null){
    this.ruleCreated=false;
    this.toast.showError("Atleast Add One Condition","error");
  }else{
    if(this.validationRulesForm.controls.upperConditionValue.value==null){
      this.ruleCreated=false;
      this.toast.showError("Please Add Upper Condition Value","error");
    }
}
}
   
    }else{
if(this.validationRulesForm.controls.lowerConditionOperator.value!=null){
  if(this.validationRulesForm.controls.lowerConditionOperator.value.key!=null){   
     }else{
  this.ruleCreated=false;
  this.toast.showError("Please Choose lower Condition Operator","error");
}
}else{
  this.ruleCreated=false;
  this.toast.showError("Please Choose lower Condition Operator","error");
}
    }
  }else{
    if(this.validationRulesForm.controls.upperConditionOperator.value!=null){
      if(this.validationRulesForm.controls.upperConditionOperator.value.key!=null){   
      }else{
      this.ruleCreated=false;
      this.toast.showError("Please Choose Upper Condition Operator","error");
    }
    }else{
      this.ruleCreated=false;
      this.toast.showError("Please Choose Upper Condition Operator","error");
    }

    if(this.validationRulesForm.controls.lowerConditionValue.value!=null){ 
      if(this.validationRulesForm.controls.lowerConditionOperator.value!=null){
    }else{
      this.ruleCreated=false;
      this.toast.showError("Please Choose Lower Condition Operator","error");
    }
  }else{
    if(this.validationRulesForm.controls.lowerConditionOperator.value==null){

    }else{
    this.ruleCreated=false;
    this.toast.showError("Please Choose Lower Condition Value","error");
  }
}

        }
  
  }else if(this.validationRulesForm.controls.ruleType.value.key==2){

    if(this.validationRulesForm.valid){  


      if(this.validationRulesForm.controls.upperConditionAppliesTo.value!=null){
      if(this.validationRulesForm.controls.upperConditionAppliesTo.value.key==null){
        this.validationRulesForm.controls.upperConditionAppliesTo.setValue(null);
}
      }

      if(this.validationRulesForm.controls.lowerConditionAppliesTo.value!=null){
        if(this.validationRulesForm.controls.lowerConditionAppliesTo.value.key==null){
          this.validationRulesForm.controls.lowerConditionAppliesTo.setValue(null);
  }
        }
    
      if(this.validationRulesForm.controls.upperConditionOperator.value!=null){
        if(this.validationRulesForm.controls.upperConditionOperator.value.key==null){
          this.validationRulesForm.controls.upperConditionOperator.setValue(null);
    
        }
      }
    
      if(this.validationRulesForm.controls.lowerConditionOperator.value!=null){
        if(this.validationRulesForm.controls.lowerConditionOperator.value.key==null){
          this.validationRulesForm.controls.lowerConditionOperator.setValue(null);
    
        }
      }
    

      if(this.validationRulesForm.controls.upperConditionAppliesTo.value==null || this.validationRulesForm.controls.upperConditionAppliesTo.value==''){
        if(this.validationRulesForm.controls.lowerConditionAppliesTo.value==null || this.validationRulesForm.controls.lowerConditionAppliesTo.value==''){
    if(this.validationRulesForm.controls.upperConditionOperator.value==null || this.validationRulesForm.controls.upperConditionOperator.value==''){
      if(this.validationRulesForm.controls.lowerConditionOperator.value==null || this.validationRulesForm.controls.lowerConditionOperator.value==''){ 
      this.ruleCreated=false;
          this.toast.showError("Atleast Add One Condition","error");
      }else{
        if(this.validationRulesForm.controls.lowerConditionOperator.value.key==null){
          this.ruleCreated=false;
          this.toast.showError("Atleast Add One Condition","error");
        }else{
          if(this.validationRulesForm.controls.lowerConditionAppliesTo.value==null){
            this.ruleCreated=false;
            this.toast.showError("Please Add Lower Condition Applies To","error");
          }
        }
    }
    }else{
      if(this.validationRulesForm.controls.upperConditionOperator.value.key==null){
        this.ruleCreated=false;
        this.toast.showError("Atleast Add One Condition","error");
      }else{
        if(this.validationRulesForm.controls.upperConditionAppliesTo.value==null){
          this.ruleCreated=false;
          this.toast.showError("Please Add Upper Condition Applies To","error");
        }
    }
    }
       
        }else{
    if(this.validationRulesForm.controls.lowerConditionOperator.value!=null){
      if(this.validationRulesForm.controls.lowerConditionOperator.value.key!=null){
        if(this.validationRulesForm.controls.upperConditionAppliesTo.value!=null){   
        if(this.validationRulesForm.controls.upperConditionAppliesTo.value.key!=null){
          if(this.validationRulesForm.controls.upperConditionOperator.value!=null){
            if(this.validationRulesForm.controls.upperConditionOperator.value.key!=null){   
            }else{
            this.ruleCreated=false;
            this.toast.showError("Please Choose Upper Condition Operator","error");
            }
      
          }else{
            this.ruleCreated=false;
            this.toast.showError("Please Choose Upper Condition Operator","error");
          }
              }else{
                this.ruleCreated=false;
                this.toast.showError("Please Choose Upper Condition Applies To","error");
              } 
      }else{
        if(this.validationRulesForm.controls.upperConditionOperator.value==null){
        }else{
      this.ruleCreated=false;
      this.toast.showError("Please Choose Upper Condition Applies To","error");
    }
  }
  }else{
    this.ruleCreated=false;
    this.toast.showError("Please Choose Lower Condition Operator","error");
  }
    }else{
      this.ruleCreated=false;
      this.toast.showError("Please Choose lower Condition Operator","error");
    }
        }
      }else{
        if(this.validationRulesForm.controls.upperConditionAppliesTo.value.key!=null){
        if(this.validationRulesForm.controls.upperConditionOperator.value!=null){
          if(this.validationRulesForm.controls.upperConditionOperator.value.key!=null){   
          }else{
          this.ruleCreated=false;
          this.toast.showError("Please Choose Upper Condition Operator","error");
          }
    
        }else{
          this.ruleCreated=false;
          this.toast.showError("Please Choose Upper Condition Operator","error");
        }
            }else{
              this.ruleCreated=false;
              this.toast.showError("Please Choose Upper Condition Applies To","error");
            }
    
          if(this.validationRulesForm.controls.lowerConditionAppliesTo.value!=null){ 
            if(this.validationRulesForm.controls.lowerConditionOperator.value!=null){
          }else{
            this.ruleCreated=false;
            this.toast.showError("Please Choose Lower Condition Operator","error");
          }
        }else{
          if(this.validationRulesForm.controls.lowerConditionOperator.value==null){
          }else{
          this.ruleCreated=false;
          this.toast.showError("Please Choose Lower Condition Applies To","error");
        }
      }
  
        }
    
  if(this.validationRulesForm.controls.overrideType.value.key==="overrideByValue"){
    if(this.validationRulesForm.controls.overrideValue.value!==null){
   // this.saveFormData.emit(this.validationRulesForm.controls);
  }else{
    this.ruleCreated=false;
    this.toast.showError("Please Fill Required Fields","error");
  }
}else if(this.validationRulesForm.controls.overrideType.value.key==="overrideByField"){
  if(this.validationRulesForm.controls.overrideAppliesTo.value.key!==null){
 // this.saveFormData.emit(this.validationRulesForm.controls);
}else{
  this.ruleCreated=false;
  this.toast.showError("Please Fill Required Fields","error");
}
}else{
  //this.saveFormData.emit(this.validationRulesForm.controls);
 }
}
// else{
//   this.toast.showError("Please Fill Required Fields","error");
// }
}else{
  this.ruleCreated=false;
  this.toast.showError("Please Fill Required Fields","error");
}

// if(this.validationRulesForm.controls.upperConditionAppliesTo.value==null || this.validationRulesForm.controls.upperConditionAppliesTo.value==''){
//    if(this.validationRulesForm.controls.upperConditionOperator.value==null || this.validationRulesForm.controls.upperConditionOperator.value==''){
//   if(this.validationRulesForm.controls.lowerConditionOperator.value==null || this.validationRulesForm.controls.lowerConditionOperator.value==''){
    
//   this.ruleCreated=false;
//     this.toast.showError("Atleast Add One Condition","error");
//    }
//   }
//   }
}else{
  this.ruleCreated=false;
    this.toast.showError("Please Fill Required Fields","error");
}
//   }else{
//     this.toast.showError("Please Fill Required Fields","error");
//   }
//   }else{
//   this.toast.showError("Please Fill Required Fields","error");
// }
if(this.ruleCreated){
  this.saveFormData.emit(this.validationRulesForm.controls); 
}

}


  getAllOperators(){
    this.fieldOpearatorList= [
     { key: '=',  value: "Equal" },
     { key: '>=', value: "Greater than or equal"},
     { key: '<=', value: "Less than or equal"},
     { key: '>',  value: "Greater than"},
     { key: '<',  value: "Less than"}

   ];
  }
   getAllRuleType(){
  //   this.ruleType= [
  //    { key: 1, value: "Static value validation" },
  //    { key: 2, value: "Field based validation" },
     
  //  ];
  
   console.log("rule type "+this.ruleType);
  }
  
  showPopup(event){
    this.validationRulesForm.reset();

  }

  onRuleTypeChange(event){
 if(this.validationRulesForm.controls.ruleId.value>0){
this.validationRulesForm.controls.ruleId.setValue(null);
 }
    if(event.key===2){
      this.showConditionAppliesTo=true;
    }else{
      this.showConditionAppliesTo=false;
    }
  }

  onOverrideTypeChange(event){
    console.log(this.validationRulesForm.controls.overrideType.value)
    if(this.validationRulesForm.controls.overrideType.value.key==="overrideByValue"){
      this.showOverrideApplies=false;
      this.showOverrideValue=true;
    }else if(this.validationRulesForm.controls.overrideType.value.key==="overrideByField"){
      this.showOverrideValue=false;
      this.showOverrideApplies=true;
    }else{
      this.showOverrideValue=false;
      this.showOverrideApplies=false;
    }
  
  }
}
