import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableStatisticService {

  constructor(private http: HttpClient) { }

  tableStatistics(data){
    return this.http.post<any>(environment.baseUrl + "/apis/tableStatistics/getDataOfStatistics",data);
  }

  distiinctAsOfDate(data){
    return this.http.post<any>(environment.baseUrl + "/apis/tableStatistics/getAllFilesExist",data)
  }

  generateStatistic(data){
const formData = new FormData();
formData.append('client_id',data.client_id);
formData.append('table_name',data.table_name);
formData.append('asofdate',data.asofdate);
formData.append('connection_id',data.connection_id);
return this.http.post(
  `${environment.baseUrl}/api/etl/stats-info/save/`,formData
);
  }
}
