import { createAction, props } from "@ngrx/store";

export enum UserActionTypes {
  STORE_USER = "[User] Store User",
  UPDATE_USER = "[User] Update User",
  SIGN_OUT = "[User] Sign Out",
  STORE_PERMISSION="[role] Store Permission",
}

export const storeUser = createAction(
  UserActionTypes.STORE_USER,
  props<{ data: any }>()
);

export const updateUser = createAction(
  UserActionTypes.UPDATE_USER,
  props<{ data: any }>()
);

export const signOut = createAction(UserActionTypes.SIGN_OUT);

export const storePermission = createAction(
  UserActionTypes.STORE_PERMISSION,
  props<{ permission: any }>()
);
