import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NotificationListTableComponent } from './components/notification-list-table/notification-list-table.component'; 
import { NotificationSelectorComponent } from './components/notification-selector/notification-selector.component';



@NgModule({
  declarations: [
    NotificationListTableComponent,
    NotificationSelectorComponent,

  ],
  imports: [
    CommonModule,SharedModule
  ],
  exports: [
    NotificationListTableComponent,
    NotificationSelectorComponent,
  ],
  providers: []
})
export class NotificationCompModule { }
