import { Injectable } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { take, switchMap } from "rxjs/operators";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private store: Store<{ user: any }>) {}
  intercept(
    req: import("@angular/common/http").HttpRequest<any>,
    next: import("@angular/common/http").HttpHandler
  ): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
    const skipUrls = [
      "authenticate",
      "data.json",
      "menu.json",
      "fdic-files.json",
    ];
    const filtered = skipUrls.filter((f) => req.url.includes(f));
    if (filtered.length <= 0) {
      console.log("with token", req.url);
      return this.store.select("user").pipe(
        take(1),
        switchMap((user) => {
          if (user) {
            const request = req.clone({
              setHeaders: {
                Authorization: user.token,
                userId: user.userId.toString(),
                // "Content-Type": "application/json",
              },
            });
            return next.handle(request);
          } else {
            return next.handle(req);
          }
        })
      );
    } else {
      console.log("no token", req.url);
      return next.handle(req);
    }
  }
}
