import { Component, Input, OnInit, Output,EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { WorkflowJobDetails } from 'src/app/dashboard/models/workflow.model';
import {trim} from 'lodash';

@Component({
  selector: 'app-setup-job-selector',
  templateUrl: './setup-job-selector.component.html',
  styleUrls: ['./setup-job-selector.component.scss']
})
export class SetupJobSelectorComponent implements OnInit {

  workflowJobDetailsEdit:WorkflowJobDetails=null;
  setupJobDataSelector:FormGroup=null;
  @Input() ingestJob;
  @Output() jobTypeOut=new EventEmitter();
  @Input() editWorkflowJobDetails:WorkflowJobDetails;
  @Output() saveJobToWorkflow=new EventEmitter();
  @Input() permission:any;
  enableEdit=false;
  errorCase:boolean=false;

  constructor(private toast:ToastService) {
    this.setupJobDataSelector=new FormGroup({
       jobType:new FormControl(null),
       jodName:new FormControl(null),
       description:new FormControl(null),
       trigger1:new FormControl(null),
       targetApi:new FormControl(null),
       enabled:new FormControl(null),
       errorCase:new FormControl(null)
      })
   }

  ngOnInit(): void {
  }

   jobTypeList=[
    { key: 'Ingest', value: "Ingest" },
    { key: 'Transform', value: "Transform" },
    { key: 'Custom', value: "Custom" }
  ]

  triggerList=[
    { key: 'Automatic', value: "Automatic" },
    { key: 'Manual', value: "Manual" }
  ]

  onJobTypeChange(){
    console.log("value before out ",this.setupJobDataSelector.controls.jobType.value)
    const val1=this.setupJobDataSelector.controls.jobType.value;
    console.log(val1)
    if(val1!==null && val1!==undefined && val1.key!==undefined ){
    this.jobTypeOut.emit(val1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
       
    if (changes && changes.editWorkflowJobDetails && changes.editWorkflowJobDetails.currentValue) {
       console.log(changes.editWorkflowJobDetails.currentValue,' inside changes') 
       const lookUp=changes.editWorkflowJobDetails.currentValue;
       this.enableEdit = true;
       this.checkForEdit() 
    }
 
    }

    checkForEdit() {

      if (this.editWorkflowJobDetails && this.enableEdit) { 

        this.setupJobDataSelector.patchValue({
          jobType:{key:this.editWorkflowJobDetails.jobType,value:this.editWorkflowJobDetails.jobType},
          jodName:{key:this.editWorkflowJobDetails.jobId,value:this.editWorkflowJobDetails.jobName},
          description:this.editWorkflowJobDetails.description,
          trigger1: {key:this.editWorkflowJobDetails.trigger1,value:this.editWorkflowJobDetails.trigger1},
          enabled: this.editWorkflowJobDetails.enabled==='Yes'?true:false,
          targetApi: this.editWorkflowJobDetails.target,
          errorCase: this.editWorkflowJobDetails.continueOnError==='Yes'?true:false
         
        });

      

        this.enableEdit = false;
       
      }
    }

    resetForm(){
      this.workflowJobDetailsEdit=null;
      this.setupJobDataSelector.reset();
    }

    saveWorkflowJob(){
  
      const validData=this.validateSetupForm();

      if(validData===false){
        return;
      }
    //  console.log('enabled ',this.setupJobDataSelector.controls.enabled.value)

      if(validData){
      const data={
        "jobType":this.setupJobDataSelector.controls.jobType.value.key,
        "jobId":this.setupJobDataSelector.controls.jodName.value.key,
        "description":this.setupJobDataSelector.controls.description.value,
        "trigger1":this.setupJobDataSelector.controls.trigger1.value.key,
        "enabled":this.setupJobDataSelector.controls.enabled.value,
        "target":this.setupJobDataSelector.controls.targetApi.value,
        "errorCase":this.setupJobDataSelector.controls.errorCase.value

      }
  
      this.saveJobToWorkflow.emit(data);
    }
    }


    validateSetupForm(){

     // console.log('trigger 1 ',this.setupJobDataSelector.controls.trigger1.value.key)

      if(this.setupJobDataSelector.controls.jobType.value===null || this.setupJobDataSelector.controls.jobType.value.key===null
        || this.setupJobDataSelector.controls.jobType.value.key===undefined){
        this.toast.showInfo('required','Job type is required');
        return false;
      }

      if(this.setupJobDataSelector.controls.jodName.value===null || this.setupJobDataSelector.controls.jodName.value.key===null
        || this.setupJobDataSelector.controls.jodName.value.key===undefined){
        this.toast.showInfo('required','Job name is required');
        return false;
      }

      if(this.setupJobDataSelector.controls.trigger1.value===null || this.setupJobDataSelector.controls.trigger1.value.key===null 
        || this.setupJobDataSelector.controls.trigger1.value.key===undefined){
        this.toast.showInfo('required','Trigger is required');
        return false;
      }

      if(this.setupJobDataSelector.controls.jobType.value!==null && this.setupJobDataSelector.controls.jobType.value.key!==null 
        && this.setupJobDataSelector.controls.jobType.value.key==='Custom'){
          const apiField=this.setupJobDataSelector.controls.targetApi.value;
          if(apiField===null || trim(apiField).length===0){
        this.toast.showInfo('required','Target Api is required for custom job');
        return false;
          }
       
      }

      return true;

    }

}
