import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from 'src/app/user/services/user.service';
import { UserModel } from '../../models/user.model';
import { UserOpsComponent } from 'src/app/user/components/user-ops/user-ops.component';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { UserSelectorComponent } from 'src/app/userComp/components/user-selector/user-selector.component';
import { reMapForAutoCompleteMultiSelect } from 'src/app/utils/re-mapper-multiselect';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
   @ViewChild(UserOpsComponent) userOpsComponent;
   @ViewChild(UserSelectorComponent, { read: false, static: false })
   userSelectorComponent: UserSelectorComponent;
  userList: UserModel[] = [];
  editUserValue:UserModel=null;
  activeRoute$:Subscription;
  roleLists:any=[];
  clientLists:any=[];
  applicationLists:any=[];
  clientApplicationDataTypeList:any=[];
  notificationList:any=[];
  roleId:number;
  
  activeNotifications:{notificationId:any;notificationName:any}[]=[];

  rolePermission:any;
  constructor(private userService: UserService,private toast:ToastService,private loader:LoaderService,
     private activeRoute: ActivatedRoute, private titleService: TitleService,private fileService: FileService) { }
  ngOnInit() {
    this.buttonShowHidePermission();
  
  }
  getAllUserList() {
    this.loader.showLoader();
    this.userService.getAllUserList().subscribe((data) => {
      this.userList = data;
      this.loader.hideLoader();
    }
    ,(err) => {
       this.loader.hideLoader();
      this.toast.showError(err.error.message, "error");
     
    }
    
    );
  }
 editUser(data){
  this.getUserById(data.userId);
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
addUser(data){
  let userStatus=0;
  if(data.statusMap.key==="Active"){
    userStatus=1;
  }

  let clientsSelectedList = data.clients;
  let applicationSelectedList = data.applications;
  let fileDataTypeSelectList = data.fileDataType;
  let notificationSeletedList =data.noticationType;
  const userData = {
        userId:this.editUserValue!==null?this.editUserValue.userId:0,
        userName:data.userName,
        firstName:data.firstName,
        lastName:data.lastName,
        status:userStatus,
        phone:this.editUserValue!==null?this.editUserValue.phone:null,
        department:this.editUserValue!==null?this.editUserValue.department:null,
       // createdBy:this.editUserValue!==null?this.editUserValue.createdBy:0,
        createdAt:this.editUserValue!==null?this.editUserValue.createdAt:null,
        //modifiedBy:this.editUserValue!==null?this.editUserValue.modifiedBy:0,
        modeifiedAt:this.editUserValue!==null?this.editUserValue.modeifiedAt:null,
        roleId:data.roleId!==null?data.roleId.key:null,
        roleName:data.roleId!==null?data.roleId.value:null,
        // clientId:0,
        clientsMappingList:clientsSelectedList,
        applicationMappingList:applicationSelectedList,
        datatypeMappingList:fileDataTypeSelectList,
        //assignAll:data.userFlag,
        assignAllClients:data.clientFlag,
        assignAllApplications:data.applicationFlag,
        assignAllDataTypes:data.fileTypeFlag,
        notificationMappingList:notificationSeletedList,

  };console.log("userData ",userData);
  this.userService
    .saveOrUpdateUser(userData)
    .subscribe((data) => {
      // this.userSelectorComponent.userForm.reset();
      this.userSelectorComponent.resetForm();
      this.userSelectorComponent.resetCheckedFlag();
      this.editUserValue=null;
      this.toast.showSuccess('saved successfully','success');
      this.getAllUserList();
    },
    (err) =>{
      this.toast.showError(err.error.message, "error");
    } 
    ); 
 
}

getUserById(id){
  this.userService.getUserById(id)
  .subscribe(
    data=>{
      this.editUserValue =data;  
    },
    error => {
      this.toast.showError(error.error.message, "error");
    }
    )
  }

getRoleList(){
  this.userService.getRoleListData().subscribe(
    (data) => {
      this.roleLists=data;
      this.roleLists=this.formatFields(this.roleLists);
      //console.log("user comp role list" +this.roleLists);
    },
    (err) =>{
      this.toast.showError(err.error.message, "error");
    } 
  );
}

 formatFields(roleList) {
    return reMapForAutoComplete(roleList, "roleId", "roleName");
  }

  // getClientList(){
  //   this.userService.getClientListData().subscribe(
  //     (data) => {
        
  //       this.clientLists=data;
  //       this.clientLists=this.formatClientFields(this.clientLists);
  //       //console.log("clientLists comp role list" +this.clientLists);
      
  //     },
  //     (err) =>{
  //       this.toast.showError(err.error.message, "error");
  //     } 
  //   );
  // }


  formatClientFields(clientLists) {
    return reMapForAutoComplete(clientLists, "clientId", "clientName");
  }

  getClientList(){
    this.userService.getClientListData().subscribe(
      (data) => {
     //   this.clientLists=data;
    //    this.clientLists=this.formatClientFields(this.clientLists);
        this.loader.hideLoader();
        this.clientLists = this.reMapForclientCompleteMultiSelect(data, "clientId", "clientName");
        
        //console.log("clientLists comp role list" +this.clientLists);
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForclientCompleteMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { clientId: string | number; clientName: string }[] {
    return data.map(d => ({ clientId: d[keyCol], clientName: d[valCol] }));
  }
  

  getAllApplications(){
    this.fileService.getAllApplications().subscribe(
      (data) => {
        console.log("Applications",data);
        this.loader.hideLoader();
        this.applicationLists = this.reMapForApplicationMultiSelect(data, "applicationId", "name");
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForApplicationMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { applicationId: string | number; name: string }[] {
    return data.map(d => ({ applicationId: d[keyCol], name: d[valCol] }));
  }

  getAllApplicationFileDataType(){
    this.fileService.getAllApplicationFileDataType().subscribe(
      (data) => {
        console.log("DataTypes",data);
        this.loader.hideLoader();
        this.clientApplicationDataTypeList = this.reMapForFileDataTypeMultiSelect(data, "clientApplicationFileDataType", "type");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForFileDataTypeMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { clientapplicationfiledatatypeId: string | number; type: string }[] {
    return data.map(d => ({ clientapplicationfiledatatypeId: d[keyCol], type: d[valCol] }));
  }


  getAllNotification(){


    this.fileService.getActiveNotificationList().subscribe(
      (data) => {
        console.log("notificationList",data);
       this.notificationList=data;
       // this.loader.hideLoader();
        this.notificationList = this.reMapForNotificationTypeMultiSelect( this.notificationList, "notificationId", "notificationName");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  
  
    )

    // this.fileService.getAllNotificationType().subscribe(
    //   (data) => {
    //     console.log("notification",data);
    //     for(var k in data){
    //       if(data[k].status==true){
    //        this.activeNotifications.push({ notificationId: data[k].notificationId, notificationName: data[k].notificationName })
    //       }
          
    //      }
    //     this.loader.hideLoader();
    //     this.notificationList = this.reMapForNotificationTypeMultiSelect(this.activeNotifications, "notificationId", "notificationName");
        
    //   },
    //   (err) =>{
    //     this.toast.showError(err.error.message, "error");
    //   }  

    // )
  }


  reMapForNotificationTypeMultiSelect(

    data: any[],
    keyCol: string,
    valCol: string
  ): {notificationId: string | number; notificationName: string }[] {
    return data.map(d => ({ notificationId: d[keyCol], notificationName: d[valCol] }));


  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};


    callNgOnInitMethods(){

      this.setTitle();
      this.getAllUserList();
      this.getRoleList();
      // this.getClientList();
      this.getClientList();
      this.getAllApplications();
      this.getAllApplicationFileDataType();
      this.getAllNotification();

    }



  


}
