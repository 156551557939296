import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ClientModel } from "src/app/dashboard/models/client.model";
import { environment } from "src/environments/environment";
import { ClientFilesModel, LayoutFilesModel } from "src/app/dashboard/models/files.model";
import { FileLogModel } from "src/app/dashboard/models/filelog.model";
import { formatDate } from "src/app/utils/dates";
import {
  TransformModel,
  JobsLogModel,
  TransformDataModel,
} from "src/app/dashboard/models/tranform.model";
import {
  FileLayoutModel,
  SheetModel,
  FieldLayoutModel,
  ClientApplicationFileEditModel,
} from "src/app/dashboard/models/file-layout.model";
import { FileMappingModel } from "src/app/dashboard/models/file-mapping.model";
import { ClientFileMasterModel } from 'src/app/dashboard/models/clientFileMaster.model';
import { NotificationModel } from "src/app/dashboard/models/notification.model";

@Injectable()
export class FileService {
  /**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http: HttpClient) {}
  /**
   * @param  {number|string} clientId
   * @param  {any[]} dateRange
   */
  getFilesByClient(clientId: number | string, fromDate: string,toDate:string) {
    const data = {
      client_id: clientId,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
    };
    return this.http.post<ClientModel[]>(
      environment.baseUrl + "/files/findbyclient",
      data
    );
  }
  /**
   * @param  {number|string} id
   */
  getFilesByClientId(id: number | string) {
    return this.http.get<ClientFilesModel[]>(
      `${environment.baseUrl}/client-application-file/findByClientId/${id}`
    );
  }


  /**
   * @param  {number|string} id
   */
   getFilesByClientIdModel(id: number | string) {
    return this.http.get<ClientFilesModel[]>(
      `${environment.baseUrl}/client-application-file/findByClientIdModel/${id}`
    );
  }



  /**
   * @param  {number|string} clientId
   * @param  {any[]} dateRange
   */
  getFilesCountByClient(clientId: number | string, fromDate:string,toDate:string) {
    const data = {
      client_id: clientId,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
    };
    return this.http.post<ClientModel[]>(
      environment.baseUrl + "/files/findbyclientcount",
      data
    );
  }
  /**
   * @param  {number} id
   */
  getLogByClientFieldId(id: number) {
    return this.http.get<FileLogModel[]>(
      `${environment.baseUrl}/files/findbyclientid/${id}`
    );
  }
  /**
   * @param  {number} id
   */
  getTransformListByClient(id: number) {
    var payload = new FormData();
    payload.append("client_id", id.toString());
    return this.http.post<any>(
      `${environment.baseUrl}/api/etl/list/`,
      payload
    );
  }

/**
   * @param  {number} id
   * @param  {number} global_job
   */
  getTransformListByClientAndGlobalJob(id: number,globalJob:number) {
    var payload = new FormData();
    payload.append("client_id", id.toString());
    if(globalJob==1){
      payload.append("global_job",globalJob.toString());
    }else{
      payload.append("global_job", "0");
    }
    return this.http.post<any>(
      `${environment.baseUrl}/api/etl/list/`,
      payload
    );
  }
  /**
   * @param  {number} id
   */
  getTransformLogByJob(id: number) {
    return this.http.get<JobsLogModel[]>(
      `${environment.baseUrl}/files/getTransformLogByJobId/${id}`
    );
  }
  /**
   * @param  {} client
   * @param  {} file
   */
  getFileSheets(client, file) {
    const data = { clientApplicationFileId: file, clientId: client };
    return this.http.post<SheetModel[]>(
      `${environment.baseUrl}/files/findByClientIdAndFileTypeId`,
      data
    );
  }
  /**
   * @param  {} client
   * @param  {} file
   */
  getFileLayout(client, file) {
    const data = { clientApplicationFileId: file, clientId: client };
    return this.http.post<FileLayoutModel>(
      `${environment.baseUrl}/file-layout/fileLayoutByClientFile`,
      data
    );
  }
  /**
   * @param  {} id
   */
  getFileLayoutById(id) {
    const url = `${environment.baseUrl}/file-layout/getFileLayoutFieldByLayoutId/${id}`;
    return this.http.get<FieldLayoutModel[]>(url);
  }
  getSheetHeaders(data: {
    clientApplicationFileId: number;
    clientId: number;
    sheetName: string;
    headerRows: number;
    noOfHeaders: number;
    startRowNo:number;
  }) {
    return this.http.post<FieldLayoutModel[]>(
      `${environment.baseUrl}/file-layout/readSheetHeaders`,
      data
    );
  }
  getFileLayoutByClientFile(client, file) {
    const data = { clientApplicationFileId: file, clientId: client };
    return this.http.post<any[]>(
      `${environment.baseUrl}/file-layout/fileLayoutByClientFile`,
      data
    );
  }
  getFieldMappingsById(id,dataType) {
    const data={
      "clientApplicationFileId":id,
      "requestUrl":environment.baseUrl,
      "mappingType":dataType
    }
    return this.http.post<FileMappingModel[]>(
      `${environment.baseUrl}/clientFileMapping/getByClientApplicationFileId`,data
    );
  }

  getAliasData(id,dataType) {
    const data={
      "clientApplicationFileId":id,
      "requestUrl":environment.baseUrl,
      "mappingType":dataType
    }
    return this.http.post<any>(
      `${environment.baseUrl}/clientFileMapping/getAliasNames`,data
    );
  }

  ingestRuleBySequence(clientFileModel) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/ingestRule/ingestRuleBySequence/`,
      clientFileModel
    );
  }

  saveOrUpdateFileLayoutField(fileLayoutField) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/file-layout-field/save`,
      fileLayoutField
    );
  }

  saveFileLayout(fileLayout) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/file-layout/saveInfo`,
      fileLayout
    );
  }

  saveMapping(clientFileMapping) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/clientFileMapping/save`,
      clientFileMapping
    );
  }

  getAllLoanFields() {
    return this.http.get<string[]>(
      `${environment.baseUrl}/loans/getLoanColumns`
    );
  }

  getLoanColumnsByClientFileId(id) {
    return this.http.get<string[]>(
      `${environment.baseUrl}/loans/getLoanColumnsByClientFileId/${id}`
    );
  }

  executeJobById(id, job_parameters) {
    const body = new HttpParams()
      .set(`id`, id)
      .set(`job_parameters`, JSON.stringify(job_parameters))
      .set(`workflowjoblog_id`,"0");
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const api = `${environment.baseUrl}/api/etl/transformation/`;
    return this.http.post(api, body.toString(), { headers });
  }

  getAllApplicationByUser() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/files/getAllApplicationByUser`
    );
  }

  getAllApplications() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/files/getAllApplication`
    );
  }

  cloneJobById(clientId, jobId, appId, name, desc) {
    const body = new HttpParams()
      .set(`client_id`, clientId)
      .set(`transformation_id`, jobId)
      .set("name", name)
      .set("description", desc)
      .set("application_id", appId);
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const api = `${environment.baseUrl}/api/etl/clone/`;
    return this.http.post(api, body.toString(), { headers });
  }

  deleteFileLayoutFieldById(id){
    return this.http.get<any[]>(
      `${environment.baseUrl}/file-layout-field/deleteById/${id}`
    );
    } 

    saveClient(clientForm){
      return this.http.post<ClientModel[]>(
        `${environment.baseUrl}/client/save`,clientForm);
    }
    getAllClientList(){
      return this.http.get<any[]>(
        `${environment.baseUrl}/client/getAll`
      );
    }

    getSelectedClientList(){
      return this.http.get<any[]>(
        `${environment.baseUrl}/client/getAllByUser`
      );
    }

    editClient(clientForm){
      return this.http.post<ClientModel[]>(
        `${environment.baseUrl}/client/edit`,clientForm);
    }

    changeStatusForClient(clientForm){
      return this.http.post<ClientModel[]>(
        `${environment.baseUrl}/client/changeStatus`,clientForm);
    }

    cloneRulesToApplicationFiles(clientFormModel){
      return this.http.post(
        `${environment.baseUrl}/templaterule/mapRuleWithClientFile`,clientFormModel);
    }

    // getLayoutFilesByClientId(id: number | string, fromDate: string, toDate: string){
    //   const data = {
    //     client_id: id,
    //     fromDate: formatDate(fromDate),
    //     toDate: formatDate(toDate),
    //   };
    //   return this.http.post<LayoutFilesModel[]>(
    //     `${environment.baseUrl}/files/getLayoutFilesByClientId/${id}`,
    //     data
    //   );
    // }

    getLayoutFilesByClientId(id: number | string){
      const data = {
        client_id: id,
      };
      return this.http.post<LayoutFilesModel[]>(
        `${environment.baseUrl}/files/getLayoutFilesByClientId/${id}`,
        data
      );
    }

    testAllRulesBySequence(clientFileModel) {
      return this.http.post(
        `${environment.baseUrl}/ingestRule/testAllRulesBySequence`,
        clientFileModel
      );
    }

    downloadFile(){
      let headers:HttpHeaders=new HttpHeaders();
     // headers=headers.append('Accept','text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9')
      headers=headers.append('Accept','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
 
      
      return this.http.get(
        `${environment.baseUrl}/ingestRule/download`,{headers}
      );
    }

    saveClientFile(file,clientFile){
      //console.log(file);
      console.log(file);
      const formData = new FormData();
      formData.append('file',file[0]);
      formData.append('clientId',clientFile.client_id);
      formData.append('clientApplicationFileId',clientFile.clientApplicationFileId);
      formData.append('asOfDate',clientFile.asOfDate);
      formData.append('clientFileMaster',clientFile.clientFileMasterId);
      return this.http.post(
        `${environment.baseUrl}/files/saveClientFile`,formData
      );

    }

    getApplicationDataTypeByUser(){
      return this.http.get<any[]>(
        `${environment.baseUrl}/client-application-file/getApplicationDataTypeByUser`
      );
    }

    getAllApplicationFileDataType(){
      return this.http.get<any[]>(
        `${environment.baseUrl}/client-application-file/getApplicationDataType`
      );
    }

    getAllTaskTypes(){
      return this.http.get<any[]>(
        `${environment.baseUrl}/apis/getAllTaskTypes`
      );
    }

    getAllNotificationType(){
      return this.http.get<any[]>(
        `${environment.baseUrl}/users/getAllNotification`
      );

    }

    getDependentUpon(id: number | string) {
      return this.http.get<ClientFilesModel[]>(
        `${environment.baseUrl}/client-application-file/findDependentUpon/${id}`
      );
    }

    saveClientApplicationFile(file,clientApplicationFileForm){
      const formData = new FormData();
      formData.append('fileName',file[0]);
      formData.append('clientId',clientApplicationFileForm.clientId);
      formData.append('clientApplicationFileTypeId',clientApplicationFileForm.clientApplicationFileTypeId);
      if(clientApplicationFileForm.dependentUpon!=null && clientApplicationFileForm.dependentUpon.key!=null){
      formData.append('dependentUpon',clientApplicationFileForm.dependentUpon.key);
      }else{
        formData.append('dependentUpon',null);
      }
      if(clientApplicationFileForm.layoutFieldId!=null && clientApplicationFileForm.layoutFieldId.key!=null){
        formData.append('layoutFieldId',clientApplicationFileForm.layoutFieldId.key);
      }else{
        formData.append('layoutFieldId',null);
      }
      if(clientApplicationFileForm.fieldSeparator!=null && clientApplicationFileForm.fieldSeparator.key!=null){
        formData.append('fieldSeparator',clientApplicationFileForm.fieldSeparator.key);
      }else{
        formData.append('fieldSeparator',null);
      }
      formData.append('schedule',clientApplicationFileForm.schedule);
     // formData.append('fieldSeparator',clientApplicationFileForm.fieldSeparator);
      formData.append('clientApplicationFileDataTypeId',clientApplicationFileForm.clientApplicationFileDataTypeId);

      return this.http.post<any[]>(
        `${environment.baseUrl}/client-application-file/saveClientApplicationFile`,formData);
         
      }
    
    
    getClientApplicationFileType(){
      return this.http.get<any[]>(
      `${environment.baseUrl}/client-application-file/getApplicationFileType`);
}
deleteTransformation(data){
  const body = new HttpParams()
    .set(`client_id`, data.client_id)
    .set(`id`, data.job_id)
    .set(`user_id`,"1")   
  const headers = new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  });
  const api = `${environment.baseUrl}/api/etl/delete/`;
  return this.http.post(api, body.toString(), { headers });
}

getClientApplicationFileByFileId(data) {
  
  return this.http.post<ClientApplicationFileEditModel>(
    `${environment.baseUrl}/client-application-file/getClientApplicationFileByFileId`,
    data
  );
}

getAppFilesByClientId(id: number | string) {
  return this.http.get<ClientFilesModel[]>(
    `${environment.baseUrl}/client-application-file/findAppFileByClientId/${id}`
  );
}


editClientApplicationFile(file,clientApplicationFileForm){
  const formData = new FormData();
  
  if(file!==undefined && file!==null){
  formData.append('fileName',file[0]);
}else{
 let emptyFIle:File=null;
  formData.append('fileName',emptyFIle);
}
  formData.append('appFileId',clientApplicationFileForm.appFileId);
  formData.append('clientId',clientApplicationFileForm.clientId);
  formData.append('clientApplicationFileTypeId',clientApplicationFileForm.clientApplicationFileTypeId);
  if(clientApplicationFileForm.dependentUpon!=null && clientApplicationFileForm.dependentUpon.key!=null){
  formData.append('dependentUpon',clientApplicationFileForm.dependentUpon.key);
  }else{
    formData.append('dependentUpon',null);
  }
  if(clientApplicationFileForm.layoutFieldId!=null && clientApplicationFileForm.layoutFieldId.key!=null){
    formData.append('layoutFieldId',clientApplicationFileForm.layoutFieldId.key);
  }else{
    formData.append('layoutFieldId',null);
  }
  if(clientApplicationFileForm.fieldSeparator!=null && clientApplicationFileForm.fieldSeparator.key!=null){
    formData.append('fieldSeparator',clientApplicationFileForm.fieldSeparator.key);
  }else{
    formData.append('fieldSeparator',null);
  }
  formData.append('schedule',clientApplicationFileForm.schedule);
 // formData.append('fieldSeparator',clientApplicationFileForm.fieldSeparator);
  formData.append('clientApplicationFileDataTypeId',clientApplicationFileForm.clientApplicationFileDataTypeId);

  return this.http.post<any[]>(
    `${environment.baseUrl}/client-application-file/editClientApplicationFile`,formData);
     
  }


  deleteClientApplicationFile(data){

    return this.http.post<any>(
      `${environment.baseUrl}/client-application-file/deleteClientApplicationFile`,
      data
    );
  }

  deleteClientFile(data){

    return this.http.post<any>(
      `${environment.baseUrl}/files/deleteClientFile`,
      data
    );
  }

  deleteDataLoans(data){
    return this.http.post<any>(
      `${environment.baseUrl}/loans/deleteData`,
      data
    );
  }

  cloneAllLayout(requestModel){
    return this.http.post(
      `${environment.baseUrl}/client-application-file/cloneLayoutFiles`,requestModel);
  }

  getTransformLogByJobLogId(requestModel) {
    return this.http.post<JobsLogModel[]>(
      `${environment.baseUrl}/apis/workflow/getTransformLogByWorkflowJobLogId`,requestModel
    );
  }

  getClientFileMasterListByClient(data){
    return this.http.get<ClientFileMasterModel[]>(environment.baseUrl + "/files/getClientMasterList/"+data);
}

getclientAppFile(data){
  return this.http.get<any[]>(environment.baseUrl + "/client-application-file/findClientAppFileByClientId/"+data);
}

editClientFile(data) {
  return this.http.post<any[]>(
    `${environment.baseUrl}/files/editClientFile`,data);
}

getclientFileMaster(data){
  return this.http.get<any[]>(environment.baseUrl + "/files/getClientMasterList/"+data);
}

cleanData(data){
  return this.http.post<any[]>(environment.baseUrl + "/files/cleanIngestionData",data);
}

updateLayoutFileDateRange(data){
  return this.http.post<any>(environment.baseUrl + "/client-application-file/updateLayoutFileDateRange",data);
}

saveOneRow(clientFileMapping) {
  return this.http.post<any[]>(
    `${environment.baseUrl}/clientFileMapping/saveOneRow`,
    clientFileMapping
  );
}

userRoleTransform(){
  return this.http.get<any>(
    `${environment.baseUrl}/users/userRoleTransform`
  );
}

getAllMappedApplication(){

  return this.http.get<any>(
    `${environment.baseUrl}/users/getAllMappedApplication`
  );
}

buttonShowHidePermission(){
  return this.http.get<any>(
    `${environment.baseUrl}/users/buttonShowHidePermission`
  );
}


saveAllNotification(notificationData){

  return this.http.post<any>(
    `${environment.baseUrl}/users/saveNotification`,
    notificationData
  );

}

getNotificationById(id){

  return this.http.get<NotificationModel>(
    `${environment.baseUrl}/users/getNotificationById/${id}`
  );

}


getActiveNotificationList(){
  return this.http.get<any[]>(
    `${environment.baseUrl}/users/getActiveNotifications`
  );
}


getActiveNotificationListByUser(){
  return this.http.get<any[]>(
    `${environment.baseUrl}/users/getActiveNotificationsByUser`
  );
}



}
