import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from 'src/app/core/services/title.service';
import { UserService } from 'src/app/user/services/user.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { RoleService } from '../../services/role.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {


  constructor(){}

  ngOnInit(){}

  

}
