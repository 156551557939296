import { Component, OnInit,Input,
  Output,
  EventEmitter,
  ViewEncapsulation,} from '@angular/core';

@Component({
  selector: 'app-modal-popup2',
  templateUrl: './modal-popup2.component.html',
  styleUrls: ['./modal-popup2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalPopup2Component implements OnInit {

  @Input() visible: boolean;
  @Input() header: string;
  @Input() infoPopup: boolean;
  @Output() decision = new EventEmitter();
  @Input() modalWidth: number = 50;
  @Input() modalHeight: number = 50;
  constructor() {}

  ngOnInit(): void {}

  onClick(type) {
    this.decision.emit(type);
  }

}
