import { Component, Input, OnChanges,  Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-calendar2',
  templateUrl: './calendar2.component.html',
  styleUrls: ['./calendar2.component.scss']
})
export class Calendar2Component implements OnChanges {

  @Input() ctrl: FormControl;
  @Input() label: string;
  @Input() blockLabel = "inline-block";
  @Input() backDateYears: number;
  @Input() selectionMode: string = "single";
  @Output() onSelect = new EventEmitter();
  @Input() placeholder: string;
  yearRange: string;
  placeholderText = "";
  constructor() {
    const currentYear = new Date().getFullYear();
    this.yearRange = `${currentYear - 50}:${currentYear}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.backDateYears) {
      const currentYear = new Date().getFullYear();
      this.yearRange = `${
        currentYear - Number.parseInt(changes.backDateYears.currentValue)
      }:${currentYear}`;
    }
    if (changes && !!changes.placeholder && changes.placeholder.currentValue) {
      this.placeholderText = changes.placeholder.currentValue;
    }
  }
  dateChange(data) {
    // this.ctrl.patchValue(data);
    this.onSelect.emit(data);
  }

}
