import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuditClientFileModel, AuditWorkflow, LayoutFileMappingModel } from '../models/audit.model';
import { RulesModel } from '../models/rules.model';
import { WorkflowJobDetails } from '../models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class AuditHomeService {

  constructor(private http:HttpClient) { }


  getClientFileByAsOfDate(data){
    return this.http.post<AuditClientFileModel[]>(environment.baseUrl + "/apis/audit/getAllClientFiles",data);
  }

  getJobAuditLogs(data){
    return this.http.post<AuditWorkflow[]>(environment.baseUrl + "/apis/audit/getAllWorkflowJobsByClientId",data);
  }

  getTransformJobAuditLogs(data){
    return this.http.post<AuditWorkflow[]>(environment.baseUrl + "/apis/audit/getAllWorkflowTransformJobsByClientId",data);
  }

  getLayoutFileRules(data){
    return this.http.post<RulesModel[]>(environment.baseUrl + "/apis/audit/getAllLayoutFileRules",data);
  }

  getLayoutFileMapping(data){
    return this.http.post<LayoutFileMappingModel[]>(environment.baseUrl + "/apis/audit/getAllLayoutFileMappings",data);
  }
}
