import { Component, OnInit } from '@angular/core';
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";
import { UserService } from 'src/app/user/services/user.service';
import { currentMonthRange } from 'src/app/utils/dates';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import * as moment from 'moment';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { CookieService } from 'ngx-cookie-service';
import {isEmpty,trim} from 'lodash';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
  activeRoute$: Subscription;
  changeLogs:any[]=[];
  showDiff:boolean=false
  previousList:any;
  newList:any;
  changeModel1:any;
  changeName:any;
  headerForm: FormGroup = null;
  dataTransferModel: DataTransferModel;
  fromDt:any;
  toDt:any;
  rolePermission:any

  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private userService: UserService,
    private toast: ToastService,
    public observalbleDataService: ObservalbleDataService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>,
    private fileService: FileService,
  ) { 
    this.headerForm = new FormGroup({    
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
      client:new FormControl(null,[Validators.required])  
    });
  }

  ngOnInit(): void {
   this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllChangeLogs(fromDate,toDate){
    this.changeLogs=[];
    let toDatePlus1 = moment(toDate).add(1, 'days');
    const changeLogData={
      "fromDate":fromDate,
      "toDate":toDatePlus1
    }
    
    this.userService.getAllChangeLog(changeLogData)
    .subscribe(
      data=>{
        this.changeLogs=data;
        if(this.changeLogs.length===0){
          this.toast.showInfo("info","No data present");
        }
      },
      error=>{
        this.toast.showError(error.error.message,'error');
      }
    )
  }

  showPopUp(rowData){
    console.log(rowData);
    this.previousList=null;
    this.newList=null;
     this.showDiff=true;
     this.previousList=JSON.stringify(rowData.previousValue);
     this.newList=JSON.stringify(rowData.newValue);
     this.changeModel1=rowData.changeTypeName+' ' +rowData.changeName;
     //console.log(this.changeModel1);
     //this.changeName=rowData.changeName;


  }

  changeToDate1(e){
    this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      this.getAllChangeLogs(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
  }

  changeFromDate1(e){

    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
      this.getAllChangeLogs(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
  }


  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0])
    this.headerForm.controls.toDate.setValue(currentDateRange[1])
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
  }

  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }


  onClientChange(data){
    // console.log("onClientChange(data) :",data);
    this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,data.key);
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    //this.maintainClientState(data,this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value);
    // this.cleanDataConfigSelectorComponent.selectorForm.reset();
      }

  getChangeLogsByClientId(fromDate,toDate,clientId){
    this.changeLogs=[];
    //console.log("from date ",fromDate," ",toDate)
    let toDatePlus1 = moment(toDate).add(1, 'days');
    const changeLogData={
      "fromDate":fromDate,
      "toDate":toDatePlus1,
      "clientId":clientId
      // "clientId":this.headerForm.controls.client.value.key
    }
    this.userService.getAllChangeLogByClientId(changeLogData)
    .subscribe(
      data=>{
        this.changeLogs=data;
        if(this.changeLogs.length===0){
          this.toast.showInfo("info","No data present");
        }
      },
      error=>{
        this.toast.showError(error.error.message,'error');
      }
    )
  }

  changeToDate(e){
    this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    const clientIdC=this.cookieService.get('clientId');
    if(validate===true){
      if(clientIdC!=="" && clientIdC!==null){
      this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
      this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,this.headerForm.controls.client.value.key);
    }else{
      this.getAllChangeLogs(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
  }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
  }

  changeFromDate(e){

    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    const clientIdC=this.cookieService.get('clientId');
    if(validate===true){
      if(clientIdC!=="" && clientIdC!==null){
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,this.headerForm.controls.client.value.key);
    }else{
      this.getAllChangeLogs(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
  }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
    }
  }
    
  getPreviousState() {
    //console.log("previous state")
    //this.loadDate();
    // const clientIdC=this.cookieService.get('clientId');
    // const clientNameC=this.cookieService.get('clientName');
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    //console.log(clientIdC);
    //console.log(clientNameC);
    
    // if(clientIdC!==undefined && clientIdC!==null){
    //   this.headerForm.get("client").patchValue({
    //            key: clientIdC,
    //            value: clientNameC,
    //            });
      
                                   
    // }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
       this.headerForm.controls.fromDate.setValue(frm)
       this.headerForm.controls.toDate.setValue(toDt)
    }else{
      this.loadDate();
    }

    // if(!isEmpty(this.headerForm.get("client").value.key)){
    // this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,this.headerForm.controls.client.value.key);
    // }
    
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     this.fromDt=new Date(this.dataTransferModel.fromDate);
    //     this.toDt=new Date(this.dataTransferModel.toDate);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && isNaN(this.fromDt)===false && isNaN(this.toDt)===false) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.headerForm.patchValue({
    //         fromDate: this.fromDt,
    //         toDate: this.toDt,
    //       });
    //       this.getChangeLogsByClientId(this.fromDt, this.toDt, this.dataTransferModel.clientId);
    //     }

    //     else if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && (isNaN(this.fromDt)===true || isNaN(this.toDt)===true)) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.loadDate();
    //       this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value, this.dataTransferModel.clientId);
    //     }

    //     else{
    //       this.loadDate();
    //     }
    //   });
  }

  maintainClientState(client, fromDate, toDate){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: toDate,
      fromDate: fromDate,
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  getAllClientData(){
    this.headerForm.controls.client.setValue(null);
    this.getAllChangeLogs(this.headerForm.value.fromDate,this.headerForm.value.toDate);
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getPreviousState();
      this.setTitle();
      //this.loadDate();
      this.getAllChangeLogs(this.headerForm.value.fromDate,this.headerForm.value.toDate);
    }
}
