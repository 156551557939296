import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ListingService } from '../../services/listing.service';
import { ListingListModel } from '../../models/listing.model';
import { DatePipe } from '@angular/common';
import { ListingSelectorComponent } from 'src/app/listingComp/components/listing-selector/listing-selector.component';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { ErrorModel } from '../../models/errorModel.model';
import { isNullOrUndefined } from 'util';
import {isEmpty,trim} from 'lodash';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  @ViewChild(ListingSelectorComponent, { read: false, static: false })

  
  listingSelectorComponent: ListingSelectorComponent;
  listingForm: FormGroup = null;
  listingListModel:ListingListModel[]=[];
  listingListEdit:ListingListModel;
  activeRoute$: Subscription;
  rolePermission:any;

  constructor(private listingService:ListingService,
    private datePipe:DatePipe,
    private toast:ToastService,
    private loader:LoaderService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,private storePermission: Store<{ role: any }>,private fileService: FileService) { 
    this.listingForm=new FormGroup({
      listingMap:new FormControl(null) 
     
    });
  }

  ngOnInit(): void {
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getListingTable(){
    const lookupMap=[
      "msa",
      "naics",
     

   ];
  
    return lookupMap.map((t) => ({ key: t, value: t }));
   
  }

  getSelected(data){
    this.getListingDataByTable();
    this.listingSelectorComponent.selectorForm.reset();
    this.listingListEdit=null;        
  

  }


  getListingDataByTable(){
    this.loader.showLoader();
    this.listingListModel=[];
    const listingModel={
      "tableName":this.listingForm.controls.listingMap.value.key,
    }

    this.listingService.getAllListingByTable(listingModel)
    .subscribe(
      data=>{
        this.loader.hideLoader();
          this.listingListModel=data;
          this.listingListModel.forEach(item=>{
            item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
           
            if(item.modifiedAt===null){
              item.modifiedAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
            }else{
              item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
            }
  
           })
           
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    )

  }


  getListingOnEdit(data){
    console.log('Listing data value ',data)
    this.getListingById(data);
  }
  getListingById(data){
    this.loader.showLoader();
    const listingModel={
      "tableName":this.listingForm.controls.listingMap.value.key,
      "primaryId":data.primaryId
    }

    this.listingService.getListingById(listingModel)
    .subscribe(
      data=>{
        this.loader.hideLoader();
         this.listingListEdit=data;
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    )

  }

  saveData(data){
   this.loader.showLoader();


   console.log("main comp data ",data.code);
    //
    if(data.code===null ||data.code===undefined ||trim(data.code).length<1){
      this.toast.showError('error','Code mandatory ')
      this.loader.hideLoader();
      return;
    }
    if(data.description===null ||data.code===undefined ||trim(data.description).length<1){
      this.toast.showError('error','Description mandatory ')
      this.loader.hideLoader();
      return;
    }
   console.log("checkingSave ############"+this.listingListEdit);
    const listingModel={
      "tableName":this.listingForm.controls.listingMap.value.key,
      "primaryId":this.listingListEdit!==null&&this.listingListEdit!==undefined?this.listingListEdit.primaryId:0,
      "code":data.code,
      "description":data.description,

    }

    this.listingService.saveListing(listingModel)
    .subscribe(
      data=>{
        this.loader.hideLoader();
        this.toast.showSuccess('success','data saved successfully');
        this.resetForm();
        this.getListingDataByTable();
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    )
  }

  deleteById(data){
    
    this.loader.showLoader();
    console.log("delete id :",data);
    const listingModel={
      "tableName":this.listingForm.controls.listingMap.value.key,
      "primaryId":data.primaryId,
      "code":data.code,
      "description":data.description,
    }

    this.listingService.deleteListing(listingModel)
    .subscribe(
      data=>{

        this.loader.hideLoader();
        this.toast.showSuccess('success','data deleted successfully');
        this.resetForm();
        this.getListingDataByTable();
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    )
  }

  resetForm(){
    this.listingSelectorComponent.selectorForm.reset();
    this.listingListEdit=null;
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.setTitle();
    }
}
