import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LoaderService } from "src/app/loader/services/loader.service";

@Component({
  selector: "app-field-mappings-table",
  templateUrl: "./field-mappings-table.component.html",
  styleUrls: ["./field-mappings-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FieldMappingsTableComponent implements OnInit, OnChanges {
  alias: string = '';
  @Input() data: any[] = [];
  @Input() clientApplicationFile: any;
  @Input() fields: { key: number | string; value: string }[] = [];
  @Input() fileMapping: any[] = [];
  @Output() onSelect = new EventEmitter();
  @Output() savingData=new EventEmitter();
  @Input() isRecomedation;
  @Input() recommendationSize;
  cols = [{ header: "system field name", field: "fieldName" }];
  fieldsArray = new FormGroup({ fields: new FormArray([]) });
  loadPage: boolean = false;
  filteredData: any[];
  editStatus = [];
  enableEdit:string='';
  constructor(private loader: LoaderService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.loader.showLoader();
      this.loadPage = false;
      this.addArrayFields(changes.data.currentValue.length);
    }
  }
  get fieldArray() {
    return this.fieldsArray.get("fields") as FormArray;
  }
  findField(id) {
    return this.fields.find((d) => d.key === id);
  }
  filterData(event) {
    let query = event.query;
    this.filteredData = this.fields.filter(
      (d) => d.value.toLowerCase().indexOf(query.toLowerCase()) === 0
    );
  }
  addArrayFields(length) {
    this.fieldArray.clear();
    for (let i = 0; i < length; i++) {
      const arr = this.fieldArray;
      const data = this.findField(this.data[i].clientFileLayoutFieldId);
      arr.push(new FormControl(data));
      this.editStatus.push(false);
    }
    this.loadPage = true;
    this.loader.hideLoader();
  }
  updateField(id, loanField, mappingId,alias) {
    if (mappingId === undefined) {
      mappingId = null;
    }
    this.onSelect.emit({
      layoutFieldId: id,
      fieldName: loanField,
      primaryKey: mappingId,
      alias:alias
    });
  }

  update(i,type,alias,dataVal) {
    console.log("data "+i);
   if(!this.enableEdit && type!='save'){
    this.enableEdit = i;
   }else if(this.enableEdit && type==='save'){
   for(let i=0;i<this.data.length;i++){
     if(this.data[i].fieldName===this.enableEdit){
       this.data[i].alias=alias;
     }
   }  
   this.save(dataVal);
   console.log("save  data" +alias);
   }else{
    this.enableEdit = '';
   }
  }

  save(dataVal){
    console.log("data"+this.clientApplicationFile);

    console.log("data "+dataVal);
    const data={
      "alias": dataVal.alias,
      "fieldName": dataVal.fieldName,
      "clientApplicationFileId":this.clientApplicationFile.clientApplicationFileId,
      "clientFileLayoutFieldId":dataVal.clientFileLayoutFieldId,
      "clientFileMappingId":dataVal.clientFileMappingId,
      "createdAt":dataVal.createdAt,
      "createdBy":dataVal.createdBy,
      "modifiedAt":dataVal.modifiedAt,
      "modifiedBy":dataVal.modifiedBy,
      "tableName":dataVal.tableName!=null? dataVal.tableName:"alias"
    }
    this.savingData.emit(data);
  }
}
