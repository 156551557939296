import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { LogsModel } from "../models/logs.model";


@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  getCategories(id) {
    const body = new HttpParams().set(`category_id`, id);
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(
      `${environment.baseUrl}/api/etl/alfred/category/`,
      body.toString(),
      { headers }
    );
  }
  getSeries(id, cat1, cat2) {
    const body = new HttpParams()
      .set(`category_id`, id)
      .set("type1", cat1)
      .set("type2", cat2);
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(
      `${environment.baseUrl}/api/etl/alfred/series/`,
      body.toString(),
      { headers }
    );
  }
  // dumpData() {
  //   return this.http.get("/assets/data.json");
  // }
  dataLogs(data?: Object) {
    let body = new HttpParams();
    Object.keys(data).forEach((d) => {
      body = body.set(d, data[d]);
    });
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post<LogsModel[]>(
      `${environment.baseUrl}/api/etl/alfred/datasavelog/`,
      body.toString(),
      { headers }
    );
  }
  dataImport(data: Object) {
    let body = new HttpParams();
    Object.keys(data).forEach((d) => {
      body = body.append(d, data[d]);
    });
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(
      `${environment.baseUrl}/api/etl/alfred/import/`,
      body.toString(),
      { headers }
    );
  }
  getSeriesMeta(series_id) {
    let body = new HttpParams().set("variable", series_id);
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(
      `${environment.baseUrl}/api/etl/alfred/getdates/`,
      body.toString(),
      { headers }
    );
  }

  deleteBatchByBatchId(id) {
    const body = new HttpParams()
      .set(`batch_id`, id)
      
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(
      `${environment.baseUrl}/api/etl/alfred/delete/`,
      body.toString(),
      { headers }
    );
  }
  userRoleTransform(){
    return this.http.get<any>(
      `${environment.baseUrl}/users/userRoleTransform`
    );
  }
}
