import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { CalendarModule } from "primeng/calendar";
import { TableModule } from "primeng/table";

import { AutoCompleteModule } from "primeng/autocomplete";
import { RowDirective } from "./directives/row.directive";
import { ColumnDirective } from "./directives/column.directive";
import { ValidatorsModule } from "../validators/validators.module";
import { FormsyModule } from "../formsy/formsy.module";
import { CardComponent } from "./components/card/card.component";
import { ClientListComponent } from "./components/client-list/client-list.component";
import { ModalPopupComponent } from "./components/modal-popup/modal-popup.component";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { ModalPopup2Component } from './components/modal-popup2/modal-popup2.component';

@NgModule({
  declarations: [
    RowDirective,
    ColumnDirective,
    CardComponent,
    ClientListComponent,
    ModalPopupComponent,
    TruncatePipe,
    SafePipe,
    ModalPopup2Component,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    CalendarModule,
    AutoCompleteModule,
    ValidatorsModule,
    FormsyModule,
    TableModule,
  ],
  exports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    AutoCompleteModule,
    ValidatorsModule,
    ColumnDirective,
    RowDirective,
    FormsyModule,
    ClientListComponent,
    TableModule,
    ModalPopupComponent,
    SafePipe,
    ModalPopup2Component
  ],
})
export class SharedModule {}
