import { NgModule } from "@angular/core";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { SharedModule } from "../shared/shared.module";
import { UiModule } from "../ui/ui.module";
import { ExcelModule } from "../excel/excel.module";
import { OrganizationOpsComponent } from "./pages/organization-ops/organization-ops.component";
import { TransformComponent } from "./pages/transform/transform.component";
import { ClientFileComponent } from "./pages/client-file/client-file.component";
import { ClientService } from "./services/client.service";
import { MappingComponent } from "./pages/mapping/mapping.component";
import { FilesModule } from "../files/files.module";
import { RulesComponent } from "./pages/rules/rules.component";
import { IngestionRulesModule } from "../ingestion-rules/ingestion-rules.module";
import { TransformListComponent } from "./pages/transform-list/transform-list.component";
import { FileLayoutComponent } from "./pages/file-layout/file-layout.component";
import { ClientComponent } from './pages/client/client.component';
import { LayoutFileListComponent } from './pages/layout-file-list/layout-file-list.component';
import { UserComponent } from './pages/user/user.component';
import { UserModule } from '../user/user.module';
import { LookupComponent } from './pages/lookup/lookup.component';
import { LookupService } from './services/lookup.service';
import { DatePipe } from '@angular/common';
import { LookUpTableModule } from '../lookup-tables/lookup-tables.module';
import {UserCompModule} from '../userComp/userComp.module';
import { RolesComponent } from './pages/roles/roles.component';
import { DualListModule } from '../roles-dual/roles-dual.module';
import { ChangeLogComponent } from './pages/change-log/change-log.component';
import { LookupActionsComponent } from './pages/lookup-actions/lookup-actions.component';
import { WorkflowSetupComponent } from './pages/workflow-setup/workflow-setup.component';
import { WorkflowModule } from '../workflows/workflow.module';
import { SetupCustomComponent } from './pages/setup-custom/setup-custom.component';
import { WorkflowComponent } from './pages/workflow/workflow.component';
import { WorkflowJobComponent } from './pages/workflow-job/workflow-job.component';
import { ListingComponent } from './pages/listing/listing.component';
import {ListingModule} from '../listingComp/listing.module';
import { LookupColumnComponent } from './pages/lookup-column/lookup-column.component';
import { LookupColumnModule } from '../lookupColumn/lookupColumn.module';
import { ClientFileMasterComponent } from './pages/client-file-master/client-file-master.component';
import { ClientFileMasterModule } from '../client-master/clientFileMaster.module';
import { SetupJobComponent } from './pages/setup-job/setup-job.component';
import { DeleteDataComponent } from './pages/delete-data/delete-data.component';
import { ClientPortalDataComponent } from './pages/client-portal-data/client-portal-data.component';
import { ClientPortalModule } from '../client-portal/client-portal.module';
import { ClientPortalFormDataComponent } from './pages/client-portal-form-data/client-portal-form-data.component';
import { ArchiveFileComponent } from './pages/archive-file/archive-file.component';
import { CashflowListComponent } from './pages/cashflow-list/cashflow-list.component';
import { ValidationRulePageComponent } from './pages/validation-rule-page/validation-rule-page.component';
import { ValidationRulesModule } from '../validation-rules/validation-rules.module';
import { AuditHomeComponent } from './pages/audit-home/audit-home.component';
import { AuditTabModule } from '../audit-tabs/audittab.module';
import { CleanDataConfigComponent } from './pages/clean-data-config/clean-data-config.component';
import { CleanDataConfigModule } from '../cleanDataConfig/cleanDataConfig.module';
import { TransformOutputFilesComponent } from './pages/transform-output-files/transform-output-files.component';
import { StatisticDataModule } from "../statistic-data/statistic-data.module";
import { StatisticsComponent } from "./pages/statistics/statistics.component";
import { WorkflowQueueComponent } from './pages/workflow-queue/workflow-queue.component';
import { WorkflowJobListComponent } from './pages/workflow-job-list/workflow-job-list.component';
import { AddonUnmatchedAcctFilesComponent } from './pages/addon-unmatched-acct-files/addon-unmatched-acct-files.component';
import { SchedulerComponent } from './pages/scheduler/scheduler.component';
import { SchedulerCompModule } from "../schedulerComp/scheduler-comp.module";
import { RoleListComponent } from './pages/role-list/role-list.component';
import { RolelistCompModule } from "../rolelistComp/rolelist-comp.module";
import { SchedulerListComponent } from './pages/scheduler-list/scheduler-list.component';
import { AccordionModule } from 'primeng/accordion';
import { RoleMenuComponent } from './pages/role-menu-permission/role-menu-permission.component';
import { FormsModule } from "@angular/forms";
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { NotificationCompModule } from "../notificationComp/notification-comp.module";





@NgModule({
  declarations: [
    MainPageComponent,
    OrganizationOpsComponent,
    TransformComponent,
    ClientFileComponent,
    MappingComponent,
    RulesComponent,
    TransformListComponent,
    FileLayoutComponent,
    ClientComponent,
    LayoutFileListComponent,
    UserComponent,
    LookupComponent,
    RolesComponent,
    ChangeLogComponent,
    LookupActionsComponent,
    WorkflowSetupComponent,
    SetupCustomComponent,
    WorkflowComponent,
    WorkflowJobComponent,
    ListingComponent,
    LookupColumnComponent,
    ClientFileMasterComponent,
    SetupJobComponent,
    DeleteDataComponent,
    ClientPortalDataComponent,
    ClientPortalFormDataComponent,
    ArchiveFileComponent,
    CashflowListComponent,
    ValidationRulePageComponent,
    AuditHomeComponent,
    CleanDataConfigComponent,
    TransformOutputFilesComponent,
    StatisticsComponent,
    WorkflowQueueComponent,
    WorkflowJobListComponent,
    AddonUnmatchedAcctFilesComponent,
    SchedulerComponent,
    RoleListComponent,
    SchedulerListComponent,
    RoleMenuComponent,
    NotificationsComponent 
    
   
   

  ],
  imports: [
    SharedModule,
    UiModule,
    //DashboardRoutingModule,
    ExcelModule,
    FilesModule,
    IngestionRulesModule,
    LookUpTableModule,
    UserModule,
    UserCompModule,
    DualListModule,
    WorkflowModule,
    ListingModule,
    LookupColumnModule,
    ClientFileMasterModule,
    ClientPortalModule,
    ValidationRulesModule,
    AuditTabModule,
    CleanDataConfigModule,
    StatisticDataModule,
    SchedulerCompModule,  
    RolelistCompModule,
    AccordionModule, // Pradeep Yadav
    FormsModule, // Pradeep Yadav
    NotificationCompModule
    
  ],
  providers: [ClientService,LookupService,DatePipe]
})
export class DashboardModule {}
