import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { Subscription } from 'rxjs';
import { FileService } from 'src/app/files/services/file.service';
import { WorkflowService } from 'src/app/dashboard/services/workflow.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ErrorHandlerService } from 'src/app/error/services/error-handler.service';
import { WorkflowJobDetails, WorkflowJobLogsTableField, WorkflowModel } from '../../models/workflow.model';
import { FileLogModel } from 'src/app/dashboard/models/filelog.model';
import { Observable, interval, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { environment } from "src/environments/environment";
import { JobsLogModel, TransformModel } from '../../models/tranform.model';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel1 } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
// import { LoaderService } from 'src/app/loader/services/loader.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {

  private updateSubscription: Subscription;

  activeRoute$: Subscription;
  headerForm: FormGroup = null;
  data: any = [];
  jobTypeList: any = [];
  workflowDataList: any = [];
  jobDataList: any = [];
  worklogJobDataInworkflowId: WorkflowJobDetails[]= [];
  workflowJoblogDataInJobId:any=[];
  worklogJobDataInJobId: any= [];
  worklogJobDataInJobIdClone: any= [];
  workflowIngestJoblogData:FileLogModel[]=[];
  visible:boolean=false;
  popupHeader="Log Details";
  id:any;
  logdata:any=null;
  transformList: TransformModel[] = [];
  transformMapData = new Map();
  worklogJobDataInworkflowIdClone: WorkflowJobDetails[]= [];
  worklogJobDataInworkflowIdClone2: WorkflowJobDetails[]= [];
  workflowTransformJoblogData: JobsLogModel[] = [];
  visibleTransform:boolean=false;
  logHeader:any=" ";
  workflowData:WorkflowModel[]=[];
  dataTransferModel: DataTransferModel1;
  fromDt:any;
  toDt: any;
  showBackButton:boolean=false;
  rolePermission:any;



  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
    private fileService: FileService,
    private datePipe:DatePipe,
    public observalbleDataService: ObservalbleDataService,
    // private loader:LoaderService,
    private workflowService: WorkflowService,
    private router: Router,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>
  ) {
    this.headerForm = new FormGroup({
      client:new FormControl(null),
      workflow: new FormControl(null),
      job: new FormControl(null),
      type: new FormControl(null),
      date: new FormControl(null),
 
    });
  }


  ngOnInit(): void {
    //this.showBackButton=false;
   // this.getAllWorkflowData();
  this.buttonShowHidePermission();
  }


  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  //get all workflow--> Drpodown
  getAllWorkflowData() {
    this.workflowService.getAllWorkflowList().subscribe(
      (data) => {
        const workflowData = data;
        for (var a = 0; a < workflowData.length; a++) {
          // console.log("type "+workflowData[a].type);
          this.workflowDataList.push({ key: workflowData[a].workflowId, value: workflowData[a].workflowName });
        }
        console.log("workflowDataList## workflow-job page##:" + this.workflowDataList);
      },
      (err) => {
        //  this.toast.showSuccess("Status Not Changed", "error");
        this.toast.showError(err.error.message, "error");
      }
    );
  }

jobType = [
    { key: 'Ingest', value: "Ingest" },
    { key: 'Transform', value: "Transform" },
    { key: 'Output', value: "Output" },
    { key: 'Custom', value: "Custom" },
  ]

  onJobTypeChange() {
    this.worklogJobDataInworkflowId=[];
	  this.worklogJobDataInJobId=[];
    this.getJobStatusData();
  }

  //TODO
  //selecting -WORKFLOW
  onWorkflowChange() {
    console.log("onWorkflowChange## w:");
    this.worklogJobDataInworkflowId=[];
    this.worklogJobDataInJobId=[];
    this.logHeader=" ";
    this.getJobStatusData();// activate later
 
   // refresh code 
        // this.updateSubscription = interval(10000).subscribe(
        //       (val) => { this.getJobStatusData()   }
        //   );

  }

  //data from JOINED table1 display
  getJobStatusData() {
    // this.loader.hideLoader();
   // console.log("in main getJobStatusData, wfID", this.headerForm.controls.workflow.value.key);
  // this.workflowJoblogDataInJobId=[];
   this.worklogJobDataInworkflowIdClone=[];
    if (this.headerForm.controls.type.value!==null) {
      const requestModel = {
        "workflowId": this.headerForm.controls.workflow.value.key,
        "jobType": this.headerForm.controls.type.value.key
      }
      // this.loader.hideLoader();
    // }
    //this.worklogJobDataInworkflowId = [];
    this.workflowService.getWorkflowJobStatusData(requestModel).subscribe(
        
        data => {
          // this.loader.hideLoader();
          this.worklogJobDataInworkflowIdClone=data
          if(this.worklogJobDataInworkflowIdClone.length>0){
            console.log('hereeeeee ')
            this.getAllTransformJob(this.worklogJobDataInworkflowIdClone[0].clientId);
          }
         
          this.worklogJobDataInworkflowIdClone2=[];
          this.worklogJobDataInworkflowIdClone.forEach(item=>{
            if(item.jobType==='Transform'){
             // const mainJobName=this.transformMapData.get(item.jobId)!==null && this.transformMapData.get(item.jobId)!==undefined?this.transformMapData.get(item.jobId):"";
            //  item.jobName=mainJobName;
            }
            this.worklogJobDataInworkflowIdClone2.push(item)
          }

          )
          this.worklogJobDataInworkflowId=this.worklogJobDataInworkflowIdClone2;
          // this.loader.hideLoader();
          console.log("in main getWorkflowlogInworkflow", this.worklogJobDataInworkflowId);
          // this.loader.hideLoader();
        }
      
      )
    }
    if (this.headerForm.controls.type.value === null) {
      const requestModel = {
        "workflowId": this.headerForm.controls.workflow.value.key,
        // "jobType": this.headerForm.controls.type.value.key
      }
    // }
    // this.loader.hideLoader();
    this.worklogJobDataInworkflowIdClone2 = [];
    this.worklogJobDataInworkflowIdClone=[];
    this.workflowService.getWorkflowJobStatusData(requestModel)
      .subscribe(
        data => {
          // this.loader.hideLoader();
          this.worklogJobDataInworkflowIdClone=data
          if(this.worklogJobDataInworkflowIdClone.length>0){
            console.log('hereeeeee ')
            this.getAllTransformJob(this.worklogJobDataInworkflowIdClone[0].clientId);
          }
          this.worklogJobDataInworkflowIdClone2=[];
          this.worklogJobDataInworkflowIdClone.forEach(item=>{
            if(item.jobType==='Transform'){
            //  const mainJobName=this.transformMapData.get(item.jobId)!==null && this.transformMapData.get(item.jobId)!==undefined?this.transformMapData.get(item.jobId):"";
            //  item.jobName=mainJobName;
            }
            this.worklogJobDataInworkflowIdClone2.push(item)
            //this.worklogJobDataInworkflowId.push(item)
          }

          )
          this.worklogJobDataInworkflowId=this.worklogJobDataInworkflowIdClone2
          
          // this.loader.hideLoader();
          //this.worklogJobDataInworkflowId = data;
          // this.loader.hideLoader();
          //console.log("in main getWorkflowlogInworkflow", this.worklogJobDataInworkflowId);
          // this.loader.hideLoader();
        }
      
      )
    }
  }


  //data from workflow_job_Log  table byJobId
  getAllJobLogs(data) {
    this.worklogJobDataInworkflowId = [];
    this.workflowService.getWorkflowJobLogsByJobId(data)
      .subscribe(
        data => {

          if (data.length === 0) {
            this.toast.showInfo('no Log present', 'info');
          }
          this.worklogJobDataInworkflowId = data;
          console.log("in main getWorkflowlogInworkflow", this.worklogJobDataInworkflowId);

        }
      )
  }


  viewworkflowJobLogTable(data){
    // viewGroupVariable(data){
      this.workflowJoblogDataInJobId=[];
      this.logdata=data;
     // console.log("log data :",this.logdata);
      this.logHeader="Job Name : "+this.logdata.jobName;
      this.getWorkflowJoblogInJob(data);
      
    }
    
    getWorkflowJoblogInJob(data){
      // this.groupDisplayName=data.groupName;
     // this.workflowJoblogDataInJobId=[];
    // this.worklogJobDataInJobId=[];
    this.worklogJobDataInJobIdClone=[];
      this.workflowService.getWorkflowJobLogsByJobId(this.logdata)
      .subscribe(
        data=>{
          // this.viewGrpVar=true;
        //  // this.hideDelete=false;
          
         if(data.length===0){
            this.toast.showInfo('no Log present','info');
         }
         this.worklogJobDataInJobIdClone=data;
           this.worklogJobDataInJobId=this.worklogJobDataInJobIdClone;
           console.log("in main getWorkflowlogInworkflow",this.worklogJobDataInJobId);
          
        }
      )
    }


    // getToDateValue(data1){
    //   console.log("getDateValue  ##",data);
    // this.getWorkflowIngestJobLog(data);
    // }
    selectedTodate:any=null;
    getToDateValue(data1){
      console.log("getToDateValue1  ##",data1);
      this.selectedTodate=data1;
      console.log("getToDateValue2  ##", this.selectedTodate);

      this.selectedTodate==this.datePipe.transform(this.selectedTodate,"MM-dd-yyyy");
      console.log("selectedTodate1 @@@@ ",this.selectedTodate);
    }

    selectedFromdate:any=null;
    getFromDateValue(data1){
      console.log("getFromDateValue3  ##",data1);
      this.selectedTodate=data1;
      console.log("getToDateValue4  ##", this.selectedTodate);
      this.selectedTodate==this.datePipe.transform(this.selectedTodate,"MM-dd-yyyy");
      console.log("selectedTodate2 @@@@ ",this.selectedTodate);
    }

    viewworkflowIngestJobLogTable(data1){
      console.log("in main comp viewworkflowIngestJobLogTable ##",data1);
      this.workflowIngestJoblogData=[];
      if(data1.data.jobType==='Ingest'){
        console.log('inside Ingest   ::::')
        this.getWorkflowIngestJobLog(data1);
      }
      if(data1.data.jobType==='Transform'){
        console.log('inside Transform   ::::')
       this.getTransformLogs(data1.data.workflowjoblogId)
      }
     
    }

    workflowViewDetaiLogsPopup:any=null;
    getWorkflowIngestJobLog(data1){
      console.log("data1  ##",data1);
    this.workflowViewDetaiLogsPopup=data1.data.workflowjoblogId;
     const requestModel = {
      "workflowjoblogId":data1.data.workflowjoblogId,
      // "workflowjoblogId":1,
      "fromDate":null,
       "toDate":null,
       
    }
      // keep this hardcoded//uncomment it
    this.workflowService.getIngestRuleLogsByWorkflowJobLogId2(requestModel).subscribe(
      data=>{
        this.workflowIngestJoblogData=data;
        this.visible=true;
        console.log("in main workflowIngestJoblogData",this.workflowIngestJoblogData);
      } )
    }
  

    closePopup(){
      this.visible=false;
      this.visibleTransform=false;
    }

    ngOnDestroy() {
     // this.activeRoute$.unsubscribe();
    
      if (this.id) {
        clearInterval(this.id);
        
      }
    }

    runJob(data){
      this.executeSingleJob(data);
    }

    executeSingleJob(data){
      const requestModel={
        "workflowId":data.workflowId,
        "workflowJobId":data.jobId,
        "workflowjoblogId":data.workflowJobLogId,
        "requestUrl":environment.baseUrl,
        "workflowLogId":data.workflowLogId,
        "workflowDetailId":data.workflowDetailId
      }

      this.workflowService.executeSingleJob(requestModel)
      .subscribe(
        data=>{
          this.toast.showSuccess("Job execution started","check logs");
        },
        error=>{
          this.toast.showError("error","error running jobs")
        }
      )
    }

    getAllTransformJob(id){
      this.transformList =[];
    // this.transformMapData=null;
      
      this.fileService.getTransformListByClient(id)
      .subscribe(
        data=>{
          if (data && data["status"] === 200) {
            this.transformList = data["data"];
          
            this.transformList.forEach(item=>{
             this.transformMapData.set(item.job_id,item.job_name);
              })
              console.log("elese portion");
          
          }
          
  
        }
      )
    }

    getTransformLogs(id) {
      this.workflowTransformJoblogData = [];
      this.visible=false;
      //this.loader.showLoader();
      const requestModel={
        "workflowjoblogId":id
      }
      this.fileService.getTransformLogByJobLogId(requestModel).subscribe(
        (data) => {
          this.workflowTransformJoblogData = data;
          this.visibleTransform=true;
        },
        (err) => {
          //this.loader.hideLoader();
          this.toast.showError("Authorization error", "Unable to fetch data");
        }
      );
    }

    onClientChange(event){
     // this.maintainClientState(event);
     this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
     this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
     this.workflowDataList=[];
     this.worklogJobDataInworkflowId=[];
    this.worklogJobDataInJobId=[];
    this.worklogJobDataInJobIdClone=[];
    this.worklogJobDataInworkflowIdClone2=[];
    this.headerForm.controls.workflow.reset();
    this.logHeader=" ";
      this.getAllWorkflow();
      this.getAllTransformJob(this.headerForm.controls.client.value.key);
    }


    getAllWorkflow(){
     // console.log("getting called");
      this.workflowDataList=[];
     
     // this.setupJobForm.controls.workflow.reset();
      const requestModel={
        "clientId":this.headerForm.controls.client.value.key
      }
      
      this.workflowService.getAllWorkflow(requestModel)
      .subscribe(
        (data)=>{
          this.workflowData=data;
          
          this.workflowData.forEach(item=>{
            this.workflowDataList.push({key:item.workflowId,value:item.workflowName})
          })
              
        },
        (err)=>{
         
         // this.toast.showError('error',err.error.message);
         this.toast.showError(err.error.message, "error");
        }
      )
  
    }

  getPreviousState() {
    this.observalbleDataService.inventoryChanged$$.subscribe(
      data => {
        this.dataTransferModel = data;
        if (this.dataTransferModel.clientId !== undefined) {
          this.headerForm.get("client").patchValue({
            key: this.dataTransferModel.clientId,
            value: this.dataTransferModel.clientName,
          });
          this.getAllTransformJob(this.headerForm.controls.client.value.key);
          this.getAllWorkflow();
        }

        if (this.dataTransferModel.workflowId !== undefined && this.dataTransferModel.workflowId !== 0) {
          if(this.dataTransferModel.workflowName !== null )
         
          this.headerForm.get("workflow").patchValue({
            key: this.dataTransferModel.workflowId,
            value: this.dataTransferModel.workflowName,
          });
         // this.onWorkflowChange();
         this.showBackButton=true;
        // this.getAllWorkflow();
       
        this.getJobStatusData();
        }
        
      });
      // if(this.showBackButton!==true){
      //   const clientIdC=this.cookieService.get('clientId');
      //   const clientNameC=this.cookieService.get('clientName');
      //   console.log(clientIdC);
      //   console.log(clientNameC);
        
      //   if(clientIdC!==undefined && clientIdC!==null && clientIdC!==""){
      //     this.headerForm.get("client").patchValue({
      //              key: clientIdC,
      //              value: clientNameC,
      //              });
      //              this.getAllWorkflow();                 
      //   }
      // }
  }
  
  maintainClientState(event){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: event.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: event.value,
      page: '',
      fileName: '',
      workflowName: this.headerForm.controls.workflow.value.value,
      workflowId: this.headerForm.controls.workflow.value.key
    }
    this.observalbleDataService.addToInventoryData(this.dataTransferModel); 
  }

  goToPage(){
    if(this.showBackButton){
    this.dataTransferModel={
           clientFileId:this.dataTransferModel.clientFileId,
           clientId: this.headerForm.controls.client.value.key,
           isRedirected:1,
           applicationId:this.dataTransferModel.applicationId,
           toDate:this.dataTransferModel.toDate,
           fromDate:this.dataTransferModel.fromDate,
           clientName:this.headerForm.controls.client.value.value,
           page:'',
           fileName:this.dataTransferModel.fileName,
           workflowName:this.headerForm.controls.workflow.value.value,
          workflowId:this.headerForm.controls.workflow.value.key
    }
   // console.log(this.dataTransferModel,'helooo ',this.pageName);
    this.observalbleDataService.addToInventoryData(this.dataTransferModel);
    this.router.navigate(['/workflow']);
  
  }

}
buttonShowHidePermission(){
  this.fileService.buttonShowHidePermission().subscribe(
    (res) =>{ 
         this.rolePermission = res  
         this.callNgOnInitMethods();
 }
  )};

  callNgOnInitMethods(){
    this.setTitle();
    this.id=setInterval(() => {
      this.getJobStatusData(); 
      if(this.logdata!==null){
        this.getWorkflowJoblogInJob(this.logdata);
      }
    }, 30000);//30 sec
    this.getPreviousState();
  }
}
