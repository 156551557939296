import { Component, OnInit, Input, SimpleChanges, Output ,EventEmitter, OnChanges} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { mainModule } from 'process';
import { formatDate } from "src/app/utils/dates";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";

@Component({
  selector: 'app-workflow-job-selector',
  templateUrl: './workflow-job-selector.component.html',
  styleUrls: ['./workflow-job-selector.component.scss']
})
export class WorkflowJobSelectorComponent implements OnInit,OnChanges {
 
  selectorForm: FormGroup = null;
  @Input() editWorkflowJobColumns;
  @Output() saveWorkflowColumns = new EventEmitter();
  @Output() resetWorkflow = new EventEmitter();
  @Input() permission:any;
  @Input() notificationList;
  @Input() applicationList;
  placeHolder:any="mm.dd.yyyy"
  selectedNotificationType: {notificationId: any;notificationName: any}[]=[];
  applicationType:any;
  // @Output() saveWorkflowColumns = new EventEmitter();
  enableEdit=false;

  constructor() { 

    this.selectorForm = new FormGroup({
      workflowName: new FormControl(null),
      isActive: new FormControl(null),
      workflowType: new FormControl(null),
      asOfDate:new FormControl(null),
      notificationType: new FormControl(null),
      clientApplicationFileDataType: new FormControl(null)
       });

  }

  isActive=[
    { key: '1', value: "Yes" },
    { key: '0', value: "No" },
 
  ]

  workflowType=[
    { key: '1', value: "System" },
    { key: '2', value: "Custom" },
  ]

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.editWorkflowJobColumns && changes.editWorkflowJobColumns.currentValue) {
    //  console.log(changes.editWorkflowJobColumns.currentValue,' inside changes') 
       const workflowJob=changes.editWorkflowJobColumns.currentValue;
       this.enableEdit = true;
       let notificationEditList=changes.editWorkflowJobColumns.currentValue.notificationMappingList
       this.selectedNotificationType=[];
       for (var i = 0; i < notificationEditList.length; i++) {
         this.selectedNotificationType.push({ notificationId: notificationEditList[i].notificationId, notificationName: notificationEditList[i].notificationName });
       }

       for(var j=0;j<this.applicationList.length;j++){
        if(workflowJob.datatypeId===this.applicationList[j].key){
          const mapData = {
            key: this.applicationList[j].key,
            value: this.applicationList[j].value,
          };
          this.applicationType=mapData;
        }

       }
 
       this.checkForEdit() 
       this.selectorForm.patchValue({
        "notificationType":this.selectedNotificationType,
        "clientApplicationFileDataType":this.applicationType
      });
      }

    } 
    
    checkForEdit() {
      if (this.editWorkflowJobColumns && this.enableEdit) { 
        this.selectorForm.patchValue({
          workflowName: this.editWorkflowJobColumns.workflowName,
          isActive:{key:this.editWorkflowJobColumns.isActive,value:this.editWorkflowJobColumns.isActive==='1'?'Yes':'No'},
          workflowType:{key:this.editWorkflowJobColumns.workflowType,value:this.editWorkflowJobColumns.workflowType==='1'?'System':'Custom'},
          notificationType:this.selectedNotificationType
        });
        this.enableEdit = false;
      }
     
    }


    saveWfData(){
      const data={
        "workflowName":this.selectorForm.controls.workflowName.value,
        "isActive":this.selectorForm.controls.isActive.value.key,
        "workflowType":this.selectorForm.controls.workflowType.value.key,
        "asofdate":this.selectorForm.controls.asOfDate.value!==null && this.selectorForm.controls.asOfDate.value!==undefined?this.selectorForm.controls.asOfDate.value:null,
        "notificationType": this.selectedNotificationType,
        "applicationType": this.selectorForm.controls.clientApplicationFileDataType
      }
      this.saveWorkflowColumns.emit(data);
      this.resetData();
    }

    

  resetData(){
    
    this.resetWorkflow.emit(true);
    this.selectorForm.reset();
    this.selectedNotificationType=[];
   
    this.enableEdit = false;
    this.editWorkflowJobColumns=null;
        if(this.selectorForm!==null){
        this.selectorForm.reset();
        }
  }

  multiNotificationTypeSelectChange(eventData){
    this.selectedNotificationType = eventData.value;
  //  console.log("Datatype Value Updated Is:", this.selectedNotificationType);

  }

}
