import { uniq } from "lodash";

export const LOOKUP_FIELDS = {

    accrualbasis_code: {
        controls: [        
          {
            label: "Accrual Basis*",
            name: "field1",
            type: "dropdown",
            options:[]
          }        
        ]
      },
      call_report_code: {
        controls: [
          {
            label: "Call Report Code*",
            name: "field1",
            type: "dropdown",
            options:[]
          },
          // {
          //   label: "Risk Weight",
          //   name: "field2",
          //   type: "text",
          //   placeholder: "Risk Weight"
          // },
          {
            label: "Product Type*",
            name: "field3",
            type: "dropdown",
            options:[]
          },
          {
            label: "Big Cd" ,
            name: "field4",
            type: "text",
            placeholder: "Big Cd"
          }
        ]
      },
      collateral_code: {
        controls: [
          {
            label: "Grouping",
            name: "field1",
            type: "text",
            placeholder: "Grouping"
          },
          {
            label: "Category1",
            name: "field2",
            type: "text",
            placeholder: "Category1"
          },
          {
            label: "Category2",
            name: "field3",
            type: "text",
            placeholder: "Category2"
          },
          {
            label: "Profit",
            name: "field4",
            type: "text",
            placeholder: "Profit"
          }
        ]
      },
      line_of_credit_code: {
        controls: [
          {
            label: "Loc Flag",
            name: "field1",
            type: "text",
            placeholder: "Loc Flag"
          },
          {
            label: "Loc Flag Profit",
            name: "field2",
            type: "text",
            placeholder: "Loc Flag Profit"
          },
          {
            label: "Loc Type Profit",
            name: "field3",
            type: "text",
            placeholder: "Loc Type Profit"
          },
          {
            label: "Loc Type Name",
            name: "field4",
            type: "text",
            placeholder: "Loc Type Name"
          }
        ]
      },
      loan_type_code: {
        controls: [
          {
            label: "Loan Type Name",
            name: "field1",
            type: "text",
            placeholder: "Loan Type Name"
          },
          {
            label: "Loan Term Type",
            name: "field2",
            type: "text",
            placeholder: "Loan Term Type"
          },
         
        ]
      },
      non_accrual_code: {
        controls: [
          {
            label: "Non Accural Flag",
            name: "field1",
            type: "text",
            placeholder: "Non Accural Flag"
          },
          {
            label: "Non Accural Flag1",
            name: "field2",
            type: "text",
            placeholder: "Non Accural Flag2"
          },
        
        ]
      },
      officer_code: {
        controls: [
          {
            label: "Division",
            name: "field1",
            type: "text",
            placeholder: "Division"
          },
          {
            label: "Department",
            name: "field2",
            type: "text",
            placeholder: "Department"
          },
        
        ]
      },
      payment_frequency_code: {
        controls: [
          {
            label: "Payment Frequency",
            name: "field1",
            type: "text",
            placeholder: "Payment Frequency"
          },
          {
            label: "Payment Frequency Bank Description",
            name: "field2",
            type: "text",
            placeholder: "Payment Frequency Bank Description"
          },
        
        ]
      },
      payment_type_code: {
        controls: [
          {
            label: "Payment Type",
            name: "field1",
            type: "text",
            placeholder: "Payment Type"
          },
        ]
      },
      purpose_code: {
        controls: [
          {
            label: "Product Type",
            name: "field1",
            type: "text",
            placeholder: "Product Type"
          },
         
        
        ]
      },
      rate_change_frequency_code: {
        controls: [
          {
            label: "Rate Change Frequency*",
            name: "field1",
            type: "dropdown",
            options:[]
          },
          {
            label: "Rate Change Frequency Pcbb Decs",
            name: "field2",
            type: "text",
            placeholder: "Rate Change Frequency Pcbb Decs"
          },
         
        
        ]
      },
      reprice_index_code: {
        controls: [
          {
            label: "Rate Flag",
            name: "field1",
            type: "text",
            placeholder: "Rate Flag"
          },
          {
            label: "Reprice Index*",
            name: "field2",
            type: "dropdown",
            options: [] 
          },
          {
            label: "Rate Flag Desc",
            name: "field3",
            type: "text",
            placeholder: "Rate Flag Desc"
          },
         
        
        ]
      },
      risk_rating_code: {
        controls: [
          {
            label: "Risk Rating Substandard",
            name: "field1",
            type: "text",
            placeholder: "Risk Rating Substandard"
          },

        ]
      },
      interest_payment_frequency_code: {
        controls: [
          {
            label: "Interest Payment Frequency",
            name: "field1",
            type: "text",
            placeholder: "Interest Payment Frequency"
          },
          {
            label: "Interest Payment Frequency Bank Description",
            name: "field2",
            type: "text",
            placeholder: "Interest Payment Frequency Bank Description"
          }
        ]
      },
//NEWTABLES
// rate_flag_code
      rate_flag_code: {
        controls: [
          {
            label: "Rate Flag",
            name: "field1",
            type: "text",
            placeholder: "Rate Flag"
          },
        
        ]
      },

// accrualbasis_borrow_code
accrualbasis_borrow_code: {
  controls: [        
    {
      label: "Accural Basis",
      name: "field1",
      type: "text",
      placeholder: "Accural Basis"
    }        
  ]
},
// call_put_frequency_borrow_code
call_put_frequency_borrow_code: {
  controls: [
    {
      label: "Call Put Frequency",
      name: "field1",
      type: "text",
      placeholder: "Call Put Frequency"
    },
    {
      label: "Call Put Frequency Bank Description",
      name: "field2",
      type: "text",
      placeholder: "Call Put Frequency Bank Description"
    },
  
  ]
},
// call_put_index_borrow_code
call_put_index_borrow_code: {
  controls: [
    {
      label: "Rate Flag",
      name: "field1",
      type: "text",
      placeholder: "Rate Flag"
    },
    {
      label: "Call Put Index",
      name: "field2",
      type: "text",
      placeholder: "Call Put Index"
    },
    {
      label: "Rate Flag Desc",
      name: "field3",
      type: "text",
      placeholder: "Rate Flag Desc"
    },
   
  
  ]
},

// call_put_method_borrow_code
call_put_method_borrow_code: {
  controls: [
    {
      label: "Call Put Method Name",
      name: "field1",
      type: "text",
      placeholder: "Call Put Method Name"
    },
   
  ]
},
// call_report_borrow_code
call_report_borrow_code: {
  controls: [
    {
      label: "Call Report Code",
      name: "field1",
      type: "text",
      placeholder: "Call Report Code"
    },
    {
      label: "Risk Weight",
      name: "field2",
      type: "text",
      placeholder: "Risk Weight"
    },
    {
      label: "Product Type",
      name: "field3",
      type: "text",
      placeholder: "Product Type"
    },
    {
      label: "Big Cd" ,
      name: "field4",
      type: "text",
      placeholder: "Big Cd"
    }
  ]
},
// compound_frequency_borrow_code
compound_frequency_borrow_code: {
  controls: [
    {
      label: "Compound Frequency",
      name: "field1",
      type: "text",
      placeholder: "Compound Frequency"
    },
    {
      label: "Compound Frequency Bank Description",
      name: "field2",
      type: "text",
      placeholder: "Compound Frequency Bank Description"
    },
  
  ]
},
// credit_rating_borrow_code
credit_rating_borrow_code: {
  controls: [
    {
      label: "Credit Rating Substandard",
      name: "field1",
      type: "text",
      placeholder: "Credit Rating Substandard"
    },

  ]
},
// interest_payment_frequency_borrow_code
interest_payment_frequency_borrow_code: {
  controls: [
    {
      label: "Interest Payment Frequency",
      name: "field1",
      type: "text",
      placeholder: "Interest Payment Frequency"
    },
    {
      label: "Interest Payment Frequency Bank Description",
      name: "field2",
      type: "text",
      placeholder: "Interest Payment Frequency Bank Description"
    }
  ]
},
// payment_frequency_borrow_code
payment_frequency_borrow_code: {
  controls: [
    {
      label: "Payment Frequency",
      name: "field1",
      type: "text",
      placeholder: "Payment Frequency"
    },
    {
      label: "Payment Frequency Bank Description",
      name: "field2",
      type: "text",
      placeholder: "Payment Frequency Bank Description"
    },
  
  ]
},
// payment_type_borrow_code
payment_type_borrow_code: {
  controls: [
    {
      label: "Payment Type",
      name: "field1",
      type: "text",
      placeholder: "Payment Type"
    },
  
  ]
},
// rate_change_frequency_borrow_code
rate_change_frequency_borrow_code: {
  controls: [
    {
      label: "Rate Change Frequency",
      name: "field1",
      type: "text",
      placeholder: "Rate Change Frequency"
    },
    {
      label: "Rate Change Frequency Pcbb Decs",
      name: "field2",
      type: "text",
      placeholder: "Rate Change Frequency Pcbb Decs"
    },
   
  
  ]
},
// reprice_index_borrow_code
reprice_index_borrow_code: {
  controls: [
    {
      label: "Rate Flag",
      name: "field1",
      type: "text",
      placeholder: "Rate Flag"
    },
    {
      label: "Reprice Index",
      name: "field2",
      type: "text",
      placeholder: "Reprice Index"
    },
    {
      label: "Rate Flag Desc",
      name: "field3",
      type: "text",
      placeholder: "Rate Flag Desc"
    },
   
  
  ]
},

          // "deposit_ownership_code",
          deposit_ownership_code: {
            controls: [
              {
                label: "Ownership Code",
                name: "field1",
                type: "text",
                placeholder: "Ownership Code"
              },
              {
                label: "Alm Ownership Category",
                name: "field2",
                type: "text",
                placeholder: "Alm Ownership Category"
              },
             
            
            ]
          },
          // "deposit_product_code",
          deposit_product_code: {
            controls: [
              {
                label: "Product Code",
                name: "field1",
                type: "text",
                placeholder: "Product Code"
              },
              {
                label: "Alm Product Category",
                name: "field2",
                type: "text",
                placeholder: "Alm Product Category"
              },
             
            
            ]
          },

          state_code: {
            controls: [
              {
                label: "State*",
                name: "field1",
                type: "dropdown",
                options:[]
              },
              {
                label: "Region1",
                name: "field2",
                type: "text",
                placeholder: "Region1"
              },
              {
                label: "Region2",
                name: "field3",
                type: "text",
                placeholder: "Region2"
              },
             
            
            ]
          },

          general_ledger_code: {
            controls: [
              
              {
                label: "BS/IS",
                name: "field9",
                type: "text",
                placeholder: "BS/IS"
              },
              {
                label: "Alm Mapping",
                name: "field10",
                type: "text",
                placeholder: "Alm Mapping"
              },
              {
                label: "Future Mapping 1",
                name: "field11",
                type: "text",
                placeholder: "Future Mapping 1"
              },
              {
                label: "Future Mapping 2",
                name: "field12",
                type: "text",
                placeholder: "Future Mapping 2"
              },
              {
                label: "Future Mapping 3",
                name: "field13",
                type: "text",
                placeholder: "Future Mapping 3"
              },
             
            
            ]
          },
          loan_class_code: {
            controls: [
              {
                label: "Loan Class Name",
                name: "field1",
                type: "text",
                placeholder: "Loan Class Name"
              },
             
            ]
          },
          credit_rating_code: {
            controls: [
              {
                label: "Credit Rating Substandard",
                name: "field1",
                type: "text",
                placeholder: "Credit Rating Substandard"
              },
    
            ]
          },


}



export function extraFields() {
    const keys = Object.keys(LOOKUP_FIELDS);
    const fields = keys.map(k => {
      const { controls } = LOOKUP_FIELDS[k];
      return { key: k, fields: controls.map(c => c.name) };
    });
    return fields;
  }
  export function uniqueFields() {
    const fields = extraFields();
    let data = [];
    for (let f of fields) {
      data = [...data, ...f["fields"]];
    }
    const uniqFields = uniq(data).map(d => ({ [d]: null }));
    return uniqFields.reduce(function(result, item) {
      var key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});
  }