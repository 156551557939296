import { NgModule } from '@angular/core';
import { StatisticListComponent } from './component/statistic-list/statistic-list.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [StatisticListComponent],
  imports: [
    SharedModule
  ],
  exports: [
    StatisticListComponent, 
    
  ],
  providers: []
  })
  
export class StatisticDataModule { }
