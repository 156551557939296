import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CashflowService {
/**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http:HttpClient) { }

 getAllTables(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/cashflow/getAllTableList");
 }

 importCashflow(tableName,file,client){

  var payload = new FormData();
  payload.append("tablename",tableName);
  payload.append("file",file[0]);
  payload.append("client_id",client);
  return this.http.post<any[]>(environment.baseUrl+"/api/etl/cashflow/import/file/",payload);  
}
}
