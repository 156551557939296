import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from '../../services/workflow.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { Subscription } from 'rxjs';
import { FileService } from 'src/app/files/services/file.service';
import { WorkflowResponseModel ,WorkflowModel, WorkflowTableField, WorkflowLogsTableField} from '../../models/workflow.model';
import { WorkflowJobSelectorComponent } from 'src/app/workflows/components/workflow-job-selector/workflow-job-selector.component';
import {isEmpty,trim} from 'lodash';
import { DatePipe } from '@angular/common';
import { environment } from "src/environments/environment"
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel1 } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { analytics } from 'firebase';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-workflow-job',
  templateUrl: './workflow-job.component.html',
  styleUrls: ['./workflow-job.component.scss']
})
export class WorkflowJobComponent implements OnInit {
  @ViewChild(WorkflowJobSelectorComponent, { read: false, static: false })  
  workflowJobSelectorComponent: WorkflowJobSelectorComponent;
  activeRoute$: Subscription;

  
 
  fileDataTypeList: any = [];
  workflowResponseModel:WorkflowResponseModel[]=[];
  workflowLogsResponseModel:WorkflowLogsTableField[]=[];
  workflowJobListEdit:WorkflowTableField;
  workflowModelListEdit:WorkflowModel;
  workflowJobForm:  FormGroup = null;

  workflowlogDataInworkflow:any=[];
  worklogDataInworkflowId:any=[];
  id:any;
  logData:any=null;
  dataTransferModel:DataTransferModel1;
  applicationList: { key: string | number; value: string }[];
  rolePermission:any;
  activeNotifications:{notificationId:any;notificationName:any}[]=[];
  notificationList:any=[];


  constructor( private titleService: TitleService,
    private datePipe:DatePipe,
    private activeRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private toast:ToastService,
    private loader:LoaderService,
    private fileService:FileService,
    public observalbleDataService:ObservalbleDataService,
    private router: Router,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>) { 
    this.workflowJobForm = new FormGroup({
      client: new FormControl(null),
      clientApplicationFileDataType: new FormControl(null),
      isActive: new FormControl(null),
      
       });

  }

  ngOnInit(): void {
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }





//display all data19
getAllWorkflow(){
  //this.worklogDataInworkflowId=[]; 
  // const workflowResponseModel1={
  //   "clientId":this.workflowJobForm.controls.client.value.key
  // }

  const workflowResponseModel1={
    "clientId":this.workflowJobForm.controls.client.value.key,
    "clientApplicationFileDataType":this.workflowJobForm.controls.clientApplicationFileDataType.value.key
  }

  this.workflowService.getWorkflowByClientNameDataType(workflowResponseModel1).subscribe(
   data=>{
    this.loader.hideLoader();
    this.workflowResponseModel=data;
    
    this.workflowResponseModel.forEach(item=>{
      // console.log("item",item.isActive===1);
      item.clientId=this.workflowJobForm.controls.client.value.key;
      item.isActiveDisplay=item.isActive===1?'Yes':'No'
      item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
      item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
    
    })
   },
   error => {
    this.toast.showError(error.error.message, "error");
  }
  )
}

//datatype dropdown value
getApplicationFileType(){
  this.fileService.getApplicationDataTypeByUser().subscribe(
 (data) => {
   const fileDataType=data;
   for(var a=0;a<fileDataType.length;a++){

     this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
   }
 },
 (err) => {
 // this.toast.showSuccess("Status Not Changed", "error");
  this.toast.showError(err.error.message, "error");
 }
);
}


//edit code
getWorkflowOnEdit(data){
  this.getWorkflowById(data);
}
getWorkflowById(data){
  this.loader.showLoader();
  const workflowModelConst={ 
    "workflowId":data.workflowId,
    "isActive":data.isActive,
    "workflowType":data.workflowType
  }
  this.workflowService.getWorkflowById(workflowModelConst)
  .subscribe(
    data=>{
      this.loader.hideLoader();
       this.workflowJobListEdit=data;
    },
    err=>{
      this.workflowJobListEdit=data;
    }
  )
}


saveData(data){
this.loader.showLoader();

  if(this.workflowJobForm.controls.client.value.value===null || this.workflowJobForm.controls.client.value.value===undefined || trim(this.workflowJobForm.controls.client.value.value).length<1){
    this.toast.showError('error','Client Name mandatory ')
    this.loader.hideLoader();
    return;
     
  }

//   if(this.workflowJobForm.controls.clientApplicationFileDataType.value.value===null || this.workflowJobForm.controls.clientApplicationFileDataType.value.value===undefined || trim(this.workflowJobForm.controls.clientApplicationFileDataType.value.value).length<1)
//   { this.toast.showError('error','Application Name mandatory ')
//   this.loader.hideLoader();
//   return;
   
// }
  if(data.workflowName===null ||data.workflowName===undefined ||trim(data.workflowName).length<1){
    this.toast.showError('error','Workflow Name mandatory ')
    this.loader.hideLoader();
    return;
  }
  if(data.isActive===null ||data.isActive===undefined ||trim(data.isActive).length<1){
    this.toast.showError('error','Is Active mandatory ')
    this.loader.hideLoader();
    return;
  }
  if(data.workflowType===null ||data.workflowType===undefined ||trim(data.workflowType).length<1){
    this.toast.showError('error','Workflow Type mandatory ')
    this.loader.hideLoader();
    return;
  }
 
  console.log("checkingSave ############"+data);
  console.log("checkingSave ############"+this.workflowJobListEdit);
  const workflowData={
    "workflowId":this.workflowJobListEdit!==null&&this.workflowJobListEdit!==undefined?this.workflowJobListEdit.workflowId:0,
    "workflowName":data.workflowName,
    "clientId":this.workflowJobForm.controls.client.value.key,
    "datatypeId":data.applicationType.value.key,
    "isActive":data.isActive,
    "workflowType":data.workflowType,
    "asofdate":data.asofdate,
    "notificationMappingList":data.notificationType
    
  }
                           
  this.workflowService.saveWorkflowById(workflowData)
  .subscribe(
    data=>{
      this.loader.hideLoader();
      this.toast.showSuccess('success','data saved successfully');
      // this.resetForm();
      this.getAllWorkflow();
      this.getApplicationFileType();
      this.getAllApplication();
    },
    err=>{
      this.toast.showError('error','error saving data');
      this.loader.hideLoader();
    }
  )
}

// viewworkflowTableLog(){}



viewworkflowTableLog(data){
// viewGroupVariable(data){
  this.workflowlogDataInworkflow=[];
  this.logData=data;
  //this.createGroupHeader=" Add Variable to group : "+data.groupName;
  this.getWorkflowlogInworkflow(data);
  
}

getWorkflowlogInworkflow(data){
  // this.groupDisplayName=data.groupName;
 // this.workflowlogDataInworkflow=[];
 
  
  this.workflowService.getWorkflowLogsByWorkflowId(this.logData)
  .subscribe(
    data=>{
    // this.viewGrpVar=true;
    //  // this.hideDelete=false;

     if(data.length===0){
        this.toast.showInfo('no Log present','info');
     }
       this.worklogDataInworkflowId=data;
    //     // this.worklogDataInworkflowId.forEach(item=>{
    //       console.log("item",item.dateOfEventRun);
          
    //     item.dateOfEventRun==this.datePipe.transform(item.dateOfEventRun,"MM-dd-yyyy");
    //     item.lastrunDate==this.datePipe.transform(item.lastrunDate,"MM-dd-yyyy");
        
    // })
           console.log("in main getWorkflowlogInworkflow",this.worklogDataInworkflowId);
     
    },
    error=>{
      this.toast.showError(error.error.message, "error");
    }
  )
}


executeWorkflow(data){
  console.log('worflow data :',data);
  let dateVal=null;
  let startDate=null;
  let endDate=null;
  if(data.asofDate!==null || data.data.asofDate!==undefined){
    console.log(data.asofDate)
    dateVal=this.datePipe.transform(data.asofDate,'yyyy-MM-dd');
  }
  if(data.startDate!==null || data.data.startDate!==undefined){
    console.log(data.startDate)
    startDate=this.datePipe.transform(data.startDate,'yyyy-MM-dd');
  }
  if(data.endDate!==null || data.data.endDate!==undefined){
    console.log(data.endDate)
    endDate=this.datePipe.transform(data.endDate,'yyyy-MM-dd');
  }
  // console.log("Transform param ",data.data.transformParams);
  // console.log("Transform param ",data.transformParams)
  const requestModel={
"requestUrl":environment.baseUrl,
  //"requestUrl":"http://localhost:8080",
  "workflowId":data.data.workflowId,
  "fromDate":dateVal,
  "startDate":startDate,
  "endDate":endDate,
  "genericTransformParams":data.transformParams,
  "overrideParams":data.overrideParams
  }
  this.loader.showLoader();
  console.log('request :',requestModel);
  this.workflowService.executeWorkflow(requestModel)
  .subscribe(
    data=>{
      this.loader.hideLoader();
      this.toast.showInfo('Success','Check logs for status');
     
      
    },
    error=>{
      this.loader.hideLoader();
     // this.toast.showInfo('Success','Added to queue,Check logs for status');
     this.toast.showError(error.error.message, "error");
    }
  )
}

resetForm(){
  console.log("this.workflowJobSelectorComponent.selectorForm",);
  this.workflowJobSelectorComponent.selectorForm.reset();
  this.workflowJobListEdit=null;
}


resetWorkflowData(data){
  this.workflowJobListEdit=null;
}

onClientChange(event){
  this.cookieService.set('clientId',this.workflowJobForm.controls.client.value.key);
  this.cookieService.set('clientName',this.workflowJobForm.controls.client.value.value);
  //this.maintainClientState(event);

  this.worklogDataInworkflowId=[];
	
  this.workflowResponseModel=[];
  this.resetOnChange(); 
  this.getAllWorkflow();
}

onDataTypeChange(){

  this.worklogDataInworkflowId=[];
	
  this.workflowResponseModel=[];
  this.cookieService.set('dataTypeName',this.workflowJobForm.controls.clientApplicationFileDataType.value.value);
  this.cookieService.set('dataTypeId',this.workflowJobForm.controls.clientApplicationFileDataType.value.key);
  this.resetOnChange();
  this.getAllWorkflow();
}


setupJob(data){
this.goToPage(data);
}

goToPage(data){
 
  this.dataTransferModel={
    clientFileId:0,
    clientId: this.workflowJobForm.controls.client.value.key,
         isRedirected:1,
         applicationId: 0,
         toDate: '',
         fromDate: '',
         clientName: this.workflowJobForm.controls.client.value.value,
         page:"/setup",
         fileName: '',
        workflowName: data.data.workflowName,
        workflowId:data.data.workflowId,
       
  }
  
  this.observalbleDataService.addToInventoryData(this.dataTransferModel);
  if(data.pageUrl.includes('setup')){
    this.router.navigate(['/setup']);
  }
  if(data.pageUrl.includes('workflow')){
    this.router.navigate(['/workflow-job']);
  }
  


}

resumeWorkflow(data){
  console.log(data)
  const requestModel={
     "requestUrl":environment.baseUrl,     
      "workflowId":data.workflowId,
      "workflowLogId":data.workflowLogId
      }

      this.workflowService.resumeWorkflow(requestModel)
      .subscribe(
        data=>{
          this.toast.showSuccess('Success','Check Logs for status')
        },
        error=>{
          this.toast.showError('error','error resuming workflow execution');
        }
      )
}

resetOnChange(){
  try{
    this.workflowJobSelectorComponent.selectorForm.reset();
  }catch{
    console.log('exception');
  }
  
  this.workflowJobListEdit=null;
}

ngOnDestroy() {
 // this.activeRoute$.unsubscribe();
  if (this.id) {
    clearInterval(this.id);
  }
}

getPreviousState() {
  const clientIdC=this.cookieService.get('clientId');
  const clientNameC=this.cookieService.get('clientName');
  const dataTypeIdC=this.cookieService.get('dataTypeId');
  const dataTypeNameC=this.cookieService.get('dataTypeName');
  console.log(clientIdC);
  console.log(clientNameC);
  
  if(clientIdC!==undefined && clientIdC!==null && clientIdC!==""){
    this.workflowJobForm.get("client").patchValue({
             key: clientIdC,
             value: clientNameC,
             });
            //  this.getAllWorkflow();                 
  }

  if(dataTypeIdC!==undefined && dataTypeNameC!==null){
    this.workflowJobForm.get("clientApplicationFileDataType").patchValue({
             key: dataTypeIdC,
             value: dataTypeNameC,
             });
             this.getAllWorkflow();                 
  }


  
    this.worklogDataInworkflowId=[];
    this.workflowResponseModel=[];
    this.resetOnChange(); 
    //this.getAllWorkflow();
}

maintainClientState(client){
  this.dataTransferModel = {
    clientFileId: 0,
    clientId: this.workflowJobForm.controls.client.value.key,
    isRedirected: 1,
    applicationId: 0,
    toDate: '',
    fromDate: '',
    clientName: this.workflowJobForm.controls.client.value.value,
    page: '',
    fileName: '',
    workflowName: '',
    workflowId: 0,
  }
  this.observalbleDataService.addToInventoryData(this.dataTransferModel); 
}

getAllApplication(){
     console.log("enter into application")
  this.fileService
  .getAllApplicationByUser()
  .subscribe(
    (data) =>
      (this.applicationList = reMapForAutoComplete(
        data,
        "applicationId",
        "name"
      )),
      (err) => {
        this.toast.showError(err.error.message, "error");
      
      }
  );
  }


  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getApplicationFileType();
      this.getAllApplication();
      this.setTitle();
      this.getAllNotification();
      this.getPreviousState();
     
      
      
      this.id=setInterval(() => {
        this.getAllWorkflow(); 
        if(this.logData!==null){
        this.getWorkflowlogInworkflow(this.logData)
        }
      }, 30000);//30 sec
    }


   getAllNotification(){

     console.log("enter into notification")
    this.fileService.getActiveNotificationListByUser().subscribe(
      (data) => {
       this.notificationList=data;
       // this.loader.hideLoader();
        this.notificationList = this.reMapForNotificationTypeMultiSelect( this.notificationList, "notificationId", "notificationName");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  
  
    )
   }

    reMapForNotificationTypeMultiSelect(

      data: any[],
      keyCol: string,
      valCol: string
    ): {notificationId: string | number; notificationName: string }[] {
      return data.map(d => ({ notificationId: d[keyCol], notificationName: d[valCol] }));
  
  
    }
  
  
  
}
