import { Injectable, OnInit } from "@angular/core";
import { NavigationStart, Router, Event } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SidebarService  {
  private _sidebarVisibility = false;
  private sidebarSubject = new BehaviorSubject<boolean>(
    this._sidebarVisibility
  );
  sidebarObservable = this.sidebarSubject.asObservable();
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          this.toggleVisibility(false);
      }
    });
  }

  toggleVisibility(show: boolean) {
    this._sidebarVisibility = show;
    this.sidebarSubject.next(this._sidebarVisibility);
  }
}
