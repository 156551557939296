import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { CleanDataConfigListComponent } from './components/clean-data-config-list/clean-data-config-list.component';
import { CleanDataConfigSelectorComponent } from './components/clean-data-config-selector/clean-data-config-selector.component';


@NgModule({
    declarations: [
        CleanDataConfigListComponent,
        CleanDataConfigSelectorComponent
    
    ],
    imports: [SharedModule],
    exports: [
        CleanDataConfigListComponent,
        CleanDataConfigSelectorComponent
    ],
    providers: []
  })
  export class CleanDataConfigModule {}