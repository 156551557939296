import { NgModule } from '@angular/core';
import { ClientPortalListComponent } from './components/client-portal-list/client-portal-list.component';
import { SharedModule } from "../shared/shared.module";



@NgModule({
declarations: [
  ClientPortalListComponent, 
  

],
imports: [SharedModule],
exports: [
  ClientPortalListComponent, 
  
],
providers: []
})
export class ClientPortalModule { }
