import { ActionReducer, MetaReducer, Action } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";

const keys = ["user"];
function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys,
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}
export const metaReducers: Array<MetaReducer<any, Action>> = [
  localStorageSyncReducer,
];
