import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-setup-custom-selector',
  templateUrl: './setup-custom-selector.component.html',
  styleUrls: ['./setup-custom-selector.component.scss']
})
export class SetupCustomSelectorComponent implements OnInit {

 
  lookUpForm: FormGroup = null;
  constructor() { 
    this.lookUpForm = new FormGroup({
      job: new FormControl(null),
      jobType: new FormControl(null),
      description: new FormControl(null),
      trigger: new FormControl(null),
      dependentUpon: new FormControl(null),
      nextJob:new FormControl(null),
      enabled: new FormControl(null),
      target: new FormControl(null),
      custom: new FormControl(null),
      frequency: new FormControl(null)
    });
  }

  getLookupTable(){
    const lookupMap=[
      "Yes",
     "No"
    

   ];
  
    return lookupMap.map((t) => ({ key: t, value: t }));
   
  }

  ngOnInit(): void {
  }

}
