import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { FormsModel } from 'src/app/models/forms.model';
import { DatePipe } from '@angular/common';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormControl  } from '@angular/forms';


import {isEmpty,trim} from 'lodash';


@Component({
  selector: 'app-workflow-job-table',
  templateUrl: './workflow-job-table.component.html',
  styleUrls: ['./workflow-job-table.component.scss']
})
export class WorkflowJobTableComponent implements OnInit {
  
  @Input() data;/////@Input() data:LookupColumnListModel[] = [];
  @Output() editWorkflowCol=new EventEmitter();
  @Input() hidebutton:boolean;
  @Output() viewworkflowLog=new EventEmitter();
  @Output() execute=new EventEmitter();
  @Output() setupJobData=new EventEmitter();
  @Output() resumeEmit=new EventEmitter();
  @Input() permission:any;
  selectedJob: any;
  modalFormModel: FormsModel = null;
  modalForm: FormGroup = null;
  
  @ViewChild('fromCal') calendarFrom: Calendar
  visible:boolean=false;
  paramHeader="Execute Job";
  asofdate:any=null;
  exeData:any=null;
  viewAsOfDate:string="";
  workFlowType:string;
  workFlowName:any;
  cvar:string;
  asDate:any=null;
  asOfDate:any=null;
  currdate:boolean=false;
  value:string;
  isChecked:boolean;
  form:any=null; 
  startDate:any=null;
  endDate:any=null;

  cols: { field: string; header: string }[];
  paramsConst:{ key: string; value: string }[];

  constructor(private datePipe:DatePipe,
    private toast:ToastService) { 
    this.cols = [
      { field: "workflowName", header: "Workflow" },
      { field: "isActiveDisplay", header: "Active" },
      { field: "workflowTypeDisplay", header: "Type" },
      { field: "applicationName", header: "Application" },
      { field: "status", header: "Status" },
      { field: "lastStatus", header: "Last Job Status" },
      
    ];

    this.form = new FormGroup({
      Date: new FormControl('', Validators.required)
    });
  
  }

  ngOnInit(): void {
  }

  editWorkflowTable(data){
    console.log("edit workflow job",data);
    this.editWorkflowCol.emit(data);

  }

  vieworkflowLogs(data){
    this.viewworkflowLog.emit(data);

  }

  // executeWorkflow(data){
  //   console.log("executeWorkflow in job-table: ",data);
  //   this.visible=true;
  //   this.exeData=data;
  //   this.paramHeader="Execute Job "+this.exeData.workflowName;
  //   this.viewAsOfDate=data.asOfDate;
  //   if(data.transformParams!==null){
      
  //     this.modalFormModel = null;
     
  //    const params = JSON.parse(data.transformParams);
  //    const keys = Object.keys(params);
  //   if (keys && keys.length <= 0) {
  //  //  this.toast.showInfo("info","no parameter available")
  //  } else {
  //    this.createModelFromParams(params,data.asOfDate);

  //    console.log("wf-job-table executeWorkflow(): createMoelFromParam- ", this.createModelFromParams(params,data.asOfDate));
  //  }
  //   }
   
    
  //   // this.execute.emit(data);

  // }


  executeWorkflow(data){​​​​​​​   
    this.visible=true;
    this.form.patchValue({
      Date: 'currDate',
    });
    this.exeData=data;
    console.log("exeData  ",this.exeData);
    this.paramHeader="Execute Job "+this.exeData.workflowName;
    this.viewAsOfDate=data.asOfDate;
    this.workFlowType=this.exeData.workflowTypeDisplay;
    this.workFlowName=this.exeData.workflowName;
    this.workFlowName=this.workFlowName.toLowerCase(); 
    console.log("workflowtype ",this.workFlowType)
    this.cvar=null;
    
    if(data.transformParams!==null){​​​​​​​
      
      this.modalFormModel = null;
     
     const params = JSON.parse(data.transformParams);
     const keys = Object.keys(params);
    if (keys && keys.length <= 0) {​​​​​​​
   //  this.toast.showInfo("info","no parameter available")
   }​​​​​​​ else {​​​​​​​
     this.createModelFromParams(params,data.asOfDate, data.clientId);
     
   }​​​​​​​
    }​​​​​​​
  
   
    
    // this.execute.emit(data);

  }​​​​​​​
 
  doAction(data){
    this.visible=false;
    this.exeData=null;
    this.modalForm.reset();
    this.modalForm=null;
    this.modalFormModel=null;
    this.paramHeader="Execute Job";
  }
  executeJobPopup(){
    let overrideValue=false;
    if(this.modalForm && this.modalForm.value!==null){
     let paramsConst1=this.modalForm.value;
     
      const retrVal=paramsConst1.override;
      console.log("boolVal ",retrVal);
      if(retrVal===true){
        overrideValue=true;
       
        for(let result in paramsConst1){
          console.log("result",result);
           if(paramsConst1[result]===null || paramsConst1[result]===undefined || trim(paramsConst1[result]).length===0 ){
              
              this.toast.showInfo("Tarnsform params are mandatory","error");
              return ;
           }
           console.log("After null check");
        }
        const hasAsofDateVal=this.exeData.hasAsOfDate;
        if(hasAsofDateVal===true){
         // console.log("this.asofdate ",this.asofdate);
          if(this.asofdate===null){
           // console.log(" null or empty not accepted");
            this.toast.showInfo("asofdate is required for transform","error");
            return ;
          }
        }
      }
     
    
    }

    if(this.form.controls.Date.value ==="dateRange"){
    if(this.startDate!==null && this.endDate!==null){
      let validate=this.validationOnDate(this.startDate,this.endDate);
      if(validate===false){
        this.toast.showWarn('error','date range incorrect');
        this.visible=false;
        this.startDate=null;
        this.endDate=null;
        return;
      }
   }

     if((this.startDate==null && this.endDate!==null) || (this.startDate!==null && this.endDate==null) ){
      this.toast.showWarn("Both start and end dates are required","error");
      this.visible=false;
      this.startDate=null;
      this.endDate=null;
      return;
     }
    }else{
      this.startDate=null;
      this.endDate=null;
    }
     
    const fwdData={
      "data":this.exeData,
      "asofDate":this.asofdate,
      "startDate":this.startDate,
      "endDate":this.endDate,
      "transformParams":this.modalForm&&this.modalForm.value!==null?JSON.stringify(this.modalForm.value):null,
      "singleDate":new Date(),
      "overrideParams":overrideValue
    }
    this.execute.emit(fwdData);
    this.visible=false;
    this.exeData=null;
    //this.calendarFrom.value = null;
    if(this.modalForm!==null){
    this.modalForm.reset();
    }
    this.modalForm=null;
    this.form.patchValue({
      Date: 'currDate',
    });
    this.modalFormModel=null;
    this.asofdate=null;
    this.startDate=null;
    this.endDate=null;
    this.calendarFrom.updateInputfield();
  }

  changeAsoFdate(e){
    this.asofdate=e;
    console.log("hello ")
  }

  onDateBlur(e){
    this.asofdate=e.target.value;
   // console.log("hello ",this.asofdate)
  }

  setUpJobs(data,pageUrl){

    const emitData={
      "data":data,
      "pageUrl":pageUrl
    }
    this.setupJobData.emit(emitData);


  }

  resumeWorkflow(data){
    //console.log("table com ",data)
      this.resumeEmit.emit(data);
  }

  // createModelFromParams(params,asOfDate) {
  //   console.log("")
  //   this.modalFormModel = { controls: [] };
  //   this.modalFormModel.controls.push({
  //     name: "override",
  //     type:"radio",
  //     label:"Override" ,
  //     defaultValue: true,
  //   });
  //   for (let k in params) {
  //     //console.log('vvvvvvv ',params[k]);
  //     let valu=params[k];
      
  //     let dataType="text";
  //     if(k.includes('date')){
  //       dataType="date";
  //     }
      
  //     this.modalFormModel.controls.push({
  //       name: k,
  //       type: dataType === "date" ? "date" : "text",
  //       label: k,
  //       defaultValue: valu,
  //     });
  //   }
  // }

  // getModalObject(form) {
    
  //   this.modalForm = form;
  //   console.log("form",form );
  //   console.log("getModalObject- this.modalForm",this.modalForm);
  // }
  createModelFromParams(params,asOfDate,client) {
    this.modalFormModel = { controls: [] };
    this.modalFormModel.controls.push({
      name: "override",
      type:"radio",
      label:"Override" ,
      defaultValue: true,
    });
    for (let k in params) {
      let valu;
      //console.log('vvvvvvv ',params[k]);
      if(k==="clientid"){
       valu=client;
      }else{
       valu=params[k];
      }
      let dataType="text";
      if(k.includes('date')){
        dataType="date";
      }
      
      this.modalFormModel.controls.push({
        name: k,
        type: dataType === "date" ? "date" : "text",
        label: k,
        defaultValue: valu,
      });
  }
  }

  getModalObject(form) {
    this.modalForm = form;
  }

  getDate(exeData){
   this.cvar=null;
   this.currdate=true;
   
  // console.log(" Value of Data is : ",this.currdate);
   
   
 }

 getDateRange(){
  this.cvar='1';
  this.currdate=false;
  return this.cvar;

 }

 
// buidform(){
//   this.form = new FormGroup({
//     Date: new FormControl('', Validators.required)
//   });}

  changeFromDate(event){
    this.startDate=event;
  }

  changeToDate(event){
  this.endDate=event;
  }
 
  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }

}
