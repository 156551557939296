import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ClientFileFormComponent } from './components/client-file-form/client-file-form.component';
import { ClientFileListComponent } from './components/client-file-list/client-file-list.component';



@NgModule({
    declarations: [
      ClientFileFormComponent,
      ClientFileListComponent
    
    ],
    imports: [SharedModule],
    exports: [
        ClientFileFormComponent,
        ClientFileListComponent
    ],
    providers: []
  })
  export class ClientFileMasterModule {}
  