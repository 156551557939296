import { Component, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import { userInfo } from 'os';
import { MenuItem } from "primeng/api";
import { take } from 'rxjs/operators';
import { storeUser } from 'src/app/user/store/auth.action';
import { MenuService } from "../../services/menu.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  providers: [MenuService]
})
export class MenuComponent implements OnInit {
  items: MenuItem[];
  counter1: any;
  user: any;
  constructor(private menuService: MenuService, private store: Store<{ user: any }>) {

  }
  ngOnInit(): void {
    this.store.select('user').pipe(take(1))
      .subscribe(u => this.user = u)
    
   //   if(this.user.clientId===0 || this.user.clientId===null){
      this.menuService.getMenuItems().subscribe(
      data => (this.items = data),
      err => console.log("error", err)
    );
     // }
   //   else{  
    //    this.menuService.getClientMenuItems().subscribe(
    //      data => (this.items = data),
     //     err => console.log("error", err)
     //   );
     //   }
  }
}
