import { Component, Input, OnInit } from '@angular/core';
import { ValidationRuleLogModel } from 'src/app/dashboard/models/validationRulelog.model';

@Component({
  selector: 'app-validation-log',
  templateUrl: './validation-log.component.html',
  styleUrls: ['./validation-log.component.scss']
})
export class ValidationLogComponent implements OnInit {
  @Input() data: ValidationRuleLogModel[] = [];
  columns = ["Validation Rule Table", "Validation Rule Name","Asofdate","Message","Created At"];
  constructor() {}

  
  ngOnInit(): void {}
}
