import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from 'src/app/core/services/title.service';
import { UserService } from 'src/app/user/services/user.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { RoleService } from 'src/app/dashboard/services/role.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rolelist-table',
  templateUrl: './rolelist-table.component.html',
  styleUrls: ['./rolelist-table.component.scss']
})
export class RolelistTableComponent implements OnInit {

  
  activeRoute$: Subscription;
  roleLists:any=[];
  cols: { field: string; header: string }[];
  visible:boolean=false;
  headerData:any;
  constructor(private titleService:TitleService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private toast:ToastService,
    private rolesService: RoleService) {



      this.cols = [
        { field: "roleName", header: "Role Name" },
        { field: "roleDescription", header: "Description" },
        { field: "roleStatus", header: "Status" },
    
      ];




     }

  ngOnInit(): void {
    this.setTitle();
    this.getRoles();
  }



  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  getRoles(){
    this.userService.getRoleListData().subscribe(
      (data) => {
        this.roleLists=data;
        console.log("rolefrombackend ",data)
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  
  actionDo(data){
    
    
    console.log("roleData",data)
    this.rolesService.setRoleStatus(data).subscribe(
      (data) => {

      
        this.toast.showSuccess("Done", "successfully");
    
        this.getRoles();
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );  
  }

}
