import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/core/services/toast.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { Subscription } from 'rxjs';
import { FileService } from "src/app/files/services/file.service";
import { ClientFilesModel, LayoutFilesModel } from '../../models/files.model';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ClientApplicationFileEditModel } from '../../models/file-layout.model';
import { environment } from 'src/environments/environment';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { isEmpty } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-layout-file-list',
  templateUrl: './layout-file-list.component.html',
  styleUrls: ['./layout-file-list.component.scss']
})
export class LayoutFileListComponent implements OnInit {
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  clientFilesModel:ClientFilesModel[]=[]
  layoutFilesModel:LayoutFilesModel[]=[]
  columns: string[] = [
    "serial.",
    "file name",
    "type",
    "valid from",
    "valid to",
    "created at",
    "actions",
  ];
  currentPage:string='/layout-files';
  dataTransferModel:DataTransferModel;
  modalHeader: string;
  visible = false;
  visibleDelete=false;
  modalForm: FormGroup = null;
  clientId: number = null;
  cloneApplicationFileId:number=null;
  editFileVisible=false;
  clientApplicationFileForm: FormGroup=null;
  fieldSeparatorList:{ key: string | number; value: string }[];
  fileTypeList: any = [];
  fileDataTypeList: any = [];
  layoutFieldList: any = [];
  dependentUponList: any = [];
  currentFileUpload: File;
  clientApplicationFileEditModel:ClientApplicationFileEditModel=null;
  appFileName="";
  dataValue:any;
  visibleCloneAll:boolean=false;
  fromDt:any;
  toDt:any;
  dateRangeForm:FormGroup=null;
  visibleDateRange:boolean=false;
  headerData:any="";
  fileName:string=null;
  cols: { field: string; header: string }[];
  type;
  rolePermission:any;

  constructor(
    private fileService: FileService,
    private loader: LoaderService,
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private storePermission: Store<{ role: any }>
  ) { 

    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
      
    });

    this.modalForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      file:new FormControl(null, [Validators.required])
      
    });

    this.clientApplicationFileForm = new FormGroup({
      file:new FormControl(null),
      fileType:new FormControl(null, [Validators.required]),
      fileDataType:new FormControl(null, [Validators.required]),
      layoutFieldId:new FormControl(null),
      dependentUpon:new FormControl(null),
      fieldSeparator:new FormControl(null),
      schedule:new FormControl(null)


  });

  this.dateRangeForm = new FormGroup({
    fromDate: new FormControl(null, [Validators.required]),
    toDate:new FormControl(null, [Validators.required])
    
  });
  
  this.cols = [
    { field: "fileName", header: "File Name" },
    { field: "type", header: "Type" },
    { field: "validFrom", header: "Valid From" },
    { field: "validTo", header: "Valid To" },
    { field: "createdAt", header: "Created At" },
  ];

  this.type=["Core","Addon","Supplemental"].map((d)=> ({key:d,value:d}));
  }

  ngOnInit(): void {
    // this.setTitle();
    // this.getPreviousState();
    // this.getApplicationFileType();
    // this.getClientApplicationFileTypeData();
    // this.getAllSeparators();
    this.buttonShowHidePermission();
   // this.storePermission.select(x => this.rolePermission = x).subscribe();
    
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  loadDate(){
    let currentDateRange=currentMonthRange();
   //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
    this.headerForm.controls.toDate.setValue(currentDateRange[1]);
   //console.log(this.headerForm.controls.fromDate.value,'  current date range ',this.headerForm.controls.toDate.value);
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
  }

  changeToDate(e){
    this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
      this.getFilesData(this.headerForm.get("client").value,"changeToDtae",
      this.headerForm.controls.fromDate.value,
      this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
    //this.maintainStateOnDateChange(e,'toDateChange');
  }

  changeFromDate(e){
    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
      this.getFilesData(this.headerForm.get("client").value,"changeFromDate",
      this.headerForm.controls.fromDate.value,
      this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //this.maintainStateOnDateChange(e,'fromDateChange');
  }

  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }
  getFilesData1(client, loadType){
    this.getFilesData(client,loadType,this.headerForm.controls.fromDate.value,
      this.headerForm.controls.toDate.value);
  }
  getFilesData(client, loadType,fromDate,toDate) {
    if(loadType==='onChange'){
    //  this.maintainClientState(client);
    }  
    this.cookieService.set('clientId',client.key);
    this.cookieService.set('clientName',client.value);
    this.layoutFilesModel=[];
    this.loader.showLoader(); 
    // this.fileService.getFilesByClientId(client.key)
    // .subscribe(
    //   (data)=>{
    //      this.clientFilesModel=data;
    //   },
    //   (err)=>{
    //     this.toast.showError('error',err.error.message);
        
    //   },    
    // )
    this.fileService.getLayoutFilesByClientId(client.key)
    .subscribe(
        (data)=>{
           this.layoutFilesModel=data;
           if(this.layoutFilesModel.length===0){
            
              this.toast.showError("No Data present","error");
              
              
           }
        },
        (err)=>{
          this.toast.showError(err.error.message, "error");
          
        },    
      )
    this.loader.hideLoader(); 
  }

  navigateToPage(page: string, data: any) {
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    const client={

    }
    this.dataTransferModel = {
      clientFileId: data.clientApplicationFileId,
      clientId: data.clientId,
      isRedirected: 1,
      applicationId: 0,
      toDate: null,
      fromDate: null,
      clientName: this.headerForm.value.client.value,
      page: this.currentPage,
      fileName:data.fileName,
      workflowName: null,
      workflowId: 0,
      dataType: null,
      dataTypeId: 0,
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  
    const fileData = {
      clientId: data.clientId,
      clientFile: data.clientApplicationFileId,
      fileName: data.fileName,
      clientName: this.headerForm.value.client.value,
      fromDate:'',
      toDate:'',
      pageName:this.currentPage
    };
    
    if(page==="client-file-master" || page==="file-layout"){
      this.router.navigate([page], {
        queryParams: {  },
      });
    }else{
      this.router.navigate([page], {
        queryParams: { ...fileData },
      });
    }
   
  }


  doAction(type) {
    if (type === "yes") {
      console.log("helloo");
     
    //  this.modalForm.reset();
     
    }
    this.modalForm.get("file").reset();
    this.modalForm.get("client").reset();
    this.dateRangeForm.reset();
    
    this.clientId=null;
    this.visible = false;
    this.visibleCloneAll=false;
    this.visibleDateRange=false;
    this.visibleDelete=false;
  }

  showPopup(data) {
   
    if(data.hasRules===0 ){
      this.toast.showInfo('info','file has no rules');
      return;
    }
   
   
    this.cloneApplicationFileId=data.clientApplicationFileId;
    this.visible = true;
  }
  showPopupCloneAll(data) {
   
    this.cloneApplicationFileId=data.clientApplicationFileId;
    this.visibleCloneAll = true;
  }
  getFiles(){
    this.modalForm.controls.file.setValue(null);
     this.clientId=this.modalForm.controls.client.value.key
  }

  cloneRule(){
    if(this.cloneApplicationFileId===this.modalForm.controls.file.value.key){
      this.toast.showWarn('error','select another file');
      return;
    }
    this.visible=false;  
      const clientFileModel={
        "clientApplicationFileId":this.cloneApplicationFileId,
        "updatedClientApplicationFileId":this.modalForm.controls.file.value.key
      }
      this.fileService.cloneRulesToApplicationFiles(clientFileModel)
      .subscribe(
        (data)=>{
          console.log(data);
          this.toast.showSuccess('success','Rules cloned');
        },
        (err)=>{
          this.toast.showError(err.error.message, "error");
        }

      )

      this.modalForm.reset();
      this.clientId=null;

    
  }

  editFile(data){
    console.log(data);
    this.editFileVisible=true;
    this.appFileName=data.fileName;
    this.getClientApplicationFileByFileId(data.clientApplicationFileId);
    this.getLayoutField(this.headerForm.controls.client.value.key);
    this.getDependentUponData(this.headerForm.controls.client.value.key);
  }

  getAllSeparators(){
    this.fieldSeparatorList= [
     { key: ',', value: "Comma" },
     { key: 'space', value: "Space" },
     { key: '|', value: "Pipe(|)" },
     { key: '"', value: "Quotes" },
     { key: ';', value: "Semicolon" },
     { key: ':', value: "Colon"},
     { key: '\t', value: "Tab" }
   ];
  
  }

  getClientApplicationFileTypeData(){
    this.fileService.getClientApplicationFileType().subscribe(
      (data) => {
        const clientApplicationFileType=data;
        for(var a=0;a<clientApplicationFileType.length;a++){
         // console.log("type "+clientApplicationFileType[a].type);
          this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
        }
      },
      (err) => {
      //  this.toast.showSuccess("Status Not Changed", "error");
      this.toast.showError(err.error.message, "error");
      }
    
    );
  
  }

  getApplicationFileType(){
    this.fileService.getApplicationDataTypeByUser().subscribe(
   (data) => {
     const fileDataType=data;
     for(var a=0;a<fileDataType.length;a++){
     // console.log("type "+fileDataType[a].type);
       this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
     }
   },
   (err) => {
   //  this.toast.showSuccess("Status Not Changed", "error");
   this.toast.showError(err.error.message, "error");
   }
 
 );
    
 }

 getLayoutField(clientId){
  this.layoutFieldList=[];
  this.fileService.getAppFilesByClientId(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}

getDependentUponData(clientId){
  this.dependentUponList=[];
  this.fileService.getDependentUpon(clientId).subscribe(
    (data) => {
      const dependentUpon=data;
      for(var a=0;a<dependentUpon.length;a++){
        console.log("type "+dependentUpon[a].fileName);
        this.dependentUponList.push({ key: dependentUpon[a].clientApplicationFileId, value: dependentUpon[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}

doAddFileAction(type){
  if (type === "yes") {
    console.log("helloo");
    this.editFileVisible = false;
  //  this.modalForm.reset();
  this.clientApplicationFileForm.reset();
  this.currentFileUpload=null;
  this.myInputVariable.nativeElement.value = "";
  }else{
    this.clientApplicationFileForm.reset();
  }
}

getClientApplicationFileByFileId(data){

  const requestModel={
    "clientApplicationFileId":data
  }
  this.fileService.getClientApplicationFileByFileId(requestModel)
  .subscribe(
    data=>{
           this.clientApplicationFileEditModel=data;

           this.clientApplicationFileForm.patchValue({
           
            fileType:{key:this.clientApplicationFileEditModel.clientApplicationFileTypeId,value:this.clientApplicationFileEditModel.clientApplicationFileTypeName},
            fileDataType:{key:this.clientApplicationFileEditModel.fileDataTypeId,value:this.clientApplicationFileEditModel.fileDataTypeId},
            layoutFieldId:{key:this.clientApplicationFileEditModel.layoutFileId,value:this.clientApplicationFileEditModel.layoutFileName},
            dependentUpon:{key:this.clientApplicationFileEditModel.dependentUponId,value:this.clientApplicationFileEditModel.dependentUponName},
            fieldSeparator:{key:this.clientApplicationFileEditModel.fieldSeperator,value:this.clientApplicationFileEditModel.fieldSeperatorValue},
           
            });
    },
    err=>{
      this.toast.showError('error',err.error.message);
    }
  );
}

      saveClientApplicationFile(){
        if(this.clientApplicationFileForm.valid){
        if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
          this.toast.showWarn('no file selected','error');
          return;
        }

        if(this.clientApplicationFileForm.controls.fileType.value.key===2){
          if(this.clientApplicationFileForm.controls.dependentUpon.value===null || this.clientApplicationFileForm.controls.dependentUpon.value.key===0){
          this.toast.showError('Please select dependent upon file','error');
          return;
        }
      }
          const ClientAppliactionFileConst = {
          "appFileId":this.clientApplicationFileEditModel!==null&&this.clientApplicationFileEditModel!==undefined?this.clientApplicationFileEditModel.clientApplicationFileId:null,
          "clientId": this.headerForm.controls.client.value.key,
          "clientApplicationFileTypeId": this.clientApplicationFileForm.controls.fileType.value.key,
          "dependentUpon":this.clientApplicationFileForm.controls.dependentUpon.value,
          "schedule":this.clientApplicationFileForm.controls.schedule.value,
          "fieldSeparator":this.clientApplicationFileForm.controls.fieldSeparator.value,
          "clientApplicationFileDataTypeId":this.clientApplicationFileForm.controls.fileDataType.value.key,
          "layoutFieldId":this.clientApplicationFileForm.controls.layoutFieldId.value
        };
        this.loader.showLoader();
        //console.log(' clapppfile data',ClientAppliactionFileConst)
        this.fileService.editClientApplicationFile(this.currentFileUpload,ClientAppliactionFileConst) .subscribe(
          data=>{
            this.toast.showSuccess('Client Application File Added','Success');
            this.clientId=null;
            this.currentFileUpload=null;
            this.myInputVariable.nativeElement.value = "";
            this.visible=false;
            this.clientApplicationFileForm.reset();
            this.loader.hideLoader();
            this.getFilesData(this.headerForm.controls.client.value,'saveAppFile',
              this.headerForm.controls.fromDate.value,  this.headerForm.controls.toDate.value);

          
          },
          error=>{
            this.toast.showError(error.error.message, "error")
            this.loader.hideLoader();
          },
        
        );
      }else{
        this.toast.showError('Please Fill the Mandatory Fields','Error')
        this.loader.showLoader();
      }
      }

      selectClientApplicationFile(event){
        this.currentFileUpload = event.target.files;
      }

      deleteClientApplicationFile(data){
        
        const requestModel={
          "clientApplicationFileId":this.dataValue.clientApplicationFileId
        }
        this.loader.showLoader();
        this.fileService.deleteClientApplicationFile(requestModel)
        .subscribe(
          data=>{
            this.loader.hideLoader();
            this.toast.showSuccess('success','Layout File deleted');
            this.visibleDelete=false;
            this.getFilesData(this.headerForm.controls.client.value,'deleteAppFile',
              this.headerForm.controls.fromDate.value,
              this.headerForm.controls.toDate.value );
          },
          err=>{
            this.loader.hideLoader();
            this.visibleDelete=false;
            this.toast.showError('error',err.error.message);
          }
          
        )

      }

      showDeletePopup(data){
        this.dataValue=data;
        this.visibleDelete=true;
      }

      hidePopup(){
        this.dataValue=null;
        this.visibleDelete=false;
      }

      cloneAll(){
        if(this.cloneApplicationFileId===this.modalForm.controls.file.value.key){
          this.toast.showWarn('error','select another file');
          return;
        }
        this.visibleCloneAll=false;  
        // "clientApplicationFileId":this.cloneApplicationFileId,
        // "cloneClientApplicationFileId":this.modalForm.controls.file.value.key
          const requestModel={
            "clientApplicationFileId":this.modalForm.controls.file.value.key,
            "cloneClientApplicationFileId":this.cloneApplicationFileId
          }
          this.fileService.cloneAllLayout(requestModel)
          .subscribe(
            (data)=>{
              console.log(data);
              this.toast.showSuccess('success','Rules cloned');
            },
            (err)=>{
              this.toast.showError(err.error.message, "error");
            }
    
          )
    
          this.modalForm.reset();
          this.clientId=null;
    

      }

      downloadAnyFile(clientFileId){
        this.loader.showLoader();
        let downloadSuccess:boolean=false;
        var link=document.createElement('a');
        link.href=environment.baseUrl+"/client-application-file/downloadClientApplicationFile/"+clientFileId;      
        link.click();
        this.toast.showSuccess('success','Download Begin Shortly'); 
        this.loader.hideLoader();
      }

      maintainClientState(client){         
          this.dataTransferModel = {
          clientFileId: 0,
          clientId: client.key,
          isRedirected: 1,
          applicationId: 0,
          toDate: '',
          fromDate: '',
          clientName: client.value,
          page: '',
          fileName: '',
          workflowName: '',
          workflowId: 0,
          dataType: '',
          dataTypeId: 0
        }
        this.observalbleDataService.addToInventory(this.dataTransferModel); 
      }

      getPreviousState(){
        this.loadDate();
        const clientIdC=this.cookieService.get('clientId');
        const clientNameC=this.cookieService.get('clientName');
        const fromDatec=this.cookieService.get('fromDate');
        const toDatec=this.cookieService.get('toDate');
        console.log(clientIdC);
        console.log(clientNameC);
        
        if(clientIdC!==undefined && clientIdC!==null){
          this.headerForm.get("client").patchValue({
                   key: clientIdC,
                   value: clientNameC,
                   });
                   this.getFilesData(this.headerForm.get("client").value,'onLoad',
                   this.headerForm.get("fromDate").value,
                   this.headerForm.get("toDate").value );
        }
          
        
        
     
        }

        showDateRangePopup(data) {
          console.log("data  ",data)
          this.headerData="Add Range : "+data.fileName;
          this.cloneApplicationFileId=data.clientApplicationFileId;
          this.visibleDateRange = true;
        }

        saveDateRange(){
          if(this.dateRangeForm.invalid){
            this.toast.showInfo('error','both date are mandatory');
            return;
          }
          const fromDate=this.dateRangeForm.controls.fromDate.value;
          const toDate=this.dateRangeForm.controls.toDate.value;

         const validate= this.validationOnDate(fromDate,toDate);
         if(!validate){
          this.toast.showInfo('error','from date should be less than to date');
          return;
         }

         const requestModel={
           "clientApplicationFileId":this.cloneApplicationFileId,
           "fromDate":fromDate,
           "toDate":toDate
         }
         console.log("requestData ",requestModel);

         this.fileService.updateLayoutFileDateRange(requestModel)
         .subscribe(
           data=>{
             this.toast.showSuccess('success','Date range updated');
             this.visibleDateRange=false;
             this.cloneApplicationFileId=null;
             this.getFilesData(this.headerForm.controls.client.value,'deleteAppFile',
              this.headerForm.controls.fromDate.value,
              this.headerForm.controls.toDate.value );
           },
           err=>{
             this.toast.showError('error','error updating date range');
             this.hidePopup();
           }
         )

        }



        buttonShowHidePermission(){
          this.fileService.buttonShowHidePermission().subscribe(
            (res) =>{ 
                 this.rolePermission = res  
                 this.callNgOnInitMethods();
         }
          )};


          callNgOnInitMethods(){
          
            this.setTitle();
            this.getPreviousState();
           this.getApplicationFileType();
          this.getClientApplicationFileTypeData();
           this.getAllSeparators();

          }
      

        

}
