import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { WorkflowQueueDataModel } from '../../models/workflow.model';
import { WorkflowService } from '../../services/workflow.service';
import { currentMonthRange,getCurrentDate } from 'src/app/utils/dates';
import * as moment from "moment";
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from "src/app/loader/services/loader.service";

@Component({
  selector: 'app-workflow-queue',
  templateUrl: './workflow-queue.component.html',
  styleUrls: ['./workflow-queue.component.scss']
})
export class WorkflowQueueComponent implements OnInit {

  activeRoute$: Subscription;
  workflowQueueDataModel:WorkflowQueueDataModel[];
  id:any;
  queueForm: FormGroup = null;
  headerForm: FormGroup = null;
  visible:boolean=false;
  headerData="Do you want to stop Workflow From Queue";
  rolePermission:any;
  timeInterval=50000;

  constructor(private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private toast:ToastService,private loader: LoaderService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>,private fileService: FileService) { 

      this.queueForm=new FormGroup({
        statusMap:new FormControl(null),
        fromDate: new FormControl(null),
        toDate: new FormControl(null)
      });

      this.headerForm = new FormGroup({
        client: new FormControl(null),
      });
    }

  ngOnInit(): void {
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllWorkflowQueue(data,fromDate,toDate,clientId){
    //console.log("getall queueu,",data);
    this.loader.showLoader();
    this.workflowQueueDataModel=[];
    const fromdate1=this.datePipe.transform(fromDate,'yyyy-MM-dd');
    let toDatePlus1 = moment(toDate).add(1, 'days');
    const toDate1=this.datePipe.transform(toDatePlus1,'yyyy-MM-dd');
    const requestModel={
      "toDate":toDate1,
      "fromDate":fromdate1,
      "queueStatus":data,
      "clientIdVal":clientId
    }
    
    this.workflowService.getAllWorkflowQueue(requestModel)
    .subscribe(
      data=>{
        console.log("all logs ",data)
        this.workflowQueueDataModel=data;
        this.loader.hideLoader();
      },
      error=>{
       // this.toast.showError(error.error.message, "error");
        this.toast.showSuccess("No Workflow is Present", "success");
        this.loader.hideLoader();
      }
    )
  }

  disableJobData(data){
    const requestModel={
      "workflowJobLogId":data.queueId,
      "workflowId":data.workflowId,
      "workflowDetailId":data.workflowDetailId
    }

   this.workflowService.disableWorkflowJobFromQueue(requestModel)
   .subscribe(
     res=>{
       this.toast.showInfo('Success','Job disabled');
       this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,null
        );
     },
     err=>{
      this.toast.showInfo('error',err.error.message);
     }
   )

  }

  removeWorkflow(data){
    const requestModel={
      "workflowLogId":data.queueId,
      "workflowId":data.workflowId
    }

    this.workflowService.removeWorkflowFromQueue(requestModel)
    .subscribe(
      res=>{
        this.toast.showInfo('Success','Workflow Removed from queue');
        this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
          this.queueForm.controls.toDate.value,null
          );
      },
      error=>{
        this.toast.showInfo('error','This workflow cannot be removed because it came into Running Stage');
      }
    )
  }

  statusMap=[
    {
      "key":"QUEUED",
      "value":"RUNNING/QUEUED"
    },
    {
      "key":"COMPLETED",
      "value":"COMPLETED/REMOVED"
    },
  ]
  
  getSelected(e){
  
    if(this.queueForm.controls.statusMap.value.key!==null && this.queueForm.controls.statusMap.value.key==='COMPLETED'){
      this.timeInterval=120000;
    }
    if(this.queueForm.controls.statusMap.value.key!==null && this.queueForm.controls.statusMap.value.key==='QUEUED'){
      this.timeInterval=50000;
    }
    if(this.headerForm.controls.client.value!=null){
    this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
      this.queueForm.controls.toDate.value,this.headerForm.controls.client.value.key
      );
    }else{
      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,null
        ); 
    }
  }

  setValueOnLoad(){
    //console.log('setting default value');
    this.loadDate();
    this.queueForm.get("statusMap").patchValue({
      key:"QUEUED",
      value: "RUNNING/QUEUED",
      });

      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,null
        );
  }

  ngOnDestroy() {
    // this.activeRoute$.unsubscribe();
     if (this.id) {
       clearInterval(this.id);
     }
   }

   changeFromDate(e){

    this.queueForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.queueForm.controls.fromDate.value,this.queueForm.controls.toDate.value);
    if(validate===true && this.headerForm.controls.client.value!=null){
      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,this.headerForm.controls.client.value.key
        );
    }else{
      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,null
        );
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
  }

  changeToDate(e){
    this.queueForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.queueForm.controls.fromDate.value,this.queueForm.controls.toDate.value);
    if(validate===true && this.headerForm.controls.client.value!=null){
      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,this.headerForm.controls.client.value.key
        );
    }else{
      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,null
        );
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
  }

  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }

  loadDate(){
    
    let currentDateRange=currentMonthRange();   
    this.queueForm.controls.fromDate.setValue(moment(new Date()).subtract(7,'d').toDate());
    this.queueForm.controls.toDate.setValue(getCurrentDate());
    
  }

  stopWorkflow(){
    this.workflowService.stopWorkflowFromQueue()
    .subscribe(
      res=>{
        this.toast.showInfo('Success','Workflow stoped from queue');
       if(this.headerForm.controls.client.value!=null){
        this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
          this.queueForm.controls.toDate.value,this.headerForm.controls.client.value.key
          );
        }else{
          this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
            this.queueForm.controls.toDate.value,null
            );
        }
      },
      error=>{
        this.toast.showInfo(error.error.message,'error');
      }
    )
  }


  hidePopup(data){
     this.visible=false;
  }

  actionDo(data){
       this.stopWorkflow();
       this.hidePopup("data");
  }

  showPopUp(data){
    this.visible=true;
    
    
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
    this.setTitle();
    this.setValueOnLoad();
   
    if(this.headerForm.controls.client.value!=null){
    this.id=setInterval(() => {
      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value,null
        );
    }, this.timeInterval);
    }
  }

    onClientChange(event){
    console.log("data client"); 
    this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
      this.queueForm.controls.toDate.value,this.headerForm.controls.client.value.key
      );
    }
}
