import { Component, OnInit, OnDestroy } from "@angular/core";
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FormGroup, FormControl } from "@angular/forms";
import { FileService } from "src/app/files/services/file.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { environment } from "src/environments/environment";
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { currentMonthRange } from "src/app/utils/dates";
import { CookieService } from "ngx-cookie-service";
import { Store } from "@ngrx/store";
import { TransformPermission } from "../../models/transformPermission.model";
import { ToastService } from "src/app/core/services/toast.service";


@Component({
  selector: "app-transform",
  templateUrl: "./transform.component.html",
  styleUrls: ["./transform.component.scss"],
})
export class TransformComponent implements OnInit, OnDestroy {
  activeRoute$: Subscription;
  showBackButton:boolean=false;
  dataTransferModel:DataTransferModel;
  pageName:string;
  headerForm = new FormGroup({
    client: new FormControl(),
    application: new FormControl(),
  });
  envUrl: string = null;
  jobId:any=null;
  applicationList: { key: string | number; value: string }[];
  userId:any=1;
  transformPermission:TransformPermission;
  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private fileService: FileService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private toast:ToastService,
    private store: Store<{ user: any }>
  ) {}

  ngOnInit(): void {
    this.userRoleTransform();
    this.setTitle();
    this.getPreviousState();
   

   // this.formatUrl();
   
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("clientId") && par.has("clientName")) {
          this.headerForm.get("client").setValue({
            key: par.get("clientId"),
            value: par.get("clientName")
          });
          
         
        }
        if ( par.has("applicationId") && par.has("applicationName")) {
     
         
          const applicationData = {
            key: par.get("applicationId"),
            value: par.get("applicationName")
          };
          this.headerForm.get("application").setValue(applicationData);
        
      }
      if ( par.has("jobId") ) {
     
        this.jobId= par.get("jobId")   
        
    }
    if(par.has("pageName")){
      this.pageName=par.get("pageName")
      this.showBackButton=true;
    }
    },
      err => {}
    );
    if(this.showBackButton===false){
      this.getPreviousState();
    }
    this.formatUrlByRoute();
    this.fileService
      .getAllApplicationByUser()
      .subscribe(
        (data) =>
          (this.applicationList = reMapForAutoComplete(
            data,
            "applicationId",
            "name"
          )),
          (err) => {
            this.toast.showError(err.error.message, "error");
          
          }
      );
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  formatUrlByRoute() {
   if(this.transformPermission==undefined || this.transformPermission.viewTransform){
    this.userId=this.cookieService.get("userId");
    let data=null;
    this.store.select("user").subscribe((u) => {
        data=u;
      });
    if(this.userId===undefined || this.userId===null){
      this.userId=1;
    }
    const { client, application } = this.headerForm.value;
    let id = client && client.key ? client.key : null;
    if(client && client.key===0){
          id=0;
    }
    let app = application && application.key ? application.key : null;
    if(application && application.key===0){
      app=0;
      }
   const jobid=this.jobId!=null?'/'+this.jobId:""
    const url = `${environment.baseUrl}/etl${jobid}?client_id=${id}&application=${app}&version=1&user=${this.userId}&token=${data.token}`;
    this.envUrl = url; 
    console.log(this.envUrl+' env url')
  }else{
    this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
  }
}

  formatUrl(event, loadType) {
    if(this.transformPermission==undefined || this.transformPermission.viewTransform){
    const { client, application } = this.headerForm.value;
    this.userId=this.cookieService.get("userId");
    let data=null;
    this.store.select("user").subscribe((u) => {
        data=u;
      });      
    
      if(this.userId===undefined || this.userId===null){
      this.userId=1;
    }
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    let id = client && client.key ? client.key : null;
    if(client && client.key===0){
          id=0;
    }
    let app = application && application.key ? application.key : null;   
    if(application && application.key===0){
      app=0;
      }
    const url = `${environment.baseUrl}/etl?client_id=${id}&application=${app}&version=1&user=${this.userId}&token=${data.token}`;
    this.envUrl = url; 
    console.log(this.envUrl+' env url');
    
    // if(loadType!=='onLoad'){
    //   this.maintainClientState(event);
    // }
    }else{
      this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
    }
  }

 
  ngOnDestroy() {
    try{
      this.activeRoute$.unsubscribe();
    }catch(ex){
      
    }
    
  }
  goToPage(){
    if(this.showBackButton){
      this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
       this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
      this.dataTransferModel={
             clientFileId:0,
             clientId: this.headerForm.controls.client.value.key,
             isRedirected:1,
             applicationId:0,
             toDate:null,
             fromDate:null,
             clientName:this.headerForm.controls.client.value.value,
             page:this.pageName,
             fileName:null,
             workflowName:null,
            workflowId:0,
            dataType:null,
            dataTypeId:0
      }
     // console.log(this.dataTransferModel);
      this.observalbleDataService.addToInventory(this.dataTransferModel);
      this.router.navigate([this.pageName]);
    
    }
  }

  maintainClientState(client){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  getPreviousState() {

    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               const clientModel = {
                        key: this.headerForm.controls.client.value.key,
                        value: this.headerForm.controls.client.value.value
                      }
                      this.formatUrl(clientModel, 'onLoad');
    }

    
  }

  userRoleTransform(){
    this.fileService
      .userRoleTransform()
      .subscribe(
        (data) =>
          (this.transformPermission = data)
      );
  }
}
