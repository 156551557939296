import { Component, OnInit, Input ,EventEmitter, Output} from '@angular/core';
import { ListingListModel } from 'src/app/dashboard/models/listing.model';

@Component({
  selector: 'app-listing-table',
  templateUrl: './listing-table.component.html',
  styleUrls: ['./listing-table.component.scss']
})
export class ListingTableComponent implements OnInit {

  @Input() data:ListingListModel[] = [];
  @Input() hidebutton:boolean;
  @Output() edit=new EventEmitter();
  @Output() delete=new EventEmitter();
  @Input() permission:any;

  visible:boolean=false;
  dataValue:any;

  cols: { field: string; header: string }[];
  constructor() { 
    this.cols = [
      { field: "code", header: "Code" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "Created At" },
      { field: "modifiedAt", header: "Modified At" }
    ];
  }

  ngOnInit(): void {
  }

  editListing(data){
    console.log("hello listing",data);
    this.edit.emit(data);

  }

  deleteListing(data){
   // console.log('table data ',data)
    this.delete.emit(data);
  }

  
  hidePopup(e){
    this.visible=false;
    this.dataValue=null;

  }

  showPopup(data){
    this.dataValue=data;
    this.visible=true;
  }
 
  actionDo(str){
    //console.log("yes delete do "+this.dataValue);
    this.deleteListing(this.dataValue);
    this.dataValue=null;
    this.visible=false;
  } 


}
