import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-list',
  templateUrl: './statistic-list.component.html',
  styleUrls: ['./statistic-list.component.scss']
})
export class StatisticListComponent implements OnInit {
  @Input() tableStatisticsList;
  cols: { field: string; header: string }[];
 
  


  constructor() { 
    this.cols = [
      { field: "columns", header: "Columns" },
      { field: "type", header: "Type" },
      { field: "count", header: "Count" },
      { field: "distinctCountWithNan", header: "Distinct count with Null" },
      { field: "distinctCountWithoutNan", header: "Distinct count without Null" },
      { field: "pDistinct", header: "Distinct (%)" },
      { field: "pMissing", header: "Missing (%)" },
      { field: "nMissing", header: "Missing (N)" },
      { field: "isUnique", header: "Is unique" },
      { field: "nUnique", header: "Unique (N)" },
      { field: "pUnique", header: "Unique (%)" },
      { field: "min", header: "Min" },
      { field: "max", header: "Max" },
      { field: "mean", header: "Mean" },
      { field: "variance", header: "Variance" },
      { field: "std", header: "Std" },
      { field: "kurtosis", header: "kurtosis" },
      { field: "skewness", header: "Skewness" },
      { field: "top", header: "Top" },
      { field: "freq", header: "Freq" },
      { field: "mode", header: "Mode" },
      { field: "range", header: "Range" },
      { field: "fivePercent", header: "5%" },
      { field: "twentyFivePercent", header: "25%" },
      { field: "fiftyPercent", header: "50%" },
      { field: "seventyFivePercent", header: "75%" },
      { field: "nintyFivePercent", header: "95%" },
      { field: "iqr", header: "Iqr" },
      { field: "sum", header: "Sum" },
      { field: "nZeros", header: "N zeros" },
      { field: "mad", header: "Mad" },
      { field: "monotonicIncrease", header: "Monotonic increase" },
      { field: "monotonicDecrease", header: "Monotonic decrease" },
     
      
    ];
    
  }

  ngOnInit(): void {
  }

    
}
