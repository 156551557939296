import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { currentMonthRange, formatDate } from 'src/app/utils/dates';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ArchiveService } from '../../services/archive.service';
import { ClientPortalService } from '../../services/client-portal.service';
import { ClientService } from '../../services/client.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import {isEmpty,trim} from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-archive-file',
  templateUrl: './archive-file.component.html',
  styleUrls: ['./archive-file.component.scss']
})
export class ArchiveFileComponent implements OnInit {
  headerForm:FormGroup;
  activeRoute$: Subscription;
  archieveList:any;
  clientId:number;
  cols: { field: string; header: string }[];
  user:any;
  showClient:boolean=false;
  clientData:any;
  clientFileId:number=null;
  dataTransferModel: DataTransferModel;
  toDt: any;
  fromDt: any;
  archievedDate:any;
  rolePermission:any;

  constructor(private clientPortalService: ClientPortalService,
    private toast:ToastService,
    private store: Store<{ user: any }>,
    private activeRoute: ActivatedRoute, 
    private titleService:TitleService,
    private loader:LoaderService,
    private archieveService:ArchiveService,
    public observalbleDataService: ObservalbleDataService,
    private clientService:ClientService,
    private fileService: FileService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>) {
    
    this.headerForm = new FormGroup({
      client: new FormControl(null),
      toDate: new FormControl(null),
      fromDate: new FormControl(null)
    });

    this.cols = [
      { field: "clientName", header: "Client Name" },
      { field: "asOfDate", header: "Date" },
      { field: "archievedDate", header: "Archived Date" },
      { field: "path", header: "File Path" },
      { field: "fileName", header: "File Name" },
           ];
   }

  ngOnInit(): void {

    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  

  changeFromDate(e){

    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
      this.getArchieveFile(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //this.maintainStateOnDateChange(e,'fromDateChange');
  }
  
  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }
  
  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
    this.headerForm.controls.toDate.setValue(currentDateRange[1]);
     //console.log(this.headerForm.controls.fromDate.value,'  from Date range');
     //console.log(this.headerForm.controls.toDate.value,'  To Date date range');
   
  }

  clientSelected(event){
    console.log("event :",event);
    this.clientId=event.key;
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    this.getArchieveFile(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    //this.maintainClientState(event, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  }

  getArchieveFile(clientId,fromDate,toDate){
    const modelVal={
        "clientId":clientId,
        "fromDate":formatDate(fromDate),
        "toDate":formatDate(toDate)
    }
    this.archieveService.getArchiveFile(modelVal).subscribe((data) => {
      console.log("archieve data "+data)
      this.archieveList=data;
      console.log("list   ",this.archieveList);
      this.loader.hideLoader();
      },
    (err) =>{
      this.toast.showError(err.error.message, "error");
      this.loader.hideLoader();
    } 
    ); 
    }

 
    changeToDate(e){
      this.headerForm.controls.toDate.setValue(e);
      let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
      if(validate===true){
        this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
        this.getArchieveFile(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
      }
      if(validate===false){
        this.toast.showWarn('error','date range incorrect')
        
      }
      //console.log(e,'  todate');
      //this.maintainStateOnDateChange(e,'toDateChange');
    }

    getClient(clientId){
      this.clientService.getClientsById(clientId).subscribe((data) => {
        this.clientData=data;
        },
      (err) =>{
        this.toast.showError(err.error.message, "error");
       } 
      ); 
      }
    
      maintainClientState(client,fromDate, toDate){
        this.dataTransferModel = {
          clientFileId: client.key,
          clientId: client.key,
          isRedirected: 1,
          applicationId: this.dataTransferModel.applicationId,
          toDate: toDate,
          fromDate: fromDate,
          clientName: client.value,
          page: this.dataTransferModel.page,
          fileName:this.dataTransferModel.fileName,
          workflowName:this.dataTransferModel.workflowName,
          workflowId:this.dataTransferModel.workflowId,
          dataType:this.dataTransferModel.dataType,
          dataTypeId:this.dataTransferModel.dataTypeId
        }
        this.observalbleDataService.addToInventory(this.dataTransferModel); 
      }

/*      maintainStateOnDateChange(newDate,typeOfRequest){
        if(typeOfRequest==='fromDateChange'){
          this.dataTransferModel.fromDate = newDate;
          this.observalbleDataService.addToInventory(this.dataTransferModel);
          console.log(this.dataTransferModel);
        }
        if(typeOfRequest==='toDateChange'){
          this.dataTransferModel.toDate = newDate;
          this.observalbleDataService.addToInventory(this.dataTransferModel);
          console.log(this.dataTransferModel);
        }
      }
 */   
      getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    //console.log(clientIdC);
    //console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
      
                                   
    }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
       this.headerForm.controls.fromDate.setValue(frm)
       this.headerForm.controls.toDate.setValue(toDt)
    }else{
      this.loadDate();
    }

    if(!isEmpty(this.headerForm.get("client").value.key)){
      this.clientId=this.headerForm.controls.client.value.key;
      this.getArchieveFile(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
      }
      
   
      downloadAnyFile(clientFileId){
        
        console.log("clientfileid    ",clientFileId);
        this.loader.showLoader();
        let downloadSuccess:boolean=false;
        var link=document.createElement('a');
        link.href=environment.baseUrl+"/apis/client-portal/getDownloadArchieveFile/"+clientFileId;      
        link.click();
        this.toast.showSuccess('success','Download Begin Shortly'); 
        this.loader.hideLoader();
      }






      buttonShowHidePermission(){
        this.fileService.buttonShowHidePermission().subscribe(
          (res) =>{ 
               this.rolePermission = res  
               this.callNgOnInitMethods();
       }
        )};




        callNgOnInitMethods(){

          this.getPreviousState();
          this.setTitle();
    //this.loadDate();
   // this.storePermission.select(x => this.rolePermission = x).subscribe();
    this.store.select('user').pipe(take(1))
      .subscribe(u => this.user = u)
     // this.getAllclientPortalData(this.user.clientId);
     if(this.user.clientId!==0 && !this.user.clientId!==null){
      this.showClient=true;
      this.clientId=this.user.clientId;
      this.getClient(this.clientId);
      this.getArchieveFile(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value); 
    }else{
      this.showClient=false;
      this.clientId=this.headerForm.controls.client.value.key;
      this.getClient(this.clientId);
      this.getArchieveFile(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);  
    
    }



        }  
}
