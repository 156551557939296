import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationRulesFormComponent } from './validation-rules-form/validation-rules-form.component';
import { ValidationRulesListComponent } from './validation-rules-list/validation-rules-list.component';
import { SharedModule } from '../shared/shared.module';
import { FormsyModule } from '../formsy/formsy.module';
import { ValidationRuleService } from '../dashboard/services/validation-rule.service';
import { ValidationLogComponent } from './validation-log/validation-log.component';



@NgModule({
  declarations: [
    
    ValidationRulesFormComponent,
    ValidationRulesListComponent,
    ValidationLogComponent,
    
  ],
  imports: [SharedModule],
  exports: [
    ValidationRulesFormComponent,
    ValidationRulesListComponent,
    ValidationLogComponent,
   
  ],
  providers: [ValidationRuleService]
})
export class ValidationRulesModule { }
