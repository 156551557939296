import { uniq } from "lodash";

export const LOOKUP_COLUMN_FIELDS = {

        accrualbasis_accrualbasiscode_list: {
          controls: [
            {
              label: "Accrual Basis*",
              name: "field1",
              type: "text",
              placeholder:"Accrual Basis"
            },
            
          ]
        },

        call_report_code_callreportcode_list: {
        controls: [
          {
            label: "Call Report Code*",
            name: "field1",
            type: "text",
            placeholder:"Call Report Code"
          },
          {
            label: "Call Report Name Code*",
            name: "field2",
            type: "text",
            placeholder:"Call Report Name Code"
          }
        ]
      },
      product_type_callreportcode_list: {
        controls: [
          {
            label: "Product Type*",
            name: "field1",
            type: "text",
            placeholder:"Product Type"
          },
          {
            label: "Risk Weight*",
            name: "field2",
            type: "text",
            placeholder:"Risk Weight"
          },
          {
            label: "Max-am*",
            name: "field3",
            type: "text",
            placeholder:"Max am"
          },
          
        ]
      },

      rate_change_frequency_ratechangefrequencycode_list: {
        controls: [
          {
            label: "Rate Change Frequency*",
            name: "field1",
            type: "text",
            placeholder:"Rate Change Frequency"
          },
          
        ]
      },
      
      reprice_index_code_repriceindex_list: {
        controls: [
          {
            label: "Reprice Index*",
            name: "field1",
            type: "text",
            placeholder:"Reprice Index"
          },
          
        ]
      },
      state_statecode_list: {
        controls: [
          {
            label: "State*",
            name: "field1",
            type: "text",
            placeholder:"State"
          },
          
        ]
      },
}



export function extraColumnFields() {
    const keys = Object.keys(LOOKUP_COLUMN_FIELDS);
    const fields = keys.map(k => {
      const { controls } = LOOKUP_COLUMN_FIELDS[k];
      return { key: k, fields: controls.map(c => c.name) };
    });
    return fields;
  }
  export function uniqueColumnFields() {
    const fields = extraColumnFields();
    let data = [];
    for (let f of fields) {
      data = [...data, ...f["fields"]];
    }
    const uniqFields = uniq(data).map(d => ({ [d]: null }));
    return uniqFields.reduce(function(result, item) {
      var key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});
  }