import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-workflow-logs-table',
  templateUrl: './workflow-logs-table.component.html',
  styleUrls: ['./workflow-logs-table.component.scss']
})
export class WorkflowLogsTableComponent implements OnInit {
// @Input() data1;
@Input() hidebutton:boolean;
@Input() data;


  cols: { field: string; header: string }[];

  constructor() { 

    this.cols = [
      { field: "workflowName", header: "Workflow" },
      { field: "status", header: "Status" },
      { field: "paramValue", header: "Param" },
      { field: "lastStatus", header: "Last Status" },
      { field: "lastrunDate", header: "Last Run Status" },
      { field: "dateOfEventRun", header: "Date Of Event Run" },
      { field: "message", header: "Message" },
      { field: "userName", header: "Created By" },
      
    ];
  }

  ngOnInit(): void {
  }

}
// <app-workflow-logs-table></app-workflow-logs-table>