import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { TransformModel } from '../../models/tranform.model';
import { CustomJobs, IngestJob, WorkflowJobDetails, WorkflowModel } from '../../models/workflow.model';
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { WorkflowService } from '../../services/workflow.service';
import { SetupJobSelectorComponent } from 'src/app/workflows/components/setup-job-selector/setup-job-selector.component';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel1 } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import {trim} from 'lodash';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-setup-job',
  templateUrl: './setup-job.component.html',
  styleUrls: ['./setup-job.component.scss']
})
export class SetupJobComponent implements OnInit {
  @ViewChild(SetupJobSelectorComponent, { read: false, static: false })
  setupJobSelectorComponent: SetupJobSelectorComponent;
  activeRoute$: Subscription;
  setupJobForm:FormGroup=null;
  workflowData:WorkflowModel[]=[];
  workflowDropDown:any[]=[];
  
  workflowJobDetails:WorkflowJobDetails[]=[];
  ingestJobData:IngestJob[]=[];
  ingestJobDropDown:any[]=[];
  transformList: TransformModel[] = [];
  ingestJobDetails:WorkflowJobDetails[]=[];
  transformJobDetails:WorkflowJobDetails[]=[];
  customJobDetails:WorkflowJobDetails[]=[];
  outputJobDetails:WorkflowJobDetails[]=[];
  workflowJobDetailsEdit:WorkflowJobDetails=null;
  transformMapData = new Map();
  visible:boolean=false;
  visible1:boolean=false;
  addJobHeader="Add Job";
  modalForm: FormGroup = null;
  clientMasterList:any[]=[];
  isCustomJob:boolean=false;
  customJobsList:CustomJobs[]=[];
 // dataTransferModel:DataTransferModel;
 //popupForm:FormGroup=null;
 paramHeader="Add Job Param";
 dataTransferModel:DataTransferModel1;
 jobsequenceData:any[]=[];
 rolePermission:any;

  constructor(private titleService: TitleService,
    private datePipe:DatePipe,
    private activeRoute: ActivatedRoute,
    private workflowService:WorkflowService,
    private toast:ToastService,
    private loader:LoaderService,
    private fileService: FileService,
    private clientFileMasterService:ClientFileMasterService,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private router: Router,private storePermission: Store<{ role: any }>) {

    
    this.setupJobForm=new FormGroup({
       client:new FormControl(null),
       workflow:new FormControl(null)
      })
  this.modalForm=new FormGroup({
        jobType:new FormControl(null),
        jobName: new FormControl(null),
        jobDesc: new FormControl(null),
        clientFile: new FormControl(null),
        apiMethod: new FormControl(null),
          
         })
    


   }

   customData=[
    { key: '1', value: "Yes" },
    { key: '0', value: "No" }
 
  ]

  apiMethodList=[
    { key: "POST", value: "POST" },
    { key: "GET", value: "GET" }
 
  ]


  ngOnInit(): void {
   this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  onClientChange(){
    this.cookieService.set('clientId',this.setupJobForm.controls.client.value.key);
    this.cookieService.set('clientName',this.setupJobForm.controls.client.value.value);
    this.setupJobForm.controls.workflow.setValue(null);
    this.resetForm();
    this.getAllWorkflow();
    this.getAllTransformJob(this.setupJobForm.controls.client.value.key,false);
   // this.maintainClientState();
  }

  getAllWorkflow(){
    console.log("getting called");
    this.workflowDropDown=[];
    this.workflowData=[];
    this.ingestJobDetails=[];
    this.transformJobDetails=[];
    this.workflowJobDetails=[];
    this.customJobDetails=[];
    try{
      this.setupJobForm.controls.workflow.reset();
    }catch{
      console.log('exception');
    }
   
    const requestModel={
      "clientId":this.setupJobForm.controls.client.value.key
    }
    
    this.workflowService.getAllWorkflow(requestModel)
    .subscribe(
      (data)=>{
        this.workflowData=data;
        
        this.workflowData.forEach(item=>{
          this.workflowDropDown.push({key:item.workflowId,value:item.workflowName})
        })

        if(this.dataTransferModel.isRedirected===1){
          this.getAllTransformJob(this.setupJobForm.controls.client.value.key,onchange);
          this.getAllWorkflowJob();
        }
      
        
      },
      (err)=>{
       
       // this.toast.showError('error',err.error.message);
       this.toast.showError(err.error.message, "error");
      }
    )

  }

  getAllWorkflowData(){
 
    return this.workflowDropDown;
   

  }

  getAllWorkflowJob(){
   // this.maintainWorkflowState();
    this.ingestJobDetails=[];
    this.transformJobDetails=[];
    this.workflowJobDetails=[];
    this.customJobDetails=[];
    
    const requestModel={
      "workflowId":this.setupJobForm.controls.workflow.value.key
    }
   // console.log("hello workflow job")
    this.workflowService.getAllWorkflowJob(requestModel)
    .subscribe(
      (data)=>{
        this.workflowJobDetails=data;
      //  console.log("hello workflow job")
        if(this.workflowJobDetails.length>0){
          if(this.setupJobForm.controls.client.value.key!==undefined){
             const onchange=false;
            this.getAllTransformJob(this.setupJobForm.controls.client.value.key,onchange);
            console.log("transform map data :"+this.transformMapData)
          }
        }
       // console.log("hello workflow job12")
        this.workflowJobDetails.forEach(item=>{
       //   console.log("hello workflow job1232")
          if(item.jobType==='Ingest'){
            this.ingestJobDetails.push(item)
          }
          if(item.jobType==='Custom'){
           // console.log("hello workflow custom")
            this.customJobDetails.push(item)
          }
          if(item.jobType==='Transform'){
          
            this.ingestJobDetails.push(item);
            
          }
         
        })
     
       // console.log("custom job details ",this.customJobDetails)
       
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
    )    
  }

  getAllTransformJob(id,onchange){
    this.transformList =[];
    
    this.ingestJobDropDown=[];
    this.fileService.getTransformListByClient(id)
    .subscribe(
      data=>{
        if (data && data["status"] === 200) {
          this.transformList = data["data"];
         
          // if(this.transformList.length>1){
           
          
          //   this.transformList.sort((a, b) => trim(a.job_name.toLowerCase()).localeCompare(trim(b.job_name.toLowerCase())))
          // }
         
          if(onchange===true){
          this.transformList.forEach(item=>{
           
            this.ingestJobDropDown.push({key:item.job_id,value:item.job_name});
           // this.transformMapData.set(item.job_id,item.job_name);
          })
         

        }else{
          this.transformList.forEach(item=>{
           this.transformMapData.set(item.job_id,item.job_name);
            })
            console.log("elese portion");
        }
        }
        this.loader.hideLoader();

      }
    )
  }

  editWorkflowDetails(data){

    this.getWorkflowDetailsById(data);
  }

  deleteWorkflowJob(data){
    this.deleteJobFromWorkflow(data);
 }

 deleteJobFromWorkflow(data){

  const requestModel={
    "workflowDetailId":data.workflowDetailId
  }

  this.workflowService.deleteJob(requestModel)
  .subscribe(
    data=>{
      this.toast.showSuccess('success','Job Deleted SuccessFully');
      this.getAllIngestJobByClientId();
      this.getAllWorkflowJob();
      this.resetForm();
    },
    error=>{
      this.toast.showError('error','error');
    }
    
  )
}

 
 saveIngestParam(data){
    
  const workflowDetailParam={
    "workflowDetailParamId":data.workflowDetailParamId!==null?data.workflowDetailParamId:0,
    "workflowDetailId":data.workflowDetailId,
    "asofdate":data.asOdDate,
    "params":data.params
  }

  this.workflowService.saveWorkflowParam(workflowDetailParam)
  .subscribe(
    data=>{
      this.toast.showSuccess('success','job param saved');
     // this.getAllIngestJobByClientId();
      this.getAllWorkflowJob();

    },
    error=>{
      this.toast.showError('error','error');
    }
  )
}

getWorkflowParam(data){
  // console.log("getworkflowparam ",data);
  // const requestModel={
  //   "workflowDetailId":data.workflowDetailId
  // }

  // this.workflowService.getWorkflowDetailParamByDetailId(requestModel)
  // .subscribe(
  //   data=>{
  //     console.log(data);
  //   }
  // )
}

getWorkflowDetailsById(data){
  const reuestModel={
    "workflowDetailId":data.workflowDetailId
  }

  this.workflowService.getWorkflowDetailsById(reuestModel)
  .subscribe(
    data=>{
        this.workflowJobDetailsEdit=data;
        const jobTypeData={
          "key":this.workflowJobDetailsEdit.jobType
        }
        this.onJobTypeChange(jobTypeData);
    },
    error=>{
      this.toast.showError('error',error.error.message);
    }
  )
}

onJobTypeChange(data){
  console.log('onJobTypeChange ',data)
  if(data.key==='Ingest'){
    this.getAllIngestJobByClientId();
  }
  if(data.key==='Transform'){
    const onchange=true;
    this.getAllTransformJob(this.setupJobForm.controls.client.value.key,onchange);
  }
  if(data.key==='Custom'){
    const onchange=true;
    this.getAllCustomJobByClientId();
  }

}

getAllIngestJobByClientId(){
  this.ingestJobDropDown=[];
  this.ingestJobData=[];
  const requestModel={
    "clientId":this.setupJobForm.controls.client.value.key
  }

  this.workflowService.getAllIngestJobByClientId(requestModel)
  .subscribe(
    (data)=>{
        this.ingestJobData=data;
        this.ingestJobData.forEach(item=>{
          this.ingestJobDropDown.push({key:item.ingestjobId,value:item.jobName});
        })

    },
    (err)=>{     
        this.toast.showError(err.error.message, "error");
    }
  )
}

getAllCustomJobByClientId(){
  this.ingestJobDropDown=[];
  this.customJobsList=[];
  const requestModel={
    "clientId":this.setupJobForm.controls.client.value.key
  }

  this.workflowService.getCustomJobsByClient(requestModel)
  .subscribe(
    (data)=>{
        this.customJobsList=data;
        this.customJobsList.forEach(item=>{
          this.ingestJobDropDown.push({key:item.customJobId,value:item.jobName});
        })

    },
    (err)=>{
      this.toast.showError(err.error.message, "error");
    }
  )
}

saveJobToWorkflow(data){

  if(this.setupJobForm.controls.client.value===null || this.setupJobForm.controls.client.value.key===0){
    return this.toast.showError("Client Not Selected","error");
  }

  if(this.setupJobForm.controls.workflow.value===null || this.setupJobForm.controls.workflow.value.key===0){
   return this.toast.showError("Workflow Not Selected","error");
  }

  const workflowDetail={
    "workflowdetailId":this.workflowJobDetailsEdit!==null?this.workflowJobDetailsEdit.workflowDetailId:0,
    "workflowId":this.setupJobForm.controls.workflow.value.key,
    "jobType":data.jobType,
    "jobId":data.jobId,
    "jobDesc":data.description,
    "trigger1":data.trigger1,
    "dependentUpon":0,
    "nextjobId":0,
    "enabled":data.enabled===true?1:0,
    "target":data.target,
    "custom":0,
    "frequency":"Daily",
    "sequence":this.workflowJobDetailsEdit!==null?this.workflowJobDetailsEdit.sequence:0,
    "continueOnError":data.errorCase===true?1:0,
  }
  this.saveWorkflowDetails(workflowDetail);
  
}


saveWorkflowDetails(data){

  this.workflowService.saveWorkflowDetails(data)
  .subscribe(
    data=>{
      this.toast.showSuccess('success','data saved');
      this.resetForm();
      this.getAllWorkflowJob();
    },
    error=>{
      this.toast.showError('error',error.error.message);
    }
  )

}

doAction(data){
  if(data===false){
    this.visible1=false;
    this.visible=false;
  }
  if(data===true){
    this.resetForm();
    this.getAllClientMasterData(this.setupJobForm.controls.client.value.key);
    this.visible1=true;
    this.visible=true;
    
  }
}

onCustomChange(e){
   
  if(this.modalForm.controls.jobType.value.key==='1'){
    console.log("true value :"+e.target.value)
    this.isCustomJob=true;
  }
  if(this.modalForm.controls.jobType.value.key==='0'){
    console.log("false value :"+e.target.value)
    this.isCustomJob=false;
  }
  }

  saveJob(){
   
    if(this.setupJobForm.controls.client.value===null || this.setupJobForm.controls.client.value===undefined || this.setupJobForm.controls.client.value.key===0){
      this.toast.showError('error','client is mandatory')
      return;
    }

  
    if(this.modalForm.controls.jobName.value===null || this.modalForm.controls.jobName.value===undefined){
      this.toast.showError('error','job name is required')
      return;
    }
    

    if(this.modalForm.controls.jobType.value!==null && this.modalForm.controls.jobType.value.key==='0')
    {

      if(this.modalForm.controls.clientFile.value===null || this.modalForm.controls.clientFile.value.key===null){
        this.toast.showError('error','client file master is required')
        return;
      }
    const ingestJob={
       "ingestjobId":0,
       "jobName":this.modalForm.controls.jobName.value,
       "jobDesc":this.modalForm.controls.jobDesc.value,
       "clientId":this.setupJobForm.controls.client.value.key,
       "clientfileId":this.modalForm.controls.clientFile.value.key,
    }

    this.workflowService.saveIngestJob(ingestJob)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','Job Saved');
        this.getAllIngestJobByClientId();
        this.visible1=false;
        this.visible=false;
        this.modalForm.reset();
      },
      error=>{
        this.toast.showError('error',error.error.message);
      }
    )
    }

    if(this.modalForm.controls.jobName.value===null){
      return this.toast.showError("name is mandatory","error");
    }

   

    if(this.modalForm.controls.jobType.value!==null && this.modalForm.controls.jobType.value.key==='1'){

      if(this.modalForm.controls.apiMethod.value===null){
        return this.toast.showError("api method is mandatory","error");
        }
    
      const customJobs={
        "customJobId":0,
        "jobName":this.modalForm.controls.jobName.value,
        "description":this.modalForm.controls.jobDesc.value,
        "clientId":this.setupJobForm.controls.client.value.key,
        "targetApi":"",
        "apiMethod":this.modalForm.controls.apiMethod.value.value
     }

     this.workflowService.saveCustomJob(customJobs)
     .subscribe(
       data=>{
        this.toast.showSuccess('success','Job Saved');
        this.getAllIngestJobByClientId();
        this.visible1=false;
        this.visible=false;
        this.modalForm.reset();
       },
       error=>{
        this.toast.showError('error','error saving job');
      }
     )
    }

  }

  getAllClientMasterData(clientId){
    console.log("clientFile master");
    this.clientMasterList=[];
    this.clientFileMasterService.getClientFileMasterListByClient(clientId).subscribe(
      (data) => {
        const clientMasterData=data;
        for(var a=0;a<clientMasterData.length;a++){
         // console.log("type "+clientApplicationFileType[a].type);
          this.clientMasterList.push({ key: clientMasterData[a].clientfilemasterId, value: clientMasterData[a].name });
        }
      },
      (err) => {
      //  this.toast.showSuccess("Status Not Changed", "error");
      this.toast.showError(err.error.message, "error");
      }
    
    );
  
  }

  updateSequence(data){
    this.jobsequenceData=[];
   const wokrData:WorkflowJobDetails[]=data;
    //console.log("in main component ",data)
    
    let i=1;
    wokrData.forEach(item => {
      //console.log("hello ",item.workflowDetailId)
      const workflowDeatil={
        "sequence":i,
        "workflowdetailId":item.workflowDetailId
      }
      this.jobsequenceData.push(workflowDeatil);
      i++;
      
    });

    this.updateWorkflowJobSequence(this.jobsequenceData);
    

  }

  updateWorkflowJobSequence(data){
    this.loader.showLoader();
    this.workflowService.updateWorkflowJobSequence(data)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','job sequence updated');
        this.getAllWorkflowJob();
        this.resetForm();
        this.loader.hideLoader();
      },
      error=>{
        this.loader.hideLoader();
        this.toast.showError(error.error.message, "error");
      }
    )

  }

  resetForm(){
    this.workflowJobDetailsEdit=null;
    this.setupJobSelectorComponent.setupJobDataSelector.reset();
  }

  getPreviousState() {
    console.log("prevous state")
    this.observalbleDataService.inventoryChanged$$.subscribe(
      data => {
        this.dataTransferModel = data;
        if (this.dataTransferModel.clientId !== undefined  ) {
          this.setupJobForm.get("client").patchValue({
            key: this.dataTransferModel.clientId,
            value: this.dataTransferModel.clientName,
          });
          this.getAllWorkflow();
          
          if(this.dataTransferModel.workflowId !== 0 && this.dataTransferModel.workflowName !==null ){
            this.setupJobForm.get("workflow").patchValue({
              key: this.dataTransferModel.workflowId,
              value: this.dataTransferModel.workflowName,
            });
           // this.getAllWorkflowJob();
          }  
        }
      });
      console.log("prevous state2 ",this.setupJobForm.controls.client.value);
      if( this.setupJobForm.controls.client.value===null){
          const clientIdC=this.cookieService.get('clientId');
          const clientNameC=this.cookieService.get('clientName');
          console.log(clientIdC);
          console.log(clientNameC);
         
          
          if(clientIdC!==undefined && clientIdC!==null){
            this.setupJobForm.get("client").patchValue({
                     key: clientIdC,
                     value: clientNameC,
                     });
                     this.getAllWorkflow();     
                           
          }
        }
      
    }

    maintainClientState(){
      this.dataTransferModel = {
        clientFileId: 0,
        clientId: this.setupJobForm.controls.client.value.key,
        isRedirected: 1,
        applicationId: 0,
        toDate: '',
        fromDate: '',
        clientName: this.setupJobForm.controls.client.value.value,
        page: '',
        fileName: '',
        workflowName: this.setupJobForm.controls.workflow.value.value,
        workflowId: this.setupJobForm.controls.workflow.value.key
      }
      this.observalbleDataService.addToInventoryData(this.dataTransferModel); 
    }
    
    goToManage(){
      this.router.navigate(['/workflow']);
    }

  // ngOnDestroy() {
  //   this.activeRoute$.unsubscribe();
  // }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getPreviousState(); 
      this.setTitle();
    }
}
