import { Component, 
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges } from '@angular/core';
  import { ToastService } from 'src/app/core/services/toast.service';
  import { FormGroup, FormControl, Validators } from '@angular/forms';
  import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-dual-list',
  templateUrl: './dual-list.component.html',
  styleUrls: ['./dual-list.component.scss']
})
export class DualListComponent implements OnInit, OnChanges {
  @Input() source: { key: any; value: any }[] = [];
  @Input() sourceHeaderText = "Available";
  @Input() boxHeight = 300;
  @Input() filterBy: string = "value";
  @Input() target: { key: any; value: any }[] = [];
  @Input() targetHeaderText = "Selected";
  @Input() btnText = "Save";
  @Input() clientLists;
  @Input() applicationLists;
  @Input() clientApplicationDataTypeList;


  @Input() allActionData;
  targetData: { key: any; value: any }[] = [];
  // @Input() editMappedRole;
  @Output() selectedValues = new EventEmitter();
  enableEdit: boolean = false;

 // allActionData:any;
  showDesc:any;
  actionId:number;
  actionGroup:string;

 
  

  constructor(private http: HttpClient,private toast:ToastService) {

   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.target && changes.target.currentValue) {
      this.targetData = changes.target.currentValue;
    }



  }

  emitValues() {
    this.selectedValues.emit(this.targetData);
  }


  





  // resetForm() {
  //   console.log("reset is working ")
  //   this.userForm.reset();
  //    this.selectedClients = [];
  //    this.selectedApplication=[];
  //    this.selectedDataType=[];
  // }




  displayTagInfo(event, item){

    // this.http
    // .get(`${environment.baseUrl}/users/getAllActionList`)
    // .subscribe((res) => {
    //   this.allActionData = res;
    //   console.log("mastersourcejson list ",this.allActionData);
    // },(err) => {
    //   this.toast.showError(err.error.message, "error");
    // }
    // );
    if(this.allActionData[0].actionGroup==item.value){
    this.showDesc=this.allActionData[0].actionDescription;
    }
    else{
      this.showDesc="";
    }
    for (let i = 1; i < this.allActionData.length; i++) {
        if(this.allActionData[i].actionGroup==item.value){
          this.showDesc= this.showDesc+"  "+"  *"+this.allActionData[i].actionDescription
        }
    }
    
   console.log("item   ",item)
   console.log("desc  ",this.showDesc)
   this.actionGroup=item.value;
  }


  mouseOutInfo(){
this.showDesc=null;

  }

}
