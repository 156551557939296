import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Console } from 'console';
import { ToastService } from 'src/app/core/services/toast.service';
import { SchedulerWorkFlow } from 'src/app/dashboard/pages/scheduler/scheduler.model';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { DatePipe } from "@angular/common";


@Component({
	selector: 'app-scheduler-form',
	templateUrl: './scheduler-form.component.html',
	styleUrls: ['./scheduler-form.component.scss']
})
export class SchedulerFormComponent implements OnInit {

	@Input() workflowName: string;
	@Output() saveScheduler = new EventEmitter();
	@Input() schedulerWorkFlow: SchedulerWorkFlow;
	@Input() viewMode: boolean;
	@Input() editMode: boolean;
//   @Input() workflowId:number;
  @Input() permission:any;

	schedulerForm: FormGroup = null;
	schedulerType = [{ 'typeId': 1, 'typeName': 'Not Recurring' }, { 'typeId': 2, 'typeName': 'Recurring' }];
	schedulerTypeList: any = [];
	occurList = [{ 'key': 1, 'value': 'Daily' }, { 'key': 2, 'value': 'Weekly' }, { 'key': 3, 'value': 'Monthly' }];
	weeksCheckboxeList = [{ id: '1', label: 'Monday' }, { id: '2', label: 'Tuesday' }, { id: '3', label: 'Wednesday' }, { id: '4', label: 'Thursday' },
	{ id: '1', label: 'Friday' }, { id: '1', label: 'Saturday' }, { id: '1', label: 'Sunday' }]
	countList = [{ id: '1', label: 'first' }, { id: '1', label: 'second' }, { id: '1', label: 'third' }, { id: '1', label: 'fourth' }]

	timeList = [{ 'timeId': 'hour', 'timeMeasure': 'hour' }, { 'timeId': 'minute', 'timeMeasure': 'minute' }]
	Id: any;
	visible: boolean = false;
	StartDate: any = null;
	onceOccurDate: any = null;
	EndDate: any = null;
	enableDate: boolean;
	mdbInput: any;
	timer: any;
	jobName: any;
	typeName: string;
	typeId: number;
	showSelectedOccurs: string = "Daily";
	enableTimer: number = 1;
	monthlyRadioValues: boolean = false;
	days: number;
	weeks: number;
	startedDate: any;
	endedDate: any;
	dailyFreqOccurse: boolean = true;
	//workFlowName: string;
	form: any = null;
	recurreName: string;
	occureName: string;
	frequency: string;

	occureTypeName: string = "Not Recurring";
	freequency: string;
	dailyFreequency: any;
	hasSelectedDailyFrequencyOccurOnlyOnce: boolean;
	hasEndDateEnabled = false;
	OccursOnceValue: string = 'Yes';
	OccursEveryValue: string = 'No';
	radioOccursOnceSelected: string = 'Yes';
	radioEndDateSelected: string = 'No';
	endDateSelected: string = 'Yes';
	noEndDateSelected: string = 'No';

	radioOccursDaySelected: string = 'Yes';
	OccursDayValue: string = 'Yes';
	OccursTheValue: string = 'No';
  endDateEnable:boolean=false;
  displayStartDate:string;
  displayEndDate:string;

  currentDate:any;
  currentTime:any;
  inputTime:any;

  displayTimeCount:string='hour';
	myForm: FormGroup;
	constructor(private fb: FormBuilder, private toast: ToastService, private cdr: ChangeDetectorRef,private router: Router,
		private datePipe: DatePipe) {
		this.InitializeForm();
	}

	InitializeForm() {
		this.schedulerForm = new FormGroup({
			schedulerName: new FormControl(null),
			scheduleType: new FormControl(null),
			occurs: new FormControl(null),
			recursEveryDays: new FormControl(null),
			recursEveryWeeks: new FormControl(null),
			monthlyMonthNumber: new FormControl(null),

			onWeeks: new FormControl(null),
			WeekName: new FormControl(null),
			monthlyDayPosition: new FormControl(null),
			monthlyWeekDay: new FormControl(null),

			dailyFrequencyOccurOnlyOnce: new FormControl(null),
			//occureOnceAt:new FormControl(null),
			dailyFrequencyCount: new FormControl(null),
			dailyFrequency: new FormControl(null),
			dailyFrequencyStartTime: new FormControl(null),
			dailyFrequencyEndTime: new FormControl(null),
			oneTimeDate: new FormControl(null, Validators.required),
			startDate: new FormControl(null, Validators.required),
			endDate: new FormControl(null, Validators.required),
			isActive: new FormControl(null),
			onceTimeOccure: new FormControl(null),
			onceDateOccure: new FormControl(null),
			monthlyDayCount: new FormControl(null),
			weekDays: new FormControl(null),
      endDateEnable:new FormControl(false),

		});

		// this.form = new FormGroup({
		// 	Date: new FormControl('Yes', Validators.required),
		// });
	}

	ngOnInit() {
		console.log(" this.SchedulerWorkFlow-1", this.schedulerWorkFlow);
		//console.log(" this.workflowName ", this.workflowName);
		this.getSchedulerTypeList();

		this.myForm = this.fb.group({
			weekname: this.fb.array([])
		});


	}

	formatSchedulerData(data: SchedulerWorkFlow) {
		if (data) {
             
			this.displayStartDate= data.startDate;
			var splittedStart=this.displayStartDate.split("T");

			this.displayEndDate= data.endDate;
			console.log("data.endDate ",data.endDate);
			var splittedEnd=this.displayEndDate.split("T");

		   data.weekDaysList = data?.weekDaysList ? data.weekDaysList : data?.weekDays?.split(',');

			const formdata = {
				schedulerName: data.schedulerName,
				scheduleType: data.scheduleType,
				occurs: data.freequency,
				recursEveryDays: data.dailyDayCount,
				recursEveryWeeks: data.weekCount,
				monthlyMonthNumber: data.monthlyMonthNumber,
				onWeeks: data.weekDaysList,
				//WeekName: new FormControl(null),
				monthlyDayPosition: data.monthlyDayPosition,
				monthlyWeekDay: data.monthlyWeekDay,

				dailyFrequencyOccurOnlyOnce: data.dailyFreequencyOccurOnlyOnce,
				// occureOnceAt:new FormControl(null),
				dailyFrequencyCount: data.dailyFreequencyCount,
				dailyFrequency: data.dailyFreequency,
				dailyFrequencyStartTime: data.dailyFreequencyStartTime,
				dailyFrequencyEndTime: data.dailyFreequencyEndTime,

				// startDate: splittedStart[0],
				// endDate: splittedEnd[0],
				// oneTimeDate:  splittedStart[0],
				startDate: data.startDate,
				endDate: data.endDate,
				oneTimeDate: data.startDate,
				isActive: data.isActive,
				onceTimeOccure: data.dailyFreequencyOccurOnlyOnce,
				onceDateOccure: data.startDate,
				monthlyDayCount: data.monthlyDayCount,
				weekDays: data.weekDays,
        endDateEnable:data.endDateEnable
			}
			// data.weekDaysList = data?.weekDaysList ? data.weekDaysList : data?.weekDays?.split(',');
      this.endDateEnable=data.endDateEnable;
			//Set value to form
			this.showSelectedOccurs = data.freequency;
			this.freequency = data.freequency;
			this.dailyFreequency = data.dailyFreequency;
            if(	this.dailyFreequency =='hour'){
             this.displayTimeCount="hour"
			}
			if(	this.dailyFreequency =='minute'){
             this.displayTimeCount="minute"
			}

			this.occureTypeName = data.scheduleType;

			//console.log("data.weekDaysList  ",data.weekDaysList)
			this.schedulerForm.patchValue(formdata);

        
		
			this.startedDate = new Date(splittedStart[0]);

			this.endedDate = new Date(splittedEnd[0]);
			

			if (this.endedDate && this.endedDate != null) {
				this.enableDate = true;
				this.isEndDateAvailable();
			}
			else {
				this.enableDate = false;
			}

			this.showHideOccursOnceRadioButton(data);
			this.showHideOccursEndDateRadioButton(data);
			this.showHideMonthlyRadioButton(data);

			console.log('this.hasSelectedDailyFrequencyOccurOnlyOnce-', this.hasSelectedDailyFrequencyOccurOnlyOnce);
			//this.OccureTypeChange(data.scheduleType);
			this.schedulerForm.patchValue({
				dailyFrequencyStartTime: data.dailyFreequencyStartTime,
				dailyFrequencyEndTime: data.dailyFreequencyEndTime,

			});
			this.schedulerForm.patchValue({
				oneTimeDate: new Date(data.startDate),
			});
			this.schedulerForm.patchValue({
				startDate: new Date(data.startDate),
				endDate: new Date(data.endDate),
			});


      if(data.endDate=="3000-12-31T00:00:00.000+0000"){

        this.radioEndDateSelected='No'
       // this.endDateSelected = 'Yes';
        
        this.enableDate=false
        this.schedulerForm.patchValue({
          endDate: null,
        });
        
      }
		}
		if (this.viewMode) {
			this.disableControls();
		}
		this.cdr.detectChanges();
	}
	ngAfterViewInit() {
		//this.formatSchedulerData(this.schedulerWorkFlow);
	}

	showHideMonthlyRadioButton(data: SchedulerWorkFlow) {
		if (this.editMode || this.viewMode) {
			if (data?.freequency === 'Monthly' && data?.monthlyDayCount && data?.monthlyMonthNumber) {
				this.monthlyRadioValues = false;
				this.OccursDayValue = 'Yes';
				this.radioOccursDaySelected = 'Yes';
			}
			else {
				this.monthlyRadioValues = true;
				this.OccursTheValue = 'No';
				this.radioOccursDaySelected = 'No';

			}
		}
	}


	showHideOccursEndDateRadioButton(data: SchedulerWorkFlow) {
		if (this.editMode || this.viewMode) {
			if (this.endedDate) {
				this.enableDate = true;
				this.endDateSelected = 'Yes';
				this.radioEndDateSelected = 'Yes';
			}
			else {
				this.enableDate = false;
				this.noEndDateSelected = 'No';
				this.radioEndDateSelected = 'No';

			}
		}
	}

	showHideOccursOnceRadioButton(data: SchedulerWorkFlow) {
		this.hasSelectedDailyFrequencyOccurOnlyOnce = data.dailyFreequencyOccurOnlyOnce;
		if (this.editMode || this.viewMode) {
			if (this.hasSelectedDailyFrequencyOccurOnlyOnce) {
				this.enableTimer = 1;
				this.OccursOnceValue = 'Yes';
				this.radioOccursOnceSelected = 'Yes';
			}
			else {
				this.enableTimer = 2;
				this.OccursEveryValue = 'No';
				this.radioOccursOnceSelected = 'No';

			}
		}
	}

	disableControls() {
		this.schedulerForm.get('startDate')?.disable({ onlySelf: true });
		this.schedulerForm.get('endDate')?.disable({ onlySelf: true });
		this.schedulerForm.get('oneTimeDate')?.disable({ onlySelf: true });
		this.schedulerForm.get('dailyFrequencyStartTime')?.disable({ onlySelf: true });
		this.schedulerForm.get('schedulerName')?.disable({ onlySelf: true });

		this.schedulerForm.get('dailyFrequencyEndTime')?.disable({ onlySelf: true });
		this.schedulerForm.get('dailyFrequencyStartTime')?.disable({ onlySelf: true });
		this.schedulerForm.get('dailyFrequencyCount')?.disable({ onlySelf: true });
		this.schedulerForm.get('monthlyMonthNumber')?.disable({ onlySelf: true });
		this.schedulerForm.get('monthlyDayCount')?.disable({ onlySelf: true });

		this.schedulerForm.get('recursEveryWeeks')?.disable({ onlySelf: true });
		this.schedulerForm.get('recursEveryDays')?.disable({ onlySelf: true });

	}


	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.workflowName && changes.workflowName.currentValue) {
			let currentChangeValue = changes.workflowName.currentValue;
			this.schedulerForm.patchValue({
				schedulerName: currentChangeValue,
			});

		}
		console.log(" this.SchedulerWorkFlow-3", this.schedulerWorkFlow);
		console.log(" this.workflowName-3 ", this.workflowName);
		this.formatSchedulerData(this.schedulerWorkFlow);



		if (changes && changes.schedulerWorkFlow && changes.schedulerWorkFlow.currentValue) {
			let currentChangeValue = changes.schedulerWorkFlow.currentValue;

     
      

			// this.schedulerForm.patchValue({
			//   startDate:currentChangeValue.startDate

			// });

		}


	}




	// EnterWorkFlowName(event: any){

	// this.workFlowName=event.target.value
	//   this.schedulerForm.patchValue({

	//     name: this.workFlowName,
	//   })
	//   console.log("workflow name   :",this.workFlowName)

	// }


	onChange(value: any) {
		this.schedulerForm.patchValue({
			occurs: value,
		});

		if (this.editMode || this.viewMode) {
			if (value === 'Weekly') {
				this.showSelectedOccurs = "Weekly";
				// const selectedWeeks = <FormArray>this.myForm.controls.weekname;
				// if (this.schedulerWorkFlow?.weekDaysList?.length > 0) {
				// 	this.schedulerWorkFlow.weekDaysList.array.forEach((x: any) => {
				// 		this.selectWeeks(x, true);
				// 	});
				// }

				this.schedulerForm.patchValue({
					recursEveryDays: null,
					monthlyMonthNumber: this.schedulerWorkFlow.monthlyMonthNumber,
					monthlyDayCount: this.schedulerWorkFlow.monthlyDayCount,
					monthlyDayPosition: this.schedulerWorkFlow.monthlyDayPosition,
					monthlyWeekDay: this.schedulerWorkFlow.monthlyWeekDay,
					recursEveryWeeks: this.schedulerWorkFlow.weekCount,
				})
			}
			if (value === 'Daily') {
				this.showSelectedOccurs = "Daily";

				this.schedulerForm.patchValue({
					recursEveryWeeks: this.schedulerWorkFlow.weekCount,
					recursEveryDays: this.schedulerWorkFlow.dailyDayCount,

					onWeeks: null,
					monthlyMonthNumber: null,
					monthlyDayCount: null,
					monthlyDayPosition: null,
					monthlyWeekDay: null,
				})
			}

			if (value === 'Monthly') {
				this.showSelectedOccurs = "Monthly";

				this.schedulerForm.patchValue({
					monthlyMonthNumber: this.schedulerWorkFlow.monthlyMonthNumber,
					monthlyDayCount: this.schedulerWorkFlow.monthlyDayCount,
					recursEveryDays: null,
					recursEveryWeeks: null,
					onWeeks: null,

				})
			}
		}
		else {

			if (value === 'Weekly') {
				this.showSelectedOccurs = "Weekly";

				this.schedulerForm.patchValue({
					recursEveryDays: null,
					monthlyMonthNumber: null,
					monthlyDayCount: null,
					monthlyDayPosition: null,
					monthlyWeekDay: null,
				})
			}

			if (value === 'Daily') {
				this.showSelectedOccurs = "Daily";

				this.schedulerForm.patchValue({
					recursEveryWeeks: null,
					onWeeks: null,

					monthlyMonthNumber: null,
					monthlyDayCount: null,
					monthlyDayPosition: null,
					monthlyWeekDay: null,
				})
			}

			if (value === 'Monthly') {
				this.showSelectedOccurs = "Monthly";

				this.schedulerForm.patchValue({
					recursEveryDays: null,
					recursEveryWeeks: null,
					onWeeks: null,

				})

			}
		}
	}

	onSubmit(): void {
		if(this.schedulerForm.controls.schedulerName.value==null ||this.schedulerForm.controls.schedulerName.value==undefined || this.schedulerForm.controls.schedulerName.value==""){
			this.toast.showWarn('Scheduler Name is  mandatory ','error');
			return;
		  }


		  this.currentDate=this.datePipe.transform(new Date() ,"MM/dd/yyyy")
		  //console.log(" this.currentDate")
		  console.log("timeSelected",this.schedulerForm.controls.dailyFrequencyStartTime.value)
		  if(this.StartDate!=null && this.schedulerForm.controls.dailyFrequencyStartTime.value!=null){
             
			if(this.datePipe.transform(this.StartDate ,"MM/dd/yyyy")==this.datePipe.transform(new Date() ,"MM/dd/yyyy")){
               
               

				let validate =  this.validateCurrentDateTimeForschedule();
				if (validate === false) {
					this.toast.showWarn('Please Select Correct Time ','error');
					
				
					return;
				}

				


		  }

		}

		  if (this.StartDate && this.EndDate) {
			if (new Date(this.StartDate) > new Date(this.EndDate)) {
				this.toast.showWarn('error', 'date range incorrect');
				return; 
			}
			
		}



        if (this.editMode || this.viewMode) {


			if(this.schedulerForm.controls.startDate.value!=null &&this.schedulerForm.controls.dailyFrequencyStartTime.value!=null){
             
				if(this.datePipe.transform(this.schedulerForm.controls.startDate.value ,"MM/dd/yyyy")==this.datePipe.transform(new Date() ,"MM/dd/yyyy")){
				   
					
					let validate =  this.validateCurrentDateTimeForschedule();
					if (validate === false) {
						this.toast.showWarn('Please Select Correct Time ','error');
						
					
						return;
					}
	
			  }
	
			}

		}








		if (this.editMode || this.viewMode) {
			if (this.StartDate && this.EndDate) {
				if (new Date(this.StartDate) > new Date(this.EndDate)) {
					this.toast.showWarn('error', 'date range incorrect');
					return; 
				}
				
			}

		}



		if (this.occureTypeName === "Not Recurring") {
			this.schedulerForm.patchValue({
				scheduleType: this.occureTypeName,
			})
		}
		if (this.monthlyRadioValues) {
			this.schedulerForm.patchValue({
				monthlyDayCount: null
			})
		}
		else {
			this.schedulerForm.patchValue({
				monthlyDayPosition: null,
				monthlyWeekDay: null,
			})
		}
 
		console.log(" schedulerForm", this.schedulerForm.value);
		this.saveScheduler.emit(this.schedulerForm.value);



}

	changeNonRecurringDate(event: any) {
		this.StartDate = event;
		this.StartDate=this.datePipe.transform(this.StartDate ,"MM/dd/yyyy")
		let dateStr=this.datePipe.transform(this.StartDate,"yyyy-MM-dd")
		
		console.log("start Date  ",this.StartDate)
		//this.startedDate = this.StartDate;

		if (this.StartDate !== null) {
			this.schedulerForm.patchValue({
				oneTimeDate: dateStr,
			});
		}
	}

	changeFromDate(event: any) {
		this.StartDate = event;
		this.StartDate=this.datePipe.transform(this.StartDate ,"MM/dd/yyyy")
		let startDateStr=this.datePipe.transform(this.StartDate,"yyyy-MM-dd")
		//this.startedDate = this.StartDate;

		if (this.editMode || this.viewMode) {
			console.log("this.editMode ",this.editMode)
			console.log("this.StartDate ",this.StartDate)
			console.log("this.EndDate ",this.EndDate)
			this.startedDate = this.StartDate;

			if (this.StartDate !== null && this.EndDate !== null) {
				let validate = this.validationOnDate(this.StartDate, this.EndDate);
				if (validate === false) {
					this.toast.showWarn('error', 'date range incorrect');
					this.visible = false;
					this.schedulerForm.patchValue({
						startDate: startDateStr,
		
					});
				
					return;
				}
			}
			this.schedulerForm.patchValue({
				startDate: startDateStr,

			});
		}
		else {
			if (this.StartDate !== null && this.EndDate !== null) {
				let validate = this.validationOnDate(this.StartDate, this.EndDate);
				if (validate === false) {
					this.toast.showWarn('error', 'date range incorrect');
					this.visible = false;
					this.schedulerForm.patchValue({
						startDate: startDateStr,
		
					});
					
					return;
				}
			}
			this.schedulerForm.patchValue({
				startDate: startDateStr,

			});
		}
	}

	changeToDate(event: any) {
		this.EndDate = event;
		this.EndDate=this.datePipe.transform(this.EndDate ,"MM/dd/yyyy")
	
		let endDateStr=this.datePipe.transform(this.EndDate,"yyyy-MM-dd")
		
		if (this.editMode || this.viewMode) {
		if (this.StartDate !== null && this.EndDate !== null) {
			let validate = this.validationOnDate(this.StartDate, this.EndDate);
			if (validate === false) {
				this.toast.showWarn('error', 'date range incorrect');
				this.visible = false;
				// this.StartDate = null;
				// this.EndDate = null;
				this.schedulerForm.patchValue({
					endDate: endDateStr,
				});
				return;
			}
		}
        this.schedulerForm.patchValue({
			endDate: endDateStr,
		});

	}
	else{
		if (this.StartDate !== null && this.EndDate !== null) {
			let validate = this.validationOnDate(this.StartDate, this.EndDate);
			if (validate === false) {
				this.toast.showWarn('error', 'date range incorrect');
				this.visible = false;
				// this.StartDate = null;
				// this.EndDate = null;
				this.schedulerForm.patchValue({
					endDate: endDateStr,
				});
				return;
			}
		}


		this.schedulerForm.patchValue({
			endDate: endDateStr,
		});
	}

		//this.endedDate = this.EndDate;
		
	}

	callEndDate() {
		this.enableDate = true;
		console.log("enable :", this.enableDate);
    this.schedulerForm.patchValue({

      endDateEnable:true,
    })
	}

	disableEndDate() {
		this.enableDate = false;
		this.EndDate=null;
		this.endedDate=null;
		this.schedulerForm.patchValue({
			endDate: null,
      endDateEnable:false,

		});
		console.log("enable :", this.enableDate);
	}

	// setTime(){
	//   this.timer = (<HTMLInputElement>document.getElementById("input")).value; 
	// console.log("timer is working",this.timer);
	// this.schedulerForm.patchValue({

	//   occuresTime: this.timer,

	// });
	// }


	validationOnDate(fromdate, todate) {
		if (fromdate && todate) {
			if (new Date(fromdate) > new Date(todate)) {

				return false;
			}
			return true;

		} else {
			return false;
		}
	}


	getSchedulerTypeList() {
		this.schedulerTypeList = this.formatFields(this.schedulerType);
	}

	formatFields(schedulerType) {
		return reMapForAutoComplete(schedulerType, "typeId", "typeName");
	}





	// onDaysChange(eventdata){
	// console.log("enter into function",eventdata)
	// this.days=eventdata;
	// this.schedulerForm.patchValue({
	//   recursEveryDays:eventdata,
	// });

	// }


	// onWeeksChange(eventdata){
	//   console.log("enter into function",eventdata)
	//   this.weeks=eventdata;
	//   this.schedulerForm.patchValue({
	//     recursEveryWeeks:eventdata,
	//   });

	//   }

	// onMonthsChange(eventdata){
	//   this.months=eventdata;
	//   this.schedulerForm.patchValue({
	//     recursEveryMonths:eventdata,
	//   });
	//   }

	selectWeeks(Data: any, isChecked: boolean) {

		const selectedWeeks = <FormArray>this.myForm.controls.weekname;
		if (this.editMode) {
			if (this.schedulerWorkFlow?.weekDaysList?.length > 0) {
				this.schedulerWorkFlow.weekDaysList.forEach((x: any) => {
					let index = selectedWeeks.controls.findIndex(a => a.value.toLowerCase() == x.toLowerCase())
					console.log('index-', index);
					if ((index === -1)) {
						selectedWeeks.push(new FormControl(x.toLowerCase()));
					}
				});
			}
		}
		console.log('isChecked-', isChecked);
		if (isChecked) {
			let index = selectedWeeks.controls.findIndex(a => a.value.toLowerCase() == Data.toLowerCase())
			console.log('index123-', index);
			if ((index === -1)) {
				selectedWeeks.push(new FormControl(Data.toLowerCase()));
			}
		} else {
			let index = selectedWeeks.controls.findIndex(x => x.value.toLowerCase() == Data.toLowerCase())
			selectedWeeks.removeAt(index);
		}
		console.log(" selectedWeeks ", selectedWeeks.value)

		this.schedulerForm.patchValue({
			onWeeks: selectedWeeks.value,
		})
	}


	onDayPostion(Data) {
		this.schedulerForm.patchValue({

			monthlyDayPosition: Data,
		})
	}

	onWeekday(Data) {
		this.schedulerForm.patchValue({

			monthlyWeekDay: Data,
		})
	}


	// onDay(eventdata){
	//   this.dayNO=eventdata;
	//   this.schedulerForm.patchValue({

	//     onDay:eventdata,
	//     })
	//   console.log("patched value")  

	// }

	// onMonth(eventdata){
	//   console.log("enter into month function",eventdata)
	//   this.monthNo=eventdata;
	//   this.schedulerForm.patchValue({
	//     onMonth:eventdata,
	//   });

	// }

	// setTime(){

	//   this.timer = (<HTMLInputElement>document.getElementById("input")).value; 
	//   this.settedTime=this.timer;
	//   this.schedulerForm.patchValue({

	//     occureOnceAt: this.timer,

	//   }); 


	// }

	// setStartTime(){
	//   this.timer = (<HTMLInputElement>document.getElementById("startinput")).value; 
	//   this.startedTime=this.timer;
	//   this.schedulerForm.patchValue({

	//     startingAt: this.timer,

	//   }); 

	// }

	// setEndTime(){
	//   this.timer = (<HTMLInputElement>document.getElementById("endinput")).value; 
	//   this.endedTime=this.timer;
	//   this.schedulerForm.patchValue({

	//     endingAt: this.timer,

	//   }); 
	// }

	// onOccurEvery(eventdata){
	//   this.schedulerForm.patchValue({
	//     occuresEvery:eventdata,
	//   }); 
	//   this.occurEvery=eventdata;

	// }

	onTimeChange(eventdata: any) {
		console.log("timeStatus ",eventdata)
		if(eventdata=='hour'){
			this.displayTimeCount="hour"
			this.schedulerForm.patchValue({
			dailyFrequencyCount: null,
		});
		}
		if(eventdata=='minute'){
         this.displayTimeCount="minute"	
		 this.schedulerForm.patchValue({
			dailyFrequencyCount: null,
		});
		}
		console.log("this.displayTimeCount ",this.displayTimeCount)
		this.schedulerForm.patchValue({
			dailyFrequency: eventdata,
		});
	}

	enableDay() {
		if (this.editMode || this.viewMode) {
			this.monthlyRadioValues = false;
			this.schedulerForm.patchValue({
				monthlyDayPosition: this.schedulerWorkFlow?.monthlyDayPosition,
				monthlyWeekDay: this.schedulerWorkFlow?.monthlyWeekDay,
				monthlyMonthNumber: this.schedulerWorkFlow?.monthlyMonthNumber,
				monthlyDayCount: this.schedulerWorkFlow?.monthlyDayCount
			})
		}
		else {
			this.monthlyRadioValues = false;
			this.schedulerForm.patchValue({
				monthlyDayPosition: null,
				monthlyWeekDay: null,
				monthlyMonthNumber: null,
				monthlyDayCount: null
			})
		}
	}


	callonce() {
		this.enableTimer = 1;
		if (this.editMode) {
			this.schedulerForm.patchValue({
				dailyFrequencyOccurOnlyOnce: true,
				dailyFrequencyCount: this.schedulerWorkFlow.dailyFreequencyCount,
				dailyFrequency: this.schedulerWorkFlow.dailyFreequency,
				dailyFrequencyStartTime: this.schedulerWorkFlow.dailyFreequencyStartTime,
				dailyFrequencyEndTime: this.schedulerWorkFlow.dailyFreequencyEndTime,

			});
		}
		else {
			this.schedulerForm.patchValue({
				dailyFrequencyOccurOnlyOnce: true,
				dailyFrequencyCount: null,
				dailyFrequency: null,
				dailyFrequencyStartTime: null,
				dailyFrequencyEndTime: null,

			});
		}
	}

	callevery() {
		this.enableTimer = 2;
		if (this.editMode) {
			this.schedulerForm.patchValue({
				dailyFrequencyOccurOnlyOnce: false,
				dailyFrequencyStartTime: this.schedulerWorkFlow.dailyFreequencyStartTime,
			});
		}
		else {
			this.schedulerForm.patchValue({
				dailyFrequencyOccurOnlyOnce: false,
				dailyFrequencyStartTime: null,
				dailyFrequency: "hour",
			});
			this.dailyFreequency="hour"
		}
	}


	enableThe() {
		if (this.editMode || this.viewMode) {
			this.monthlyRadioValues = true;
			this.schedulerForm.patchValue({
				monthlyMonthNumber: this.schedulerWorkFlow?.monthlyMonthNumber,
				monthlyDayCount: this.schedulerWorkFlow?.monthlyDayCount,
			})
		}
		else {
			this.monthlyRadioValues = true;
			this.schedulerForm.patchValue({
				monthlyMonthNumber: null,
				monthlyDayCount: null,
			})
		}

	}



	// setOnceOccurTime(){

	//   this.timer = (<HTMLInputElement>document.getElementById("onceOccureinput")).value; 
	//   this.schedulerForm.patchValue({
	//     onceTimeOccure: this.timer,
	//     }) 
	// }


	// setOnceOccurDate(event: any) {
	// 	this.onceOccurDate = event;
	// 	this.schedulerForm.patchValue({
	// 		startDate: this.schedulerWorkFlow?.startDate ? this.schedulerWorkFlow.startDate : null,
	// 	});
	// }

	OccureTypeChange(eventdata: any) {

		this.occureTypeName = eventdata;
		console.log("occureTypeName ", this.occureTypeName);
		this.schedulerForm.patchValue({
			scheduleType: this.occureTypeName
		})

		if (this.occureTypeName === "Not Recurring") {
			this.schedulerForm.reset();

			
           
			this.schedulerForm.patchValue({
				schedulerName:  this.workflowName,
			});

			if (this.schedulerWorkFlow) {
				this.schedulerForm.patchValue({
				schedulerName: this.schedulerWorkFlow?.schedulerName,
			});
			}

			// this.schedulerForm.patchValue({
			// 	schedulerName: this.schedulerWorkFlow?.schedulerName,
			// });
			this.weeks = null;
			this.days = null;
			this.startedDate = null;
			this.endedDate = null;

		}

		if (this.occureTypeName === "Recurring") {
			console.log("in recurring function");
			//this.schedulerForm.reset();
			// if (this.schedulerWorkFlow) {
			// 	this.formatSchedulerData(this.schedulerWorkFlow);
			// }
			//else {

				this.schedulerForm.patchValue({
					schedulerName:  this.workflowName,
				});

				if (this.schedulerWorkFlow) {
					this.schedulerForm.patchValue({
					schedulerName: this.schedulerWorkFlow?.schedulerName,
				});
				}
				this.schedulerForm.patchValue({
					scheduleType: this.occureTypeName,
					//schedulerName: this.schedulerWorkFlow?.schedulerName,
					occurs: "Daily",
					dailyFrequencyOccurOnlyOnce: true,
					dailyFrequencyStartTime:null,
					

				})



			//}
			this.startedDate = null;
			this.endedDate = null;
			
		}
	}

	hasSelectedWeeks(data: any) {
		return (this.schedulerWorkFlow?.weekDaysList?.indexOf(data.toLowerCase()) >= 0);
	}

	isEndDateAvailable() {
		this.hasEndDateEnabled = this.endedDate ? true : false;
		this.cdr.detectChanges();
	}

	isFrequencyOccurOnlyOnce() {
		this.hasSelectedDailyFrequencyOccurOnlyOnce = this.schedulerWorkFlow?.dailyFreequencyOccurOnlyOnce ? true : false;
		this.cdr.detectChanges();
	}


    validateCurrentDateTimeForschedule(){

		var d = new Date();
		var m = d.getMinutes();
		var h = d.getHours();
		console.log("h"  ,h)
		console.log("m"  ,m)
		if(h ==0)
		 {h = 24}
		if(m<10){
			this.currentTime = h+".0"+m;
		}
		if(m>=10){

			this.currentTime = h+"."+m;
		}

	   
	   console.log(this.currentTime);

		// get input time
	   var time = this.schedulerForm.controls.dailyFrequencyStartTime.value.split(":");
	   var hour = time[0];
	   if(hour == '00') {hour = 24}
	   var min = time[1];

		this.inputTime = hour+"."+min;
		console.log(this.inputTime);

		let totalTime = this.currentTime - this.inputTime;

		console.log("totalTime  ",totalTime)

  
		 if(totalTime>=0){

		
		return false;

		 }


	}


}
