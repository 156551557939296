import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';


@Component({
  selector: 'app-transform-job',
  templateUrl: './transform-job.component.html',
  styleUrls: ['./transform-job.component.scss']
})
export class TransformJobComponent implements OnInit {

  @Input() data = [];
  @Output() deleteJob=new EventEmitter();
  @Output() paramEmit=new EventEmitter();
  @Output() paramEditEmit=new EventEmitter();
  @Output() editWorkflowJobDetailsData=new EventEmitter();
  cols: { field: string; header: string }[];

  visible:boolean=false;
  workflowDetailId:any=null;
  paramHeader="Add Param";
  modalForm: FormGroup = null;
  fromDt:string="";
  workflowDetailParamId:any=null;
  
  constructor(
    private datePipe:DatePipe,
    private toast:ToastService
  ) { 
    this.cols = [
      { field: "jobName", header: "Job" },
     
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },
      { field: "enabled", header: "Enabled" }
    ];

    this.modalForm = new FormGroup({
      asOfDate: new FormControl(null),
      
      
    });
  }

  ngOnInit(): void {
  }

  deleteTransformJob(data){
    this.deleteJob.emit(data);
  }

  showParamValue(data){
  
    this.workflowDetailId=data.workflowDetailId;
    this.workflowDetailParamId=data.workflowDetailParamId;
  
    this.fromDt="";
     console.log("hello 2");
    if(data.workflowDetailParamId!==null && data.workflowDetailParamId>0){
      console.log(data);
      this.fromDt="Current AsOfDate :"+this.datePipe.transform(data.asOfDate,'yyyy-MM-dd');
      
      console.log(this.fromDt);
      
    }
    this.visible=true;
  }
  
    addParamToJob(data){
      console.log(this.modalForm.controls.asOfDate);
      if(this.modalForm.controls.asOfDate.value===null || this.modalForm.controls.asOfDate.value===undefined){
        this.toast.showInfo('error','select as of date');
        return;
      }
     const paramVal={
       "workflowDetailParamId":this.workflowDetailParamId!==null?this.workflowDetailParamId:0,
       "workflowDetailId":this.workflowDetailId,
       "asOdDate":this.modalForm.controls.asOfDate.value
     }
     this.paramEmit.emit(paramVal);
     this.doAction(data);
    }
  
    doAction(data){
      console.log('inside do action');
      this.visible=false;
      this.workflowDetailId=null;
      this.fromDt=""
    }
  
    onDateChange(e){
      //console.log('on date change ',data)
      const valt=this.datePipe.transform(e,'yyyy-MM-dd')
    console.log('on date changegfgf ',valt)
   
      this.modalForm.controls.asOfDate.setValue(valt);
    }

    editWorkflowJobDetails(data){

      this.editWorkflowJobDetailsData.emit(data);
    }

}
