import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileLogModel } from 'src/app/dashboard/models/filelog.model';

@Component({
  selector: 'app-workflow-ingest-job-log-list',
  templateUrl: './workflow-ingest-job-log-list.component.html',
  styleUrls: ['./workflow-ingest-job-log-list.component.scss']
})
export class WorkflowIngestJobLogListComponent implements OnInit {
  @Input() data: FileLogModel[] = [];
  @Input() workflowViewDetaiLogsPopup;
  popForm:FormGroup = null;
  @Output() dateRange=new EventEmitter();
  @Output() dateRangeS=new EventEmitter();
  @Output() dateRangeE=new EventEmitter();

  columns = ["file name", "log type", "code","ingestion time","message", "date"];

  constructor(
    private toast: ToastService,

  ) { this.popForm = new FormGroup({
    toDate: new FormControl(null),
    fromDate: new FormControl(null),


  });}

  
  ngOnInit(): void {}



  changeToDate(e1){
    this.popForm.controls.toDate.setValue(e1);
    console.log("check",this.popForm.controls.toDate.value);
    const parameters1={         
      "toDate":this.popForm.controls.toDate.value,           
  }
  console.log("target changeToDate ",e1);
    console.log("target changeToDate ",this.popForm.controls.toDate.value);
    this.dateRangeE.emit(parameters1);
  }

  changeFromDate(e2){
    this.popForm.controls.fromDate.setValue(e2);
    console.log("check",this.popForm.controls.fromDate.value);
    const parameters2={         
      "fromDate":this.popForm.controls.fromDate.value,           
  }
    console.log("target changeToDate ",e2);
    console.log("target changeToDate ",this.popForm.controls.fromDate.value);
    this.dateRangeS.emit(parameters2);
  }

  // changeToDate1(){
  //   // console.log("ctrl fromDate  ##",this.popForm.controls.fromDate);
  //   // console.log("ctrl toDate  ##",this.popForm.controls.toDate);
  //   console.log("workflowjoblogId",this.workflowViewDetaiLogsPopup);
  //   const data={     
  //       "workflowjoblogId":this.workflowViewDetaiLogsPopup
  //     }
  //   const parameters={      
  //       "data":data,      
  //       "toDate":this.popForm.controls.toDate.value,      
  //       "fromDate":this.popForm.controls.toDate.value
  //   }
  //   this.dateRange.emit(parameters);
  //   }
  // changeToDate(e1){
  //   this.popForm.controls.toDate.setValue(e1);
  //   console.log("check",this.popForm.controls.toDate.value);
  //   this.changeToDate1();
  //   console.log("target changeToDate ",e1);
  //   console.log("target changeToDate ",this.popForm.controls.toDate.value);
  //   // this.dateRange.emit(parameters1);
  // }

  // changeFromDate(e2){
  //   this.popForm.controls.fromDate.setValue(e2);
  //   console.log("check",this.popForm.controls.fromDate);
  //   this.changeToDate1();
  //   console.log("target changeToDate ",e2);
  //   console.log("target changeToDate " ,this.popForm.controls.fromDate.value);
  //   // this.dateRange.emit(parameters2);
  // }

}