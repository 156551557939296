import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransformService {

  constructor(private http: HttpClient) { }

  getAllByClientAndDate(ActionData) {
    return this.http.post<any>(environment.baseUrl + "/apis/transformFileStore/getAllByClientId",ActionData);
    }

    download(id){
      return this.http.get<any>(environment.baseUrl + "/apis/transformFileStore/downloadOutputFile/"+id);
    }
 
    
}
