
import { Component, Input, OnInit,EventEmitter,Output } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-portal-list',
  templateUrl: './client-portal-list.component.html',
  styleUrls: ['./client-portal-list.component.scss']
})
export class ClientPortalListComponent implements OnInit {
 // cols: { field: string; header: string }[];
  @Input() data = [];
  @Input() cols = [];
  @Input() isClient;
  @Output() addLayoutData=new EventEmitter();
  @Output() addMasterData=new EventEmitter();
  @Output() deleteFileData=new EventEmitter();
  @Input()  rolePermission;
 

  constructor(private toast:ToastService,private loader:LoaderService) { 
  
  }

  ngOnInit(): void {
  }

  addLayout(data){
    this.addLayoutData.emit(data);
  }

  addMaster(data){
    if(data.clientFileId===0){
      this.toast.showInfo('error','No layout defined');
      return;
    }
    this.addMasterData.emit(data);
  }

  deleteData(data){
    this.deleteFileData.emit(data);
  }

  downloadAnyFile(clientFileId){
    this.loader.showLoader();
    let downloadSuccess:boolean=false;
    var link=document.createElement('a');
    link.href=environment.baseUrl+"/apis/client-portal/downloadClientPortalFile/"+clientFileId;      
    link.click();
    this.toast.showSuccess('success','Download Begin Shortly'); 
    this.loader.hideLoader();
  }
  
}