import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ToastService } from "src/app/core/services/toast.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { FileService } from "../../services/file.service";

@Component({
  selector: "app-files-list",
  templateUrl: "./files-list.component.html",
  styleUrls: ["./files-list.component.scss"],
  providers: [FileService],
})
export class FilesListComponent implements OnInit, OnChanges {
  filesList: any[] = [];
  @Input() ctrl: FormControl;
  @Output() onSelect = new EventEmitter();
  @Input() id: number;

  constructor(
    private fileService: FileService,
    private loader: LoaderService,private toast:ToastService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue !== null && changes.id.currentValue !== '') {
      this.filesList = [];
      this.loader.showLoader();
      this.getAllFiles(changes.id.currentValue);
    }
  }
  getAllFiles(id) {
    this.fileService.getFilesByClientId(id).subscribe(
      (data) => {
        this.filesList = reMapForAutoComplete(
          data,
          "clientApplicationFileId",
          "fileName"
        );
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      this.loader.hideLoader()
      }
    );
  }
  getSelected(val) {
    this.onSelect.emit(val);
  }
}
