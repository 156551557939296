import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { currentMonthRange } from 'src/app/utils/dates';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservalbleDataService } from '../../services/observalble-data.service';

@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.scss']
})
export class DeleteDataComponent implements OnInit {

  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  visible:boolean=false;
  dataTransferModel: DataTransferModel;
  fileDataTypeList: any = [];
  rolePermission:any;

  constructor( private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private loader: LoaderService,
    private fileService: FileService,
    private toast: ToastService,
    public observalbleDataService: ObservalbleDataService,
    private datePipe:DatePipe,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>) {
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      asOfDate: new FormControl(null, [Validators.required]),
      fileDataType:new FormControl(null,[Validators.required])
    });
   }

  ngOnInit(): void {
    this.buttonShowHidePermission();
    //this.storePermission.select(x => this.rolePermission = x).subscribe();
  
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  changeAsOfDate(e){
    console.log(e);
    this.headerForm.controls.asOfDate.setValue(e);
  }

  deleteData(){
    this.deleteDataByClientAsofDate();

  }

  deleteDataByClientAsofDate(){
    // if(this.headerForm.controls.client.value.key===null|| this.headerForm.controls.client.value.key===undefined){
    //   this.toast.showInfo('error','client is mandatory');
    //   this.visible=false;
    //   return;
    // }
  let date1=this.datePipe.transform(this.headerForm.controls.asOfDate.value,"yyyy-MM-dd")
   const dataDeleteModel={
     "dbName":"pcbb_datamart",
     "tableName":this.headerForm.controls.fileDataType.value.key,
     "clientId":this.headerForm.controls.client.value.key,
     "asOfDate":date1
   }

   this.fileService.deleteDataLoans(dataDeleteModel)
   .subscribe(
     data=>{
      // let res=data;
      // console.log(data);
       if(data===0){
       this.toast.showInfo('info','No Data Present');
       this.visible=false;
       }
       if(data!==0){
        this.toast.showInfo('success','Data Deleted Successfully');
        this.headerForm.reset();
        this.visible=false;
        }
     },
     error=>{
        this.toast.showError(error.error.message, "error");
        this.visible=false;
     }
   )

  }

  hidePopup(data){
    this.visible=false;
  }

  actionDo(){
    this.deleteData();
  }

  showPopup(){
    if(this.headerForm.invalid){
      this.toast.showInfo('error','client and asofdate is mandatory');
      this.visible=false;
      return;
    }
    this.visible=true;
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
   
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
                
                     
    }
  }

  loadDate(){
    let currentDateRange=currentMonthRange();
    this.headerForm.controls.asOfDate.setValue(currentDateRange[0]);
  }

  maintainClientState(client){ 
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
     this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
     
  }

  getApplicationFileType(){
    this.fileService.getApplicationDataTypeByUser().subscribe(
   (data) => {
     const fileDataType=data;
     for(var a=0;a<fileDataType.length;a++){
     // console.log("type "+fileDataType[a].type);
       this.fileDataTypeList.push({ key: fileDataType[a].datamartTable, value: fileDataType[a].type });
     
     }
   },
   (err) => {
   //  this.toast.showSuccess("Status Not Changed", "error");
   this.toast.showError(err.error.message, "error");
   }
 
 );
    
 }
 buttonShowHidePermission(){
  this.fileService.buttonShowHidePermission().subscribe(
    (res) =>{ 
         this.rolePermission = res  
         this.callNgOnInitMethods();
 }
  )};

  callNgOnInitMethods(){
    this.getApplicationFileType();
    this.getPreviousState();
    this.setTitle();   
  }
}
