import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup,FormBuilder, FormControl,Validators } from '@angular/forms';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit,OnChanges {

  @Input() editUser; 
  @Output() saveUser = new EventEmitter();
  @Input() roleList;
  // @Input() clientLists;
  @Input() clientLists;
  @Input() applicationLists;
  @Input() clientApplicationDataTypeList;
  @Input() notificationList;
  
  isChecked:boolean=false;
  uncheck:boolean;
  form:any=null; 
  myGroup :FormGroup;
  myGroup1 :FormGroup;
  myGroup2 :FormGroup;
  isClientChecked:boolean=false;
  isApplicationChecked:boolean=false;
  isFileTypeChecked:boolean=false;

  @Input()   rolePermission;
  userForm: FormGroup=null;
  enableEdit:boolean=false;
  roleLists:{ key: string | number; value: string }[];
  clientList:{ key: string | number; value: string }[];
  roleId:number;
  selectedClients: { clientId: any; clientName: any }[] = [];
  selectedApplication: { applicationId: any; name: any }[] = [];
  selectedDataType: { clientapplicationfiledatatypeId: any; type: any }[] = [];
  selectedNotificationType: {notificationId: any;notificationName: any}[]=[];
  constructor(private toast: ToastService,private fb: FormBuilder,private storePermission: Store<{ role: any }>) { 
    this.userForm = new FormGroup({
      userName: new FormControl(null,{ validators: [Validators.required, Validators.email], updateOn: "blur" }),
      firstName: new FormControl(null,Validators.required),
      lastName:new FormControl(null,Validators.required),
      roleId:new FormControl(null,Validators.required),
      statusMap: new FormControl(null,Validators.required),
      clients: new FormControl(null),
      applications: new FormControl(null),
      fileDataType: new FormControl(null),

      noticationType: new FormControl(null),
     // userFlag:new FormControl(null),
      clientFlag:new FormControl(null),
      applicationFlag:new FormControl(null),
      fileTypeFlag:new FormControl(null),
    });
  }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.userForm.get('statusMap').setValue(defaultMap);


   this.myGroup = new FormGroup({
    checkClientFlag: new FormControl()
 });
 this.myGroup1 = new FormGroup({
  checkApplicationFlag: new FormControl()
});
this.myGroup2 = new FormGroup({
  checkFileTypeFlag: new FormControl()
});

this.setGroupFalse();
//this.storePermission.select(x => this.rolePermission = x).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editUser && changes.editUser.currentValue) {
     console.log(changes.editUser.currentValue,' indise changes') 
       const lookUp=changes.editUser.currentValue;
       this.enableEdit = true;
       this.checkForEdit() 
    }
       if (changes && changes.roleList && changes.roleList.currentValue) {
        this.roleLists = this.formatFields(changes.roleList.currentValue);  
      //  console.log("roles" +this.roleLists)  
   }

   if (changes && changes.clientLists && changes.clientLists.currentValue) {
    this.clientList = this.formatClientFields(changes.clientLists.currentValue);  
    //console.log("client" +this.clientList)  
}


if (changes && changes.editUser && changes.editUser.currentValue) {
  let currentChangeValue = changes.editUser.currentValue;
  let clientEditList = currentChangeValue.clientsMappingList
  let applicationEditList = currentChangeValue.applicationMappingList
  let dataTypeEditList = currentChangeValue.datatypeMappingList
  let notificationEditList=currentChangeValue.notificationMappingList
  console.log("clientEditList  ",clientEditList)
   this.selectedClients = [];
   this.selectedApplication=[];
   this.selectedDataType=[];
   this.selectedNotificationType=[];
  for (var i = 0; i < clientEditList.length; i++) {
    this.selectedClients.push({ clientId: clientEditList[i].clientId, clientName: clientEditList[i].clientName });
    console.log("Selected Clients Are:", this.selectedClients);
  }

  for (var i = 0; i < applicationEditList.length; i++) {
    this.selectedApplication.push({ applicationId: applicationEditList[i].applicationId, name: applicationEditList[i].name });
    console.log("selectedApplication Are:", this.selectedApplication);
  }

  for (var i = 0; i < dataTypeEditList.length; i++) {
    this.selectedDataType.push({ clientapplicationfiledatatypeId: dataTypeEditList[i].clientapplicationfiledatatypeId, type: dataTypeEditList[i].type });
    console.log("selectedDataType Are:", this.selectedDataType);
  }


  // let getAssignAll=currentChangeValue.assignAll
  // if(getAssignAll===true){
  //   this.isChecked=true;
  //   this.myGroup.get('checkFlag').setValue(true);
  // }
  // else{
  //   this.isChecked=false;
  //   this.myGroup.get('checkFlag').setValue(false)
  // }
 
  for (var i = 0; i < notificationEditList.length; i++) {
    this.selectedNotificationType.push({ notificationId: notificationEditList[i].notificationId, notificationName: notificationEditList[i].notificationName });
    console.log("selectedNotificationType Are:", this.selectedNotificationType);
  }



  let getAssignAllClient=currentChangeValue.assignAllClients
  let getAssignAllApplications=currentChangeValue.assignAllApplications
  let getAssignAllDataType=currentChangeValue.assignAllDataTypes

  if(getAssignAllClient===true){
    this.isClientChecked=true;
    this.myGroup.get('checkClientFlag').setValue(true);

  }
  else{
    this.isClientChecked=false;
    this.myGroup.get('checkClientFlag').setValue(false);
  }

  if(getAssignAllApplications===true){
    this.isApplicationChecked=true;
    this.myGroup1.get('checkApplicationFlag').setValue(true);

  }
  else{
    this.isApplicationChecked=false;
    this.myGroup1.get('checkApplicationFlag').setValue(false);
  }


  if(getAssignAllDataType===true){
    this.isFileTypeChecked=true;
    this.myGroup2.get('checkFileTypeFlag').setValue(true);

  }
  else{
    this.isFileTypeChecked=false;
    this.myGroup2.get('checkFileTypeFlag').setValue(false);
  }

  

  this.userForm.patchValue({
    
     clients: this.selectedClients,
     applications: this.selectedApplication,
     fileDataType: this.selectedDataType,
     noticationType:this.selectedNotificationType
   });
}


    }
    // if (changes && changes.newRule && changes.newRule.currentValue > 1) {
    //   this.resetForm();
    // }
  

    multiClientSelectChange(eventData) {
      this.selectedClients = eventData.value;
      console.log("Client Value Updated Is:", eventData.value);
    }
  
    multiApplicationSelectChange(eventData) {
      this.selectedApplication = eventData.value;
      console.log("Application Value Updated Is:", this.selectedApplication);
    }
  
    multiDataTypeSelectChange(eventData) {
      this.selectedDataType = eventData.value;
      console.log("Datatype Value Updated Is:", this.selectedDataType);
    }

    multiNotificationTypeSelectChange(eventData){
      this.selectedNotificationType = eventData.value;
      console.log("Datatype Value Updated Is:", this.selectedNotificationType);

    }


  checkForEdit() {
    const RoleData = {
      key: this.editUser.roleId,
      value:this.editUser.roleName,
    };

    const ClientData = {
      key: this.editUser.clientId,
      value:this.editUser.clientName,
    };

    const statusActive = {
      key: "Active",
      value:"Active",
    };
    const statusInactive = {
      key: "Inactive",
      value:"Inactive",
    };
    if (this.editUser && this.enableEdit) { 
      //console.log("edit user data"+this.editUser)
     if(this.editUser.status==0){
      this.userForm.patchValue({
        userName: this.editUser.userName,
        firstName: this.editUser.firstName,
        lastName: this.editUser.lastName,
        roleId: RoleData,
        statusMap:statusInactive,
        clientId:ClientData
      });
     }else{
      this.userForm.patchValue({
        userName: this.editUser.userName,
        firstName: this.editUser.firstName,
        lastName: this.editUser.lastName,
        roleId: RoleData,
        statusMap:statusActive,
        clientId:ClientData
      });
    }
     
      this.enableEdit = false;
     
    }
  }

  createUser(){
  //console.log("user form "+this.userForm.value)
  if(this.userForm.invalid){
    if(this.userForm.controls.userName.invalid){
      this.toast.showWarn('username mandatory & should be in email format','error');
      return;
    }
    this.toast.showWarn('All fields are mandatory ','error');
    return;
  }


// this.userForm.patchValue({

//   userFlag:false,

// });

this.userForm.patchValue({

  clientFlag:this.myGroup.get('checkClientFlag').value,

});

this.userForm.patchValue({

  applicationFlag:this.myGroup1.get('checkApplicationFlag').value,

});

this.userForm.patchValue({

  fileTypeFlag:this.myGroup2.get('checkFileTypeFlag').value,

});

  this.userForm.patchValue({
     
    clients: this.selectedClients,
   
  });

  this.userForm.patchValue({
   
    applications: this.selectedApplication,
   
  });

  this.userForm.patchValue({
     
    fileDataType: this.selectedDataType,
   
  }); 

  this.userForm.patchValue({
     
    noticationType: this.selectedNotificationType,
   
  }); 

  
    this.saveUser.emit(this.userForm.value)

  }

  formatFields(roleList) {
    return reMapForAutoComplete(roleList, "roleId", "roleName");
  }

  formatClientFields(clientList) {
    return reMapForAutoComplete(clientList, "clientId", "clientName");
  }

  get status() {
    const status = [
      "Active",
      "Inactive",
    ];
   return status.map((t) => ({ key: t, value: t }));
  }

  resetForm() {
    console.log("reset is working ")
    this.userForm.reset();
     this.selectedClients = [];
     this.selectedApplication=[];
     this.selectedDataType=[];
     this.selectedNotificationType=[];
  }

  // checkedFlag(event){

  
  //   console.log("is checked  ",event.target.checked)
  //   if(event.target.checked){

  //   this.isChecked=true;

  //   this.selectedClients = [];
  //   this.selectedApplication=[];
  //   this.selectedDataType=[];

  //   }
  //   else{
  //     this.isChecked=false;

    
  //   }
  //   console.log("this.isChecked ",this.isChecked)
  // }

  resetCheckedFlag(){


    this.isClientChecked=false;
    this.isApplicationChecked=false;
    this.isFileTypeChecked
    // this.myGroup.get('checkFlag').setValue(false)
    this.myGroup.get('checkClientFlag').setValue(false);
    this.myGroup1.get('checkApplicationFlag').setValue(false);
    this.myGroup2.get('checkFileTypeFlag').setValue(false);

    
  }

  checkedClientFlag(event){
    if(event.target.checked){
    this.isClientChecked=true;
    this.selectedClients = [];
  }
  else{
    this.isClientChecked=false;
  }
}

  checkedApplicationFlag(event)
  {
    if(event.target.checked){
    this.isApplicationChecked=true;
    this.selectedApplication=[];
    }
    else{
      this.isApplicationChecked=false;
    }

  }

  checkedFileTypeFlag(event){
    if(event.target.checked){
    this.isFileTypeChecked=true;
    this.selectedDataType=[];

    }
    else{
      this.isFileTypeChecked=false;
    }

  }



  setGroupFalse(){

    this.myGroup.get('checkClientFlag').setValue(false);
    this.myGroup1.get('checkApplicationFlag').setValue(false);
    this.myGroup2.get('checkFileTypeFlag').setValue(false);

  }


  

  


}
