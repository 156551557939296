import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { FormsModel } from 'src/app/models/forms.model';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientModel } from '../../models/client.model';
import { Binary } from '@angular/compiler';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { map } from 'rxjs/operators';
import { LayoutFilesModel } from '../../models/files.model';
import { getCurrentDate,formatDate } from "src/app/utils/dates";
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { DatePipe } from '@angular/common';
import { TransformPermission } from '../../models/transformPermission.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  @ViewChild('myInput')
myInputVariable: ElementRef;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
  clientList:any[];
  errorMessage:any;
  fileDataTypeList: any = [];
  clientMasterList: any=[];
  layoutFieldList: any = [];
  dependentUponList: any = [];
  fileTypeList: any = [];
  showClientFilePopUp=false; 
  showClientAppFilePopUp=false;
  //validationMessages:any;
  clientForm: FormGroup = null;
  clientModel:ClientModel[];
  activeRoute$: Subscription;
  hidebutton:boolean=false;
  visible = false;
  modalForm: FormGroup = null;
  clientApplicationFileForm: FormGroup=null;
  clientId:any=null;
  layoutFilesModel:LayoutFilesModel[]=[]
  currentFileUpload: File;
  clientName:any;
  fieldSeparatorList:{ key: string | number; value: string }[];
  clientPermission:TransformPermission;
  viewClientButton:any;
  rolePermission:any;
  
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,
    private router: Router,private clientFileMasterService:ClientFileMasterService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
      this.clientForm=new FormGroup({
        clientId:new FormControl(null),
        clientName: new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
        shortCd: new FormControl(null,[Validators.required]),
        cert: new FormControl(null,[Validators.required]),
        statusMap: new FormControl(null,{validators:[Validators.required]}),
        email: new FormControl(null,{validators:[Validators.email], updateOn: "blur"}),
        phone: new FormControl(null,{validators:[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")], updateOn: "blur"}),
        fax: new FormControl(null,{validators:[Validators.pattern("^[0-9]*$")], updateOn: "blur"}),
        contactPerson: new FormControl(null,{validators:[Validators.pattern("[a-zA-Z ]*")], updateOn: "blur"}),
        address1: new FormControl(null),
        address2: new FormControl(null),
        city: new FormControl(null),
        stateMap: new FormControl(null,[Validators.required]),
        zip: new FormControl(null, {validators:[Validators.pattern("^[0-9]*$")],updateOn:"blur"}),
        subClassCecl: new FormControl(false),
        subClassAll: new FormControl(false),
        callReportNameCecl: new FormControl(false),
        callReportNameAll: new FormControl(false),
        creditUnion: new FormControl(false),
        cleanData:new FormControl(false),
        
      });
     this.clientForm.valueChanges.subscribe(data => this.onValueChanged(data));

     this.modalForm = new FormGroup({
      file:new FormControl(null, [Validators.required]),
      asOfDate:new FormControl(null, [Validators.required]),
      clientFieMaster:new FormControl(null)
     });
      
      this.clientApplicationFileForm = new FormGroup({
        file:new FormControl(null),
        fileType:new FormControl(null, [Validators.required]),
        fileDataType:new FormControl(null, [Validators.required]),
        layoutFieldId:new FormControl(null),
        dependentUpon:new FormControl(null),
        fieldSeparator:new FormControl(null),
        schedule:new FormControl(null)
  

    });

    }
  ngOnInit(): void {
   this.buttonShowHidePermission();
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  saveClient(){
    if(this.clientForm.valid && this.clientForm.controls.stateMap.value.key!=null){
   this.loader.showLoader();
   if(this.clientForm.controls.clientId.value>0){
    this.clientModel=this.clientForm.value;
    this.fileService.editClient(this.clientModel).subscribe(
      (data) => {
     this.toast.showSuccess("Field Updated", "success");
     this.formReset();
     this.getAllClientList();
     this.loader.hideLoader();
   },
   (err) => {
     this.loader.hideLoader();
     this.toast.showError(err.error.message, "error");
   }
 
 );
   }else{
   this.clientModel=this.clientForm.value;
   this.fileService.saveClient(this.clientModel).subscribe(
     (data) => {
    this.toast.showSuccess("Field Saved", "success");
    this.formReset();
    this.getAllClientList();
    this.loader.hideLoader();
  },
  (err) => {
    this.loader.hideLoader();
    this.toast.showError(err.error.message, "error");
  }

);
   }
  }else{
    this.toast.showError("Please Fill Required Fields","error");
  }
}


getSelectedClientList(){
  this.loader.showLoader();
  this.fileService.getSelectedClientList().subscribe((data)=>{
    this.clientList=data;
    this.loader.hideLoader();
    if(!this.clientList.length){
      this.hidebutton=true;
    }else{
      this.hidebutton=false;
    }
  },
  (err) => {
    this.toast.showError(err.error.message, "error");
    this.loader.hideLoader();
  }
  );
}


getAllClientList(){
  this.loader.showLoader();
  this.fileService.getAllClientList().subscribe((data)=>{
    this.clientList=data;
    this.loader.hideLoader();
    if(!this.clientList.length){
      this.hidebutton=true;
    }else{
      this.hidebutton=false;
    }
  },
  (err) => {
    this.toast.showError(err.error.message, "error");
    this.loader.hideLoader();
  }
  );
}



  get status() {
    const status = [
      "Active",
      "Inactive",
    ];
   return status.map((t) => ({ key: t, value: t }));
  }


  get getstates(){
    const states = [
"Alabama",
"Alaska",
"Arizona",
"Arkansas",
"California",
"Colorado",
"Connecticut",
"Delaware",
"Florida",
"Georgia",
"Hawaii",
"Idaho",
"Illinois",
"Indiana",
"Iowa",
"Kansas",
"Kentucky",
"Louisiana",
"Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Puerto Rico",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virgin Islands",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming",

    ];
    return states.map((t) => ({ key: t, value: t }));
  }
  

  editClient(rowData){
      this.clientModel=rowData;
      this.clientForm.patchValue(
        {clientId:rowData.clientId,
          clientName: rowData.clientName,
          shortCd: rowData.shortCd,
          cert: rowData.cert,
          email: rowData.email,
          phone: rowData.phone,
          fax: rowData.fax,
          contactPerson: rowData.contactPerson,
          address1: rowData.address1,
          address2: rowData.address2,
          city: rowData.city,
          zip: rowData.zip,
          subClassAll:rowData.subClassAll,
          subClassCecl: rowData.subClassCecl,
          callReportNameCecl: rowData.callReportNameCecl,
          callReportNameAll: rowData.callReportNameAll,
          creditUnion: rowData.creditUnion,
          cleanData: rowData.cleanData,
          
        });
        
          const map1 = {
            key: "Active",
            value: "Active",
          };
        
          const map2 = {
            key: "Inactive",
            value: "Inactive",
          };
        
        rowData.status==true? this.clientForm.controls.statusMap.setValue(map1):this.clientForm.controls.statusMap.setValue(map2);

          
            const mapData = {
              key: rowData.state,
              value: rowData.state,
            };
            this.clientForm.controls.stateMap.setValue(mapData);
          
}

changeStatusForClient(rowData){
  this.clientModel=rowData;
   this.clientForm.patchValue(
    {clientId:rowData.clientId,   
    });
    if(rowData.status==true){
      const map = {
        key: "Deactive",
        value: "Deactive",
      };
      this.clientForm.controls.statusMap.patchValue(map);
      console.log(this.clientModel);
      this.clientModel=this.clientForm.value;
      this.fileService.changeStatusForClient(this.clientModel).subscribe(
        (data) => {
          this.toast.showSuccess("Status change", "success");
          this.formReset();
          this.getAllClientList();
          this.loader.hideLoader();
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showError(err.error.message, "error");
        }
      
      );
    
    }else{
      const map = {
        key: "Active",
        value: "Active",
      };
      this.clientForm.controls.statusMap.setValue(map);
      console.log(this.clientModel);
      this.clientModel=this.clientForm.value;
      this.fileService.changeStatusForClient(this.clientModel).subscribe(
        (data) => {
          this.toast.showSuccess("Status change", "success");
          this.formReset();
          this.getAllClientList();
          this.loader.hideLoader();
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showError(err.error.message, "error");
        }
      
      );
         
    }
    
 }
 formReset(){
   this.clientForm.reset();
   const defaultMap = {
    key: "Active",
    value: "Active",
  };
 this.clientForm.get('statusMap').setValue(defaultMap);
 }

 onValueChanged(data?: any) {
  if (!this.clientForm) { return; }
  const form = this.clientForm;
  for (const field in this.formErrors) {
   // clear previous error message (if any)
   this.formErrors[field] = '';
   const control = form.get(field);
   if (control && control.invalid && control.dirty) {
     const messages = this.validationMessages[field];
     for (const key in control.errors) {
      // this.formErrors[field] += messages[key] + ' ';
       this.errorMessage=messages;
        this.toast.showError('error',this.errorMessage);
        console.log(this.errorMessage);
      }
    }
   }
 }

 formErrors = {
  'email': '',
  'phone': '',
  'zip':'',
  'clientName':'',
  'fax':'',
  'contactPerson':'',
};

validationMessages = {
  'email':'Email is invalid',
 'phone':'Phone Number Should Not Exceed More Than 10 Digits',
 'zip':'Must Be Numeric Only',
  'clientName':'Must Be Alphabets Only',
  'fax':'Must Be Numeric Only',
  'contactPerson':'Must Be Alphabets Only',

  }


  showPopup(data) {
    this.clientId=data.clientId; 
    this.showClientFilePopUp=true; 
    this.showClientAppFilePopUp=false;
    this.clientName="Add Client File For "+data.clientName
    this.visible = true;
    
    
  
    
   
  }
  showPopupAddFile(data) {
    this.showClientAppFilePopUp=true;
    this.showClientFilePopUp=false; 
    this.clientName="Add Layout File For "+data.clientName
    this.visible = true;
    this.layoutFieldList=[];
    this.dependentUponList=[];
    this.clientId=data.clientId;
    this.getLayoutField(this.clientId);
    this.getDependentUponData(this.clientId);   
  }

  doAction(type) {
    if (type === "yes") {
      console.log("helloo");
      this.visible = false;
     this.modalForm.reset();
    // this.modalForm.get("asOfdate").reset();
     this.currentFileUpload=null;
      this.myInputVariable.nativeElement.value = "";
     // this.fromCalVariable.nativeElement.value = "";
         }else{
      this.modalForm.reset();
    }
   
   
  }

  doAddFileAction(type){
    if (type === "yes") {
      console.log("helloo");
      this.visible = false;
    //  this.modalForm.reset();
    this.clientApplicationFileForm.reset();
    this.currentFileUpload=null;
    this.myInputVariable.nativeElement.value = "";
    }else{
      this.clientApplicationFileForm.reset();
    }
  }


  selectFile(event) {
  this.currentFileUpload = event.target.files;
}

    selectClientApplicationFile(event){
    this.currentFileUpload = event.target.files;
  }

  saveClientFile(){
    
   if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
     this.toast.showWarn('no file selected','error');
     return;
   }
   if(this.modalForm.controls.asOfDate.value===null || this.modalForm.controls.file.value===null){
    this.toast.showWarn('ApplicationFile and AsOfDate are mandatory','error');
    return;
   }
    const clientFile={
      "clientFileId":null,
      "client_id":this.clientId,
      "fileName":null,
      "asOfDate":formatDate(this.modalForm.controls.asOfDate.value),
      "clientApplicationFileId":this.modalForm.controls.file.value.key,
      "createdBy":1, 
      "clientFileMasterId":this.modalForm.controls.clientFieMaster.value!==null ? this.modalForm.controls.clientFieMaster.value.key:0,
    }
    this.loader.showLoader();
 
    this.fileService.saveClientFile(this.currentFileUpload,clientFile)
    .subscribe(
      data=>{
        this.toast.showSuccess('Client File Added','Success');
        this.clientId=null;
        this.currentFileUpload=null;
        this.myInputVariable.nativeElement.value = "";    
        this.visible=false;
        this.modalForm.reset();
        this.loader.hideLoader();
        this.clientMasterList=[];
      //  this.fromCalVariable.nativeElement.value = "";
       
      },
      error=>{
        this.toast.showError(error.error.message, "error");
        this.loader.hideLoader();
      },
     
    );
    
  }


  onDateChange(e){
    const valt=this.datePipe.transform(e,'yyyy-MM-dd');
    //console.log("date val ",valt);
    this.modalForm.controls.asOfDate.setValue(valt);
  }

    
  saveClientApplicationFile(){
    if(this.clientApplicationFileForm.valid){
    if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      this.toast.showWarn('no file selected','error');
      return;
    }
  
if(this.clientApplicationFileForm.controls.fileType.value.key===2){
  if((this.clientApplicationFileForm.controls.layoutFieldId.value!=null || this.clientApplicationFileForm.controls.layoutFieldId.value!="") && this.clientApplicationFileForm.controls.dependentUpon.value===null){
  this.toast.showError('Please select dependent upon file','error');
  return;
}
}
  }else{
    this.toast.showError('Please Fill the Mandatory Fields','Error');
  }

  

      const ClientAppliactionFileConst = {
       "clientId": this.clientId,
       "clientApplicationFileTypeId": this.clientApplicationFileForm.controls.fileType.value.key,
       "dependentUpon":this.clientApplicationFileForm.controls.dependentUpon.value,
       "schedule":this.clientApplicationFileForm.controls.schedule.value,
       "fieldSeparator":this.clientApplicationFileForm.controls.fieldSeparator.value,
       "clientApplicationFileDataTypeId":this.clientApplicationFileForm.controls.fileDataType.value.key,
       "layoutFieldId":this.clientApplicationFileForm.controls.layoutFieldId.value
     };
     this.fileService.saveClientApplicationFile(this.currentFileUpload,ClientAppliactionFileConst) .subscribe(
      data=>{
        this.toast.showSuccess('Client Application File Added','Success');
        this.clientId=null;
        this.currentFileUpload=null;
        this.myInputVariable.nativeElement.value = "";
        this.visible=false;
        this.clientApplicationFileForm.reset();
        this.loader.hideLoader();

       
      },
      error=>{
        this.toast.showError(error.error.message, "error")
        this.loader.hideLoader();
      },
     
    );
  
  }

  getApplicationFileType(){
   this.fileService.getApplicationDataTypeByUser().subscribe(
  (data) => {
    const fileDataType=data;
    for(var a=0;a<fileDataType.length;a++){
    // console.log("type "+fileDataType[a].type);
      this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
    
    }
  },
  (err) => {
  //  this.toast.showSuccess("Status Not Changed", "error");
  this.toast.showError(err.error.message, "error");
  }

);
   
}
//this.fileService.getFilesByClientId(clientId)
getLayoutField(clientId){
  this.fileService.getAppFilesByClientId(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}

getDependentUponData(clientId){
  this.fileService.getDependentUpon(clientId).subscribe(
    (data) => {
      const dependentUpon=data;
      for(var a=0;a<dependentUpon.length;a++){
        console.log("type "+dependentUpon[a].fileName);
        this.dependentUponList.push({ key: dependentUpon[a].clientApplicationFileId, value: dependentUpon[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}

getClientApplicationFileTypeData(){
  this.fileService.getClientApplicationFileType().subscribe(
    (data) => {
      const clientApplicationFileType=data;
      for(var a=0;a<clientApplicationFileType.length;a++){
       // console.log("type "+clientApplicationFileType[a].type);
        this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    this.toast.showError(err.error.message, "error");

    }
  
  );

}

 getAllSeparators(){
   this.fieldSeparatorList= [
    { key: ',', value: "Comma" },
    { key: 'space', value: "Space" },
    { key: '|', value: "Pipe(|)" },
    { key: '"', value: "Quotes" },
    { key: ';', value: "Semicolon" },
    { key: ':', value: "Colon"},
    { key: '\t', value: "Tab" }
  ];
 //this.clientForm.get('statusMap').setValue(defaultMap);
 }

//  getAllClientMasterData(clientId){
//   this.clientFileMasterService.getClientFileMasterListByClient(clientId).subscribe(
//     (data) => {
//       const clientMasterData=data;
//       for(var a=0;a<clientMasterData.length;a++){
//        // console.log("type "+clientApplicationFileType[a].type);
//         this.clientMasterList.push({ key: clientMasterData[a].clientfilemasterId, value: clientMasterData[a].name });
//       }
//       console.log(this.clientMasterList);
//     },
//     (err) => {
//     //  this.toast.showSuccess("Status Not Changed", "error");
//     }
  
//   );

// }

userRoleForClient(){

  this.fileService.userRoleTransform().subscribe(
      data =>{
        this.clientPermission = data; 
        this.viewClientButton=this.clientPermission.saveClient;                  
      }
        )
}

buttonShowHidePermission(){
  this.fileService.buttonShowHidePermission().subscribe(
    (res) =>{ 
         this.rolePermission = res  
         this.callNgOnInitMethods();
 }
  )};

  callNgOnInitMethods(){
    this.setTitle();
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.clientForm.get('statusMap').setValue(defaultMap);
    //console.log("this.clientForm " +this.clientForm.controls.statusMap.value);
   this.userRoleForClient();
  
   this.getApplicationFileType();
   this.getClientApplicationFileTypeData();
   this.getAllSeparators();
   
   this.getAllClientList();
   //this.getSelectedClientList();
  }

}
