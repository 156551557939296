import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { ClientFileMasterModel } from '../models/clientFileMaster.model';

@Injectable({
    providedIn: 'root'
  })
export class ClientFileMasterService {
  /**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  saveOrUpdateData(data){
    return this.http.post<ClientFileMasterModel[]>(environment.baseUrl + "/files/saveClientFileMaster",data);
} 

getClientFileMasterListByClient(data){
    return this.http.get<ClientFileMasterModel[]>(environment.baseUrl + "/files/getClientMasterList/"+data);
}

getClientFileMasterById(data){
  return this.http.get<ClientFileMasterModel[]>(environment.baseUrl + "/files/getClientMasterListById/"+data);
}

deleteClientFileMasterById(data){
  return this.http.get<ClientFileMasterModel[]>(environment.baseUrl + "/files/deleteClientFileMasterById/"+data);
}

  }

