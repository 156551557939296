import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastModule } from "primeng/toast";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UserModule } from "./user/user.module";
import { CoreModule } from "./core/core.module";
import { HttpClientModule } from "@angular/common/http";
import { LoaderModule } from "./loader/loader.module";
import { ErrorModule } from "./error/error.module";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { DashboardModule } from "./dashboard/dashboard.module";
import { UiModule } from "./ui/ui.module";
import { ValidationRulesModule } from './validation-rules/validation-rules.module';
import { DashboardModule1 } from "./alfred/dashboard1.module";
// import { ClientFileFormComponent } from './client-master/components/client-file-form/client-file-form.component';
// import { ClientFileListComponent } from './client-master/components/client-file-list/client-file-list.component';

//import { DualListComponent } from './roles-dual/component/dual-list/dual-list.component';

@NgModule({
  
  declarations: [AppComponent],
  // declarations: [AppComponent, ClientFileFormComponent, ClientFileListComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    AppRoutingModule,
    DashboardModule,
    UiModule,
    AngularFireModule.initializeApp(environment.firebase),
    CoreModule.forRoot(),
    LoaderModule.forRoot(),
    ErrorModule.forRoot(),
    UserModule.forRoot(),
    ValidationRulesModule,
    DashboardModule1
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
