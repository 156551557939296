import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./user/containers/login/login.component";
import { ErrorPageComponent } from "./error/components/error-page/error-page.component";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { OrganizationOpsComponent } from "./dashboard/pages/organization-ops/organization-ops.component";
import { ClientFileComponent } from "./dashboard/pages/client-file/client-file.component";
import { MappingComponent } from "./dashboard/pages/mapping/mapping.component";
import { RulesComponent } from "./dashboard/pages/rules/rules.component";
import { TransformListComponent } from "./dashboard/pages/transform-list/transform-list.component";
import { FileLayoutComponent } from "./dashboard/pages/file-layout/file-layout.component";
import { TransformComponent } from "./dashboard/pages/transform/transform.component";
import { ClientComponent } from "./dashboard/pages/client/client.component";
import { LayoutFileListComponent } from './dashboard/pages/layout-file-list/layout-file-list.component';
import { AuthGuard } from "./user/services/auth.guard";
import { UserComponent } from './dashboard/pages/user/user.component';
import { LookupComponent } from './dashboard/pages/lookup/lookup.component';
import { RolesComponent } from './dashboard/pages/roles/roles.component';
import { ChangeLogComponent } from './dashboard/pages/change-log/change-log.component';
import { LookupActionsComponent } from './dashboard/pages/lookup-actions/lookup-actions.component';
import {WorkflowSetupComponent} from './dashboard/pages/workflow-setup/workflow-setup.component'
import { SetupCustomComponent } from './dashboard/pages/setup-custom/setup-custom.component';
import { WorkflowComponent } from './dashboard/pages/workflow/workflow.component';
import {WorkflowJobComponent} from './dashboard/pages/workflow-job/workflow-job.component';
import { ListingComponent } from './dashboard/pages/listing/listing.component'
import { LookupColumnComponent } from './dashboard/pages/lookup-column/lookup-column.component';
import { ClientFileMasterComponent } from './dashboard/pages/client-file-master/client-file-master.component';
import { SetupJobComponent } from './dashboard/pages/setup-job/setup-job.component';
import { DeleteDataComponent } from './dashboard/pages/delete-data/delete-data.component';
import { ClientPortalDataComponent } from './dashboard/pages/client-portal-data/client-portal-data.component';
import { ClientPortalFormDataComponent } from './dashboard/pages/client-portal-form-data/client-portal-form-data.component';
import { ArchiveFileComponent } from './dashboard/pages/archive-file/archive-file.component';
import { CashflowListComponent } from './dashboard/pages/cashflow-list/cashflow-list.component';
import { ValidationRulePageComponent } from './dashboard/pages/validation-rule-page/validation-rule-page.component';
import { AuditHomeComponent } from './dashboard/pages/audit-home/audit-home.component';
import { CleanDataConfigComponent } from './dashboard/pages/clean-data-config/clean-data-config.component';
import { TransformOutputFilesComponent } from "./dashboard/pages/transform-output-files/transform-output-files.component";
import { StatisticsComponent } from "./dashboard/pages/statistics/statistics.component";
import { AlfredComponent } from "./alfred/pages/alfred/alfred.component";
import { WorkflowQueueComponent } from "./dashboard/pages/workflow-queue/workflow-queue.component";
import { WorkflowJobListComponent } from "./dashboard/pages/workflow-job-list/workflow-job-list.component";
import { AddonUnmatchedAcctFilesComponent } from "./dashboard/pages/addon-unmatched-acct-files/addon-unmatched-acct-files.component";
import { SchedulerComponent } from './dashboard/pages/scheduler/scheduler.component';
import { RoleListComponent } from './dashboard/pages/role-list/role-list.component';
import { SchedulerListComponent } from './dashboard/pages/scheduler-list/scheduler-list.component';
import { NotificationsComponent } from './dashboard/pages/notifications/notifications.component';
const routes: Routes = [

  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  {
    path: "org-ops",
    component: OrganizationOpsComponent,
    canActivate: [AuthGuard],
    data: { title: "Organization Ops" },
  },
  {
    path: "client-file",
    component: ClientFileComponent,
    canActivate: [AuthGuard],
    data: { title: "Client File" },
  },
  {
    path: "client-file-master",
    component: ClientFileMasterComponent,
    canActivate: [AuthGuard],
    data: { title: "Client File Master" },
  },
  {
    path: "mapping",
    component: MappingComponent,
    canActivate: [AuthGuard],
    data: { title: "Mapping" },
  },
  {
    path: "rules",
    component: RulesComponent,
    canActivate: [AuthGuard],
    data: { title: "Ingestion Rules" },
  },
  {
    path: "transform",
    component: TransformComponent,
    canActivate: [AuthGuard],
    data: { title: "Transform" },
  },
  {
    path: "transform-list",
    component: TransformListComponent,
    canActivate: [AuthGuard],
    data: { title: "Transform List" },
  },
  {
    path: "file-layout",
    component: FileLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: "File Layout" },
  },
  {
    path: "client",
    component: ClientComponent,
    canActivate: [AuthGuard],
    data: { title: "Client" },
  },
  {
    path: "user",
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { title: "User" },
  },
  {
    path: "external-data",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./external-data/external-data.module").then(
        (m) => m.ExternalDataModule
      ),
  },
  {
    path: "layout-files",
    canActivate: [AuthGuard],
    component: LayoutFileListComponent,
    data: { title: "Layout Files" },
  },

  {
    path: "lookup",
    canActivate: [AuthGuard],
    component: LookupComponent,
    data: { title: "Lookup" },
  },
  {
    path: "lookup-admin",
    canActivate: [AuthGuard],
    component: LookupActionsComponent,
    data: { title: "Lookup Admin" },
  },
  // {
  //   path: "dashboard",
  //   canActivate: [AngularFireAuthGuard],
  //   loadChildren: () =>
  //     import("./dashboard/dashboard.module").then(m => m.DashboardModule)
  // },
  {
    path: "sample-pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  
  {
    path: "role",
    component: RolesComponent,
    canActivate: [AuthGuard],
    data: { title: "Role" },
  },
  {
    path: "change-log",
    component: ChangeLogComponent,
    canActivate: [AuthGuard],
    data: { title: "Logging" },
  },
  {
    path: "setup",
    component: SetupJobComponent,
    canActivate: [AuthGuard],
    data: { title: "Setup" },
  }, 
  {
    path: "clean-data-config",
    component: CleanDataConfigComponent,
    canActivate: [AuthGuard],
    data: { title: "Clean Data Config" },
  },
  {
    path: "setup-custom",
    component: SetupCustomComponent,
    canActivate: [AuthGuard],
    data: { title: "Setup Custom" },
  },
  {
    path: "workflow-job",
    component: WorkflowComponent,
    canActivate: [AuthGuard],
    data: { title: "Workflow Job Logs" },
  },
  {
    path: "workflow",
    component: WorkflowJobComponent,
    canActivate: [AuthGuard],
    data: { title: "Workflow" },
  },
  {
    path: "listing",
    component: ListingComponent,
    canActivate: [AuthGuard],
    data: { title: "Listing" },
  },
  {
  path: "lookup-list",
  component: LookupColumnComponent,
  canActivate: [AuthGuard],
  data: { title: "Lookup List" },
  },
  {
    path: "clean-data",
    component: DeleteDataComponent,
    canActivate: [AuthGuard],
    data: { title: "Clean Data" },
    },

    {
      path: "client-portal",
      component: ClientPortalDataComponent,
      canActivate: [AuthGuard],
      data: { title: "Files" },
    },

    {
      path: "client-portal-form-data",
      component: ClientPortalFormDataComponent,
      canActivate: [AuthGuard],
      data: { title: "Upload Files" },
    },

    {
      path: "archive-file",
      component: ArchiveFileComponent,
      canActivate: [AuthGuard],
      data: { title: "Archived Files" },
    },
    
    {
      path: "scheduler",
      component: SchedulerComponent,
      canActivate: [AuthGuard],
      data: { title: "Scheduler" },
    },

    {
      path: "schedulerlist",
      component: SchedulerListComponent,
      canActivate: [AuthGuard],
      data: { title: "Scheduler" },
    },

    {
      path: "rolelist",
      component: RoleListComponent,
      canActivate: [AuthGuard],
      data: { title: "Role-List" },
    },

    {
      path: "notification",
      component: NotificationsComponent,
      canActivate: [AuthGuard],
      data: { title: "Notification Group" },
    },
    

    {
      path: "cashflow-list",
      component: CashflowListComponent,
      canActivate: [AuthGuard],
      data: { title: "Cashflow Master" },
    },

    {
      path: "validation-rule",
      canActivate: [AuthGuard],
      component: ValidationRulePageComponent,
      data: { title: "Validation Rules" },
    },
    
    {
      path: "audit",
      canActivate: [AuthGuard],
      component: AuditHomeComponent,
      data: { title: "Audit" },
    },

    {
      path: "transform-output-files",
      canActivate: [AuthGuard],
      component: TransformOutputFilesComponent,
      data: { title: "Files" },
    },
    {
      path:"statistics",
      canActivate:[AuthGuard],
      component:StatisticsComponent,
      data:{ title: "Statistics"}
    },
    {
      path:"addon-unmatched-acct-files",
      canActivate:[AuthGuard],
      component:AddonUnmatchedAcctFilesComponent,
      data:{ title: "Addon unmatched acct number"}
    },
    {
      path: "regression",
      loadChildren: () =>
        import("./regression/regression.module").then((m) => m.RegressionModule),
    },
    {
      path: "selfServicePortal",
      loadChildren: () =>
      import("./selfServicePortal/selfServicePortal.module").then((m) => m.SelfServicePortalModule),
      canActivate: [AuthGuard]

    },
    {
      path: "alfred",
      component: AlfredComponent,
      data: { title: "Alfred" },
      canActivate: [AuthGuard],
    },
    {
      path: "workflow-queue",
      component: WorkflowQueueComponent,
      data: { title: "Queue" },
      canActivate: [AuthGuard],
    },
    {
      path: "workflow-job-list",
      component: WorkflowJobListComponent,
      data: { title: "Job List" },
      canActivate: [AuthGuard],
    },


  { path: "**", component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
