import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  RulesModel,
  RuleTypesModel
} from "src/app/dashboard/models/rules.model";
import { environment } from "src/environments/environment";
import { TemplateModel } from "src/app/dashboard/models/template.model";
import { RuleTemplateVersion } from 'src/app/dashboard/models/ruleTemplate.model';

@Injectable()
export class RulesService {
  constructor(private http: HttpClient) {}
  /**
   * @param  {number} id
   */
  getIngestionRulesById(id: number) {
    return this.http.get<RulesModel[]>(
      `${environment.baseUrl}/ingestRule/getAllClientRules/${id}`
    );
  }
  getAllRulesType() {
    return this.http.get<RuleTypesModel[]>(
      `${environment.baseUrl}/ingestRule/getAllRulesType`
    );
  }
  getAllTemplates() {
    return this.http.get<TemplateModel>(
      `${environment.baseUrl}/templaterule/getalltemplate`
    );
  }
  getRawData(clientId, fileId) {
    const data = { clientApplicationFileId: fileId, clientId: clientId ,requestFrom:"raw"};
    return this.http.post(
      `${environment.baseUrl}/ingestRule/rawDataUpload`,
      data
    );
  }
  getIngestionRuleByRuleId(id) {
    return this.http.get(
      `${environment.baseUrl}/ingestRule/findbyingestrule/${id}`
    );
  }

  saveRule(ruleCreateForm,clientId){

    return this.http.post(
      `${environment.baseUrl}/ingestRule/saveRule/${clientId}`,ruleCreateForm
    );
   }

   applyRule(clientFileModel){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/dataTransformation`,clientFileModel);  
   }

   deleteIngestRule(clientFileModel){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/deleteRuleById`,clientFileModel);
  
  }

  getAllIngestRules() {
    return this.http.get<RulesModel[]>(
      `${environment.baseUrl}/ingestRule/getAllIngestRules`
    );
  }

  saveTemplate(arry,id){

    return this.http.post(
      `${environment.baseUrl}/ingestRule/mapRuleWithClientFile/${id}`,arry);
  }

  saveDataForTemplate(itemsList){	
    return this.http.post(
    `${environment.baseUrl}/templaterule/savetemplateversiondetail`,itemsList);
  	
   }

  getTemplateVersionById(id){	
   
    return this.http.get<RuleTemplateVersion[]>(
      `${environment.baseUrl}/templaterule/findtemplateversion/${id}`
    );
     
  }	

  getRulesByVersionId(id){
    return this.http.get<RulesModel[]>(
      `${environment.baseUrl}/templaterule/getversiondetail/${id}`
    );  
  }

  saveClientFileIngestRule(clientFileIngestRule){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/saveClientFileIngestRule`,clientFileIngestRule);
  }

  getListOfUniqueDate(id){
    return this.http.get<any[]>(
      `${environment.baseUrl}/loans/getUniqueDates/${id}`
    );  
  }


  mapLibraryRuleToClientFile(ruleCreateForm,fileId){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/mapLibraryRuleToClientFile/${fileId}`,ruleCreateForm
    );
  }

  getAllLibrarayRules() {
    return this.http.get<RulesModel[]>(
      `${environment.baseUrl}/ingestRule/getAllLibrarayRules`
    );
  }

  getIngestionRuleLibraryByRuleId(id) {
    return this.http.get(
      `${environment.baseUrl}/ingestRule/findingestruleLibrarayById/${id}`
    );
  }

  deleteIngestRuleLibrary(clientFileModel){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/deleteIngestRuleLibraryById`,clientFileModel);
  
  }

  deactivateIngestRule(data){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/deactivateRuleById`,data);
  
  }

  getRulesAsCSV(clientApplicationFileId, fileName){
    const data = { ingestRuleTypeId: clientApplicationFileId, fileName: fileName };
    return this.http.get(
    `${environment.baseUrl}/ingestRule/downloadRulesByClientApplicationFileId/${clientApplicationFileId}`
    );
    //return this.http.get("/assets/rules.json");
  }

  downloadFile(data, filename = 'data') {
    console.log("Again", data);
    let csvData = this.convertToCSV(data, ['Rule Name', 'Definition', 'Rule Type', 'Display Name', 'Applies To', 'Sequence No']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    console.log("Here Is An Array", array);
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
}
