import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  router$: Subscription;
  hideHeaders: boolean;
  constructor(private router: Router) {
    this.hideHeaders = true;
  }
  ngOnInit() {
    this.router$ = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === "/login" || event.url === "/" || event.url.startsWith("/login")) {
          this.hideHeaders = true;
        } else {
          this.hideHeaders = false;
        }
      }
    });
  }
  ngOnDestroy() {
    this.router$.unsubscribe();
  }
}
