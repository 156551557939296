import { Component, OnInit,EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-client-file-list',
  templateUrl: './client-file-list.component.html',
  styleUrls: ['./client-file-list.component.scss']
})
export class ClientFileListComponent implements OnInit {
  @Input() data = [];
  @Input() rolePermission;
  @Input() hidebutton:boolean;
  @Output() edit=new EventEmitter();
  @Output() delete= new EventEmitter();
  visible=false;
  dataValue:any;

  cols: { field: string; header: string }[];
  constructor() { 
    this.cols = [
      { field: "name", header: "Name" },
      {field:"fileNamePrefix",header:"File Name Prefix"},
      { field: "desc", header: "Description" },
      { field: "fileTypeValue", header: "File Type" },
      { field: "fileDataTypeValue", header: "File Data Type" },
      { field: "frequency", header: "Frequency" }

    ];
  }

  ngOnInit(): void {
  }

  editClientMasterData(data){
    console.log("hello",data);
    this.edit.emit(data);

  }

  actionDo(data){
    // console.log('table data ',data)
     //  console.log("###printing child delete variable comp"+data.tableName);
     this.delete.emit(this.dataValue);
     this.dataValue=null;
    this.visible=false;
   }

   hidePopup(e){
    this.dataValue=null;
    this.visible=false;

  }

  showPopup(data){
    this.dataValue=data;
    this.visible=true;
  }
}


