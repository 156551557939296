import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { FieldLayoutModel } from "src/app/dashboard/models/file-layout.model";
import {isEmpty,trim} from 'lodash';

@Component({
  selector: "app-fields-layout-table",
  templateUrl: "./fields-layout-table.component.html",
  styleUrls: ["./fields-layout-table.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FieldsLayoutTableComponent implements OnInit {

  @ViewChild('searchField') inputName1; // accessing the reference element
  @ViewChild('searchType') inputName2; 
  @ViewChild('dt') tableData;

  @Input() data: FieldLayoutModel[] = [];
  @Input() hideButton:boolean;
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Input() visible=false;
  @Input() rolePermission;
  dataValue:any;
  cols = [];
  constructor() {
    this.cols = [
      { header: "field name", field: "fieldName" },
      { header: "type", field: "fieldType" },
      { header: "format", field: "fieldFormat" },
      { header: "field size", field: "fieldSize" }
    ];
  }

  ngOnInit(): void {}
  editField(data) {
    this.edit.emit(data);
  }

  hidePopup(e){
    this.visible=false;

  }

  showPopup(data){
    this.dataValue=data;
    this.visible=true;
  }
 
  actionDo(){
    //console.log("yes delete do "+this.dataValue);
    this.deleteField(this.dataValue)
    this.dataValue=null;
    this.visible=false;
     
  }
  deleteField(data) {
   // console.log(data);
    this.delete.emit(data);
  }

  resetSearch(){
    //console.log("reset search")
    const value1=this.inputName1.nativeElement.value;
    const value2=this.inputName2.nativeElement.value;
    // this.inputName1.nativeElement.value ="";
    // this.inputName2.nativeElement.value ="";
    this.tableData.reset();
   // console.log("Value1 ",value1," hello ",this.inputName1.nativeElement.value)
   if(!isEmpty(trim(value1))){
    this.tableData.filter(value1,'fieldName', 'contains');
  }
    
    if(!isEmpty(trim(value2))){
      this.tableData.filter(value2,'fieldType', 'contains');
    }
    
    //  this.inputName1.nativeElement.value =value1;
    
  }

 
 
}
