import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import {LookupColumnListComponent} from "../lookupColumn/components/lookup-column-list/lookup-column-list.component";
import { LookupColumnSelectorComponent } from './components/lookup-column-selector/lookup-column-selector.component';


@NgModule({
  declarations: [

    LookupColumnListComponent,
    LookupColumnSelectorComponent
  ],
  imports: [SharedModule],
  exports: [
    LookupColumnListComponent,
    LookupColumnSelectorComponent 
  ],
  providers: []
})
export class LookupColumnModule {}
