import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { WorkflowService } from '../../services/workflow.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { CookieService } from "ngx-cookie-service";
import { FileService } from 'src/app/files/services/file.service';
import { ClientService } from '../../services/client.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SchedulerLogModel } from '../../models/schedulerLog.model';
import { DatePipe } from "@angular/common";
import { currentMonthRange, dateBetween } from "src/app/utils/dates";
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-scheduler-list',
  templateUrl: './scheduler-list.component.html',
  styleUrls: ['./scheduler-list.component.scss']
})
export class SchedulerListComponent implements OnInit {
  activeRoute$: Subscription;
  schedulerList: any = [];
  editSchedulerValue: any = [];
  viewMode: boolean = false;
  editMode: boolean = false;
  rolePermission:any;
  clientData:any;
  workflowData:any;
  headerForm: FormGroup = null;
  logData:SchedulerLogModel[];

  constructor(private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private toast: ToastService,
    private router: Router,
    private cookieService: CookieService, private fileService: FileService,private clientService:ClientService,private datePipe: DatePipe) { 


      this.headerForm = new FormGroup({
        client: new FormControl(null, [Validators.required]),
        //dateRange: new FormControl(currentMonthRange(), [Validators.required]),
        fromDate: new FormControl(null, [Validators.required]),
        toDate: new FormControl(null, [Validators.required]),
      });
    }

  ngOnInit(): void {
   this.buttonShowHidePermission();
  }



  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  // getWorkFlowScheduler() {

  //   this.workflowService.getScheduler().subscribe(
  //     (data) => {
  //       this.schedulerList = data;
  //       // this.toast.showSuccess(' successfully','success');
  //     },
  //     (err) => {
  //       this.toast.showError(err.error.message, "error");
  //     }



  //   );
  // }

  editScheduler(data: any) {
    // this.getClient(data.clientId);
    // this.getWorkflowById(data);
    this.viewMode = false;
    this.editMode = true;
    this.cookieService.set('schedulerId', data.schedulerId);
    this.cookieService.set('viewMode', this.viewMode.toString());
    this.cookieService.set('editMode', this.editMode.toString());
     const fileData = {
      clientId: data.clientId,
      clientName:data.clientName,
      workflowId:data.workflowId,
      workflowName:data.workflowName
     };
     let page = 'scheduler';
     this.router.navigate([page], {
      queryParams: {...fileData},
    });
  }


  viewScheduler(data: any) {
    // this.getClient(data.clientId);
    // this.getWorkflowById(data);
    this.viewMode = true;
    this.editMode = false;;
    this.cookieService.set('schedulerId', data.schedulerId);
    this.cookieService.set('viewMode', this.viewMode.toString());
    this.cookieService.set('editMode', this.editMode.toString());
    const fileData = {
      clientId: data.clientId,
      clientName:data.clientName,
      workflowId:data.workflowId,
      workflowName:data.workflowName
     };
    let page = 'scheduler';
    this.router.navigate([page], {
      queryParams: {...fileData},
    });
  }


  navigate() {
    this.cookieService.delete('schedulerId');
    this.cookieService.delete('viewMode');
    this.cookieService.delete('editMode');
    let page = 'scheduler';
    if((this.cookieService.get('clientId')==='') || (this.cookieService.get('clientId')===undefined)){
    this.router.navigate([page], {
      queryParams: {},
    });
  }else{
    const fileData = {
      clientId:this.cookieService.get('clientId'),
      clientName:this.cookieService.get('clientName'),
     };
    this.router.navigate([page], {
      queryParams: {...fileData},
    });
  }
  }



  setStatus(data) {

    const setdata = {
      isActive: data.isActive,
      schedulerId:data.schedulerId
    };

    this.workflowService.setSchedulerStatus(setdata).subscribe(
      (data) => {


       // this.getWorkFlowScheduler();
       this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        this.toast.showSuccess("Done", "successfully");
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );

  }


  deleteScheduler(data) {
    console.log("data  ", data)
    this.workflowService.deleteScheduler(data.schedulerId).subscribe(
      (data) => {


        //this.getWorkFlowScheduler();
        this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        this.toast.showSuccess("Delete", "successfully");
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }

    );
  }


  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.setTitle();
      this.loadDate();
      this.getPreviousState();
      
    }

    getClient(clientId){
      this.clientService.getClientsById(clientId).subscribe((data) => {
        this.clientData=data;
        const mapVal={
          "clientId":this.clientData.clientId,
          "clientName":this.clientData.clientName
        }
      //   this.headerForm.controls.client.setValue(mapVal);
       
        },
      (err) =>{
        this.toast.showError(err.error.message, "error");
       } 
      ); 
      }

      getWorkflowById(data){
        const workflowModelConst={ 
          "workflowId":data.workflowId,
         }
        this.workflowService.getWorkflowById(workflowModelConst)
        .subscribe(
          data=>{
             this.workflowData=data;
          },
          err=>{
         //   this.workflowJobListEdit=data;
          }
        )
      }


      onClientChange(event){
        console.log(this.headerForm.controls.client.value.key,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        this.getSchedulerData(event, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
          this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
          this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
        //this.maintainClientState(event,this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value);
      }


      changeToDate(e){
        this.headerForm.controls.toDate.setValue(e);
        console.log(this.headerForm.controls.toDate.value)
        let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        if(validate===true){
          // this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
          this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
          this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
        this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
        if(validate===false){
          this.toast.showWarn('error','date range incorrect')
          
        }
        //console.log(e,'  todate');
        //this.maintainStateOnDateChange(e,'toDateChange');
      }
    
      changeFromDate(e){
    
        this.headerForm.controls.fromDate.setValue(e);
        console.log( this.headerForm.controls.fromDate.value)
        let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        if(validate===true){
          this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
          this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
           this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
        if(validate===false){
         // this.toast.showWarn('error','date range incorrect')
          
        }
        //this.maintainStateOnDateChange(e,'fromDateChange');
      }

      validationOnDate(fromdate,todate){
        if(fromdate && todate){
        if(new Date(fromdate)>new Date(todate)){
        
            return false;
          }
          return true;
          
        }else{
          return false;
        }
      }


      getSchedulerData(client,fromDate,toDate){

        
       this.workflowService.getSchedulersByClientId(client.key, fromDate,toDate).subscribe(
          data=>{
             this.schedulerList=data;
            //  this.schedulerList.forEach(item=>{
            //   item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
            //   item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
            
            //  })
          },
          err=>{
         //   this.workflowJobListEdit=data;
            this.toast.showError(err.error.message, "error");
          }
        )


      }

      getLogData(data){
        this.workflowService.getSchedulerLogData(data.schedulerId)
        .subscribe(
          data=>{
             this.logData=data;
             console.log("data"+this.logData);
          },
          err=>{
         //   this.workflowJobListEdit=data;
          }
        )
      }

      getPreviousState() {
        const clientIdC=this.cookieService.get('clientId');
        const clientNameC=this.cookieService.get('clientName');
        //console.log("cookies check ",this.cookieService.get('toDaaaate')===null)
        //console.log("cookies check ",this.cookieService.get('toDaaaate'))
        // console.log(clientIdC);
        // console.log(clientNameC);
        // console.log("from date ",fromDatec);
        // console.log("todate ",toDatec);
        if(clientIdC!==undefined && clientIdC!==null){
          this.headerForm.get("client").patchValue({
                   key: clientIdC,
                   value: clientNameC,
                   });
                  
        }

        if(!isEmpty(this.headerForm.get("client").value.key)){
          this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
       
      }



      loadDate(){
        let currentDateRange=currentMonthRange();
        //console.log(currentDateRange,'  current date range');
        this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
        this.headerForm.controls.toDate.setValue(currentDateRange[1]);
       //console.log(this.headerForm.controls.fromDate.value,'  current date range ',this.headerForm.controls.toDate.value);
        
      }
      
}
