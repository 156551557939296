import { Component, OnInit, Input ,EventEmitter, Output} from '@angular/core';


@Component({
  selector: 'app-workflow-joblogs-list',
  templateUrl: './workflow-joblogs-list.component.html',
  styleUrls: ['./workflow-joblogs-list.component.scss']
})
export class WorkflowJoblogsListComponent implements OnInit {
  @Input() hidebutton:boolean;
  @Input() data;
  @Input() logHeader;
  @Output() viewWorkflowIngestJobLog=new EventEmitter();
  @Input() permission:any;
  
  cols: { field: string; header: string }[];

  constructor() { 
    this.cols = [

      { field: "jobId", header: "Job Id" },
      { field: "jobType", header: "Job Type" },
      { field: "paramValues", header: "Param Values" },
      { field: "status", header: "Status" },
      { field: "lastJobStatus", header: "Last Job Status" },
      { field: "message", header: "Message" },
      { field: "createdAt", header: "Created At" },
      
    ];

  }

  ngOnInit(): void {
  }

  viewWorkflowIngestJobLogs(data){

    const parameters={
      "data":data,
      "toDate":null,
      "fromDate":null

    }
    console.log("in Table2 onButtonClick ########",data);
    this.viewWorkflowIngestJobLog.emit(parameters);

  }

}
