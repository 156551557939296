import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs'
import { DataTransferModel, DataTransferModel1 } from '../models/dataTransfer.model'

@Injectable({
  providedIn: 'root'
})
export class ObservalbleDataService {

  dataTransferModel:DataTransferModel={
    clientFileId:0,
    clientId:0,
    isRedirected:0,
    applicationId:0,
    toDate:'',
    fromDate:'',
    clientName:'',
    page:'',
    fileName:'',
    workflowName:'',
    workflowId:0,
    dataType:'',
    dataTypeId:0
}

dataTransferModel1:DataTransferModel1={
  clientFileId:0,
  clientId:0,
  isRedirected:0,
  applicationId:0,
  toDate:'',
  fromDate:'',
  clientName:'',
  page:'',
  fileName:'',
  workflowName:'',
  workflowId:0
}

  private inventorySubject$=new BehaviorSubject<DataTransferModel>(this.dataTransferModel);
  inventoryChanged$=this.inventorySubject$.asObservable();

  private inventorySubject$$=new BehaviorSubject<DataTransferModel1>(this.dataTransferModel1);
  inventoryChanged$$=this.inventorySubject$$.asObservable();

  constructor() { }

  addToInventory(dataTransferModelData:DataTransferModel){
    this.dataTransferModel=dataTransferModelData;
    this.inventorySubject$.next(dataTransferModelData);
  }

  addToInventoryData(dataTransferModelData1:DataTransferModel1){
    this.dataTransferModel1=dataTransferModelData1;
    this.inventorySubject$$.next(dataTransferModelData1);
  }
}
