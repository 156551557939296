import { Component, Input, OnInit, Output ,EventEmitter, SimpleChanges} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CleanDataConfigModel } from 'src/app/dashboard/models/cleanDataConfig.model';

@Component({
  selector: 'app-clean-data-config-selector',
  templateUrl: './clean-data-config-selector.component.html',
  styleUrls: ['./clean-data-config-selector.component.scss']
})
export class CleanDataConfigSelectorComponent implements OnInit {

  // constructor() { }

  // ngOnInit(): void {
  // }

  cleanDataConfigListEdit:CleanDataConfigModel;
  selectorForm: FormGroup = null;
  @Input() editCleanDataConfig;
  @Output() saveCleanDataConfig = new EventEmitter();
  @Input() permission:any;
  enableEdit=false;

  constructor() { 

    this.selectorForm = new FormGroup({
      fileName: new FormControl(null),
      startIndex: new FormControl(null),
      length: new FormControl(null)
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
       
    if (changes && changes.editCleanDataConfig && changes.editCleanDataConfig.currentValue) {
     console.log(changes.editCleanDataConfig.currentValue,' inside changes') 
       const lookUp=changes.editCleanDataConfig.currentValue;
       this.enableEdit = true;
       this.checkForEdit() 
    }
 
    }
    checkForEdit() {

      if (this.editCleanDataConfig && this.enableEdit) { 

        this.selectorForm.patchValue({
          fileName: this.editCleanDataConfig.fileName,
          startIndex: this.editCleanDataConfig.startIndex,
          length:this.editCleanDataConfig.length ,
          // createdAt:this.editCleanDataConfig.createdAt
        });

      

        this.enableEdit = false;
       
      }
    }

  saveData(){

    
    const data={
      "fileName":this.selectorForm.controls.fileName.value,
      "startIndex":this.selectorForm.controls.startIndex.value,
      "length":this.selectorForm.controls.length.value,
    }
    console.log("child data emitted"+data);
    this.saveCleanDataConfig.emit(data);


  }

  resetForm(){
    this.selectorForm.reset();
    this.cleanDataConfigListEdit=null;
  }
}
