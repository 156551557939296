import { Component, OnInit, ViewChild } from '@angular/core';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { DatePipe } from "@angular/common";
import { NotificationSelectorComponent }  from 'src/app/notificationComp/components/notification-selector/notification-selector.component';
import { NotificationModel } from '../../models/notification.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @ViewChild(NotificationSelectorComponent, { read: false, static: false })
  notificationSelectorComponent:NotificationSelectorComponent;

  activeRoute$: Subscription;
  notificationList:any[];
  editNotificationList:NotificationModel=null;
  rolePermission:any;


  constructor(private fileService: FileService, 
    private toast:ToastService,
    private loader:LoaderService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private datePipe: DatePipe) {}

  ngOnInit(): void {

    this.buttonShowHidePermission();

    

    // console.log("editNotificationList   ",this.editNotificationList)
    // console.log("editNotificationList.notificationId  =",this.editNotificationList.notificationId)

  }


  getAllNotifications(){
   
    this.fileService.getAllNotificationType().subscribe(
      (data) => {
        console.log("notification",data);
        this.notificationList=data;
        this.notificationList.forEach(item=>{
          item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
          item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
        
         })
       // this.loader.hideLoader();
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  

    )

  }


  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }



  addNotification(data){

    let userStatus=0;
    if(data.statusMap.key==="Active"){
      userStatus=1;
    }

  const notificationData={
    notificationId:this.editNotificationList!==null?this.editNotificationList.notificationId:0,
    notificationName:data.notificationName,
    status:userStatus
  }

  this.fileService.saveAllNotification(notificationData).subscribe(
    (data) => {
      
     // this.loader.hideLoader();
     this.toast.showSuccess('saved successfully','success');
     this.notificationSelectorComponent.resetForm();
     this.editNotificationList=null;
     this.getAllNotifications();
    },
    (err) =>{
      this.toast.showError(err.error.message, "error");
    }  


  )

  console.log("notificationData  ",notificationData)


  }



  editNotificationData(data){

    this.fileService.getNotificationById(data.notificationId).subscribe(

      (data :NotificationModel) => {
        
      this.editNotificationList=data;  
      console.log("editData    ",data)
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  
    );
  }

  resetNotification(){

    this.notificationSelectorComponent.resetForm();
    this.editNotificationList=null;

  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};

    callNgOnInitMethods(){
      this.setTitle();
      this.getAllNotifications();

    }


}
