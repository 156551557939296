import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientPortalService {
/**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http:HttpClient,
    private datePipe:DatePipe) { }
  getAllclientPortalData(clientData){
    return this.http.post<any[]>(environment.baseUrl + "/apis/client-portal/getAllclientPortalData",clientData);
  }

    saveClientFile(file,clientFile){
      //console.log(file);
      console.log(file);
      const formData = new FormData();
      for(let i=0;file.length>i;i++){
      formData.append('file',file[i]);
      }
     // formData.append('fileData',file[1]);
     if(clientFile.controls.client.value>0){
      formData.append('clientId',clientFile.controls.client.value);
    }else{
      formData.append('clientId',clientFile.controls.client.value.key);
    }
      formData.append('asOfDate',this.datePipe.transform(clientFile.controls.asOfDate.value,'yyyy-MM-dd'));
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/save`,formData
      );

    }

    saveZipClientFile(file,clientFile){
      //console.log(file);
      //console.log(file);
       const formData = new FormData();
      for(let i=0;file.length>i;i++){
      formData.append('file',file[i]);
      }
     // formData.append('fileData',file[1]);
    // formData.append('file',file)
     if(clientFile.controls.client.value>0){
      formData.append('clientId',clientFile.controls.client.value);
    }else{
      formData.append('clientId',clientFile.controls.client.value.key);
    }
      formData.append('asOfDate',this.datePipe.transform(clientFile.controls.asOfDate.value,'yyyy-MM-dd'));
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/saveZip`,formData
      );

    }


    updateClientFile(data){
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/updateClientFile`,data
      );
    }

    updateClientFilMaster(data){
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/updateClientFileMaster`,data
      );
    }

    deleteFileLayout(data){
     
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/deleteLayoutAndMaster`,data
      );
    }

}
