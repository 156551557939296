import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

  @Input() ctrl: FormControl;
  @Input() label: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() multiline: boolean;
  @Input() inline: boolean;
  @Input() groupInline: boolean = false;
  @Input() blockLabel = "inline-block";

  placeholderText = "";
  className = "form-group";

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.inline && changes.inline.currentValue) {
      this.className = "form-inline";
    }
    if (changes && !!changes.placeholder && changes.placeholder.currentValue) {
      this.placeholderText = changes.placeholder.currentValue;
    }
    if (
      changes &&
      changes.groupInline &&
      changes.groupInline.currentValue === true
    ) {
      this.className = "form-group form-inline";
    }
    if (
      changes &&
      changes.groupInline &&
      changes.groupInline.currentValue === false
    ) {
      this.className = "form-group";
    }
  }

}
