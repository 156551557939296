import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { DatePipe } from '@angular/common';

@Injectable()
export class ToastService {
  
  lastErrorTime:any=null;

  constructor(private messageService: MessageService,
    private datePipe:DatePipe) {}

  showSuccess(summary: string, detail: string) {
    this.messageService.add({
      severity: "success",
      summary,
      detail
    });
  }

  showInfo(summary: string, detail: string) {

    this.messageService.add({
      severity: "info",
      summary,
      detail
    });
  }

  showWarn(summary: string, detail: string) {

    this.messageService.add({
      severity: "warn",
      summary,
      detail
    });
  }

  showError(summary: string, detail: string) {
    let showError=this.preventDuplicate();
    if(showError){
      this.messageService.add({
        severity: "error",
        summary,
        detail
      });
    }
    
  }

  preventDuplicate(){ 
    if(this.lastErrorTime!==null && this.lastErrorTime!==undefined){
      let currentDate:any=new Date();
     // console.log("cureent ",currentDate)
      //console.log("time diff ",(currentDate-this.lastErrorTime)/1000);
     let timediff=(currentDate-this.lastErrorTime)/1000;
      this.lastErrorTime=new Date();
      if(timediff<1){
        return false;
      }
    }else{
     
      this.lastErrorTime=new Date();
      
    }

   return true;
  }
}
