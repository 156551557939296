import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { SidebarService } from "src/app/core/services/sidebar.service";
import { environment } from "src/environments/environment";
import { TitleService } from "src/app/core/services/title.service";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/user/services/auth.service";
import { FormsModel } from 'src/app/models/forms.model';
import { FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/user/services/user.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  title: string;
  titleService$: Subscription;
  ChangePasswordForm: FormGroup;
  addPasswodPopup=false;
  changePasswordModel: FormsModel = {
    controls: [
      {
        name: "oldPassword",
        type: "password",
        validators: [Validators.required],
        label: "Old Password",
      },
      {
        name: "newPassword",
        type: "password",
        validators: [Validators.required,Validators.minLength(3),Validators.maxLength(15)],
        label: "New Password",
      },
      {
        name: "confirmPassword",
        type: "password",
        validators: [Validators.required],
        label: "Confirm Password",
      },
    ],
  };
  
  
  constructor(
    private sidebar: SidebarService,
    private titleService: TitleService,
    private authService: AuthService,
    private userService: UserService,
    private toast: ToastService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.titleService$ = this.titleService.titleObservable.subscribe(
      title => (this.title = title),
      err => console.log("error", err)
    );
  }
  ngOnDestroy() {
    this.titleService$.unsubscribe();
  }
  signOut(){
     this.cookieService.deleteAll();
     this.authService.signOut();
  }

  toggleStatus(show: boolean) {
    this.sidebar.toggleVisibility(show);
  }
  showPopup() {
    this.addPasswodPopup = true;
  }

  doAction(type: string) {
    if (type.toLowerCase() === "yes") {
      this.ChangePasswordForm.reset();
    }
    this.addPasswodPopup = false;
    this.ChangePasswordForm.reset();
  }
  getForm(form: FormGroup) {
    this.ChangePasswordForm = form;
  }

  changePassword(){
    if(this.ChangePasswordForm.controls.newPassword.value!==this.ChangePasswordForm.controls.confirmPassword.value){
      this.toast.showError("Confirm password must be same as New Password","error");
    }else{
    const userData={
      "newPassword":this.ChangePasswordForm.controls.newPassword.value,
      "oldPassword":this.ChangePasswordForm.controls.oldPassword.value,
    }
    this.userService.changePassword(userData).subscribe(
      (data) => {
       this.addPasswodPopup=false;
       this.toast.showSuccess("Password Change SuccesFully", "success");
       this.ChangePasswordForm.reset();
       this.signOut();
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }
}
}
