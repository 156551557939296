import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-textbox",
  templateUrl: "./textbox.component.html",
  styleUrls: ["./textbox.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TextboxComponent implements OnInit, OnChanges {
  @Input() type: string = "text";
  @Input() ctrl: FormControl;
  @Input() label: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() multiline: boolean;
  @Input() inline: boolean;
  @Input() groupInline: boolean = false;
  caseLabel:string;

  placeholderText = "";
  className = "form-group";

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.inline && changes.inline.currentValue) {
      this.className = "form-inline";
    }
    if (changes && !!changes.placeholder && changes.placeholder.currentValue) {
      this.placeholderText = changes.placeholder.currentValue;
    }
    if (
      changes &&
      changes.groupInline &&
      changes.groupInline.currentValue === true
    ) {
      this.className = "form-group form-inline";
    }
    if (
      changes &&
      changes.groupInline &&
      changes.groupInline.currentValue === false
    ) {
      this.className = "f-10 form-group no-padding pl-2 pr-4 pt-3";
    }
  }

  onTextChange(e) {
  if(this.label!==undefined){
    this.caseLabel=this.label.toLowerCase();
    if(!this.caseLabel.includes('date')){
     this.ctrl.setValue(e.target.value)

     
     }
     console.log("lowercase ",this.caseLabel.includes('date'));

   }
  }
  
   onChange(e){
    this.caseLabel=this.label.toLowerCase(); 
   if(this.caseLabel.includes('date')){
    this.ctrl.setValue(e.target.value)
   
     }

   }


}
