import { Component, OnInit, Input, Output,EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { WorkflowJobDetails } from 'src/app/dashboard/models/workflow.model';


@Component({
  selector: 'app-setup-selector',
  templateUrl: './setup-selector.component.html',
  styleUrls: ['./setup-selector.component.scss']
})
export class SetupSelectorComponent implements OnInit {
  @Input() ingestJob;
  @Input() jobTypeList;
  @Input() editWorkflowJobDetails:WorkflowJobDetails;
  @Output() jobTypeOut=new EventEmitter();
  @Output() saveJobToWorkflow=new EventEmitter();
  enableEdit=false;
 // frequencyType:[]=[];
  setupForm: FormGroup = null;
  constructor() { 
    this.setupForm = new FormGroup({
      job: new FormControl(null),
      jobType: new FormControl(null),
      description: new FormControl(null),
      trigger1: new FormControl(null),
      dependentUpon: new FormControl(null),
      nextJob:new FormControl(null),
      enabled: new FormControl(null),
      target: new FormControl(null),
      custom: new FormControl(null),
      frequency: new FormControl(null)
    });

  
  }

  ngOnInit(): void {
  }

  frequencyType= [
    { key: 'Daily', value: "Daily" },
    { key: 'Weekly', value: "Weekly" },
    { key: 'Monthly', value: "Monthly" },
    { key: 'Quarterly', value: "Quarterly" },
    { key: 'Semi Annually', value: "Semi Annually" },
    { key: 'Annually', value: "Annually" },
    
  ];

  // jobTypeList=[
  //   { key: 'Ingest', value: "Ingest" },
  //   { key: 'Transform', value: "Transform" },
  //   { key: 'Output', value: "Output" },
  //   { key: 'Custom', value: "Custom" }
  // ]

  triggerList=[
    { key: 'Automatic', value: "Automatic" },
    { key: 'Manual', value: "Manual" }
  ]

  customList=[
    { key: '1', value: "Yes" },
    { key: '0', value: "No" }
  ]

  onJobTypeChange(){
    console.log("value before out ",this.setupForm.controls.jobType.value)
    const val1=this.setupForm.controls.jobType.value;
    console.log(val1)
    if(val1!==null && val1!==undefined && val1.key!==undefined ){
    this.jobTypeOut.emit(val1);
    }
  }

  saveWorkflowJob(){
    const data={
      "jobType":this.setupForm.controls.jobType.value.key,
      "jobId":this.setupForm.controls.job.value.key,
      "description":this.setupForm.controls.description.value,
      "trigger1":this.setupForm.controls.trigger1.value.key,
      "dependentUpon":0,
      "nextJob":0,
      "enabled":this.setupForm.controls.enabled.value,
      "target":this.setupForm.controls.target.value,
      "custom":0,
      "frequency":"Weekly"
     
    }

    this.saveJobToWorkflow.emit(data);
  }

  ngOnChanges(changes: SimpleChanges) {
       
    if (changes && changes.editWorkflowJobDetails && changes.editWorkflowJobDetails.currentValue) {
       console.log(changes.editWorkflowJobDetails.currentValue,' inside changes') 
       const lookUp=changes.editWorkflowJobDetails.currentValue;
       this.enableEdit = true;
       this.checkForEdit() 
    }
 
    }
    checkForEdit() {

      if (this.editWorkflowJobDetails && this.enableEdit) { 

        this.setupForm.patchValue({
          jobType:{key:this.editWorkflowJobDetails.jobType,value:this.editWorkflowJobDetails.jobType},
          job:{key:this.editWorkflowJobDetails.jobId,value:this.editWorkflowJobDetails.jobName},
          description:this.editWorkflowJobDetails.description,
          trigger1: {key:this.editWorkflowJobDetails.trigger1,value:this.editWorkflowJobDetails.trigger1},
          dependentUpon: {key:this.editWorkflowJobDetails.dependentUponId,value:this.editWorkflowJobDetails.dependentUpon},
          nextJob:{key:this.editWorkflowJobDetails.nextJobId,value:this.editWorkflowJobDetails.nextJob},
          enabled: this.editWorkflowJobDetails.enabled==='Yes'?1:0,
          target: this.editWorkflowJobDetails.target,
          custom: {key:0,value:'No'},
          frequency: {key:this.editWorkflowJobDetails.frequency,value:this.editWorkflowJobDetails.frequency},
         
        });

      

        this.enableEdit = false;
       
      }
    }

}
