import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { LookupService } from '../dashboard/services/lookup.service';
import { LookupListComponent } from './components/lookup-list/lookup-list.component';
import { LookupSelectorComponent } from './components/lookup-selector/lookup-selector.component';


@NgModule({
  declarations: [
    LookupListComponent,
    LookupSelectorComponent
  
  ],
  imports: [SharedModule],
  exports: [
    LookupListComponent,
    LookupSelectorComponent
   
  ],
  providers: [LookupService]
})
export class LookUpTableModule {}
