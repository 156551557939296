import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api/menuitem";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable()
export class MenuService {
  constructor(private http: HttpClient) {}

  // getMenuItems() {
  //   return this.http.get<MenuItem[]>("/assets/menu.json");
  // }

  getMenuItems() {
       return this.http.get<MenuItem[]>(`${environment.baseUrl}/apis/menu/getAllMenu`);
     }
  
  getClientMenuItems() {
    return this.http.get<MenuItem[]>("/assets/client-menu.json");
  }
}
