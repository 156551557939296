import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-output-jobs',
  templateUrl: './output-jobs.component.html',
  styleUrls: ['./output-jobs.component.scss']
})
export class OutputJobsComponent implements OnInit {

  @Input() data = [];
  
  @Output() editWorkflowJobDetailsData=new EventEmitter();
  @Output() deleteJob=new EventEmitter();
 
  cols: { field: string; header: string }[];
  
    
    
  
  constructor() { 
    this.cols = [
      { field: "jobName", header: "Job" },  
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },
     
      { field: "enabled", header: "Enabled" }
    ];
  }

  ngOnInit(): void {
  }

  editWorkflowJobDetails(data){

    this.editWorkflowJobDetailsData.emit(data);
  }

  deleteIngestJob(data){

    this.deleteJob.emit(data);
  }

}
