import { _isNumberValue } from '@angular/cdk/coercion';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {isEmpty,trim} from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { CleanDataConfigSelectorComponent } from 'src/app/cleanDataConfig/components/clean-data-config-selector/clean-data-config-selector.component';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { currentMonthRange } from 'src/app/utils/dates';
import { CleanDataConfigModel } from '../../models/cleanDataConfig.model';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { CleanDataConfigService } from '../../services/clean-data-config.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';



@Component({
  selector: 'app-clean-data-config',
  templateUrl: './clean-data-config.component.html',
  styleUrls: ['./clean-data-config.component.scss']
})
export class CleanDataConfigComponent implements OnInit {
  @ViewChild(CleanDataConfigSelectorComponent, { read: false, static: false })

  cleanDataConfigSelectorComponent: CleanDataConfigSelectorComponent;
  cleanDataConfigForm: FormGroup = null;
  cleanDataConfigListModel:CleanDataConfigModel[]=[];
  cleanDataConfigListEdit:CleanDataConfigModel;
  activeRoute$: Subscription;
  dataTransferModel: DataTransferModel;
  rolePermission:any;


  constructor(private cleanDataConfigService:CleanDataConfigService,
    private datePipe:DatePipe,
    private toast:ToastService,
    private loader:LoaderService,
    private activeRoute: ActivatedRoute,
    public observalbleDataService: ObservalbleDataService,
    private titleService: TitleService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>, private fileService: FileService) { 
    this.cleanDataConfigForm=new FormGroup({
      cleanDataConfigMap:new FormControl(null) ,
      client:new FormControl(null,[Validators.required]) 
     
    });
  }

  ngOnInit(): void {
  this.buttonShowHidePermission();
  }

  // getAllData(data){
  //   this.loader.showLoader();
  //   this.cleanDataConfigListModel=[];
  //   const cleanDataConfigModel={
  //     "clientId":data
  //     // "clientId":1
  //   }
  //   this.cleanDataConfigService.getAll()
  //   .subscribe(
  //     data=>{
  //       this.loader.hideLoader();
  //         this.cleanDataConfigListModel=data;
  //         this.cleanDataConfigListModel.forEach(item=>{
  //           item.createdAt=this.datePipe.transform(item.createdAt,"MM-dd-yyyy")
           
  //           if(item.modifiedAt===null){
  //             item.modifiedAt=this.datePipe.transform(item.createdAt,"MM-dd-yyyy")
  //           }else{
  //             item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM-dd-yyyy")
  //           }
  
  //          })
           
  //     },
  //     err=>{
  //       this.toast.showError('error','error fetching data')
  //       this.loader.hideLoader();
  //     }
  //   )

  // }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

   getClient(){
    const lookupMap={
      "clientId":this.cleanDataConfigForm.controls.client.value.key
    }
    return lookupMap.clientId
    // return lookupMap.map((t) => ({ key: t, value: t }));
  }

  onClientChange(data){
   
    this.cookieService.set('clientId',this.cleanDataConfigForm.controls.client.value.key);
     this.cookieService.set('clientName',this.cleanDataConfigForm.controls.client.value.value);
    this.getcleanDataConfigByClient(data.key);
    this.cleanDataConfigSelectorComponent.selectorForm.reset();
    //this.maintainClientState(data);
      }

   getcleanDataConfigByClient(data){
    this.loader.showLoader();
    this.cleanDataConfigListModel=[];
    const cleanDataConfigModel={
      "clientId":data
      // "clientId":1
    }

    this.cleanDataConfigService.getByClientId(cleanDataConfigModel)
    .subscribe(
      data=>{
        this.loader.hideLoader();
          this.cleanDataConfigListModel=data;
          this.cleanDataConfigListModel.forEach(item=>{
            item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
           
            if(item.modifiedAt===null){
              item.modifiedAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
            }else{
              item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
            }
  
           })
           
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    )

  }




 getSelected(data){
    // this.getcleanDataConfigDataByClient();
    this.cleanDataConfigSelectorComponent.selectorForm.reset();
    this.cleanDataConfigListEdit=null;        
  }

  getcleanDataConfigOnEdit(data){
    console.log('cleanDataConfig data value ',data)
    this.getcleanDataConfigById(data);
  }
  getcleanDataConfigById(data){
    this.loader.showLoader();
    const cleanDataConfigModel={
      // "tableName":this.cleanDataConfigForm.controls.cleanDataConfig.value.key,
      "cleandataId":data.cleandataId
    }

    this.cleanDataConfigService.getById(cleanDataConfigModel)
    .subscribe(
      data=>{
        this.loader.hideLoader();
         this.cleanDataConfigListEdit=data;
      },
      err=>{
        this.toast.showError(err.error.message, "error");
         this.loader.hideLoader();
      }
    )

  }

  saveData(data){
   this.loader.showLoader();


   console.log("main comp data ",data);

    if(data.fileName===null ||data.fileName===undefined ||trim(data.fileName).length<1){
      this.toast.showError('error','File Name mandatory ')
      this.loader.hideLoader();
      return;
    }


    
    if( data.startIndex===null || data.startIndex===undefined ||trim(data.startIndex).length<1||_isNumberValue(data.startIndex)===false || data.startIndex.length>9 ){

      if(data.startIndex===null||data.startIndex===undefined ||trim(data.startIndex).length<1){ 
        this.toast.showError('error','Start Index mandatory')
        this.loader.hideLoader();
        return;
      }
      if(_isNumberValue(data.startIndex)===false ){ 
        this.toast.showError('error','Start Index must be Numeric')
        this.loader.hideLoader();
        return;
      }
      if(data.startIndex.length>9 ){ 
        this.toast.showError('error','Start Index must be Upto 9 digits')
        this.loader.hideLoader();
        return;
      }
      else{
      this.toast.showError('error','Error Saving Start Index ')
      this.loader.hideLoader();
      return;
      }
    }


    if( data.length===null ||data.length===undefined ||_isNumberValue(data.length)===false || data.length.length>4 ||trim(data.length).length<1){
      if(data.length===null||data.length===undefined ||trim(data.length).length<1){ 
        this.toast.showError('error','Length mandatory ')
        this.loader.hideLoader();
        return;
      }
      if(_isNumberValue(data.length)===false ){ 
        this.toast.showError('error','Length must be Numeric')
        this.loader.hideLoader();
        return;
      }
      if(data.length.length>4){ 
        this.toast.showError('error','Length must be Upto 4 digits')
        this.loader.hideLoader();
        return;
      }
      else{
      this.toast.showError('error','Error Saving Length')
      this.loader.hideLoader();
      return;
      }
    }

    const cleanDataConfigModel={
      "clientId":this.cleanDataConfigForm.controls.client.value.key,
      "cleandataId":this.cleanDataConfigListEdit!==null&&this.cleanDataConfigListEdit!==undefined?this.cleanDataConfigListEdit.cleandataId:0,

      // "cleandataId":this.cleanDataConfigListEdit.cleandataId,
      "fileName":data.fileName,
      "startIndex":data.startIndex,
      "length":data.length,
    }
    if(cleanDataConfigModel.clientId===0){
      this.toast.showError('error','Select Client');
    }else{
    this.cleanDataConfigService.saveData(cleanDataConfigModel)
    .subscribe(
      data=>{
        this.loader.hideLoader();
        this.toast.showSuccess('success','data saved successfully');
        this.resetForm();
        this.getcleanDataConfigByClient(this.cleanDataConfigForm.controls.client.value.key);
             
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    )
    }
  }

  deleteById(data){
    
    this.loader.showLoader();
    console.log("delete id :",data);
    const cleanDataConfigModel={
      "cleandataId":data.cleandataId,
      // "cleandataId":this.cleanDataConfigListEdit!==null&&this.cleanDataConfigListEdit!==undefined?this.cleanDataConfigListEdit.cleandataId:0,
      // "fileName":data.fileName,
      // "startIndex":data.startIndex,
      // "length":data.length,
    }

    this.cleanDataConfigService.deleteById(cleanDataConfigModel)
    .subscribe(
      data=>{

        this.loader.hideLoader();
        this.toast.showSuccess('success','data deleted successfully');
        this.resetForm();
        this.getcleanDataConfigByClient(this.cleanDataConfigForm.controls.client.value.key);
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    )
  }

  resetForm(){
    this.cleanDataConfigSelectorComponent.selectorForm.reset();
    this.cleanDataConfigListEdit=null;
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
   
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.cleanDataConfigForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               this.getcleanDataConfigByClient(this.cleanDataConfigForm.controls.client.value.key);      
                     
    }
  }

  maintainClientState(client){  
   
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getPreviousState();
      this.setTitle();
    }

}
