import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { ToastService } from "src/app/core/services/toast.service";
import { Subscription } from "rxjs";
import { FileService } from "src/app/files/services/file.service";
import { TransformModel, JobsLogModel } from "../../models/tranform.model";
import { FormGroup, FormControl } from "@angular/forms";
import { FormsModel } from "src/app/models/forms.model";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange, getCurrentDate } from "src/app/utils/dates";
import * as moment from "moment";
import {isEmpty,trim} from 'lodash';
import { CookieService } from "ngx-cookie-service";
import { TransformPermission } from "../../models/transformPermission.model";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-transform-list",
  templateUrl: "./transform-list.component.html",
  styleUrls: ["./transform-list.component.scss"],
})
export class TransformListComponent implements OnInit {
  activeRoute$: Subscription;
  transformList: TransformModel[] = [];
  transformData:TransformModel[]=[];
  visible = false;
  headerForm = new FormGroup({
    client: new FormControl(),
    globalJob:new FormControl()
  });
  modalFormModel: FormsModel = null;
  modalForm: FormGroup = null;

  logs: JobsLogModel[] = [];
  selectedJob: any;
  applicationList: any;
  modalHeader: string;
  dataTransferModel:DataTransferModel;
  currentPage:string='/transform-list'
  exeResponse:any;
  rolePermission:TransformPermission;
  editable:boolean;
  executable:boolean;
  viewTranformList:boolean;
  cloneTransform:boolean;
  showUniversalJob:boolean;
  flag=false;
  applications:any;
  rolePermissionVal:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private fileService: FileService,
    private loader: LoaderService,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>
  ) {
    this.modalForm = new FormGroup({
      client: new FormControl(),
    });
  }

  ngOnInit(): void {
   this.buttonShowHidePermission();
  }

  getApplications() {
    this.fileService
      .getAllApplicationByUser()
      .subscribe(
        (data) =>{
          (this.applicationList = reMapForAutoComplete(
            data,
            "applicationId",
            "name"
          ))
        },
        (err) => {
          this.toast.showError(err.error.message, "error");
        }
      );
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getTransformList(id, loadType) {
    // if(loadType==='onChange'){
    //   this.maintainClientState();
    // }
    this.transformList=[];
    console.log(this.viewTranformList);
    if(this.viewTranformList){
    if(this.flag==true){
      this.getGlobalJobs();
    }else{
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    this.loader.showLoader();
    this.logs = [];
    this.fileService.getTransformListByClientAndGlobalJob(id,0).subscribe(
      (res) => {
        if (res && res["status"] === 200) {
          this.transformData = res["data"];
          for(let i=0;i<this.transformData.length;i++){
            for(let j=0;j<this.applications.length;j++)
            if(this.transformData[i].application_id===this.applications[j].applicationId){
            this.transformList.push(this.transformData[i]);
            }
          }
        }
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError("Server Error", "Unable to fetch data");
      }
    );
  }
}
else{
  if(this.headerForm.controls.client.value!==null && this.headerForm.controls.client.value.key!=='' && !this.viewTranformList){
    console.log(this.cookieService.get("viewTransformList"));
    if(this.cookieService.get("viewTransformList")==="false"){
      this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
    }
    
  }
  
}
}
  getLogs(id) {
    this.logs = [];
    this.loader.showLoader();
    this.fileService.getTransformLogByJob(id).subscribe(
      (data) => {
        this.logs = data;
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
        // this.toast.showError("Server Error", "Unable to fetch data");
        this.toast.showError(err.error.message, "error");
      }
    );
  }
  doAction(type) {
    if (type === "yes") {
    this.visible = false;
  }
}
  showPopup(data) {
    this.modalHeader = "Execute";
    this.modalFormModel = null;
    this.selectedJob = data;
   if(data.global_job===1){
    //const dataVal="{\"clientid\":"+this.headerForm.controls.client.value.key+"}";
    const params = JSON.parse(data.job_parameter);
    const keys = Object.keys(params);
    this.createModelFromParams(params);
    this.visible = true;
   }else{
    const params = JSON.parse(data.job_parameter);
    const keys = Object.keys(params);
    if (keys && keys.length <= 0) {
      this.executeJob(data.job_id);
    } else {
      this.createModelFromParams(params);
      this.visible = true;
    }
  }
  }
  showClonePopup(data) {
    this.visible = true;
    this.modalHeader = "Clone";
    this.selectedJob = data;
    const defaultMap = {
      key: this.headerForm.controls.client.value.key,
      value: this.headerForm.controls.client.value.value,
    };
    // this.modalForm.get("client").patchValue({
    //   key: this.headerForm.controls.client.value.key,
    //   value:this.headerForm.controls.client.value.value,
    // });
    console.log(defaultMap)
    
    this.modalFormModel = {
      controls: [
        { name: "name", type: "text", label: "Name *",placeholder:"Enter Name", defaultValue: this.selectedJob.job_name+' '+moment.utc().format('ddd MMM DD YYYY HH:mm:ss z')},
        {
          name: "application_id",
          type: "dropdown",
          options: this.applicationList,
          label: "Application *",
          defaultValue:{"key":this.selectedJob.application_id,"value":this.getName(this.selectedJob.application_id)}
        },
        { name: "description", type: "multiline", label: "Description *",placeholder:"Enter Description",defaultValue:this.selectedJob.job_description },
      ],
    };
    this.modalForm.controls.client.setValue(defaultMap)
}
  createModelFromParams(params) {
    this.modalFormModel = { controls: [] };
    for (let k in params) {
    if(k==="clientid"){
      this.modalFormModel.controls.push({
        name: k,
        type: "text",
        label: k,
        defaultValue: this.headerForm.controls.client.value.key,
      });
    }else{
      this.modalFormModel.controls.push({
        name: k,
        type: k === "asofdate" ? "date" : "text",
        label: k,
        defaultValue: params[k],
      });
    }
  }
  }
  getModalObject(form) {
    this.modalForm = form;
    if (this.modalHeader.toLowerCase() === "clone") {
      this.modalForm.addControl("client", new FormControl());
    }
  }
  executeJob(jobId, params = {}) {
    this.loader.showLoader();
    this.logs = [];
    this.fileService.executeJobById(jobId, params).subscribe(
      (data) => {
        this.exeResponse=data;
       // if (data && data["status"] === 200) {
        if (this.exeResponse && this.exeResponse.status !== 200) {      
          this.toast.showError(data["msg"], 'error'); 
          this.visible=false;        
        }  
       if (this.exeResponse && this.exeResponse.data.data.final_job_status === "Success") {      
          this.toast.showInfo("Job Status", data["msg"]); 
          this.visible=false;        
        } else {    
             
          this.toast.showError("Execute Error", "Error executing selected job");
          this.visible=false;  
        }
        this.loader.hideLoader();
        this.selectedJob = this.modalFormModel = null;       
      },
      (err) => {
        this.loader.hideLoader();
        this.visible=false;
        this.toast.showError("Execute Error", "Error executing selected job");
        this.selectedJob = this.modalFormModel = null;       
      }
    );
  }
  cloneJob(clientId, jobId, params) {
    let valid=false;
    if(params.client!==null && params.application_id!==null && params.description!==null && params.name!==null){
     
      valid=true;
    }
    
    let nameSize:number=trim(params.name).length;
    if(nameSize>250 || nameSize===0 ){  
      this.toast.showInfo('error','Job name mandatory & size should be 250 character');
      valid=false;
      return;
    }

    
    if(valid){
      this.loader.showLoader();
    const { name, description, application_id } = params;
    clientId=params.client.key;
    this.fileService
      .cloneJobById(clientId, jobId, application_id.key, name, description)
      .subscribe(
        (data) => {
          if (data && data["status"] === 200) {
            this.toast.showInfo("Clone Status", data["msg"]);
            this.getTransformList(this.headerForm.controls.client.value.key, 'Dummy')
            this.visible=false;
          } else {
            this.toast.showError("Error", data["msg"]);
            this.visible=false;
          }
          this.loader.hideLoader();
          this.selectedJob = this.modalFormModel = null;
          
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showError("Error", "Error while cloning selected job");
          this.selectedJob = this.modalFormModel = null;
          
        }
      );
    }else{
     
      this.toast.showWarn('error','All fields are mandatory');
    }
  }

  navigateToPage(data: any) {
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    let page='transform';
   
    const fileData = {
      clientId: this.headerForm.controls.client.value.key,
      applicationId:data.d.application_id,
      clientName:this.headerForm.controls.client.value.value,
      applicationName:data.app,
      jobId:data.d.job_id,
      pageName:this.currentPage
      
    };
    
    this.router.navigate([page], {
      queryParams: { ...fileData },
    });
  }

  deleteJob(data){  
    this.loader.showLoader();
    if(this.rolePermission==undefined || this.rolePermission.transformListDelete){
    this.fileService.deleteTransformation(data)
    .subscribe(
      data=>{
        this.toast.showSuccess('Job deleted','success');
        this.getTransformList(this.headerForm.controls.client.value.key, 'DeleteJob')
        this.loader.hideLoader();
      },
      error=>{
        this.loader.hideLoader();
        this.toast.showError('server error','error');
      }
    )
    this.loader.hideLoader();
  }else{
    this.loader.hideLoader();
    this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
  }
}

  execute(){
    if (this.modalHeader.toLowerCase() === "execute") {
      this.executeJob(this.selectedJob.job_id, this.modalForm.value);
      //this.visible=false;
    } else {
      this.cloneJob(
        this.headerForm.value.client.key,
        this.selectedJob.job_id,
        this.modalForm.value
      );
     // this.visible=false;
    }
  }

  getName(id) {
    try {
      if (this.applicationList && this.applicationList.length > 0) {
        return this.applicationList.find((d) => d.key === id).value.toUpperCase();
      } else {
        throw "";
      }
    } catch {
      return null;
    }
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               this.getTransformList(this.headerForm.get("client").value.key, 'onLoad');
    }
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.getTransformList(this.headerForm.get("client").value.key, 'onLoad');
    //     }
    //   });
  }

  maintainClientState(){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: this.headerForm.controls.client.value.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: this.headerForm.controls.client.value.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }
 
  
  getGlobalJob(){
  console.log("Button clicked");
  if(this.viewTranformList){
  if(this.flag==true){
    this.flag=false;
    this.getTransformList(this.headerForm.controls.client.value.key,0)
  }else{
    this.flag=true;
    this.transformList=[];
    this.getGlobalJobs();
  }
}
else{

  this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
}
}
  
  getGlobalJobs(){
    this.transformList=[];
    this.fileService.getTransformListByClientAndGlobalJob(this.headerForm.controls.client.value.key,1).subscribe(
       (res) => {
         if (res && res["status"] === 200) {
           this.transformData = res["data"];
           for(let i=0;i<this.transformData.length;i++){
            for(let j=0;j<this.applications.length;j++)
            if(this.transformData[i].application_id===this.applications[j].applicationId){
            this.transformList.push(this.transformData[i]);
            }
          }
         }
         this.loader.hideLoader();
       },
       (err) => {
         this.loader.hideLoader();
         this.toast.showError("Server Error", "Unable to fetch data");
       }
     );
   }

   rolePermissionTransform(){

    this.fileService .userRoleTransform().subscribe(
     (data) =>{

          (this.rolePermission = data)
          console.log("this.rolePermission  ",data);
          this.editable=data.editable;
          this.executable=data.executable;
          this.viewTranformList=data.viewTransformList;
          this.cloneTransform=data.cloneTransform;
          this.showUniversalJob=data.universalJob;
          if(this.viewTranformList){
            this.cookieService.set('viewTransformList',"true");
          }else{
            this.cookieService.set('viewTransformList',"false");
          }
          this.getPreviousState();
          //console.log("edit permission  ",this.editable);
         // console.log("execute permission  ", this.executable);
         // console.log("cloneTransform permission  ",this.cloneTransform);
         // console.log("showUniversalJob permission  ",this.showUniversalJob);
        }

      );

     
  }

  getAllMappedApplication(){
  this.fileService.getAllMappedApplication()
        .subscribe(
          data=>{
            this.applications =data;
            console.log("return mapped values  ",this.applications)  
    })
}

buttonShowHidePermission(){
  this.fileService.buttonShowHidePermission().subscribe(
    (res) =>{ 
         this.rolePermissionVal = res  
         this.callNgOnInitMethods();
 }
  )};

  callNgOnInitMethods(){
   this.rolePermissionTransform();  
    this.setTitle();
    this.getApplications();
    //this.getPreviousState();
    this.getAllMappedApplication();
  }
}
