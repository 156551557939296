import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-transform-log",
  templateUrl: "./transform-log.component.html",
  styleUrls: ["./transform-log.component.scss"]
})
export class TransformLogComponent implements OnInit {
  @Input() data: any[];
  cols: any[];
  constructor() {
    this.cols = [
      { field: "jobStatus", header: "job status" },
      { field: "actionType", header: "action type" },
      { field: "startTime", header: "start time" },
      { field: "endTime", header: "job end time" },
      { field: "stepMessage", header: "step message" }
    ];
  }
  ngOnInit(): void {}
}
