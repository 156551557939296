import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NavigationStart, Router, ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map, filter } from "rxjs/operators";
import { FormGroup, FormControl } from "@angular/forms";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { LoaderService } from "src/app/loader/services/loader.service";
import { FileService } from "src/app/files/services/file.service";
import { FieldLayoutModel } from "../../models/file-layout.model";
import { ToastService } from "src/app/core/services/toast.service";
import { RulesService } from "src/app/ingestion-rules/services/rules.service";
import { TitleService } from "src/app/core/services/title.service";
import { FieldMappingsTableComponent } from "src/app/files/components/field-mappings-table/field-mappings-table.component";
import { ObservalbleDataService} from 'src/app/dashboard/services/observalble-data.service';
import {DataTransferModel} from 'src/app/dashboard/models/dataTransfer.model';
import { currentMonthRange } from "src/app/utils/dates";
import { CookieService } from "ngx-cookie-service";
import { AliasModel } from "../../models/aliasName";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-mapping",
  templateUrl: "./mapping.component.html",
  styleUrls: ["./mapping.component.scss"],
})
export class MappingComponent implements OnInit, OnDestroy {
  @ViewChild(FieldMappingsTableComponent, { read: false, static: false })
  fieldMappingsTableComponent: FieldMappingsTableComponent;
  state$: Observable<object>;
  filesList: any[] = [];
  headerForm: FormGroup = null;
  clientId: any = null;
  layoutFields: any[];
  fileLayout: any;
  excelTemplate :any;
  fieldMappings: AliasModel[];
  activeRoute$: Subscription;
  paramMap$: Subscription;
  mapData = new Map();
  dataTransferModel:DataTransferModel;
  showBackButton:boolean=false;
  toDate:string;
  fromDate:string;
  pageName:string;
  isRecommendation:boolean=false;
  recommendationSize:number=0;
  // loanFieldsList:string[];
  fieldMappingsdata:any[];
  rolePermission:any;

  constructor(
    private toast: ToastService,
    private fileService: FileService,
    private ruleService: RulesService,
    private loader: LoaderService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    public observalbleDataService:ObservalbleDataService,
    private router: Router,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(),
      file: new FormControl(),
    });
  }
  ngOnInit(): void {
  this.buttonShowHidePermission();
  }

  ngOnDestroy() {
    try{
      this.activeRoute$.unsubscribe();
      this.paramMap$.unsubscribe();
    }catch(ex){
      console.log(ex);
    }
    
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getFiles(id) {
    //this.maintainClientState(id);
    console.log("inside file ",id)
    this.clientId = id;
    this.fieldMappings = [];
    this.excelTemplate = null;
    //this.headerForm.get("file").reset();
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  }
  getFileLayout(file) {
    this.loader.showLoader();
    this.fieldMappings = [];
    const { client } = this.headerForm.value;
    this.getRawExcelData(client.key, file.key);
    this.fileService.getFileLayoutByClientFile(client.key, file.key).subscribe(
      (data) => {
        if (data === null) {
          this.toast.showInfo("No Layout defined", "Select another file");
        } else {
          this.fileLayout = data;
          this.getAliasList(this.fileLayout.clientApplicationFileId);
          this.getFieldLayout(this.fileLayout.clientFileLayoutId);
          this.getFieldMapping(this.fileLayout.clientApplicationFileId);
          // this.getLoanColumnsByClientFileId(this.fileLayout.clientApplicationFileId);
        }
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getFieldLayout(id) {
    this.loader.showLoader();
    this.fileService.getFileLayoutById(id).subscribe(
      (data) => {
        this.layoutFields = data.map((d) => ({
          key: d.clientFileLayoutFieldId,
          value: `${d.fieldName} (${d.fieldType})`,
        }));
        this.layoutFields.splice(0,0,{key:null,value:'Select Layout Field'})
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getFieldMapping(id) {
    this.loader.showLoader();
    this.isRecommendation=false;
    this.fileService.getFieldMappingsById(id,'Mapping').subscribe(
      (data) => {
        //this.fieldMappings = data;
       // console.log("here again");
        this.getLoanColumnsByClientFileId(
          this.fileLayout.clientApplicationFileId,
          data
        );
        
        const objects1 = Object.keys(data).map((key) => data[key]);
        console.log(objects1.length);
        if(objects1.length>0){
          console.log(objects1[0].clientFileMappingId);
          if(objects1[0].clientFileMappingId===0){
            this.isRecommendation=true;
            this.recommendationSize=objects1.length;
          }
        }
        objects1.forEach((element) => {
          
          let arry = [];
          arry.push(
            element.fieldName,
            element.clientFileLayoutFieldId,
            element.clientApplicationFileId,
            element.tableName,
            element.clientFileMappingId,
            element.alias

          );
          this.mapData.set(element.fieldName, arry);
        });

        this.loader.hideLoader();
       
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getRawExcelData(client, file) {
    this.loader.showLoader();
   // this.excelTemplate = null;
    this.ruleService.getRawData(client, file).subscribe(
      (data) => {
        this.excelTemplate = Object.values(data);
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }


  updateField(data) {
    const arry = [
      data.fieldName,
      data.layoutFieldId,
      this.headerForm.controls.file.value.key,
      "loans",
      data.primaryKey,
      data.alias
    ];

var array=Array.from(this.mapData.values());
  for(let a=0;a<array.length;a++){
    var arr=Array.from(array[a]); 
    if(arr[1]===data.layoutFieldId && arr[1]!=null){
     this.toast.showWarn("Already mapped with "+arr[0],"warn");
    }
  }  
    this.mapData.set(data.fieldName, arry);
    console.log("update", this.mapData);
  }

  saveMapping() {
    const dataToSave = [];
    
    if(this.fieldMappingsTableComponent.isRecomedation===true){
    for (let entry of this.mapData.entries()) {
      const saveData = entry[1];
      const dataMapping = {
        clientFileMappingId: saveData[4],
        clientApplicationFileId: saveData[2],
        clientFileLayoutFieldId: saveData[1],
        tableName: saveData[3],
        fieldName: saveData[0],
        createdBy: 0,
        alias:saveData[5],
        recommendation:1
      };
      dataToSave.push(dataMapping);
    }    
  }else{
      for (let entry of this.mapData.entries()) {
        const saveData = entry[1];
        const dataMapping = {
          clientFileMappingId: saveData[4],
          clientApplicationFileId: saveData[2],
          clientFileLayoutFieldId: saveData[1],
          tableName: saveData[3],
          fieldName: saveData[0],
          createdBy: 0,
          alias:saveData[5],
          recommendation:0
        };
        dataToSave.push(dataMapping);
    }
    }
    this.loader.showLoader();
    this.fileService.saveMapping(dataToSave).subscribe(
      (res) => {
        this.toast.showSuccess("success", "Data Saved");
        this.loader.hideLoader();
        this.fieldMappings = [];
        this.layoutFields = [];
        this.fieldMappingsTableComponent.fields = [];
        this.mapData.clear();
        // this.fieldMappingsTableComponent.addArrayFields(0);
        // this.getFieldLayout
        // this.getFieldMapping(this.headerForm.controls.file.value.key);
        const fileData = {
          key: this.headerForm.controls.file.value.key,
          value: this.headerForm.controls.file.value.value,
        };
        this.getFileLayout(fileData);
      },
      (err) => {
        this.toast.showError(err.error.message,"error")
        this.loader.hideLoader();
      }
    );
  }

  getLoanColumnsByClientFileId(id, dataVal) {
    this.loader.showLoader();
    this.fileService.getLoanColumnsByClientFileId(id).subscribe((data) => {
      // this.loanFieldsList=_.toArray(data);
      // this.fieldMappings=[...this.fieldMappings,...data];
     // console.log("here again");
      this.fieldMappings = [...dataVal, ...data];
      this.getAliasName(this.fieldMappings);
     
      this.loader.hideLoader();
      //console.log(this.fieldMappings, " data here");
    });
    this.loader.hideLoader();
  }

  goToPage(){
    if(this.showBackButton){
    this.dataTransferModel={
           clientFileId:this.headerForm.controls.file.value.key,
           clientId: this.headerForm.controls.client.value.key,
           isRedirected:1,
           applicationId:0,
           toDate:null,
           fromDate:null,
           clientName:this.headerForm.controls.client.value.value,
           page:this.pageName,
           fileName:this.headerForm.controls.file.value.value,
           workflowName:null,
          workflowId:0,
          dataType:null,
          dataTypeId:0
    }
   // console.log(this.dataTransferModel);
    this.observalbleDataService.addToInventory(this.dataTransferModel);
    this.router.navigate([this.pageName]);
  
  }
}

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               this.clientId = this.headerForm.controls.client.value.key;
              
    }
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0){
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //     }
    //     this.clientId = this.dataTransferModel.clientId;
    //     this.fieldMappings = [];
    //     this.excelTemplate = null;
    //     this.headerForm.get("file").reset();
    //   });
  }

  maintainClientState(client){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }


  getAliasList(id) {
    this.fileService.getAliasData(id,'Mapping').subscribe(
      (data) => {
        this.fieldMappingsdata = data;      
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }


getAliasName(fieldMappings){
for(let i=0;i<fieldMappings.length;i++){
 // console.log("count of i "+i);
  for(let j=0;j<this.fieldMappingsdata.length;j++){
   // console.log("count of j "+j);
    if(fieldMappings[i].fieldName===this.fieldMappingsdata[j].datalakeColumns){
    //console.log("field match ::"+fieldMappings[i].fieldName);
      this.fieldMappings[i].alias=this.fieldMappingsdata[j].alias;
    this.fieldMappings[i].tableName=this.fieldMappingsdata[j].datalakeTables;
    break;
    }else{
      continue;
    }
  }

}
//console.log("list data"+fieldMappings);
}
  
updateOneRow(data){
console.log(data);
this.fileService.saveOneRow(data).subscribe(
  (res) => {
    this.toast.showSuccess("success", "Data Saved");
    this.loader.hideLoader();
    this.fieldMappingsTableComponent.enableEdit='';
    // this.fieldMappings = [];
    // this.layoutFields = [];
    // this.fieldMappingsTableComponent.fields = [];
    // this.mapData.clear();
    // // this.fieldMappingsTableComponent.addArrayFields(0);
    // this.getFieldLayout
    // this.getFieldMapping(this.headerForm.controls.file.value.key);
    const fileData = {
      key: this.headerForm.controls.file.value.key,
      value: this.headerForm.controls.file.value.value,
    };
    this.getFileLayout(fileData);
    this.getAliasList(this.fileLayout.clientApplicationFileId);
    this.getAliasName(this.fieldMappings);
  },
  (err) => {
    this.toast.showError(err.error.message,"error")
    this.loader.hideLoader();
  }
);

}

buttonShowHidePermission(){
  this.fileService.buttonShowHidePermission().subscribe(
    (res) =>{ 
         this.rolePermission = res  
         this.callNgOnInitMethods();
 }
  )};

  callNgOnInitMethods(){
   this.setTitle();
    this.paramMap$ = this.activeRoute.queryParamMap.subscribe(
      (par) => {      
        if (par.has("clientId") && par.has("clientName")) {      
          this.headerForm.get("client").patchValue({
            key: par.get("clientId"),
            value: par.get("clientName"),
          });
          this.getFiles(par.get("clientId"));
        }
        if (par.has("fileName") && par.has("clientFile")) {
          const fileData = {
            key: par.get("clientFile"),
            value: par.get("fileName"),
          };
          this.headerForm.get("file").setValue(fileData);
          this.getFileLayout(fileData);
        }

          if(par.has("toDate") && par.has("fromDate")){
            this.toDate=par.get("toDate")
            this.fromDate=par.get("fromDate")
            this.showBackButton=true;
          }

          if(par.has("pageName")){
            this.pageName=par.get("pageName");   
          }
        },
        (err) => {}
        );

    if(this.showBackButton!==true){
      this.getPreviousState();
     }
  }
}
