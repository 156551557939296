import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { TransformModel } from 'src/app/dashboard/models/tranform.model';
import { FormsModel } from 'src/app/models/forms.model';


@Component({
  selector: 'app-setup-table-new',
  templateUrl: './setup-table-new.component.html',
  styleUrls: ['./setup-table-new.component.scss']
})
export class SetupTableNewComponent implements OnInit {
  @Input() data:any= [];
  @Input() transformJobDetailsData:any=[];
  @Input() customJobDetailsData:any=[];
  @Output() editWorkflowJobDetailsData=new EventEmitter();
  @Output() deleteJob=new EventEmitter();
  @Output() paramEmit=new EventEmitter();
  @Output() rowChangeEmit=new EventEmitter();
  @Input() transformList: TransformModel[] = [];
  @Input() permission:any;
  modalFormModel: FormsModel = null;
  modalForm1: FormGroup = null;
  modalForm: FormGroup = null;
  

  cols: { field: string; header: string }[];
  transformcols: { field: string; header: string }[];
  customcols:{ field: string; header: string }[];
  visible:boolean=false;
  workflowDetailId:any=null;
  workflowDetailParamId:any=null;
  paramHeader="Add Param";
  fromDt:string="";
  selectedDate:any=null;
  selectedJob: any;
  visibleTransform:boolean=false;
  jobData:any;
 


  constructor(private datePipe:DatePipe,
    private toast:ToastService) { 

    this.modalForm1 = new FormGroup({
      asOfDate: new FormControl(null),
      
      
    });
    this.cols = [
      { field: "jobName", header: "Job" },
      { field: "jobType", header: "Type" },
      { field: "fileName", header: "File" },
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },    
      { field: "enabled", header: "Enabled" },
      { field: "continueOnError", header: "Continue On Error" }

    ];

    this.transformcols = [
      { field: "jobName", header: "Transform Job" },
      { field: "jobType", header: "Type" },
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },
     
      { field: "enabled", header: "Enabled" },
      { field: "continueOnError", header: "Continue On Error" }
    ];

    this.customcols = [
      { field: "jobName", header: "Custom Job" },
      { field: "target", header: "Target Api" },
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },    
      { field: "enabled", header: "Enabled" },
      { field: "continueOnError", header: "Continue On Error" }
    ];

    
  }

  ngOnInit(): void {
  }

  editWorkflowJobDetails(data){
    console.log(data)
    this.editWorkflowJobDetailsData.emit(data);
  }

  deleteIngestJob(data){
    console.log(data)
    this.deleteJob.emit(data);
  }

  doAction(data){
    console.log('inside do action1222');
    this.visible=false;
    this.workflowDetailId=null;
    this.fromDt=""
    this.selectedDate=null;
    this.visibleTransform=false;
    this.jobData=null;
  }

  onDateChange(e){
    console.log('on date change ',e)
    const valt=this.datePipe.transform(e,'yyyy-MM-dd')
    console.log('on date changegfgf ',valt)
    this.selectedDate=valt;
  }

  onDateBlur(e){
    console.log('on date change ',e.target.value)
    const valt=this.datePipe.transform(e.target.value,'yyyy-MM-dd')
    console.log('on date changegfgf ',valt)
    this.selectedDate=valt;
  }

  showParamValue(data){
    this.jobData=data;
    this.visibleTransform=false;
    this.visible=false;
    this.workflowDetailId=data.workflowDetailId;
    this.workflowDetailParamId=data.workflowDetailParamId;
    //console.log("hello 2",this.jobData);
    //this.modalForm.controls.asOfDate.setValue(null);
    if(data.jobType==='Ingest'){
    this.fromDt="";
     //console.log("hello 2",this.jobData);
    if(data.workflowDetailParamId!==null && data.workflowDetailParamId>0){
     // console.log("hellooo "+data);
     // this.fromDt="Current AsOfDate :"+this.datePipe.transform(data.asOfDate,'MM-dd-yyyy');
     this.fromDt="Current AsOfDate :"+this.datePipe.transform(data.asOfDate,'MM/dd/yyyy');
     
      console.log(this.fromDt);
      
    }
    
    if(data.asOfDate!==null){
     // console.log("controls :",this.modalForm1.controls)
      this.modalForm1.controls.asOfDate.setValue(new Date(data.asOfDate));
    }
    this.visible=true;
   
  }
  if(data.jobType==='Transform'){
  //  console.log(this.transformList);
   // console.log(data);
   // this.visible=true;
   this.transformList.forEach(item=>{
     if(item.job_id===data.jobId){
       if(this.jobData.transformParams!==null){
        // console.log("this.jobData.transformParams ",JSON.stringify(this.jobData.transformParams));
        // console.log("this.jobData.job_parameter ",item.job_parameter)
        this.selectedJob=JSON.stringify(this.jobData.transformParams);
       }else{
        //console.log("item.job_parameter ",item.job_parameter)
        this.selectedJob=item.job_parameter;
       }
     
     }
     
   })
   this.modalFormModel = null;
  
   const params = JSON.parse(this.selectedJob);
   const keys = Object.keys(params);
   if (keys && keys.length <= 0) {
     this.toast.showInfo("info","no parameter available")
   } else {
     this.createModelFromParams(params,data.asOfDate);
     this.visibleTransform = true;
   }
  }
  }

  createModelFromParams(params,asOfDate) {
    this.modalFormModel = { controls: [] };
    for (let k in params) {
      //console.log('vvvvvvv ',params[k]);
      let valu=params[k];
      if(k==="asofdate" && asOfDate!==null){
        valu=this.datePipe.transform(asOfDate,'yyyy-MM-dd')
        //valu=this.datePipe.transform(asOfDate,'MM/dd/yyyy');
      }
      this.modalFormModel.controls.push({
        name: k,
        type: k === "asofdate" ? "date" : "text",
        label: k,
        defaultValue: valu,
      });
    }
  }

  addParamToJob(data){
   
    let paramValue=null;
    if(this.jobData.jobType==='Ingest'){
     // console.log(" here ");
     // console.log(" here ",this.modalForm.controls.asOfDate.value);
       if(this.modalForm1.controls.asOfDate && this.modalForm1.controls.asOfDate.value!==null){
       this.selectedDate=this.datePipe.transform(this.modalForm1.controls.asOfDate.value,'yyyy-MM-dd');
      
       }

      if(this.selectedDate===null || this.selectedDate===undefined){
        this.toast.showInfo('error','asOfDate is required');
        return;
      }
      paramValue={
        "clientid":this.jobData.clientId
      };
    }

    if(this.jobData.jobType==='Transform'){
     paramValue=this.modalForm.value;
    // console.log("param value ",paramValue)
     if(paramValue===null || paramValue===undefined){
       paramValue={
         "clientid":this.jobData.clientId
       };
     }
    }
   

   const paramVal={
     "workflowDetailParamId":this.workflowDetailParamId!==null?this.workflowDetailParamId:0,
     "workflowDetailId":this.workflowDetailId,
     "asOdDate":this.selectedDate,
     "params":paramValue
   }
   console.log("data 1234   :"+this.jobData.clientId);
   this.paramEmit.emit(paramVal);
   this.doAction(data);
  }

  onRowChange(e,type){

    if(type==='System'){
      this.rowChangeEmit.emit(this.data);
    }
    if(type==='Custom'){
      this.rowChangeEmit.emit(this.customJobDetailsData);
    }

    
    console.log(this.data);
  }

  getModalObject(form) {
    this.modalForm = form;
  }

  

  
}
