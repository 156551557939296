import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationRuleService } from 'src/app/dashboard/services/validation-rule.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { UserService } from 'src/app/user/services/user.service';

@Component({
  selector: 'app-validation-rules-list',
  templateUrl: './validation-rules-list.component.html',
  styleUrls: ['./validation-rules-list.component.scss']
})
export class ValidationRulesListComponent implements OnInit {
  cols: { field: string; header: string }[];
  @Input() data;
  @Input() ruleLog;
  @Output() apply = new EventEmitter;
  @Output() edit = new EventEmitter;
  @Output() deactivate=new EventEmitter;
  @Output() logData=new EventEmitter;
  @Input() permission:any;
  visible:boolean=false;
  dataValue:any;
  status;
  headerData:any;
  constructor(
    private loader:LoaderService,
    private validationRuleService:ValidationRuleService
  ) {

    this.cols = [
      { field: "ruleName", header: "Rule Name" },
      { field: "appliesTo", header: "Applied To" },
      { field: "userName", header: "Created By" },
      { field: "asOfDate", header: "Effective Date" },
      { field:"modifiedAt", header: "Inactive Date"},
      { field: "status", header: "Status" }
    ];
    this.status=["Active","Inactive"].map((d)=> ({key:d,value:d}));
   }

  ngOnInit(): void {
  }

  editRule(rowData){
this.edit.emit(rowData);
  }

  applyRule(rowData){
   this.apply.emit(rowData);
  }

  actionDo(str){
    //console.log("yes delete do "+this.dataValue);
    this.deactivateRule(this.dataValue);
    this.dataValue=null;
    this.visible=false;
   }


  deactivateRule(data){
    this.deactivate.emit(data);
  }

showPopup(str,data){
   if(data.status==="Inactive"){
    this.headerData="Do you want to Activate?";
    this.visible=true;
    this.dataValue=data;
   }else{ 
  this.headerData="Do you want to Deactivate?";
  this.visible=true;
  this.dataValue=data;
   } 
}

hidePopup(e){
  this.visible=false;
  this.dataValue=null;

}

getLog(data){
this.logData.emit(data);
}

}
