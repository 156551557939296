import { Component, Input, OnInit } from '@angular/core';
import { SchedulerLogModel } from 'src/app/dashboard/models/schedulerLog.model';

@Component({
  selector: 'app-scheduler-log-table',
  templateUrl: './scheduler-log-table.component.html',
  styleUrls: ['./scheduler-log-table.component.scss']
})
export class SchedulerLogTableComponent implements OnInit {
  @Input() data: SchedulerLogModel[] = [];
  columns = ["Refrence No","Status", "Status Message", "Email Sent","Created At"];

  constructor() { }

  ngOnInit(): void {
  }

}
