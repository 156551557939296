import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
/**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http:HttpClient) { }

  getArchiveFile(archieveData){
    return this.http.post<any[]>(environment.baseUrl + "/apis/client-portal/getArchieveFile",archieveData);
  }
}
