import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { UiModule } from "../ui/ui.module";
import { ExcelModule } from "../excel/excel.module";
import { FilesModule } from "../files/files.module";
import { UserModule } from '../user/user.module';
import {UserCompModule} from '../userComp/userComp.module';
import { DatePipe } from '@angular/common';
import { DualListModule } from '../roles-dual/roles-dual.module';
import { AlfredLogsComponent } from './components/alfred-logs/alfred-logs.component';
import { AlfredComponent } from "./pages/alfred/alfred.component";
import { FormsyModule } from "../formsy/formsy.module";
import { FormsModule }   from '@angular/forms';

@NgModule({
  declarations: [
    
    AlfredComponent,
    AlfredLogsComponent,
   
  ],
  imports: [
    SharedModule,
    UiModule,
    //DashboardRoutingModule,
    ExcelModule,
    FilesModule,
    UserCompModule,
    DualListModule,
    FormsyModule,
    FormsModule
    
  ],
  providers: [DatePipe]
})
export class DashboardModule1 {}
