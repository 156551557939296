import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-client-file-tab',
  templateUrl: './client-file-tab.component.html',
  styleUrls: ['./client-file-tab.component.scss']
})
export class ClientFileTabComponent implements OnInit {

  @Input() data;
  @Input() multiple: boolean = false;
  @Output() viewRuleData=new EventEmitter();
  @Output() viewMappingData=new EventEmitter();
  cols: { field: string; header: string }[];

  constructor() { 
    this.cols = [
      { field: "clientFileName", header: "Name" },
      {field:"layoutFileName",header:"Layout"},
      { field: "desc", header: "Description" },
      { field: "fileType", header: "File Type" },
     
    

    ];

  }

  ngOnInit(): void {
  }

  viewRules(data){
     this.viewRuleData.emit(data);
  }

  viewMapping(data){
    this.viewMappingData.emit(data);
  }

}
