import { Action, createReducer, on } from "@ngrx/store";
import { storeUser, signOut, updateUser, storePermission } from "./auth.action";
export const featureKey = "user";
export const featureKeyPermission = "role";

export const initialState: any = null;

const _authReducer = createReducer(
  initialState,
  on(storeUser, (state, { data }) => data),
  on(updateUser, (state, { data }) => ({ ...state, ...data })),
  on(signOut, (state) => null),
  on(storePermission, (state, { permission }) => ({ ...state, ...permission })),
 );

//  const _authReducerPermission = createReducer(
//   initialState,
//   on(storePermission, (state, { data }) => data),
// );

export function authReducer(state: any, action: Action) {
  return _authReducer(state, action);
}

// export function authReducerPermission(state: any, action: Action) {
//   return _authReducerPermission(state, action);
// }