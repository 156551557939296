import { Component, OnInit, Input, Output ,EventEmitter} from '@angular/core';
import { WorkflowJobDetails } from 'src/app/dashboard/models/workflow.model';
// import { LoaderService } from 'src/app/loader/services/loader.service';
// import { Observable,Subscription, interval  } from 'rxjs';

@Component({
  selector: 'app-workflow-table',
  templateUrl: './workflow-table.component.html',
  styleUrls: ['./workflow-table.component.scss']
})
export class WorkflowTableComponent implements OnInit {
  // private updateSubscription: Subscription;

  @Input() data:WorkflowJobDetails[] = [];
  @Output() viewworkflowJobLog=new EventEmitter();
  @Output() runSingleJob=new EventEmitter();
  @Input() permission:any;
  
  cols: { field: string; header: string }[];

  
  constructor(
    // private loader:LoaderService,
  ) { 
    this.cols = [
      { field: "jobName", header: "Job Name" },
      { field: "jobType", header: "Job Type" },
      { field: "status", header: "Status" },
      { field: "trigger1", header: "Trigger" },
      { field: "continueOnError", header: "Continue On Error" },
      { field: "lastJobStatus", header: "Last Job Status" },
    ];
  }

  ngOnInit(): void {
  }

  viewworkflowJobLogs(data){
    // this.loader.hideLoader();
    this.viewworkflowJobLog.emit(data);

  }

  runJob(data){
      this.runSingleJob.emit(data);
  }

}
