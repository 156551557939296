import { NgModule } from "@angular/core";
import { FilesLogComponent } from "./components/files-log/files-log.component";
import { SharedModule } from "../shared/shared.module";
import { FilesListComponent } from "./components/files-list/files-list.component";
import { FileService } from "./services/file.service";
import { TransformLogComponent } from "./components/transform-log/transform-log.component";
import { TransformTableComponent } from "./components/transform-table/transform-table.component";
import { FieldsLayoutTableComponent } from "./components/fields-layout-table/fields-layout-table.component";
import { FieldsLayoutFormComponent } from "./components/fields-layout-form/fields-layout-form.component";
import { FieldMappingsTableComponent } from "./components/field-mappings-table/field-mappings-table.component";
import { ClientFileMasterListComponent } from './components/client-file-master-list/client-file-master-list.component';

@NgModule({
  declarations: [
    FilesLogComponent,
    FilesListComponent,
    TransformLogComponent,
    TransformTableComponent,
    FieldsLayoutTableComponent,
    FieldsLayoutFormComponent,
    FieldMappingsTableComponent,
    ClientFileMasterListComponent,
  ],
  imports: [SharedModule],
  exports: [
    FilesLogComponent,
    FieldsLayoutTableComponent,
    FilesListComponent,
    TransformLogComponent,
    TransformTableComponent,
    FieldsLayoutFormComponent,
    FieldMappingsTableComponent,
    ClientFileMasterListComponent,

  ],
  providers: [FileService]
})
export class FilesModule {}
